import React from 'react';
import { Route } from 'react-router-dom';

import Authorize from './authorize';

interface AreaProps {
  path: string;
  allow?: number[];
  component: React.ComponentType;
}

const Area: React.FC<AreaProps> = ({ path, allow, component: Component, ...props }) => (
  <Authorize allow={allow}>
    {() => <Route path={path} render={() => <Component {...props} />} />}
  </Authorize>
);

export default Area;
