import AdminMgtSvg from '../../assets/icon-admin-mgt.svg';

import Icon from './icon';
import { IconProps } from './icon';

export default class extends Icon {
  constructor(props: IconProps) {
    super(AdminMgtSvg, props);
  }
}
