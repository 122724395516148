import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import EmptyBase from '../../../common/empty';
import Flex from '../../../common/flex';

import ChartBase from './chart';
import Legend from './legend';
import { query } from './state/query';
import Table from './table';
import YearRange from './year-range';

interface GraphProps {
  showEmpty: boolean;
}

const Container = styled(Flex.Item)`
  ${breakpoint('lg')`
    padding-top: 1rem;
  `}
`;

const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('lg')`
    flex-direction: row;
  `}
`;

const Chart = styled(({ className }) => (
  <Flex.Item className={className} fill={true}>
    <ChartBase />
  </Flex.Item>
))`
  padding-right: 1rem;

  ${breakpoint('lg')`
    padding-right: 0;
    min-width: 80%;
    max-width: 85%;
  `}
`;

const HorizontalLegend = styled(({ className }) => (
  <Flex.Item className={className}>
    <Legend />
  </Flex.Item>
))`
  margin-bottom: 2rem;

  ${breakpoint('lg')`
    display: none;
  `}
`;

const VerticalLegend = styled(({ className }) => (
  <Flex.Item className={className}>
    <Legend />
  </Flex.Item>
))`
  display: none;
  padding-left: 1rem;
  min-width: 15%;
  max-width: 20%;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const Empty = styled(({ className, text }) => (
  <div className={className}>
    <EmptyBase text={text} />
  </div>
))`
  margin-top: 2rem;
`;

const Graph: React.FC<GraphProps> = ({ showEmpty }) => {
  const outputType = useObservable(() => query.outputType$, null);
  const legend = useObservable(() => query.legend$, []);
  const data = useObservable(() => query.data$, []);

  if (!outputType) {
    return null;
  }

  // Workout what to show in the data space
  let dataComponents = (
    <Empty text="No outputs available, please contact your Milliman consultant for help." />
  );

  if (data.length > 0) {
    dataComponents = (
      <>
        <Flex.Item>
          <YearRange />
        </Flex.Item>
        <Flex.Item>
          <Table />
        </Flex.Item>
      </>
    );
  }

  return (
    <>
      <Flex.Col>
        <Container>
          {legend.length === 0 && showEmpty && <Empty text="No scenario data available" />}
          {legend.length > 0 && (
            <>
              <ChartContainer>
                <HorizontalLegend />
                <Chart />
                <VerticalLegend />
              </ChartContainer>
            </>
          )}
        </Container>
        {legend.length > 0 && dataComponents}
      </Flex.Col>
    </>
  );
};

export default Graph;
