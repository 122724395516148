import React from 'react';
import { useObservable } from 'rxjs-hooks';

import Create from './create';
import List from './list';
import * as state from './state';

interface ScenariosProps {
  loading: boolean;
}

const Scenarios: React.FC<ScenariosProps> = ({ loading }) => {
  const createScenario = useObservable(() => state.createScenario$, false);
  const modelId = useObservable(() => state.modelId$, null);
  const scenarios = useObservable(() => state.scenarios$, []);

  if (loading) {
    return null;
  }

  return createScenario ? (
    <Create
      modelId={modelId}
      scenarios={scenarios}
      onClose={() => state.setCreateScenario(false)}
    />
  ) : (
    <List modelId={modelId} scenarios={scenarios} onCreate={() => state.setCreateScenario(true)} />
  );
};

export default Scenarios;
