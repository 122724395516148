import { EntityStore, StoreConfig } from '@datorama/akita';

import helperUtils from '../../../../utils/helper';
import storageUtils from '../../../../utils/storage';
import { GRAPH_TYPE_KEY } from '../../common/storage';
import { MultiOutputData, OutputData } from '../../outputs/state';

import { GraphTypeEntity, GraphTypeId, GraphTypeState } from './models';

const graphTypes: GraphTypeEntity[] = [
  {
    id: 'line',
    label: 'Line graph',
    enabled: true,
    multi: false,
  },
  {
    id: 'bar',
    label: 'Bar graph',
    enabled: true,
    multi: false,
  },
  {
    id: 'multi',
    label: 'Multiple outputs',
    enabled: true,
    multi: true,
  },
];

@StoreConfig({
  name: 'Projection Tool > Graph Types',
})
export class GraphTypeStore extends EntityStore<GraphTypeState, GraphTypeEntity> {
  constructor() {
    super({
      selectedId: null,
    });
  }

  initialize(outputs: OutputData[], multiOutputs: MultiOutputData[]): void {
    const hasOutputs = outputs && outputs.length > 0;
    const hasMultiOutputs = multiOutputs && multiOutputs.length > 0;
    const types = graphTypes.map(type => ({
      ...type,
      enabled: (type.id !== 'multi' && hasOutputs) || (type.id === 'multi' && hasMultiOutputs),
    }));

    this.set(types);
  }

  setSelectedId(id: GraphTypeId): void {
    this.update(state => {
      const types = state.ids.map(current => state.entities[current]);
      const selected = helperUtils.ensureSelection(
        types,
        type => type.id === id && type.enabled,
        types.find(type => type.enabled)
      );

      // Set the selected id
      const selectedId = selected ? selected.id : null;
      storageUtils.set(GRAPH_TYPE_KEY, selectedId);

      return {
        selectedId,
      };
    });
  }
}

export const store = new GraphTypeStore();
