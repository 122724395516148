const get = (key: string) => localStorage.getItem(key);
const set = (key: string, value: string) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

export default { get, set };
