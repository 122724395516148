import { Store, StoreConfig } from '@datorama/akita';

import { AdminManagementState } from './models';

@StoreConfig({
  name: 'Admin Management > All',
})
export class AdminManagementStore extends Store<AdminManagementState> {
  constructor() {
    super({
      newSaveable: false,
      newError: null,
      manageSaveable: false,
      manageError: null,
    });
  }

  setNewSaveable(saveable: boolean): void {
    this.update({
      newSaveable: saveable,
    });
  }

  setNewError(error?: string): void {
    this.update({
      newError: error,
    });
  }

  setManageSaveable(saveable: boolean): void {
    this.update({
      manageSaveable: saveable,
    });
  }

  setManageError(error?: string): void {
    this.update({
      manageError: error,
    });
  }
}

export const store = new AdminManagementStore();
