import React from 'react';
import { Redirect } from 'react-router-dom';
import { useObservable } from 'rxjs-hooks';

import { routes } from '../constants';
import { Profile } from '../models';
import * as profileState from '../state/profile';

interface AuthorizeProps {
  allow?: number[];
  children: (state: Profile) => React.ReactElement;
}

const Authorize: React.FC<AuthorizeProps> = ({ allow, children }) => {
  const profile = useObservable(() => profileState.profile$, null);

  // Render nothing if the profile has not loaded
  if (!profile) {
    return null;
  }

  // If access is granted then check the user has the required permissions
  if (allow && allow.indexOf(profile.userType) === -1) {
    return <Redirect to={routes.app.home} />;
  }

  return children(profile);
};

export default Authorize;
