import { Store, StoreConfig } from '@datorama/akita';

import { ProfileData, ProfileState } from './models';

@StoreConfig({
  name: 'App > Profile',
  idKey: 'uniqueId',
})
export class ProfileStore extends Store<ProfileState> {
  constructor() {
    super({});
  }

  initialize(data: ProfileData): void {
    let state: ProfileState = {
      uniqueId: null,
      userType: null,
      fullName: null,
      email: null,
      authenticated: false,
      clientIds: [],
    };

    // If a profile is provided, generate the state
    if (data) {
      state = {
        ...data,
        authenticated: true,
        clientIds: [],
      };
    }

    this.update(state);
  }
}

export const store = new ProfileStore();
