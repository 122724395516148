import { ColProps } from 'antd/lib/col';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../../common/content';
import Flex from '../../common/flex';
import FormBase from '../../common/form';
import theme from '../../theme';

interface FormProps {
  onSubmit: (ev: React.FormEvent<HTMLFormElement>) => void;
}

interface FieldProps {
  className?: string;
  label: string;
  layout?: {
    labelCol?: ColProps;
    wrapperCol?: ColProps;
  };
}

interface ErrorProps {
  className?: string;
  message: string;
  layout?: {
    wrapperCol?: ColProps;
  };
}

const Heading = styled(Content.SubHeading)`
  margin-top: 1rem;
`;

const TableListField = styled(({ className, ...props }) => (
  <div className={className}>
    <Field {...props} />
  </div>
))`
  .ant-form-item-label {
    display: none;

    ${breakpoint('md')`
      display: block;
      margin-top: 1.7rem;
    `};
  }
`;

const ErrorContainer = styled(FormBase.Item)`
  margin-bottom: 1rem !important;
`;

const ErrorMessage = styled.div`
  color: ${theme.color.cranberry};
  line-height: 1rem;
`;

const WarningMessage = styled.span`
  color: ${theme.color.cranberry};
  font-weight: ${theme.weight.bold};
`;

const Form: React.FC<FormProps> = ({ onSubmit, children }) => (
  <FormBase layout="horizontal" hideRequiredMark={true} onSubmit={onSubmit}>
    {children}
  </FormBase>
);

const Field: React.FC<FieldProps> = ({ className, label, children, ...props }) => {
  const layout = props.layout || FormBase.inputLayout;
  return (
    <FormBase.Item className={className} label={label} labelAlign="left" {...layout}>
      {children}
    </FormBase.Item>
  );
};

const Buttons = styled(({ className, children, ...props }) => {
  const layout = props.layout || FormBase.buttonLayout;
  return (
    <FormBase.Item className={className} {...layout}>
      <Flex.Row justify="space-between">{children}</Flex.Row>
    </FormBase.Item>
  );
})`
  padding: 2rem 0 1rem 0;
`;

const Error: React.FC<ErrorProps> = ({ className, message, ...props }) => {
  const layout = props.layout || FormBase.buttonLayout;
  return (
    <ErrorContainer className={className} {...layout}>
      <ErrorMessage>{message}</ErrorMessage>
    </ErrorContainer>
  );
};

export default {
  Heading,
  Form,
  Field,
  TableListField,
  Buttons,
  Error,
  ErrorMessage,
  WarningMessage,
};
