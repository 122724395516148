import { EntityStore, StoreConfig } from '@datorama/akita';

import { User } from '../../../../models';

import { ConsultantsState } from './models';

@StoreConfig({
  name: 'Client Management > Consultants',
  idKey: 'uniqueId',
})
export class ConsultantsStore extends EntityStore<ConsultantsState, User, string> {
  constructor() {
    super({
      admin: null,
      saveable: false,
      error: null,
    });
  }
}

export const store = new ConsultantsStore();
