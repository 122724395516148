import { Checkbox } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../../common/button';
import Flex from '../../../common/flex';
import FormBase from '../../../common/form';
import { Api } from '../../../http/api';
import { Client, Profile, User } from '../../../models';
import * as clientState from '../../../state/clients';
import FormLayout from '../../common/form-layout';

import ListBase from './list';
import * as userState from './state';

interface UsersProps {
  profile: Profile;
  client: Client;
  onAdd: () => void;
  onEdit: (user: User) => void;
  onDelete: (user: User, callback: (error?: string) => void) => void;
}

interface FormProps extends UsersProps {
  users: User[];
}

interface LicenseAgreementProps {
  client: Client;
}

const Agreement = styled.div`
  margin: 1rem 0 2rem 0;

  ${breakpoint('md')`
    display: flex;
    flex-direction: row;
  `}
`;

const Download = styled(Button)`
  margin: 1rem 0 0 0;

  ${breakpoint('md')`
    margin: 0 0 0 1rem;
  `}
`;

const CheckboxContainer = styled(Flex.Row)`
  margin-bottom: 2rem;
`;

const CheckboxText = styled(Flex.Item)`
  padding-left: 0.5rem;
`;

const LicenseAgreement: React.FC<LicenseAgreementProps> = ({ client }) => {
  const [loading, setLoading] = React.useState(false);
  const [agreed, setAgreed] = React.useState(false);

  const handleAgree = async () => {
    setLoading(true);

    await Api.channel.post(`/api/client/${client.uniqueId}/AcceptedTerms`);

    clientState.updateClient({
      ...client,
      hasAcceptedTerms: true,
    });

    setLoading(false);
  };

  return (
    <>
      <FormLayout.Heading>License Agreement</FormLayout.Heading>
      <Agreement>
        <Flex.Item fill={true}>
          Before you can add a client user to Milliman Optic&trade; you must have received a signed
          copy of the license agreement from your client.
        </Flex.Item>
        <Flex.Item>
          <Download
            type="dark-blue"
            onClick={() => {
              window.open('/Optic License Agreement.doc', '_blank');
            }}
          >
            Download License Agreement
          </Download>
        </Flex.Item>
      </Agreement>
      <CheckboxContainer>
        <Flex.Item>
          <Checkbox onChange={ev => setAgreed(ev.target.checked)} />
        </Flex.Item>
        <CheckboxText fill={true}>
          I confirm that the client has signed a copy of the Milliman Optic&trade; license agreement
        </CheckboxText>
      </CheckboxContainer>
      <Button type="green" loading={loading} disabled={!agreed} onClick={handleAgree}>
        Create Client User
      </Button>
    </>
  );
};

const List = React.forwardRef<any, any>((props, _) => <ListBase {...props} />);

class Form extends FormBase<FormProps, {}> {
  private readonly USERS_FIELD_NAME = 'users';

  render(): React.ReactNode {
    return (
      <FormLayout.Form onSubmit={e => e.preventDefault()}>
        <FormLayout.Heading>Current Client Users</FormLayout.Heading>
        <FormLayout.TableListField label="Client users:">
          {this.renderUsersField()}
        </FormLayout.TableListField>
      </FormLayout.Form>
    );
  }

  private renderUsersField(): React.ReactNode {
    const { form, profile, users, onAdd, onEdit, onDelete } = this.props;
    const decorator = form.getFieldDecorator(this.USERS_FIELD_NAME, {
      initialValue: users,
      valuePropName: 'users',
    });

    return decorator(
      <List
        profile={profile}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={(values: User, callback: () => void) => {
          onDelete(values, error => {
            if (!error) {
              callback();
            }
          });
        }}
      />
    );
  }
}

const Users: React.FC<UsersProps> = ({ client, ...props }) => {
  const users = useObservable(() => userState.users$, []);

  // The user need to accept the terms before adding users
  if (!client.hasAcceptedTerms) {
    return <LicenseAgreement client={client} />;
  }

  return (
    <>
      {FormBase.renderType(
        Form,
        {
          users,
          ...props,
        },
        (values: any) => {
          userState.setUsers(values.users);
        }
      )}
    </>
  );
};

export default Users;
