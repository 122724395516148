import { Tooltip } from 'antd';
import { transparentize } from 'polished';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import { css } from 'styled-components';

import Flex from '../../../common/flex';
import theme from '../../../theme';
import { zoneColors } from '../common/colors';
import formatUtils from '../common/format';

import { query } from './state/query';

interface ZoneTipMap {
  [color: string]: string;
}

const zoneTips: ZoneTipMap = {
  green: 'Not critical or endangered',
  yellow: 'Endangered',
  orange: 'Seriously endangered',
  red: 'Critical',
  deepRed: 'Critical and declining',
};

const Scroll = styled(Flex.Item)`
  overflow-x: auto;
  overflow-y: hidden;
`;

const Color = styled.div`
  content: ' ';
  margin-bottom: 2px;

  ${({ color }) =>
    color &&
    css`
      border-right: 0.5rem solid ${color};
      height: 1.75rem;
    `}

  ${({ color }) =>
    !color &&
    css`
      height: 1rem;
    `}
`;

const Header = styled(Flex.Row)`
  font-weight: ${theme.weight.medium};
  margin-bottom: 2px;
`;

const HeaderCell = styled(Flex.Item)`
  height: 1rem;
`;

const HeaderValue = styled.div`
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  height: 100%;
  width: 5rem;
`;

const Row = styled(Flex.Row)`
  margin-bottom: 2px;
`;

const RowCell = styled(Flex.Item)`
  background-color: ${({ color }) => transparentize(0.9, color)};
  height: 1.75rem;
`;

const RowValue = styled(Flex.Row)`
  margin-left: 0.5rem;
  overflow: hidden;
  height: 100%;
  width: 5rem;
  white-space: nowrap;
`;

const Zone = styled.div`
  position: relative;
  height: 0.5rem;
  width: 0.5rem;

  ${({ color }) =>
    color &&
    css`
      &::after {
        content: ' ';
        background-color: ${color};
        border: 3px solid ${color};
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    `}
`;

const Footer = styled(Flex.Row)`
  flex-wrap: wrap;
  margin-top: 0.25rem;
`;

const FooterZone = styled(Zone)`
  display: inline-block;
`;

const FooterName = styled.div`
  display: inline-block;
  padding: 0.25rem 0.5rem 0 0.1rem;
  white-space: nowrap;
`;

const Table: React.FC = () => {
  const outputType = useObservable(() => query.outputType$, null);
  const years = useObservable(() => query.years$, []);
  const data = useObservable(() => query.data$, []);

  return (
    outputType && (
      <>
        <Flex.Row>
          <Flex.Item>
            <Color />
            {data.map(scenario => (
              <Color key={scenario.id} color={scenario.color} />
            ))}
          </Flex.Item>
          <Scroll fill={true}>
            <Header align="middle">
              {years.map(year => (
                <HeaderCell key={year}>
                  <HeaderValue>{year}</HeaderValue>
                </HeaderCell>
              ))}
            </Header>
            {data.map(scenario => (
              <Row key={scenario.id}>
                {scenario.values.map((item, index) => {
                  const value = formatUtils.format(
                    outputType.formatType,
                    item.value,
                    outputType.div,
                    outputType.decimalPlaces
                  );

                  return (
                    <RowCell key={index} color={scenario.color}>
                      <RowValue align="middle">
                        <Flex.Item>
                          <Zone color={item.color} />
                        </Flex.Item>
                        <Flex.Item>{value}</Flex.Item>
                      </RowValue>
                    </RowCell>
                  );
                })}
              </Row>
            ))}
          </Scroll>
        </Flex.Row>
        <Footer>
          {Object.entries(zoneColors).map(([key, color]) => (
            <Flex.Item key={color}>
              <FooterZone color={color}></FooterZone>
              <FooterName>{zoneTips[key]}</FooterName>
            </Flex.Item>
          ))}
        </Footer>
      </>
    )
  );
};

export default Table;
