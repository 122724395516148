import { Input, Radio } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import Button from '../../../common/button';
import Flex from '../../../common/flex';
import { Api } from '../../../http/api';
import * as bannerState from '../../../state/banner';
import theme from '../../../theme';
import * as inputState from '../inputs/state';
import * as outputState from '../outputs/state';

import Badge from './badge';
import * as state from './state';
import { Scenario, ScenarioData } from './state/models';

interface CreateState {
  name?: string;
  copyId?: string;
  submitting?: boolean;
  message?: string;
}

interface CreateProps {
  modelId: string;
  scenarios: Scenario[];
  onClose: () => void;
}

const Container = styled(Flex.Col)`
  height: 100%;
`;

const Header = styled(Flex.Item)`
  background-color: ${theme.color.panelGrey};
  padding: 1rem;
`;

const Form = styled(Flex.Col)`
  height: 100%;
`;

const NameLabel = styled(Flex.Item)`
  font-weight: ${theme.weight.medium};
  padding-right: 1rem;
`;

const Name = styled(Flex.Item)`
  padding: 1rem;
`;

const CopyLabel = styled(Flex.Item)`
  font-weight: ${theme.weight.medium};
  padding: 0 0 0.5rem 1rem;
`;

const Copy = styled(Flex.Item)`
  position: relative;
`;

const Scroll = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
`;

const Item = styled(Flex.Row)`
  margin-bottom: 0.5rem;
`;

const Label = styled(Flex.Item)`
  cursor: pointer;
  margin-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Error = styled.div`
  color: ${theme.color.cranberry};
  font-weight: ${theme.weight.medium};
  padding: 0.5rem 1rem 0 1rem;
`;

const Buttons = styled(Flex.Item)`
  padding: 0.5rem 1rem 1rem 1rem;

  button {
    margin-right: 0.25rem;
  }
`;

const canCreate = (create: CreateState) => {
  if (create.name) {
    return create.name.trim() !== '';
  }

  return false;
};

const createScenario = (modelId: string, create: CreateState) =>
  Api.channel
    .post(`/api/projection/${modelId}/scenario`, {
      name: create.name,
      scenarioId: create.copyId,
    })
    .then(({ data }) => {
      const [first] = data.scenarios;
      const scenario = first as ScenarioData;

      // Add the new scenario
      state.addScenario(scenario);

      // Also add inputs and outputs
      inputState.addScenarioInputs(scenario);
      outputState.addScenarioOutputs(scenario);
    });

const Create: React.FC<CreateProps> = ({ modelId, scenarios, onClose }) => {
  const create = useObservable(() => state.createScenarioState$, {});
  const [firstScenario] = scenarios;

  return (
    <Container>
      <Header>
        Enter a name for the new scenario. Select a scenario to copy settings from. Press the Create
        button to complete setup.
      </Header>
      <Form>
        <Name>
          <Flex.Row align="middle">
            <NameLabel>Name:</NameLabel>
            <Flex.Item fill={true}>
              <Input
                value={create?.name}
                onChange={ev => state.setCreateScenarioState({ ...create, name: ev.target.value })}
              />
            </Flex.Item>
          </Flex.Row>
        </Name>
        <CopyLabel>Copy scenario settings:</CopyLabel>
        <Copy fill={true}>
          <Scroll>
            {scenarios.map((scenario, index) => (
              <Item key={scenario.id} align="middle">
                <Flex.Item>
                  <Radio
                    checked={scenario.id === create.copyId || (!create.copyId && index === 0)}
                    value={scenario.id}
                    onChange={() =>
                      state.setCreateScenarioState({ ...create, copyId: scenario.id })
                    }
                  />
                </Flex.Item>
                <Label
                  fill={true}
                  onClick={() => state.setCreateScenarioState({ ...create, copyId: scenario.id })}
                >
                  {scenario.name}
                </Label>
                <Flex.Item>{scenario.milliman && <Badge />}</Flex.Item>
              </Item>
            ))}
          </Scroll>
        </Copy>
        {create.message && <Error>{create.message}</Error>}
        <Buttons>
          <Button
            type="light-grey"
            secondary={true}
            onClick={() => {
              onClose();
              state.setCreateScenarioState({});
            }}
          >
            Cancel
          </Button>
          <Button
            type="blue"
            loading={create.submitting}
            disabled={!canCreate(create)}
            onClick={async () => {
              bannerState.setCreatingBanner();
              state.setCreateScenarioState({
                copyId: firstScenario.id,
                ...create,
                submitting: true,
              });
              try {
                await createScenario(modelId, { copyId: firstScenario.id, ...create });
                state.setCreateScenarioState({});
                onClose();
              } catch {
                state.setCreateScenarioState({
                  ...create,
                  submitting: false,
                  message: 'Unable to create scenario',
                });
              } finally {
                bannerState.clearBanner();
              }
            }}
          >
            Create
          </Button>
        </Buttons>
      </Form>
    </Container>
  );
};

export default Create;
