import { Form as FormBase } from 'antd';
import { FormComponentProps, FormProps, ValidationRule } from 'antd/lib/form';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import theme from '../theme';

interface ValidationRules {
  [name: string]: ValidationRule;
}

class Form<TProps, TState> extends React.Component<TProps & FormProps, TState> {
  static inputLayout = {
    labelCol: {
      xs: 24,
      md: 6,
    },
    wrapperCol: {
      xs: 24,
      md: 18,
    },
  };

  static buttonLayout = {
    wrapperCol: {
      xs: 24,
      md: {
        offset: 6,
        span: 18,
      },
    },
  };

  static rules: ValidationRules = {
    notEmptyOrWhiteSpace: {
      required: true,
      pattern: new RegExp('.*\\S.*'),
    },
  };

  static Item = styled(FormBase.Item)`
    margin-bottom: 0.5rem;

    // Add some additional padding to validation messages
    .ant-form-item-label {
      font-weight: ${theme.weight.medium};
      line-height: 1rem;
      padding: 0;
    }

    .ant-form-explain {
      margin: 0.1rem 0 0.25rem 0;
    }

    ${breakpoint('md')`
      margin-bottom: 0;

      .ant-form-item-label {
        line-height: 40px;
      }
    `}
  `;

  static createType(
    type: React.ComponentType,
    valuesChange?: (values: any) => void
  ): React.ComponentType {
    const factory = FormBase.create({
      onValuesChange: (_, __, allValues) => valuesChange && valuesChange(allValues),
    });

    return factory(type);
  }

  static renderType(
    type: React.ComponentType,
    props: any = {},
    valuesChange?: (values: any) => void
  ): React.ReactNode {
    const FormType = Form.createType(type, valuesChange);
    return <FormType {...props} />;
  }

  render(): React.ReactNode {
    return <FormBase labelAlign="left" {...this.props} />;
  }
}

export { FormComponentProps as FormProps };
export default Form;
