import { ColProps } from 'antd/lib/col';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../../common/content';
import Splitter from '../../common/splitter';
import theme from '../../theme';
import FormLayout from '../common/form-layout';

import Summary from './summary';

interface LayoutProps {
  layout?: {
    wrapperCol: ColProps;
  };
}

interface FieldProps {
  label: string;
}

interface ErrorProps extends LayoutProps {
  message: string;
}

interface SectionProps {
  title: string;
}

const Left = styled.div`
  padding: 1rem;

  ${breakpoint('lg')`
    padding: 2rem;
  `}
`;

const Right = styled.div`
  display: none;
  padding: 2rem;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const StepTitle = styled(Content.SubHeading)`
  margin: 1rem 0;
`;

const SectionTitle = styled.div`
  font-weight: ${theme.weight.medium};
  margin: 0.5rem 0;
`;

const SectionContainer = styled.div`
  margin-bottom: 1rem;
`;

const inputLayout = {
  labelCol: {
    xs: 24,
    md: 8,
  },
  wrapperCol: {
    xs: 24,
    md: 16,
  },
};

const buttonLayout = {
  wrapperCol: {
    xs: 24,
    md: {
      offset: 8,
      span: 16,
    },
  },
};

export default class extends React.Component {
  static inputLayout = inputLayout;
  static buttonLayout = buttonLayout;

  static Form = FormLayout.Form;

  static Field: React.FC<FieldProps> = props => (
    <FormLayout.Field layout={inputLayout} {...props} />
  );

  static TableListField: React.FC<FieldProps> = props => (
    <FormLayout.TableListField layout={inputLayout} {...props} />
  );

  static Buttons: React.FC<LayoutProps> = ({ layout, ...props }) => (
    <FormLayout.Buttons layout={layout || buttonLayout} {...props} />
  );

  static Error: React.FC<ErrorProps> = ({ message, layout, ...props }) => (
    <FormLayout.Error message={message} layout={layout || buttonLayout} {...props} />
  );

  static Step: React.FC<SectionProps> = ({ title, children }) => (
    <div>
      <StepTitle>{title}</StepTitle>
      {children}
    </div>
  );

  static Section: React.FC<SectionProps> = ({ title, children }) => (
    <div>
      <SectionTitle>{title}</SectionTitle>
      <SectionContainer>{children}</SectionContainer>
    </div>
  );

  render(): React.ReactNode {
    const { children } = this.props;
    return (
      <Splitter defaultSize="70%" minSize={300}>
        <Left>
          <Content.Heading>New Client Wizard</Content.Heading>
          {children}
        </Left>
        <Right>
          <Summary />
        </Right>
      </Splitter>
    );
  }
}
