import { Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import Button from '../common/button';
import Content from '../common/content';
import Flex from '../common/flex';
import FormBase from '../common/form';
import Select from '../common/select';
import { USER_ROLE } from '../constants';
import { Api } from '../http/api';
import { useGuard } from '../router';
import * as clientState from '../state/clients';
import * as profileState from '../state/profile';
import theme from '../theme';

const Container = styled.div`
  padding: 1rem;
  min-height: 360px;
`;

const Buttons = styled(Flex.Row)`
  margin-top: 1rem;
`;

const inputLayout = {
  labelCol: {
    xs: 24,
    md: 8,
  },
  wrapperCol: {
    xs: 24,
    md: 16,
  },
};

const buttonLayout = {
  wrapperCol: {
    xs: 24,
    md: {
      offset: 8,
      span: 16,
    },
  },
};

const SubSettingHeading = styled(Content.Heading)`
  font-size: ${theme.size.base};
  padding-top: 1rem;
`;

const Rule = styled.div`
  font-size: 10px;
  margin-top: -0.75rem;
  padding-left: 0.5rem;
`;

const Error = styled.div`
  color: ${theme.color.cranberry};
  line-height: normal;
`;

type SettingsFormProps = FormComponentProps & {};

const SettingForm: React.FC<SettingsFormProps> = ({ form, ...props }) => {
  const guard = useGuard();
  const profile = useObservable(() => profileState.profile$, null);
  const clientId = useObservable(() => clientState.selectedId$, null);
  const clients = useObservable(() => clientState.clients$, []);

  const [password, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [error, setError] = React.useState<string>();

  const resetForm = () => {
    // reset form
    setError(undefined);
    setCurrentPassword('');
    form.resetFields();
  };

  const handleSubmit = (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        // update user settings

        try {
          await Api.channel.put(`/api/user/${profile?.uniqueId}`, {
            email: values.email,
            name: values.name,
            currentPassword: values['confirm-password'],
            password: values['new-password'],
          });
        } catch (e) {
          const { response } = e;
          if (response.status === 400) {
            switch (response.data?.reasonCode) {
              case 4:
                setError(`The specified email address is either invalid or already in use`);
                break;
              case 5:
                setError(`Password incorrect, try again`);
                break;
              default:
                setError(`Server error: ${e}`);
            }
          }
          return;
        }

        profileState.updateProfile(
          values.name ?? profile?.fullName,
          values.email ?? profile?.email
        );

        // reset form
        resetForm();
      }
    });
  };

  const validatePasswordsMatch = (_: any, value: any, callback: any) => {
    if (value && value !== form.getFieldValue('new-password')) {
      callback('Your passwords do not match, please check and try again.');
    } else {
      callback();
    }
  };

  // we only show boards for normal users with more than one client
  const showBoards = profile?.userType === USER_ROLE && clients.length > 1;

  return (
    <FormBase layout="horizontal" hideRequiredMark={true} onSubmit={handleSubmit} {...props}>
      {showBoards && (
        <>
          <SubSettingHeading>Change Board</SubSettingHeading>
          <FormBase.Item label="Current Board:" {...inputLayout}>
            {form.getFieldDecorator('board', {
              initialValue: clientId,
            })(
              <Select
                onChange={value =>
                  value !== clientId &&
                  guard.invoke('select-board', () => clientState.setSelectedId(value))
                }
              >
                {clients?.map(client => (
                  <Select.Option key={client.uniqueId} value={client.uniqueId}>
                    {client.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormBase.Item>
        </>
      )}
      <SubSettingHeading>Update Details</SubSettingHeading>
      <FormBase.Item label="Full name:" {...inputLayout}>
        {form.getFieldDecorator('name', {
          rules: [
            {
              required: true,
              message: 'A full name is required',
            },
          ],
          initialValue: profile?.fullName,
        })(<Input />)}
      </FormBase.Item>
      <FormBase.Item label="Email address:" {...inputLayout}>
        {form.getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: 'A valid email address is required',
            },
          ],
          initialValue: profile?.email,
        })(<Input />)}
      </FormBase.Item>
      <FormBase.Item label="New password:" {...inputLayout}>
        {form.getFieldDecorator('new-password', {
          rules: [
            {
              pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-)(]).{8,}$/,
              message:
                'New passwords must have a minimum length of 8 characters including upper and lower case letters, plus a number and symbol',
            },
          ],
        })(<Input.Password onChange={e => setNewPassword(e.target.value)} />)}
      </FormBase.Item>
      {newPassword && (
        <FormBase.Item label="Confirm password:" {...inputLayout}>
          {form.getFieldDecorator('confirm-new-password', {
            rules: [
              {
                required: true,
                message: 'Confirm your new password',
              },
              {
                validator: validatePasswordsMatch,
              },
            ],
          })(<Input.Password />)}
          <Rule>Min 8 mixed case letters, plus a number and symbol.</Rule>
        </FormBase.Item>
      )}
      <SubSettingHeading>Confirm Password To Update Settings</SubSettingHeading>
      <FormBase.Item label="Current password:" {...inputLayout}>
        {form.getFieldDecorator('confirm-password')(
          <Input.Password
            autoComplete="new-password"
            onChange={e => setCurrentPassword(e.target.value)}
          />
        )}
      </FormBase.Item>
      <FormBase.Item {...buttonLayout}>
        {error && <Error>{error}</Error>}
        <Buttons justify="space-between">
          <Flex.Item>
            <Button disabled={!form.isFieldsTouched() || !password} htmlType="submit" type="blue">
              Update Settings
            </Button>
          </Flex.Item>
          <Flex.Item>
            <Button
              type="light-grey"
              disabled={!form.isFieldsTouched()}
              secondary={true}
              onClick={resetForm}
            >
              Cancel
            </Button>
          </Flex.Item>
        </Buttons>
      </FormBase.Item>
    </FormBase>
  );
};

const Settings: React.FC = props => (
  <Container>{FormBase.renderType(SettingForm, props)}</Container>
);

export default Settings;
