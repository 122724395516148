// ROLES
export const USER_ROLE = 0;
export const ADMIN_STANDARD_ROLE = 1;
export const ADMIN_PUBLISHER_ROLE = 2;
export const ADMIN_ROLE = 3;
export const ADMIN_ROLES = [ADMIN_STANDARD_ROLE, ADMIN_PUBLISHER_ROLE, ADMIN_ROLE];

// ROUTES
const AUTH_PATH = '/auth';
const APP_PATH = '/app';
const ADMIN_PATH = '/admin';
const LOGIN_PATH = '/login';
const FORGOT_PASSWORD_PATH = '/forgot-password';
const RESET_PASSWORD_PATH = '/reset-password';
const HOME_PATH = '/home';
const PROJECTION_TOOL_PATH = '/projection-tool';
const INFORMATION_CENTER_PATH = '/information-center';
const HELP_PATH = '/help';
const CLIENT_MANAGEMENT_PATH = '/client-management';
const NEW_CLIENT_PATH = '/new-client';
const ADMIN_MANAGEMENT_PATH = '/admin-management';

export const routes = {
  auth: {
    root: AUTH_PATH,
    login: `${AUTH_PATH}${LOGIN_PATH}`,
    forgotPassword: `${AUTH_PATH}${FORGOT_PASSWORD_PATH}`,
    resetPassword: `${AUTH_PATH}${RESET_PASSWORD_PATH}`,
  },
  app: {
    root: APP_PATH,
    home: `${APP_PATH}${HOME_PATH}`,
    projectionTool: `${APP_PATH}${PROJECTION_TOOL_PATH}`,
    informationCenter: `${APP_PATH}${INFORMATION_CENTER_PATH}`,
    help: `${APP_PATH}${HELP_PATH}`,
  },
  admin: {
    root: ADMIN_PATH,
    clientManagement: `${ADMIN_PATH}${CLIENT_MANAGEMENT_PATH}`,
    informationCenter: `${ADMIN_PATH}${INFORMATION_CENTER_PATH}`,
    newClient: `${ADMIN_PATH}${NEW_CLIENT_PATH}`,
    adminManagement: `${ADMIN_PATH}${ADMIN_MANAGEMENT_PATH}`,
  },
};
