import { User } from '../../../../models';

import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const consultants$ = query.consultants$;
export const admin$ = query.admin$;
export const saveable$ = query.saveable$;
export const error$ = query.error$;

export const setConsultants = (consultants: User[]) => {
  store.remove();
  store.upsertMany(consultants);
};

export const setAdmin = (admin: string) =>
  store.update({
    admin,
  });

export const setSaveable = (saveable: boolean) =>
  store.update({
    saveable,
  });

export const setError = (error?: string) =>
  store.update({
    error,
  });
