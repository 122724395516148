import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

interface CaretProps {
  className?: string;
  rotate?: boolean;
}

const Container = styled.div`
  color: ${theme.color.millimanBlue};
  display: inline-block;

  svg {
    transition: transform 0.3s;
  }
`;

const Caret: React.FC<CaretProps> = ({ className, rotate }) => (
  <Container className={className}>
    <Icon type="caret-down" theme="filled" rotate={rotate === true ? 180 : 0} />
  </Container>
);

export default Caret;
