export const PANEL_ID_KEY = '_projection_panel_id';
export const SPLIT_KEY = '_projection_split';

export const DISABLED_ID_KEY = '_projection_disabled_ids';
export const START_YEAR_KEY = '_projection_start_year';
export const END_YEAR_KEY = '_projection_end_year';
export const YEARS_MODEL_ID_KEY = '_projection_years_model_id';

export const GRAPH_TYPE_KEY = '_projection_graph_type';

export const INPUT_ID_KEY = '_projection_input_id';

export const OUTPUT_ID_KEY = '_projection_output_id';
