import { NotificationData } from './models';
import { query } from './query';
import { store } from './store';

export { NotificationData };

// Expose an API for others to consume
export const modelId$ = query.modelId$;
export const scenarioId$ = query.scenarioId$;
export const fileId$ = query.fileId$;
export const notifications$ = query.notifications$;

export const initialize = (data: NotificationData[]) => store.initialize(data);

export const setModelId = (uniqueId: string) => {
  store.update({
    modelId: uniqueId,
  });
};

export const setScenarioId = (uniqueId: string) => {
  store.update({
    scenarioId: uniqueId,
  });
};

export const setFileId = (uniqueId: string) => {
  store.update({
    fileId: uniqueId,
  });
};

export const markAsViewed = (uniqueId: string) =>
  store.update(uniqueId, {
    viewed: true,
  });

export const remove = (clientId: string) => store.remove(entity => entity.clientId === clientId);
