import React from 'react';

import Badge from '../../common/badge';
import Flex from '../../common/flex';
import Select from '../../common/select';
import { User } from '../../models';
import userUtils from '../../utils/user';

interface ConsultantSelectProps {
  selectedId?: string;
  consultants: User[];
  onChange?: (value: string) => void;
}

interface ConsultantSelectOptionProps {
  consultant: User;
}

const Option: React.FC<ConsultantSelectOptionProps> = ({ consultant }) => (
  <Flex.Row align="middle">
    <Flex.Item fill={true}>{consultant.fullName}</Flex.Item>
    <Flex.Item>
      <Badge>{userUtils.getRoleString(consultant.userType)}</Badge>
    </Flex.Item>
  </Flex.Row>
);

export default class extends React.Component<ConsultantSelectProps> {
  static Option = Option;

  render(): React.ReactNode {
    const { selectedId, consultants, onChange } = this.props;

    return (
      <Select value={selectedId} onChange={onChange}>
        {consultants &&
          consultants.map(consultant => (
            <Select.Option key={consultant.uniqueId} value={consultant.uniqueId}>
              <Option consultant={consultant} />
            </Select.Option>
          ))}
      </Select>
    );
  }
}
