import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import helperUtils from '../../../../utils/helper';
import * as spreadsheetState from '../../spreadsheets/state';

import { DataState, DataValues } from './models';
import { DataStore, store } from './store';

export class DataQuery extends Query<DataState> {
  readonly contentData$ = combineLatest([
    spreadsheetState.contentSelectedId$,
    this.select(state => state.data),
  ]).pipe(map(([spreadsheetId, data]) => this.getData(spreadsheetId, data)));

  readonly summaryData$ = combineLatest([
    spreadsheetState.summarySelectedId$,
    this.select(state => state.data),
  ]).pipe(map(([spreadsheetId, data]) => this.getData(spreadsheetId, data)));

  readonly saveable$ = this.select(state => state.saveable);
  readonly error$ = this.select(state => state.error);

  constructor(_store: DataStore) {
    super(_store);
  }

  private getData(selectedId: string, data: { [id: string]: DataValues }): DataValues {
    const values = data[selectedId];

    if (values) {
      return {
        inputs: helperUtils.sort(values.inputs, 'displayName'),
        outputs: helperUtils.sort(values.outputs, 'displayName'),
        multiOutputs: helperUtils.sort(values.multiOutputs, 'displayName'),
      };
    }

    return null;
  }
}

export const query = new DataQuery(store);
