import { Store, StoreConfig } from '@datorama/akita';

import { NewClientState } from './models';

@StoreConfig({
  name: 'Admin > New Client',
})
export class NewClientStore extends Store<NewClientState> {
  constructor() {
    super({
      name: null,
      address: null,
      spreadsheets: [],
      consultants: [],
      admin: null,
      saving: false,
    });
  }

  setValues(values: Partial<NewClientState>): void {
    this.update({
      ...values,
    });
  }

  setSaving(saving: boolean): void {
    this.update({
      saving,
    });
  }
}

export const store = new NewClientStore();
