import AvatarSvg from '../../assets/icon-avatar.svg';

import Icon from './icon';
import { IconProps } from './icon';

export default class extends Icon {
  constructor(props: IconProps) {
    super(AvatarSvg, props);
  }
}
