import React from 'react';

import Select from '../../common/select';

interface SummarySelectProps {
  value: string;
  disabled?: boolean;
}

const SummarySelect: React.FC<SummarySelectProps> = ({ value, disabled, children }) => (
  <Select value={value} disabled={disabled} dropdownMatchSelectWidth={true}>
    {children}
  </Select>
);

export default SummarySelect;
