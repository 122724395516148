import { Checkbox, Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../common/button';
import Content from '../../common/content';
import File from '../../common/file';
import Flex from '../../common/flex';
import FormBase from '../../common/form';
import Select from '../../common/select';
import { Model } from '../../models';
import FormLayout from '../common/form-layout';

interface FormProps {
  onSave: (formData: FormData) => void;
}

interface FormState {
  loading: boolean;
  accepted: boolean;
}

interface CategoriesProps {}

const inputLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const buttonLayout = {
  wrapperCol: {
    span: 24,
  },
};

const SubHeading = styled(Content.SubHeading)`
  display: none;
  margin: 1rem 0;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const FormItem = styled(FormBase.Item)`
  margin-bottom: 1rem !important;

  .ant-form-item-label {
    line-height: 1rem;
    margin-bottom: 0.25rem;
  }
`;

const CheckboxGroup = styled(Checkbox.Group)`
  margin-top: 0.25rem;
  width: 100%;
`;

const CategoryCheckbox = styled(props => <Col span={12} {...props} />)`
  padding-bottom: 0.5rem;
`;

const CheckboxContainer = styled(Flex.Item)`
  line-height: 1rem;
  padding-top: 0.25rem;
`;

const CheckboxTextContainer = styled(Flex.Item)`
  line-height: 1rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
`;

const Categories: React.FC<CategoriesProps> = React.forwardRef<any, CategoriesProps>(
  (props, ref) => (
    <CheckboxGroup ref={ref} {...props}>
      <Row>
        <CategoryCheckbox>
          <Flex.Row>
            <CheckboxContainer>
              <Checkbox value="ActuarialReports" />
            </CheckboxContainer>
            <CheckboxTextContainer fill={true}>Actuarial reports</CheckboxTextContainer>
          </Flex.Row>
        </CategoryCheckbox>
        <CategoryCheckbox>
          <Flex.Row>
            <CheckboxContainer>
              <Checkbox value="Presentations" />
            </CheckboxContainer>
            <CheckboxTextContainer fill={true}>Presentations</CheckboxTextContainer>
          </Flex.Row>
        </CategoryCheckbox>
      </Row>
      <Row>
        <CategoryCheckbox>
          <Flex.Row>
            <CheckboxContainer>
              <Checkbox value="ActuarialLetters" />
            </CheckboxContainer>
            <CheckboxTextContainer fill={true}>Actuarial letters</CheckboxTextContainer>
          </Flex.Row>
        </CategoryCheckbox>
        <CategoryCheckbox>
          <Flex.Row>
            <CheckboxContainer>
              <Checkbox value="Other" />
            </CheckboxContainer>
            <CheckboxTextContainer fill={true}>Other</CheckboxTextContainer>
          </Flex.Row>
        </CategoryCheckbox>
      </Row>
    </CheckboxGroup>
  )
);

class Form extends FormBase<FormProps, FormState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      accepted: false,
    };
  }

  render(): React.ReactNode {
    const { form } = this.props;
    const { loading, accepted } = this.state;

    return (
      <FormLayout.Form onSubmit={e => this.handleSubmit(e)}>
        <SubHeading>Upload Resource</SubHeading>
        <FormItem label="Select Resource" {...inputLayout}>
          {form.getFieldDecorator('file', {
            rules: [
              {
                required: true,
                message: 'A file required',
              },
              {
                validator: this.isValidFile,
              },
            ],
          })(<File />)}
        </FormItem>
        <FormItem label="Choose Categories" {...inputLayout}>
          {form.getFieldDecorator('categories', {
            rules: [
              {
                required: true,
                message: 'A category is required',
              },
            ],
          })(<Categories />)}
        </FormItem>
        <FormItem label="Privacy Declaration" {...inputLayout}>
          <Flex.Row>
            <CheckboxContainer>
              <Checkbox onChange={() => this.setState({ accepted: !accepted })} />
            </CheckboxContainer>
            <CheckboxTextContainer fill={true}>
              I confirm that this file contains no Personally Identifiable Information (PII) or
              Protected Health Information (PHI).
            </CheckboxTextContainer>
          </Flex.Row>
        </FormItem>
        <FormItem {...buttonLayout}>
          <Button type="green" htmlType="submit" loading={loading} disabled={!accepted}>
            Upload
          </Button>
        </FormItem>
      </FormLayout.Form>
    );
  }

  private isValidFile(rule: any, value: any, callback: any): void {
    if (value) {
      const validExtensions = ['xls', 'xlsx', 'xlsm', 'docx', 'doc', 'jpeg', 'jpg', 'cvc', 'pdf'];
      const fileExt = value.name.split('.').pop();
      if (!validExtensions.find(ext => fileExt.toLowerCase() === ext.toLowerCase())) {
        callback('Warning: unauthorized file type detected. Please try again.');
      }
    }
    callback();
  }

  private handleSubmit(e: React.FormEvent<HTMLElement>): void {
    e.preventDefault();

    const { form, onSave } = this.props;
    form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({
          loading: true,
        });

        const formData = new FormData();
        const categories = values.categories.reduce((output: any, current: string) => {
          output[current] = current;
          return output;
        }, {});

        formData.append('file', values.file.data, values.file.name);
        formData.append('fileType', JSON.stringify(categories));
        formData.append('planName', values.plan);

        onSave && onSave(formData);

        form.resetFields();

        this.setState({
          loading: false,
          accepted: false,
        });
      }
    });
  }
}

export default Form;
