import React from 'react';
import { ThemeProvider } from 'styled-components';

import border from './borders.json';
import breakpoints from './breakpoints.json';
import color from './colors.json';
import spacing from './spacing.json';
import size from './typography-sizes.json';
import weight from './typography-weights.json';

const theme = {
  color,
  border,
  size,
  weight,
};

export default theme;
export const Theme: React.FC = ({ children }) => (
  <ThemeProvider
    theme={{
      ...theme,
      breakpoints,
      spacing,
    }}
  >
    {children}
  </ThemeProvider>
);
