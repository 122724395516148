import { Input, MultiOutput, Output } from '../../../models';

import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const contentData$ = query.contentData$;
export const summaryData$ = query.summaryData$;
export const saveable$ = query.saveable$;
export const error$ = query.error$;

export const setInputs = (spreadsheetId: string, inputs: Input[]) =>
  store.setData(spreadsheetId, {
    inputs,
  });

export const setOutputs = (spreadsheetId: string, outputs: Output[]) =>
  store.setData(spreadsheetId, {
    outputs,
  });

export const setMultiOutputs = (spreadsheetId: string, multiOutputs: MultiOutput[]) =>
  store.setData(spreadsheetId, {
    multiOutputs,
  });

export const setSaveable = (saveable: boolean) =>
  store.update({
    saveable,
  });

export const setError = (error?: string) =>
  store.update({
    error,
  });
