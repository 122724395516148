import { QueryEntity } from '@datorama/akita';

import { Spreadsheet } from '../../../models';

import { SpreadsheetsState } from './models';
import { SpreadsheetsStore, store } from './store';

export class SpreadsheetsQuery extends QueryEntity<SpreadsheetsState, Spreadsheet, string> {
  readonly contentSelectedId$ = this.select(state => state.contentSelectedId);
  readonly summarySelectedId$ = this.select(state => state.summarySelectedId);
  readonly contentLoading$ = this.select(state => state.contentLoading);
  readonly summaryLoading$ = this.select(state => state.summaryLoading);
  readonly spreadsheets$ = this.selectAll({
    sortBy: 'label',
  });

  constructor(_store: SpreadsheetsStore) {
    super(_store);
  }
}

export const query = new SpreadsheetsQuery(store);
