import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { GraphType, GraphTypeEntity, GraphTypeState } from './models';
import { GraphTypeStore, store } from './store';

export class GraphTypeQuery extends QueryEntity<GraphTypeState, GraphTypeEntity> {
  readonly selectedId$ = this.select(state => state.selectedId);

  readonly graphTypes$ = combineLatest([this.selectedId$, this.selectAll()]).pipe(
    map(([selectedId, entities]) =>
      entities.map(entity => {
        const graphType: GraphType = {
          ...entity,
          selected: entity.id === selectedId,
        };

        return graphType;
      })
    )
  );

  constructor(_store: GraphTypeStore) {
    super(_store);
  }
}

export const query = new GraphTypeQuery(store);
