import React from 'react';
import { Observable } from 'rxjs';
import { useObservable } from 'rxjs-hooks';

import { Api } from '../../../http/api';
import * as clientState from '../../../state/clients';
import SpreadsheetDrawerBase from '../../common/spreadsheet-drawer';
import { Spreadsheet } from '../../models';

import * as spreadsheetState from './state';

interface SpreadsheetData {
  uniqueId: string;
  label: string;
  notes: string;
  default?: boolean;
  fileName: string;
}

interface DrawerProps {
  spreadsheet: Spreadsheet;
  selectObservable: Observable<{
    current: Spreadsheet;
    previous?: Spreadsheet;
  }>;
  deleteObservable: Observable<{
    spreadsheet: Spreadsheet;
    callback: (error?: string) => void;
  }>;
  onClose: () => void;
}

const Drawer: React.FC<DrawerProps> = ({
  spreadsheet,
  selectObservable,
  deleteObservable,
  onClose,
}) => {
  const clientId = useObservable(() => clientState.selectedId$, null);
  const spreadsheets = useObservable(() => spreadsheetState.spreadsheets$, []);

  const selectSpreadsheet = (current: Spreadsheet, previous: Spreadsheet) => {
    if (previous) {
      const match = spreadsheets.find(item => item.uniqueId === previous.uniqueId);

      // Update the current spreadsheet
      if (match) {
        const currentFormData = new FormData();
        currentFormData.append('notes', previous.notes || '');

        updateSpreadsheet(
          {
            ...previous,
            selected: false,
          },
          currentFormData
        );
      }
    }

    // Update the newly selected spreadsheet
    const newFormData = new FormData();
    newFormData.append('notes', current.notes || '');

    updateSpreadsheet(
      {
        ...current,
        selected: true,
      },
      newFormData
    );
  };

  const addSpreadsheet = (
    current: Spreadsheet,
    formData: FormData,
    callback: (spreadsheet: Spreadsheet, error?: string) => void
  ) => {
    // Specific property for add
    formData.append('label', current.label);

    Api.channel
      .post<{ models: SpreadsheetData[] }>(`/api/client/${clientId}/models`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data }) => {
        if (data.models.length > 0) {
          const [first] = data.models;
          callback({
            ...current,
            uniqueId: first.uniqueId,
            selected: false,
            file: {
              name: current.file.name,
            },
          });
        } else {
          callback(current, 'An error occurred. Verify the spreadsheet and try again.');
        }
      })
      .catch(() => {
        callback(current, 'An error occurred. Verify the spreadsheet and try again.');
      });
  };

  const updateSpreadsheet = (
    current: Spreadsheet,
    formData: FormData,
    callback?: (spreadsheet: Spreadsheet, error?: string) => void
  ) => {
    // Specific properties for update
    formData.append('planLabel', current.label);
    formData.append('default', `${current.selected}`);

    Api.channel
      .put(`/api/client/${clientId}/models/${current.uniqueId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        if (callback) {
          callback({
            ...current,
            file: {
              name: current.file.name,
            },
          });
        }
      })
      .catch(() => {
        callback(current, 'An error occurred. Verify the spreadsheet and try again.');
      });
  };

  const deleteSpreadsheet = (current: Spreadsheet, callback: (error?: string) => void) => {
    Api.channel
      .delete(`/api/client/${clientId}/models/${current.uniqueId}`)
      .then(() => {
        callback();
      })
      .catch(() => {
        callback('An error occurred. Verify the spreadsheet and try again.');
      });
  };

  React.useEffect(() => {
    if (selectObservable) {
      const subscription = selectObservable.subscribe(context => {
        selectSpreadsheet(context.current, context.previous);
      });

      return () => subscription.unsubscribe();
    }

    return null;
  });

  React.useEffect(() => {
    if (deleteObservable) {
      const subscription = deleteObservable.subscribe(context => {
        deleteSpreadsheet(context.spreadsheet, context.callback);
      });

      return () => subscription.unsubscribe();
    }

    return null;
  });

  return (
    <SpreadsheetDrawerBase
      spreadsheet={spreadsheet}
      onSave={(values, callback) => {
        const formData = new FormData();
        const { uniqueId, file } = values;

        formData.append('notes', values.notes || '');

        if (file.data) {
          formData.append('file', file.data, file.name);
        }

        if (uniqueId) {
          updateSpreadsheet(values, formData, (updated, error) => {
            if (!error) {
              const list = spreadsheets.map(current =>
                current.uniqueId === uniqueId ? updated : current
              );

              spreadsheetState.setSpreadsheets(list);
              onClose();
            }

            callback(error);
          });
        } else {
          addSpreadsheet(values, formData, (added, error) => {
            if (!error) {
              spreadsheetState.setSpreadsheets([...spreadsheets, added]);
              onClose();
            }

            callback(error);
          });
        }
      }}
      onClose={onClose}
    />
  );
};

export default Drawer;
