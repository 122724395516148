import { ScenarioData } from '../../scenarios/state';

import { Input, InputData, InputValues } from './models';
import { query } from './query';
import { store } from './store';

export { Input };

// Expose an API for others to consume
export const selectedId$ = query.selectedId$;
export const inputs$ = query.inputs$;
export const updates$ = query.updates$;
export const scenarios$ = query.scenarios$;
export const ref$ = query.ref$;
export const years$ = query.years$;
export const inputTypes$ = query.inputTypes$;
export const inputValues$ = query.inputValues$;
export const split$ = query.split$;

export const initialize = (inputs: InputData[], scenarios: ScenarioData[]) =>
  store.initialize(inputs, scenarios);

export const setInputId = (id: string) => store.setSelectedId(id);
export const ensureSelectionForScenarios = (scenarioIds: string[]) =>
  store.ensureSelectionForScenarios(scenarioIds);

export const addScenarioInputs = (scenario: ScenarioData) => store.addScenarioInputs(scenario);
export const applyInputUpdates = (doSave: boolean) => store.applyInputUpdates(doSave);
