import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../../common/content';
import ResourceTable from '../../common/resource-table';
import Splitter from '../../common/splitter';
import { Resource } from '../../models';
import * as clientState from '../../state/clients';
import * as notificationState from '../../state/notifications';
import theme from '../../theme';
import resourceUtils from '../../utils/resource';
import Layout from '../layout';

import Search from './search';

const Table = styled.div`
  display: none;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const Notes = styled.div`
  line-height: 1.2rem;
  margin-top: 2rem;
`;

const SearchSidebar = styled.div`
  background-color: ${theme.color.white};
  padding: 1rem;
  height: 100%;
  overflow: auto;

  ${breakpoint('lg')`
    background-color: ${theme.color.panelGrey};
    padding: 2rem;
  `}
`;

const InformationCenter: React.FC = () => {
  const clientId = useObservable(() => clientState.selectedId$, null);
  const fileId = useObservable(() => notificationState.fileId$, null);

  const [selectedIds, setSelectedIds] = React.useState([]);
  const [resources, setResources] = React.useState<Resource[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (fileId) {
      setSelectedIds([fileId]);

      // Clear the selection
      notificationState.setFileId(null);
    }
  }, [fileId]);

  React.useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      setResources(await resourceUtils.getResources(clientId));
      setLoading(false);
    })();
  }, [clientId]);

  return (
    <Layout>
      <Splitter defaultSize="70%" minSize={300}>
        <Table>
          <Content>
            <Content.Heading>Information Center</Content.Heading>
            <ResourceTable
              selectedIds={selectedIds}
              dataSource={resources}
              loading={loading}
              onResourceDeleted={() => {
                // do nothing
              }}
            />
            <Notes>
              <p>
                Milliman's work is prepared solely for the internal business use of the Trust and
                its Trustees and employees (for their use in administering the Trust). Milliman's
                work may not be provided to third parties without Milliman's prior written consent.
                Milliman's consent to release its work product to any third party may be conditioned
                on the third party signing a Release; subject to the following exceptions:
              </p>
              <ul>
                <li>
                  The Trust may provide a copy of Milliman's work, in its entirety to the Trust's
                  professional service advisors who are subject to a duty of confidentiality and who
                  agree to not use Milliman's work for any purpose other than to benefit the Trust.
                </li>
                <li>
                  The Trust may distribute certain work products that Milliman and the Trustees
                  mutually agree is appropriate for distribution to participating employers, pension
                  participants and other parties as may be required by the Pension Protection Act of
                  2006 and/or the Multiemployer Pension Reform Act of 2014.
                </li>
              </ul>
              <p>
                No third party recipient of Milliman's work product should rely upon Milliman's work
                product. Such recipients should engage qualified professionals for advice
                appropriate to their own specific needs.
              </p>
            </Notes>
          </Content>
        </Table>
        <SearchSidebar>
          <Search resources={resources} />
        </SearchSidebar>
      </Splitter>
    </Layout>
  );
};

export default InformationCenter;
