import { ADMIN_PUBLISHER_ROLE, ADMIN_ROLE, ADMIN_STANDARD_ROLE } from '../constants';
import { User } from '../models';

import helperUtils from './helper';

const isUser = (userType: number) => userType === 0;
const isAdmin = (userType: number) => userType !== 0;

const getRoleString = (userType: number) => {
  switch (userType) {
    case ADMIN_STANDARD_ROLE: {
      return 'Consultant';
    }
    case ADMIN_PUBLISHER_ROLE: {
      return 'Publisher';
    }
    case ADMIN_ROLE: {
      return 'Admin';
    }
    default: {
      return '';
    }
  }
};

const getSortedUsers = (users: User[]) => {
  const list = users?.filter(user => isUser(user.userType));
  return helperUtils.sort(list, 'fullName');
};

const getSortedConsultants = (users: User[]) => {
  const list = users.filter(user => isAdmin(user.userType));
  return helperUtils.sort(list, 'fullName');
};

export default {
  isUser,
  isAdmin,
  getRoleString,
  getSortedUsers,
  getSortedConsultants,
};
