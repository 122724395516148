import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';

import FormBase from '../../common/form';
import { FormProps as FormPropsBase } from '../../common/form';
import { routes } from '../../constants';
import { Api, ApiResponse } from '../../http/api';
import Layout from '../layout';

interface FormProps extends FormPropsBase {
  token: string;
}

interface FormState {
  messages: string[];
  compare: boolean;
  attempted: boolean;
  submitting: boolean;
  submitted: boolean;
}

const Confirm = styled(FormBase.Item)`
  // Style the input validation messages
  .ant-form-explain {
    font-size: 0.75rem;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
`;

class Form extends FormBase<FormProps, FormState> {
  private readonly PASSWORD_FIELD_NAME = 'password';
  private readonly CONFIRM_FIELD_NAME = 'confirm';

  constructor(props: FormProps) {
    super(props);

    this.state = {
      messages: null,
      compare: false,
      attempted: false,
      submitting: false,
      submitted: false,
    };
  }

  render(): React.ReactNode {
    const { getFieldError } = this.props.form;
    const { attempted, submitting, submitted } = this.state;

    // Work out which messages to show
    let { messages } = this.state;

    if (!messages && attempted) {
      messages = getFieldError(this.PASSWORD_FIELD_NAME) || getFieldError(this.CONFIRM_FIELD_NAME);
    }

    return (
      <FormBase
        layout="vertical"
        hideRequiredMark={true}
        onSubmit={ev => this.handleFormSubmit(ev)}
      >
        <FormBase.Item label="Enter new password:" validateStatus="" help="">
          {this.renderNewPasswordField()}
        </FormBase.Item>
        <Confirm
          label="Confirm password:"
          validateStatus=""
          help="Min 8 mixed case letters. Plus number and symbol."
        >
          {this.renderConfirmPasswordField()}
        </Confirm>
        <FormBase.Item>
          <Layout.Button disabled={submitted} loading={submitting}>
            Confirm
          </Layout.Button>
        </FormBase.Item>
        <Layout.Footer
          success={submitted ? 'Password updated, login page will reload in 3 seconds' : null}
          errors={messages}
        />
      </FormBase>
    );
  }

  private renderNewPasswordField(): React.ReactNode {
    const { getFieldDecorator } = this.props.form;
    const { submitting, submitted } = this.state;
    const decorator = getFieldDecorator(this.PASSWORD_FIELD_NAME, {
      rules: [
        {
          required: true,
          pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-)(]).{8,}$/,
          message: 'Password does not meet minimum specifications, try again',
        },
        {
          validator: (_: any, __: string, callback: (error: string) => void) =>
            this.validateNewPassword(callback),
        },
      ],
    });

    return decorator(
      <Input.Password placeholder="New password" disabled={submitting || submitted} />
    );
  }

  private renderConfirmPasswordField(): React.ReactNode {
    const { getFieldDecorator } = this.props.form;
    const { submitting, submitted } = this.state;
    const decorator = getFieldDecorator(this.CONFIRM_FIELD_NAME, {
      rules: [
        {
          validator: (_: any, value: string, callback: (error: string) => void) =>
            this.validateConfirmPassword(value, callback),
        },
      ],
    });

    return decorator(
      <Input.Password
        placeholder="Confirm password"
        disabled={submitting || submitted}
        onBlur={ev => this.handleConfirmPasswordBlur(ev)}
      />
    );
  }

  private validateNewPassword(callback: (error?: string) => void): void {
    const { validateFields } = this.props.form;
    const { compare } = this.state;

    if (compare) {
      validateFields([this.CONFIRM_FIELD_NAME], { force: true });
    }

    callback();
  }

  private validateConfirmPassword(value: string, callback: (error?: string) => void): void {
    const { getFieldValue } = this.props.form;
    const password = getFieldValue(this.PASSWORD_FIELD_NAME);

    if (value !== password) {
      callback('Passwords do not match, try again');
    } else {
      callback();
    }
  }

  private handleConfirmPasswordBlur(ev: React.FocusEvent<HTMLInputElement>): void {
    const { value } = ev.target;
    const { compare } = this.state;

    this.setState({
      compare: compare || !!value,
    });
  }

  private handleFormSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();

    // Flag the submit attempt
    this.setState({
      attempted: true,
    });

    // Validate the fields and post the data
    const { form } = this.props;
    form.validateFields((err, fieldsValue) => {
      if (!err) {
        this.setState({
          messages: null,
          submitting: true,
          submitted: false,
        });

        // Post the form data
        const token = new URL(document.location.href).searchParams.get('token');

        // tslint:disable-next-line: no-floating-promises
        Api.channel
          .post<ApiResponse>(`/api/user/resetpassword/?token=${token}`, {
            password: fieldsValue[this.PASSWORD_FIELD_NAME],
          })
          .then(({ data }) => {
            const success = data.status === Api.SUCCESS;
            this.setState({
              messages: success
                ? null
                : ['Reset password access has expired, please request again'],
              submitted: success,
            });

            if (success) {
              // Redirect to login after 3 secs
              window.setTimeout(() => (window.location.href = routes.auth.login), 3000);
            }
          })
          .catch(() => {
            this.setState({
              messages: ['An error has occurred'],
            });
          })
          .finally(() => {
            this.setState({
              submitting: false,
            });
          });
      }
    });
  }
}

export default FormBase.createType(Form);
