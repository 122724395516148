import React from 'react';

import Flex from '../../common/flex';
import FormBase from '../../common/form';
import storageUtils from '../../utils/storage';
import Layout from '../layout';

import Form from './form';

const REMEMBER_ME_KEY = '_remember_me';
const AGREE_TERMS_KEY = '_agree_terms';
const AGREE_TERMS_VALUE = 'true';

const Login: React.FC = props => {
  const rememberMe = storageUtils.get(REMEMBER_ME_KEY);
  const agreeTerms = storageUtils.get(AGREE_TERMS_KEY);
  const formProps = {
    ...props,
    rememberMe,
    agreeTerms: agreeTerms === AGREE_TERMS_VALUE,
    onLogin: (email: string) => {
      storageUtils.set(REMEMBER_ME_KEY, email);
      storageUtils.set(AGREE_TERMS_KEY, AGREE_TERMS_VALUE);
    },
  };

  return (
    <Layout fill={!agreeTerms}>
      <Flex.Col className="o-height-100">
        <Flex.Item>
          <Layout.Heading>Log in</Layout.Heading>
        </Flex.Item>
        <Flex.Item>
          <Layout.SubHeading>
            Welcome to the Milliman Online Projection Tool & Information Center
          </Layout.SubHeading>
        </Flex.Item>
        <Flex.Item fill={true}>{FormBase.renderType(Form, formProps)}</Flex.Item>
      </Flex.Col>
    </Layout>
  );
};

export default Login;
