import { Icon as IconBase } from 'antd';
import React from 'react';
import styled from 'styled-components';

import ButtonBase from '../../../common/button';
import ListTable from '../../../common/list-table';
import Modal from '../../../common/modal';
import { ADMIN_STANDARD_ROLE } from '../../../constants';
import { Profile, User } from '../../../models';
import theme from '../../../theme';
import FormLayout from '../../common/form-layout';

interface ListProps {
  className?: string;
  profile: Profile;
  users?: User[];
  onAdd: () => void;
  onEdit: (user: User) => void;
  onDelete: (user: User, callback: () => void) => void;
  onChange?: (user: User[]) => void;
}

interface ActionsCellProps {
  profile: Profile;
  user: User;
  onEdit: () => void;
  onDelete: () => void;
}

const Icon = styled(IconBase)`
  cursor: pointer;
`;

const Edit = styled(props => <Icon type="edit" {...props} />)`
  color: ${theme.color.millimanBlue};
`;

const Delete = styled(props => <Icon type="delete" {...props} />)`
  color: ${theme.color.cranberry};
  margin-left: 1rem;
`;

const Actions = styled.div`
  white-space: nowrap;
`;

const Button = styled(props => (
  <ButtonBase type="blue" {...props}>
    Add New User
  </ButtonBase>
))`
  margin-top: 0.5rem;
`;

const ActionsCell: React.FC<ActionsCellProps> = ({ profile, onEdit, onDelete }) => {
  const [show, setShow] = React.useState(false);
  return (
    <Actions>
      <Edit onClick={onEdit} />
      {profile?.userType !== ADMIN_STANDARD_ROLE && (
        <>
          <Delete onClick={() => setShow(true)} />
          <Modal
            title="Revoke Access"
            visible={show}
            onOk={() => {
              setShow(false);
              onDelete();
            }}
            okText="Delete"
            okType="danger"
            onCancel={() => setShow(false)}
          >
            <p>
              <FormLayout.WarningMessage>Warning:</FormLayout.WarningMessage> Deleting this client
              user will remove them from the system and cannot be restored.
            </p>
            <p>Are you sure you want to continue?</p>
          </Modal>
        </>
      )}
    </Actions>
  );
};

const List: React.FC<ListProps> = ({
  className,
  profile,
  users,
  onAdd,
  onEdit,
  onDelete,
  onChange,
}) => {
  const columns: any[] = [
    {
      key: 'fullName',
      title: 'Name',
      render: (user: User) => user.fullName,
    },
    {
      key: 'email',
      title: 'Email',
      render: (user: User) => user.email,
    },
    {
      key: 'clientIds',
      title: 'Boards',
      render: (user: User) => user.clientIds.length,
    },
    {
      key: 'actions',
      title: '',
      width: 85,
      align: 'right',
      render: (user: User) => (
        <ActionsCell
          profile={profile}
          user={user}
          onEdit={() => onEdit(user)}
          onDelete={() => {
            onDelete(user, () => {
              let items = users || [];
              items = items.filter(item => item.uniqueId !== user.uniqueId);

              if (onChange) {
                onChange(items);
              }
            });
          }}
        />
      ),
    },
  ];

  return (
    <div className={className}>
      <ListTable rowKey="uniqueId" columns={columns} data={users || []} />
      <Button onClick={onAdd} />
    </div>
  );
};

export default List;
