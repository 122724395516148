import { Divider as DividerBase, Icon, Popover, Radio } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import CaretBase from '../../../common/caret';
import Flex from '../../../common/flex';
import theme from '../../../theme';
import * as graphTypeState from '../graph-types/state';
import * as graphState from '../graph/state';

import { OutputType } from './state/models';
import { query } from './state/query';
import { store } from './state/store';

interface MenuProps {
  onSelect: (type: OutputType) => void;
  onClose: () => void;
}

const Heading = styled.div`
  color: ${theme.color.millimanBlue};
  font-weight: ${theme.weight.medium};
  margin-right: 0.25rem;
`;

const Selected = styled(Flex.Item)`
  cursor: pointer;
`;

const Caret = styled(({ className, rotate }) => (
  <Flex.Item>
    <CaretBase className={className} rotate={rotate} />
  </Flex.Item>
))`
  cursor: pointer;
  margin: 0 0.25rem;
`;

const Header = styled.div`
  color: ${theme.color.white};
  background-color: ${theme.color.millimanBlue};
  border-top-right-radius: ${theme.border.radius};
  border-top-left-radius: ${theme.border.radius};
  font-weight: ${theme.weight.medium};
  padding: 0.5rem 1rem;
`;

const Container = styled(Flex.Row)`
  flex-wrap: wrap;
  padding: 0.5rem;

  ${breakpoint('md')`
    width: 500px;
  `}
`;

const Type = styled(Flex.Item)`
  padding: 0.5rem;
  width: 100%;

  ${breakpoint('md')`
    width: 50%;
  `}
`;

const Name = styled.span`
  cursor: pointer;
`;

const Divider = styled(DividerBase)`
  color: ${theme.color.millimanBlue} !important;

  &::before {
    display: none !important;
  }

  &::after {
    border-top-color: ${theme.color.millimanBlue} !important;
  }
`;

const Close = styled(Flex.Item)`
  ${breakpoint('md')`
    display: none;
  `}
`;

const Menu: React.FC<MenuProps> = ({ onSelect, onClose }) => {
  const outputTypes = useObservable(() => query.outputTypes$, []);
  const multiTypes = outputTypes.filter(({ multi, enabled }) => multi && enabled);

  return (
    <>
      <Header>
        <Flex.Row justify="space-between">
          <Flex.Item>Optic&trade; Output Selection</Flex.Item>
          <Close>
            <Icon type="close" onClick={onClose} />
          </Close>
        </Flex.Row>
      </Header>
      <Container>
        {outputTypes
          .filter(({ multi, enabled }) => !multi && enabled)
          .map(type => (
            <Type key={type.id}>
              <Flex.Row>
                <Flex.Item>
                  <Radio checked={type.selected} onChange={() => onSelect(type)} />
                </Flex.Item>
                <Flex.Item>
                  <Name onClick={() => onSelect(type)}>{type.name}</Name>
                </Flex.Item>
              </Flex.Row>
            </Type>
          ))}
        {multiTypes.length > 0 && <Divider orientation="left">Multiple Outputs</Divider>}
        {multiTypes.map(type => (
          <Type key={type.id}>
            <Flex.Row>
              <Flex.Item>
                <Radio checked={type.selected} onChange={() => onSelect(type)} />
              </Flex.Item>
              <Flex.Item>
                <Name onClick={() => onSelect(type)}>{type.name}</Name>
              </Flex.Item>
            </Flex.Row>
          </Type>
        ))}
      </Container>
    </>
  );
};

const Outputs: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const graphType = useObservable(() => query.graphType$, null);
  const outputType = useObservable(
    () => query.outputTypes$.pipe(map(types => types.find(type => type.selected))),
    null
  );

  return (
    <Popover
      trigger="click"
      placement="bottom"
      visible={visible}
      content={
        <Menu
          onSelect={type => {
            setVisible(false);

            // Calculate if a graph type switch is needed (single -> multi and vice-versa)
            const multi = graphType === 'multi';
            if (type.multi !== multi) {
              graphTypeState.setGraphType(type.multi ? 'multi' : null);
            } else {
              graphState.refreshChart();
            }

            store.setSelectedId(type.id);
          }}
          onClose={() => setVisible(false)}
        />
      }
      overlayClassName="o-popover-menu"
      onVisibleChange={setVisible}
    >
      <Flex.Row>
        <Flex.Item>
          <Heading>Output:</Heading>
        </Flex.Item>
        {outputType && (
          <>
            <Selected>{outputType.name}</Selected>
            <Caret rotate={visible} />
          </>
        )}
      </Flex.Row>
    </Popover>
  );
};

export default Outputs;
