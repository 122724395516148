import { EntityStore, StoreConfig } from '@datorama/akita';

import { User } from '../../../../models';

import { UsersState } from './models';

@StoreConfig({
  name: 'Client Management > Users',
  idKey: 'uniqueId',
})
export class UsersStore extends EntityStore<UsersState, User, string> {}

export const store = new UsersStore();
