import { Store, StoreConfig } from '@datorama/akita';

import { Scenario } from '../../../models';

import { ScenariosState } from './models';

@StoreConfig({
  name: 'Client Management > Scenarios',
})
export class ScenariosStore extends Store<ScenariosState> {
  constructor() {
    super({
      scenarios: {},
      saveable: false,
      error: null,
    });
  }

  setScenarios(spreadsheetId: string, scenarios: Scenario[]): void {
    this.update(state => ({
      scenarios: {
        ...state.scenarios,
        [spreadsheetId]: [...scenarios],
      },
    }));
  }
}

export const store = new ScenariosStore();
