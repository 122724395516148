import { EntityStore, StoreConfig } from '@datorama/akita';

import { NotificationCategory } from '../../models';

import { NotificationData, NotificationEntity, NotificationState } from './models';

@StoreConfig({
  name: 'App > Notifications',
  idKey: 'uniqueId',
})
export class NotificationStore extends EntityStore<NotificationState, NotificationEntity> {
  constructor() {
    super({
      modelId: null,
      scenarioId: null,
      fileId: null,
    });
  }

  initialize(data: NotificationData[]): void {
    const notifications = data.map(({ category, ...props }) => ({
      ...props,
      category: category.toLowerCase() as NotificationCategory,
    }));

    this.add(notifications);
  }
}

export const store = new NotificationStore();
