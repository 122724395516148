import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled, { css } from 'styled-components';

import Flex from '../../../common/flex';
import * as outputState from '../outputs/state';

import Bar from './bar';
import Line from './line';
import Multi from './multi';
import { GraphType } from './state/models';
import { query } from './state/query';
import { store } from './state/store';

interface GraphTypesProps {
  loading: boolean;
}

const List = styled(Flex.Row)`
  flex-wrap: wrap;
  padding: 0 1rem;
`;

const Item = styled(Flex.Item)`
  padding: 1rem;
  width: 33%;
  max-width: 8rem;
`;

const Type = styled(({ enabled, ...props }) => <div {...props} />)`
  cursor: pointer;

  ${({ enabled }) =>
    !enabled &&
    css`
      cursor: not-allowed;
    `}

  svg {
    width: 4rem;
  }
`;

const getGraphTypeIcon = (type: GraphType) => {
  switch (type.id) {
    case 'line':
      return <Line {...type} />;
    case 'bar':
      return <Bar {...type} />;
    case 'multi':
      return <Multi {...type} />;
    default:
      return null;
  }
};

const GraphTypes: React.FC<GraphTypesProps> = ({ loading }) => {
  const graphTypes = useObservable(() => query.graphTypes$, []);
  const outputTypes = useObservable(() => outputState.outputTypes$, []);
  const outputType = outputTypes.find(current => current.selected);

  if (loading) {
    return null;
  }

  return (
    <List>
      {graphTypes
        .filter(graphType => graphType.multi === outputType?.multi)
        .map(graphType => (
          <Item key={graphType.id}>
            <Type
              enabled={graphType.enabled}
              onClick={() => {
                if (graphType.enabled && !graphType.selected) {
                  store.setSelectedId(graphType.id);

                  // Check the output selection is still valid
                  outputState.ensureSelectionForGraphType(graphType.id);
                }
              }}
            >
              {getGraphTypeIcon(graphType)}
            </Type>
          </Item>
        ))}
    </List>
  );
};

export default GraphTypes;
