import { css } from 'styled-components';

import InfoCenterMgtSvg from '../../assets/icon-info-center-mgt.svg';

import Icon from './icon';
import { IconProps, IconStyle } from './icon';

const style: IconStyle = (color: string) => css`
  svg {
    path {
      fill: ${color} !important;
      stroke: ${color} !important;
    }
  }
`;

export default class extends Icon {
  static style = style;

  constructor(props: IconProps) {
    super(InfoCenterMgtSvg, props, style);
  }
}
