import { Tag } from 'antd';
import styled from 'styled-components';

import theme from '../theme';

const Badge = styled(Tag)`
  color: ${theme.color.white};
  background-color: ${theme.color.millimanBlue};
  border-color: ${theme.color.millimanBlue};
  line-height: 1rem;
  margin-right: 0;
  padding: 0 0.25rem;
  opacity: 1 !important;
`;

export default Badge;
