import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import Radio from '../radio';

import { query } from './state/query';
import { store } from './state/store';

const Container = styled.div`
  padding: 0.5rem;
`;

const Tab = styled.div`
  display: inline-block;
  margin-right: 1rem;
`;

const Tabs: React.FC = () => {
  const scenarios = useObservable(() => query.scenarios$, []);
  return (
    <Container>
      {scenarios.map(scenario => (
        <Tab key={scenario.id} onClick={() => store.setSelectedId(scenario.id)}>
          <Radio color={scenario.color} enabled={scenario.selected} />
        </Tab>
      ))}
    </Container>
  );
};

export default Tabs;
