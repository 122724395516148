import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Client } from '../../models';

import { ClientEntity, ClientState } from './models';
import { ClientStore, store } from './store';

export class ClientQuery extends QueryEntity<ClientState, ClientEntity> {
  readonly selectedId$ = this.select(state => state.selectedId);
  readonly loaded$ = this.select(state => state.loaded);
  readonly email$ = this.select(state => state.email);

  readonly clients$: Observable<Client[]> = combineLatest([
    this.selectedId$,
    this.selectAll({
      sortBy: 'name',
    }),
  ]).pipe(
    map(([selectedId, entities]) =>
      entities.map(entity => ({
        ...entity,
        selected: entity.uniqueId === selectedId,
      }))
    )
  );

  constructor(_store: ClientStore) {
    super(_store);
  }
}

export const query = new ClientQuery(store);
