import { EntityStore, StoreConfig } from '@datorama/akita';

import {
  CreateScenarioState,
  Scenario,
  ScenarioData,
  ScenarioEntity,
  ScenarioState,
} from './models';

@StoreConfig({
  name: 'Projection Tool > Scenarios',
})
export class ScenarioStore extends EntityStore<ScenarioState, ScenarioEntity> {
  constructor() {
    super({
      enabledIds: [],
      createScenario: false,
      createScenarioState: {},
    });
  }

  initialize(scenarios: ScenarioData[]): void {
    const entities = scenarios.map(
      ({ uniqueId, name, milliman, canEdit, canDelete, modelId, dirty }) => ({
        id: uniqueId,
        name,
        milliman,
        canEdit,
        canDelete,
        modelId,
        dirty,
      })
    );

    this.remove(() => true);
    this.upsertMany(entities);

    this.update({
      createScenario: false,
      createScenarioState: {},
      enabledIds: scenarios.filter(({ enabled }) => enabled).map(({ uniqueId }) => uniqueId),
    });
  }

  addScenario(scenario: ScenarioData): void {
    this.add({
      id: scenario.uniqueId,
      name: scenario.name,
      milliman: scenario.milliman,
      canEdit: scenario.canEdit,
      canDelete: scenario.canDelete,
      modelId: scenario.modelId,
      dirty: scenario.dirty,
    });
  }

  updateScenarios(scenarios: ScenarioData[]): void {
    const entities = scenarios.map(
      ({ uniqueId, name, milliman, canEdit, canDelete, modelId, dirty }) => ({
        id: uniqueId,
        name,
        milliman,
        canEdit,
        canDelete,
        modelId,
        dirty,
      })
    );

    this.set(entities);

    this.update({
      enabledIds: scenarios.filter(({ enabled }) => enabled).map(({ uniqueId }) => uniqueId),
    });
  }

  updateScenario(scenario: Scenario): void {
    this.upsert(scenario.id, scenario);
  }

  removeScenario(id: string): void {
    this.remove(id);
  }

  setCreateScenario(createScenario: boolean): void {
    this.update({
      createScenario,
    });
  }

  setCreateScenarioState(createScenarioState: CreateScenarioState): void {
    this.update({
      createScenarioState,
    });
  }
}

export const store = new ScenarioStore();
