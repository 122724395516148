import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import FormBase from '../../../common/form';
import LoaderBase from '../../../common/loader';
import Select from '../../../common/select';
import FormLayout from '../../common/form-layout';
import { Scenario, Spreadsheet } from '../../models';
import Buttons from '../buttons';
import * as spreadsheetState from '../spreadsheets/state';

import * as scenarioState from './state';
import TransferBase from './transfer';

interface ScenariosProps {
  scenarios: Scenario[];
  onSelect: (value: string) => void;
  onChange: (values: any) => void;
  onSave: () => void;
  onCancel: () => void;
}

interface FormProps extends ScenariosProps {
  spreadsheetId: string;
  spreadsheets: Spreadsheet[];
  loading: boolean;
}

const Loader = styled(LoaderBase)`
  justify-content: flex-start;
  margin-top: 0.5rem;
`;

const Transfer = React.forwardRef<any, any>((props, _) => <TransferBase {...props} />);

const Error: React.FC = () => {
  const message = useObservable(() => scenarioState.error$, null);
  return message ? <FormLayout.Error message={message} /> : null;
};

class Form extends FormBase<FormProps, {}> {
  private readonly SCENARIOS_FIELD_NAME = 'scenarios';

  render(): React.ReactNode {
    const { spreadsheetId, spreadsheets, loading, onSelect, onCancel } = this.props;
    return (
      <FormLayout.Form onSubmit={e => this.handleSubmit(e)}>
        <FormLayout.Heading>Customize Model Data</FormLayout.Heading>
        <FormLayout.Field label="Select model:">
          <Select value={spreadsheetId} onChange={onSelect}>
            {spreadsheets.map(current => (
              <Select.Option key={current.uniqueId} value={current.uniqueId}>
                {current.label}
              </Select.Option>
            ))}
          </Select>
          {loading && <Loader />}
        </FormLayout.Field>
        {!loading && (
          <>
            <FormLayout.TableListField label="Scenarios:">
              {this.renderScenarioTransfer()}
            </FormLayout.TableListField>
            <Error />
            <Buttons savableObservable={scenarioState.saveable$} onCancel={onCancel} />
          </>
        )}
      </FormLayout.Form>
    );
  }

  private renderScenarioTransfer(): React.ReactNode {
    const { form, scenarios } = this.props;
    const decorator = form.getFieldDecorator(this.SCENARIOS_FIELD_NAME, {
      initialValue: scenarios,
      valuePropName: 'scenarios',
    });

    return decorator(<Transfer />);
  }

  private handleSubmit(e: React.FormEvent<HTMLElement>): void {
    e.preventDefault();

    // Trigger the onUpdate handler
    const { form, onSave } = this.props;
    form.validateFields(err => {
      if (!err && onSave) {
        onSave();
      }
    });
  }
}

const Scenarios: React.FC<ScenariosProps> = ({ onChange, ...props }) => {
  const spreadsheetId = useObservable(() => spreadsheetState.contentSelectedId$, null);
  const spreadsheets = useObservable(() => spreadsheetState.spreadsheets$, []);
  const loading = useObservable(() => spreadsheetState.contentLoading$, false);

  return (
    <>
      {FormBase.renderType(
        Form,
        {
          spreadsheetId,
          spreadsheets,
          loading,
          ...props,
        },
        onChange
      )}
    </>
  );
};

export default Scenarios;
