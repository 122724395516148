import { Icon as IconBase } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import FormBase from '../../../common/form';
import Modal from '../../../common/modal';
import { ADMIN_ROLE } from '../../../constants';
import { Profile } from '../../../models';
import theme from '../../../theme';
import ConsultantSearchBase from '../../common/consultant-search';
import ConsultantSelect from '../../common/consultant-select';
import FormLayout from '../../common/form-layout';
import { Consultant } from '../../models';
import Buttons from '../buttons';

import { query } from './state/query';

interface ScenariosProps {
  profile: Profile;
  consultants: Consultant[];
  admin: string;
  deletable: string[];
  onChange: (values: any) => void;
  onSave: () => void;
  onCancel: () => void;
}

const Icon = styled(IconBase)`
  color: ${theme.color.cranberry};
`;

interface FormProps extends ScenariosProps {}

interface DeleteProps {
  onDelete: () => void;
}

const ConsultantSearch = React.forwardRef<any, any>((props, _) => (
  <ConsultantSearchBase {...props} />
));

const DeleteIcon: React.FC<DeleteProps> = ({ onDelete }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Icon type="delete" onClick={() => setVisible(true)} />
      <Modal
        title="Revoke Access"
        visible={visible}
        onOk={() => {
          setVisible(false);
          onDelete();
        }}
        okText="Revoke"
        okType="danger"
        onCancel={() => setVisible(false)}
      >
        <p>
          <FormLayout.WarningMessage>Warning:</FormLayout.WarningMessage> You are about to revoke
          this consultant's access to the selected client's account.
        </p>
        <p>Are you sure you want to continue?</p>
      </Modal>
    </>
  );
};

const Error: React.FC = () => {
  const message = useObservable(() => query.error$, null);
  return message ? <FormLayout.Error message={message} /> : null;
};

class Form extends FormBase<FormProps, {}> {
  private readonly CONSULTANTS_FIELD_NAME = 'consultants';
  private readonly ADMIN_FIELD_NAME = 'admin';

  render(): React.ReactNode {
    const { onCancel } = this.props;
    return (
      <FormLayout.Form onSubmit={e => this.handleSubmit(e)}>
        <FormLayout.Heading>Add Consultant</FormLayout.Heading>
        {this.renderConsultantsField()}
        <FormLayout.Field label="Main admin:">{this.renderAdminField()}</FormLayout.Field>
        <Error />
        <Buttons savableObservable={query.saveable$} onCancel={onCancel} />
      </FormLayout.Form>
    );
  }

  private renderConsultantsField(): React.ReactNode {
    const { form, profile, consultants, admin, deletable } = this.props;
    const admins = consultants.filter(
      consultant => consultant.selected && consultant.userType === ADMIN_ROLE
    );

    const decorator = form.getFieldDecorator(this.CONSULTANTS_FIELD_NAME, {
      initialValue: consultants,
      valuePropName: 'consultants',
    });

    const resolveAdmin = (consultant: Consultant) => {
      // Ensure another default admin is selected if the current is deleted
      if (consultant.uniqueId === admin) {
        const [first] = admins.filter(({ uniqueId }) => uniqueId !== admin);
        form.setFieldsValue({
          admin: first.uniqueId,
        });
      }
    };

    return decorator(
      <ConsultantSearch
        deleteIcon={(consultant: Consultant, callback: () => void) => {
          if (profile?.userType === ADMIN_ROLE) {
            // Admin - delete anyone, but 1 admin must remain
            if (consultant.userType !== ADMIN_ROLE || admins.length > 1) {
              return (
                <DeleteIcon
                  onDelete={() => {
                    resolveAdmin(consultant);
                    callback();
                  }}
                />
              );
            }
          } else {
            // Non-admin - only delete consultants added in the current save session
            if (deletable.indexOf(consultant.uniqueId) > -1) {
              return (
                <DeleteIcon
                  onDelete={() => {
                    resolveAdmin(consultant);
                    callback();
                  }}
                />
              );
            }
          }

          return null;
        }}
      />
    );
  }

  private renderAdminField(): React.ReactNode {
    const { form, consultants, admin } = this.props;
    const admins = consultants.filter(
      consultant => consultant.selected && consultant.userType === ADMIN_ROLE
    );

    const decorator = form.getFieldDecorator(this.ADMIN_FIELD_NAME, {
      initialValue: admin,
      valuePropName: 'selectedId',
    });

    return decorator(<ConsultantSelect consultants={admins} />);
  }

  private handleSubmit(e: React.FormEvent<HTMLElement>): void {
    e.preventDefault();

    // Trigger the onUpdate handler
    const { form, onSave } = this.props;
    form.validateFields(err => {
      if (!err && onSave) {
        onSave();
      }
    });
  }
}

const Consultants: React.FC<ScenariosProps> = ({ onChange, ...props }) => (
  <>{FormBase.renderType(Form, props, onChange)}</>
);

export default Consultants;
