import { User } from '../../../../models';

import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const users$ = query.users$;

export const setUsers = (users: User[]) => {
  store.remove();
  store.upsertMany(users);
};
