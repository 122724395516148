import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import LineChartSvg from '../../../assets/icon-line-chart.svg';
import theme from '../../../theme';

import Icon from './icon';

const Line = styled(props => (
  <Icon {...props}>
    <LineChartSvg />
  </Icon>
))`
  #line-1,
  #line-2 {
    stroke: ${theme.color.steel} !important;
  }

  #line-3 {
    fill: ${theme.color.steel} !important;
  }

  ${({ selected, enabled }) =>
    enabled &&
    !selected &&
    css`
      &:hover {
        #line-1 {
          stroke: ${theme.color.gold} !important;
        }

        #line-2 {
          stroke: ${theme.color.sky} !important;
        }

        #line-3 {
          fill: ${theme.color.slate} !important;
        }
      }
    `}

  ${({ selected, enabled }) =>
    enabled &&
    selected &&
    css`
      #line-1 {
        stroke: ${theme.color.gold} !important;
      }

      #line-2 {
        stroke: ${theme.color.sky} !important;
      }

      #line-3 {
        fill: ${theme.color.black} !important;
      }
    `}
`;

export default Line;
