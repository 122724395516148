import { Icon as IconBase, Pagination, Table as TableBase } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { ColumnProps, PaginationConfig, SorterResult, TableProps } from 'antd/lib/table';
import React from 'react';
import { Resizable } from 'react-resizable';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Api, ApiResponse } from '../http/api';
import { Resource } from '../models';
import * as bannerState from '../state/banner';
import * as clientState from '../state/clients';
import theme from '../theme';

import Button from './button';
import EmptyBase from './empty';
import Flex from './flex';
import LoaderBase from './loader';
import Modal from './modal';
import ResourceIcon from './resource-icon';

type ResourceTableProps = TableProps<Resource> & {
  selectedIds?: string[];
  showDelete?: boolean;
  onResourceDeleted: (resource: string[]) => void;
};

interface ResourceTableColumnProps {
  resizable: boolean;
  width: number;
  onClick: (ev: any) => void;
  onResize: (size: number) => void;
}

interface ResourceTableFooterProps {
  total: number;
  showDelete?: boolean;
  selectedKeys: any[];
  onPageChange: (page: number) => void;
  onResourceDeleted: (resource: string[]) => void;
}

const Loader = styled(LoaderBase)`
  margin-top: 2rem;
`;

const Table = styled(TableBase)`
  // Hide the table pagination use a custom one (so it can be placed in the footer)
  .o-table-pagination {
    display: none;
  }
`;

const TableHeader = styled.th`
  border-right: 1px solid #e8e8e8;
  position: relative;

  .react-resizable-handle {
    cursor: col-resize;
    position: absolute;
    bottom: 0;
    right: -5px;
    height: 100%;
    width: 10px;
    z-index: 1;
  }
`;

const Empty = styled(({ className, text }) => (
  <div className={className}>
    <EmptyBase text={text} />
  </div>
))`
  margin-top: 2rem;
`;

const DeleteIcon = styled(IconBase)`
  color: ${theme.color.cranberry};
`;

const DownloadButton = styled(Button)`
  margin-right: 0.5rem;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint('md')`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const SimplePagination = styled(Flex.Item)`
  margin-bottom: 0.5rem;

  ${breakpoint('md')`
    display: none;
  `}
`;

const FullPagination = styled(Flex.Item)`
  display: none;

  ${breakpoint('md')`
    display: block;
  `}
`;

// Compare two dates (could be of any type supported by the convert
// function above) and returns:
//  -1 : if a < b
//   0 : if a = b
//   1 : if a > b
const compareDates = (a: Date, b: Date) => {
  const aVal = a.valueOf();
  const bVal = b.valueOf();
  return (aVal > bVal ? 1 : 0) - (aVal < bVal ? 1 : 0);
};

interface DeleteFileModalProps extends ModalProps {
  isButton: boolean;
  resources: string[];
  onResourceDeleted: (resource: string[]) => void;
}

const DeleteFileModal: React.FC<DeleteFileModalProps> = ({
  resources,
  onResourceDeleted,
  onOk,
  isButton,
  ...props
}) => {
  const clientId = useObservable(() => clientState.selectedId$, null);
  const [visible, setVisible] = React.useState(false);
  const deleteFile = (clientId: string, files: string[]): void => {
    bannerState.setDeletingBanner();

    // tslint:disable-next-line: no-floating-promises
    Api.channel
      .post<ApiResponse>(`/api/client/${clientId}/files/delete`, { files })
      .then(({ data }) => {
        if (data.status === Api.SUCCESS) {
          setVisible(false);
          onResourceDeleted(resources);
        }
      })
      .finally(() => {
        bannerState.clearBanner();
      });
  };

  return (
    <>
      {isButton && (
        <Button type="red" onClick={() => setVisible(true)} disabled={resources.length === 0}>
          Delete
        </Button>
      )}
      {!isButton && <DeleteIcon type="delete" onClick={() => setVisible(true)} />}
      <Modal
        title="Delete Files"
        visible={visible}
        onOk={e => {
          deleteFile(clientId, resources);
          setVisible(false);
          if (onOk) {
            onOk(e);
          }
        }}
        okText="Delete"
        okType="danger"
        onCancel={() => setVisible(false)}
        {...props}
      >
        <p>Are you sure you wish to delete the selected file(s)?</p>
      </Modal>
    </>
  );
};

const ResourceTableColumn: React.FC<ResourceTableColumnProps> = ({
  resizable,
  onClick,
  onResize,
  ...props
}) => {
  const [allowClick, setAllowClick] = React.useState(true);

  if (resizable) {
    return (
      <Resizable
        height={0}
        width={props.width}
        draggableOpts={{ enableUserSelectHack: false }}
        onResizeStart={() => setAllowClick(false)}
        onResizeStop={() => setTimeout(() => setAllowClick(true))}
        onResize={(_: any, data: any) => onResize(data.size.width)}
      >
        <TableHeader
          onClick={ev => {
            if (allowClick && onClick) {
              onClick(ev);
            }
          }}
          {...props}
        />
      </Resizable>
    );
  }

  return <th onClick={onClick} {...props} />;
};

const ResourceTableFooter: React.FC<ResourceTableFooterProps> = ({
  total,
  showDelete,
  selectedKeys,
  onResourceDeleted,
  onPageChange,
}) => {
  const clientId = useObservable(() => clientState.selectedId$, null);
  return (
    <FooterContainer>
      <SimplePagination>
        <Pagination
          simple
          defaultCurrent={1}
          total={total}
          hideOnSinglePage={false}
          showLessItems={true}
          onChange={onPageChange}
        />
      </SimplePagination>
      <Flex.Item>
        <Flex.Row>
          <Flex.Item>
            <form action={`/api/client/${clientId}/files/download`} method="post">
              {selectedKeys.map(key => (
                <input type="hidden" name="Files" id="Files" key={key} value={key} />
              ))}
              <DownloadButton htmlType="submit" disabled={selectedKeys.length === 0}>
                Download
              </DownloadButton>
            </form>
          </Flex.Item>
          {showDelete && (
            <Flex.Item>
              <DeleteFileModal
                resources={selectedKeys}
                onResourceDeleted={onResourceDeleted}
                isButton={true}
              />
            </Flex.Item>
          )}
        </Flex.Row>
      </Flex.Item>
      <FullPagination>
        <Pagination
          size="small"
          defaultCurrent={1}
          total={total}
          hideOnSinglePage={false}
          showLessItems={true}
          onChange={onPageChange}
        />
      </FullPagination>
    </FooterContainer>
  );
};

const ResourceTable: React.FC<ResourceTableProps> = ({
  selectedIds,
  showDelete,
  onResourceDeleted,
  loading,
  ...props
}) => {
  const clientId = useObservable(() => clientState.selectedId$, null);
  const [tableInfo, setTableInfo] = React.useState<{ sorter: SorterResult<Resource> }>({
    sorter: {} as any,
  });

  const [selectedKeys, setSelectedKeys] = React.useState<any[]>([]);
  const [columnWidth, setColumnWidth] = React.useState(200);
  const [page, setPage] = React.useState(1);

  const handleResourceDeleted = (resources: string[]) => {
    setSelectedKeys([]);
    onResourceDeleted(resources);
  };

  const columns = [
    {
      title: 'File name',
      dataIndex: 'fileName',
      key: 'fileName',
      sorter: (a, b) => a.fileName.localeCompare(b.fileName),
      sortOrder: tableInfo.sorter.columnKey === 'fileName' && tableInfo.sorter.order,
      render: (_, resource) => (
        <a href={`/api/client/${clientId}/files/${resource.uniqueId}`} download>
          {resource.fileName}
        </a>
      ),
      onHeaderCell: () => ({
        resizable: true,
        width: columnWidth,
        onResize: setColumnWidth,
      }),
    },
    {
      title: 'Uploaded',
      dataIndex: 'created',
      key: 'created',
      width: 100,
      sorter: (a, b) => compareDates(a.created, b.created),
      sortOrder: tableInfo.sorter.columnKey === 'created' && tableInfo.sorter.order,
      render: (_, resource) => resource.created.toLocaleDateString(),
    },
    {
      title: 'Types',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      sorter: (a, b) => a.fileType.join(', ').localeCompare(b.fileType.join(', ')),
      sortOrder: tableInfo.sorter.columnKey === 'type' && tableInfo.sorter.order,
      render: (_, resource) => resource.fileType.join(', '),
    },
    {
      title: 'Format',
      dataIndex: 'format',
      key: 'format',
      align: 'center',
      width: 100,
      sorter: (a, b) => a.format.localeCompare(b.format),
      sortOrder: tableInfo.sorter.columnKey === 'format' && tableInfo.sorter.order,
      render: (_, resource) => <ResourceIcon format={resource.format} />,
    },
  ] as ColumnProps<Resource>[];

  if (showDelete) {
    columns.push({
      title: '',
      align: 'center',
      width: 30,
      fixed: 'right',
      render: (_, resource) => (
        <DeleteFileModal
          resources={[resource.uniqueId]}
          onResourceDeleted={handleResourceDeleted}
          isButton={false}
        />
      ),
    });
  }

  const handleChange = (_: PaginationConfig, __: any, sorter: SorterResult<Resource>) => {
    setTableInfo({ sorter });
  };

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: setSelectedKeys,
  };

  React.useEffect(() => {
    if (selectedIds) {
      setSelectedKeys(selectedIds);
    }
  }, [selectedIds]);

  // Can't seem to set the no data image in the table
  if (loading) {
    return <Loader />;
  }

  if (props.dataSource.length === 0) {
    return <Empty text="No resources" />;
  }

  return (
    <Table
      bordered={false}
      columns={columns}
      rowKey="uniqueId"
      size="middle"
      onChange={handleChange}
      rowSelection={rowSelection}
      pagination={{
        className: 'o-table-pagination',
        current: page,
        defaultCurrent: 1,
      }}
      scroll={{ x: '100%' }}
      components={{
        header: {
          cell: ResourceTableColumn,
        },
      }}
      footer={() => (
        <ResourceTableFooter
          total={props.dataSource.length}
          showDelete={showDelete}
          selectedKeys={selectedKeys}
          onResourceDeleted={handleResourceDeleted}
          onPageChange={setPage}
        />
      )}
      {...props}
    />
  );
};

export default ResourceTable;
