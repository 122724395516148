import _ from 'lodash';

const normalize = (value: any) =>
  `${value}`
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(segment => segment.toLowerCase().trim())
    .join('-');

const sort = (list: any[], key?: string) =>
  _.sortBy(list, item => {
    // Ensure a lower case compare is done
    const value = key ? item[key] : item;
    return value?.toLowerCase();
  });

const isNumber = (value: any) => {
  if (!value && value !== 0) {
    return false;
  }

  return !isNaN(value);
};

const ensureSelection = (
  list: any[],
  predicate: (item: any) => boolean = null,
  fallback: any = null
) => {
  // Check the list is valid
  if (!list || list.length === 0) {
    return null;
  }

  // Then find the match and return a specified fallback or first in the list
  let match = predicate ? list.find(predicate) : null;
  if (!match) {
    match = fallback;
  }

  if (!match) {
    const [first] = list;
    return first;
  }

  return match;
};

export default { normalize, sort, isNumber, ensureSelection };
