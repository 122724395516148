import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const name$ = query.name$;
export const address$ = query.address$;
export const saveable$ = query.saveable$;
export const error$ = query.error$;

export const setValues = (name: string, address: string) =>
  store.update({
    name,
    address,
  });

export const setSaveable = (saveable: boolean) =>
  store.update({
    saveable,
  });

export const setError = (error?: string) =>
  store.update({
    error,
  });
