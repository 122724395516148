import { Select as SelectBase } from 'antd';
import { SelectProps as SelectPropsBase } from 'antd/lib/select';
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import theme from '../theme';

import Caret from './caret';

interface SelectProps extends SelectPropsBase {
  borderless?: boolean;
  fullWidth?: boolean;
  size?: 'default' | 'large';
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const Select = styled(({ borderless, fullWidth, size, ...props }) => <SelectBase {...props} />)`
  ${({ fullWidth }) =>
    fullWidth !== false &&
    css`
      width: 100%;
    `}

  // Override the ANTD styles
  .ant-select-selection {
    ${({ borderless }) =>
      borderless === true &&
      css`
        border: 0;
        box-shadow: none;
      `}

    .ant-select-selection-selected-value {
      width: 100%;
    }

    ${({ size }) =>
      size === 'large' &&
      css`
        .ant-select-selection-selected-value {
          font-size: ${theme.size.subHeading};
          font-weight: ${theme.weight.medium};
        }
      `}

    .ant-select-arrow {
      right: 0.5rem;
    }
  }
`;

export default class extends React.Component<SelectProps> {
  static Group = SelectBase.OptGroup;
  static Option = SelectBase.Option;

  render(): React.ReactNode {
    const { children, ...props } = this.props;
    return (
      <Select
        suffixIcon={<Caret />}
        dropdownMatchSelectWidth={props.dropdownMatchSelectWidth === true ? true : false}
        {...props}
      >
        {children}
      </Select>
    );
  }
}
