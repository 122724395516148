import ClientMgtSvg from '../../assets/icon-client-mgt.svg';

import Icon from './icon';
import { IconProps } from './icon';

export default class extends Icon {
  constructor(props: IconProps) {
    super(ClientMgtSvg, props);
  }
}
