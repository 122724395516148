import {
  CellPosition,
  ColDef,
  ColumnApi,
  GridApi,
  ICellEditorParams,
  IHeaderParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Icon, Input as InputBase, Tooltip } from 'antd';
import numeral from 'numeral';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled from 'styled-components';

import Flex from '../../../common/flex';
import theme from '../../../theme';
import formatUtils from '../common/format';

import { InputType, InputValues } from './state/models';
import { query } from './state/query';
import { store } from './state/store';

interface GridApis {
  gridApi: GridApi;
  columnApi: ColumnApi;
}

interface MoveContext {
  offset: number;
  allow: (cell: CellPosition, apis: GridApis) => boolean;
}

interface CopyContext {
  value: string;
}

interface CellEditorProps extends ICellEditorParams {
  value: string;
  setValue: (value: string) => void;
  copy: (value: string) => void;
  moveUp: () => void;
  moveDown: () => void;
}

const Container = styled.div`
  flex: 1 1 auto;
  position relative;

  // This is created by ag-grid and needs positioning for safari
  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .o-empty {
    background-color: ${theme.color.panelGrey};
  }

  .ag-root {
    border: 0;

    .ag-row {
      border: 0;
    }
  }
`;

const HeaderName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 70px !important;
  white-space: break-spaces;
`;

const NoInputs = styled.div`
  padding: 0 0.5rem;
  width: 100%;
  text-align: center;
  color: ${theme.color.vibrantBlue};
`;

const Input = styled(InputBase)`
  color: ${theme.color.vibrantBlue};
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 0.75rem;
  margin-bottom: 4px;
  padding: 0 0.5rem;
  height: calc(100% - 2px);

  &:focus {
    box-shadow: none;
  }
`;

const Copy = styled(Icon)`
  cursor: pointer;
  color: ${theme.color.spring};
  font-size: 1rem;
  margin: 2px 2px 0 0;
`;

const Header = (params: IHeaderParams) => {
  const { displayName } = params;
  return (
    <Tooltip title={displayName} placement="bottom">
      <HeaderName>{displayName}</HeaderName>
    </Tooltip>
  );
};

const CellEditor = React.forwardRef<any, CellEditorProps>((props, ref) => {
  const inputRef = React.useRef();
  const [value, setValue] = React.useState(props.value);
  const [focused, setFocused] = React.useState(false);

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      if (value !== null && value !== 'N/A' && (Number.isNaN(Number(value)) || value === '')) {
        return '0';
      }
      return value;
    },
  }));

  React.useEffect(() => {
    const element: any = inputRef.current;
    setTimeout(() => {
      try {
        // Can sometimes error with input = null - I think this is the hmr, wrap in try..catch just cos
        // Also only allow focus to happen once to avoid entry overwrites
        if (element && !focused) {
          element.select();
          setFocused(true);
        }
      } catch {
        // Do nothing
      }
    }, 10);
  }, []);

  return (
    <Flex.Row>
      <Flex.Item fill={true}>
        <Input
          ref={inputRef}
          value={value}
          maxLength={12}
          onChange={ev => {
            const value = ev.target.value.trim();
            const regex = /^(\-)?[0-9]{0,10}(\.[0-9]*)?$/;

            if (value === '' || regex.test(value)) {
              setValue(value);
            }
          }}
          onKeyDown={ev => {
            const key = typeof ev.which === 'undefined' ? ev.keyCode : ev.which;
            const map: any = {
              38: () => props.moveUp(),
              40: () => props.moveDown(),
            };

            // Match the key action
            const action = map[key];
            if (action) {
              action();

              // Prevent the default key action
              ev.preventDefault();
              ev.stopPropagation();
            }
          }}
        />
      </Flex.Item>
      <Flex.Item>
        <Copy type="down-circle" theme="filled" onClick={() => props.copy(value)} />
      </Flex.Item>
    </Flex.Row>
  );
});

const setInputUpdates = (types: InputType[], values: InputValues) => {
  // The store holds percent as a decimal value
  // Therefore need to convert percent values
  const data = { ...values };
  for (const type of types) {
    if (type.formatType === 'percent') {
      const value = data[type.id];
      if (value && !isNaN(value)) {
        // Divide by 100 to get the decimal value
        const converted = numeral(value)
          .divide(100)
          .value();

        data[type.id] = `${converted}`;
      }
    }
  }

  store.setInputUpdates(data);
};

const Grid: React.FC = () => {
  const ref = useObservable(() => query.ref$, 0);
  const inputTypes = useObservable(() => query.inputTypes$, []);
  const inputValues = useObservable(() => query.inputValues$, []);
  const panelId = useObservable(() => query.panelId$, null);
  const split = useObservable(() => query.split$, 0);
  const selected = useObservable(
    () => query.scenarios$.pipe(map(scenarios => scenarios.find(current => current.selected))),
    null
  );

  const [apis, setApis] = React.useState<GridApis>();
  const [copy, setCopy] = React.useState<CopyContext>();
  const [move, setMove] = React.useState<MoveContext>();

  const canEdit = (value: any) => value !== null && selected && selected.canEdit;

  // Resize of the split changes
  React.useEffect(() => {
    if (apis && panelId === 'inputs') {
      apis.gridApi.sizeColumnsToFit();
    }
  }, [panelId, split]);

  // Resize of the window is resizes
  React.useEffect(() => {
    const handleResize = () => {
      if (apis) {
        apis.gridApi.sizeColumnsToFit();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle values being copied down
  React.useEffect(() => {
    if (apis) {
      const cells = apis.gridApi.getEditingCells();
      if (cells.length > 0) {
        // Should only be one cell that is editing
        const [cell] = cells;

        // Apply the value to all the cells below
        const id = cell.column.getColId();
        apis.gridApi.forEachNode((node, index) => {
          if (index > cell.rowIndex) {
            // Work out if we have a value or not
            let value = node.data[id];
            if (value === undefined || value === null) {
              value = '';
            }

            // Only copy down if there is a value to overwrite
            if (value.length > 0) {
              node.setDataValue(id, copy.value);

              // Update the store
              setInputUpdates(inputTypes, node.data);
            }
          }
        });
      }
    }
  }, [copy]);

  // Handle key navigation around the grid
  React.useEffect(() => {
    if (apis) {
      const cells = apis.gridApi.getEditingCells();
      if (cells.length > 0) {
        const [cell] = cells;

        // Run an allow check to verify the move is permitted
        if (move.allow(cell, apis)) {
          apis.gridApi.stopEditing();
          apis.gridApi.startEditingCell({
            rowIndex: cell.rowIndex + move.offset,
            colKey: cell.column.getColId(),
          });
        }
      }
    }
  }, [move]);

  // Don't render the grid if there is no selection
  // This is mainly a hack to cater for model switches
  if (!selected) {
    return null;
  }

  return (
    <Container key={ref} className="o-grid">
      {inputTypes.length > 0 && inputValues.length > 0 ? (
        <AgGridReact
          gridOptions={{
            columnDefs: [
              {
                field: 'year',
                headerName: 'Year',
                headerComponentFramework: Header,
                pinned: true,
                minWidth: 60,
                maxWidth: 60,
              },
              ...inputTypes.map(type => {
                const colDef: ColDef = {
                  field: type.id,
                  headerName: type.name,
                  headerComponentFramework: Header,
                  editable: params => {
                    const colId = params.column.getColId();
                    return canEdit(params.data[colId]);
                  },
                  minWidth: 80,
                  cellEditorFramework: CellEditor,
                  cellEditorParams: {
                    copy: (value: string) =>
                      setCopy({
                        value,
                      }),
                    moveUp: () =>
                      setMove({
                        offset: -1,
                        allow: cell => cell.rowIndex > 0,
                      }),
                    moveDown: () =>
                      setMove({
                        offset: 1,
                        allow: (cell, apis) => {
                          const rowCount = apis.gridApi.getDisplayedRowCount();
                          return cell.rowIndex < rowCount - 1;
                        },
                      }),
                  },
                  cellClassRules: {
                    'o-empty': (params: any) => !canEdit(params.value),
                  },
                  valueFormatter: params => {
                    // Need to divide percentages by 100 so allow for formatting adjustments
                    const div = type.formatType === 'percent' ? 100 : 1;
                    return formatUtils.format(
                      type.formatType,
                      params.value,
                      div,
                      type.decimalPlaces
                    );
                  },
                };

                return colDef;
              }),
            ],
            rowData: inputValues,
            headerHeight: 85,
            rowHeight: 23,
            singleClickEdit: true,
            rowSelection: 'multiple',
            rowDeselection: true,
            suppressLoadingOverlay: true,
            suppressNoRowsOverlay: true,
            stopEditingWhenGridLosesFocus: true,
            suppressMovableColumns: true,
            onGridReady: ev => {
              // Force the columns to resize
              ev.api.sizeColumnsToFit();

              // Set the grid apis for access in other handlers
              setApis({
                gridApi: ev.api,
                columnApi: ev.columnApi,
              });
            },
            onCellEditingStopped: ev => setInputUpdates(inputTypes, ev.data),
          }}
        />
      ) : (
        <NoInputs>
          No input data available, please contact your Milliman consultant for help.
        </NoInputs>
      )}
    </Container>
  );
};

export default Grid;
