import { CreateScenarioState, Scenario, ScenarioData, ScenarioEntity } from './models';
import { query } from './query';
import { ScenarioStore, store } from './store';

export { Scenario, ScenarioData, ScenarioEntity };

// Expose an API for others to consume
export const scenarios$ = query.scenarios$;
export const createScenario$ = query.createScenario$;
export const createScenarioState$ = query.createScenarioState$;
export const modelId$ = query.modelId$;

export const addScenario = (scenario: ScenarioData) => store.addScenario(scenario);
export const initialize = (scenarios: ScenarioData[]) => store.initialize(scenarios);
export const updateScenario = (scenario: Scenario) => store.updateScenario(scenario);

export const setCreateScenario = (createScenario: boolean) =>
  store.setCreateScenario(createScenario);

export const setCreateScenarioState = (createScenarioState: CreateScenarioState) =>
  store.setCreateScenarioState(createScenarioState);
