import { Icon, Spin } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import Admin from '../admin';
import App from '../app';
import Auth from '../auth';
import Flex from '../common/flex';
import TimeoutDialog from '../common/timeout-dialog';
import { ADMIN_ROLES, routes } from '../constants';
import { Api, ApiResponse } from '../http/api';
import { User } from '../models';
import Router from '../router';
import * as clientState from '../state/clients';
import * as notificationState from '../state/notifications';
import * as profileState from '../state/profile';
import { Theme } from '../theme';

interface ProfileData extends profileState.ProfileData, ApiResponse {
  clients: clientState.ClientData[];
  notifications: notificationState.NotificationData[];
}

const Container = styled(Flex.Row)`
  height: 100vh;
`;

const Loader = styled(Icon)`
  font-size: 3.5rem;
`;

const Index: React.FC = () => {
  const profile = useObservable(() => profileState.profile$, null);
  const clientId = useObservable(() => clientState.selectedId$, null);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    Api.channel
      .get<ProfileData>('/api/user/profile')
      .then(({ data }) => {
        profileState.initialize(data);
        clientState.initialize(data.clients);
        notificationState.initialize(data.notifications);
        setLoading(false);
      })
      .catch(() => {
        profileState.initialize(null);
        clientState.initialize([]);
        notificationState.initialize([]);
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (clientId) {
      // tslint:disable-next-line: no-floating-promises
      Api.channel.get<{ user: User }>(`/api/client/${clientId}/admin`).then(({ data }) => {
        clientState.setEmail(data.user.email);
      });
    }
  }, [clientId]);

  let body: React.ReactNode = null;

  // While the user session is loading show a loader
  if (loading) {
    const loader = <Loader type="loading" spin />;
    body = (
      <Container align="middle" justify="center">
        <Flex.Item>
          <Spin indicator={loader} />
        </Flex.Item>
      </Container>
    );
  } else {
    body = (
      <>
        <Router defaultTo={routes.app.home}>
          <Router.Area path={routes.auth.root} component={Auth} />
          <Router.Area path={routes.app.root} component={App} />
          <Router.Area path={routes.admin.root} allow={ADMIN_ROLES} component={Admin} />
        </Router>
        {profile?.uniqueId && <TimeoutDialog />}
      </>
    );
  }

  return <Theme>{body}</Theme>;
};

export default Index;
