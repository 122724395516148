import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled from 'styled-components';

import Flex from '../../../common/flex';
import SelectBase from '../../../common/select';
import theme from '../../../theme';

import { query } from './state/query';
import { store } from './state/store';

const Container = styled(Flex.Row)`
  margin-bottom: 0.5rem;
`;

const Heading = styled.div`
  color: ${theme.color.millimanBlue};
  font-weight: ${theme.weight.medium};
  margin-right: 0.25rem;
`;

const List = styled(SelectBase)`
  .ant-select-selection {
    height: auto;

    .ant-select-selection__rendered {
      line-height: 1rem;
      margin-left: 0;
    }
  }
`;

const Select: React.FC = () => {
  const scenarios = useObservable(() => query.scenarios$, []);
  const scenario = useObservable(
    () => query.scenarios$.pipe(map(scenarios => scenarios.find(({ selected }) => selected))),
    null
  );

  if (scenarios.length === 0) {
    return null;
  }

  return (
    <Container align="middle">
      <Flex.Item>
        <Heading>Scenario:</Heading>
      </Flex.Item>
      <Flex.Item>
        <List
          value={scenario ? scenario.id : null}
          borderless={true}
          fullWidth={false}
          onChange={(scenarioId: string) => store.setSelectedId(scenarioId)}
        >
          {scenarios.map(scenario => (
            <SelectBase.Option key={scenario.id} value={scenario.id}>
              {scenario.name}
            </SelectBase.Option>
          ))}
        </List>
      </Flex.Item>
    </Container>
  );
};

export default Select;
