import numeral from 'numeral';
import React from 'react';
import SplitPaneBase from 'react-split-pane';
import { Size } from 'react-split-pane';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import theme from '../theme';
import helperUtils from '../utils/helper';

interface Sizes {
  value: number;
  minSize: number;
  maxSize: number;
}

interface SplitterProps {
  className?: string;
  size?: number;
  defaultSize?: Size;
  minSize?: number;
  maxSize?: number;
  allowResize?: boolean;
  onResize?: (size: number) => void;
}

interface SplitterState {
  value: number;
  containerWidth: number;
}

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const Splitter = styled(SplitPaneBase)`
  overflow-y: auto !important;

  .Resizer {
    background-color: ${theme.color.steel};
    position: relative;
    width: 1px;
    z-index: 1;

    ${({ allowResize }) =>
      allowResize &&
      css`
        &::after {
          content: '';
          background-image: url('/splitter.png');
          background-size: cover;
          cursor: col-resize;
          position: absolute;
          top: 50%;
          width: 2.5rem;
          height: 2.5rem;
          transform: translate(-50%, -50%);
        }
      `}
  }

  .Pane {
    overflow-y: auto;
  }

  ${breakpoint('xs', 'lg')`
    flex-direction: column !important;

    .Resizer {
      display: none;
    }

    .Pane {
      width: 100% !important;
      overflow-y: unset;
    }
  `}
`;

const Panel = styled.div`
  height: 100%;
`;

export default class extends React.Component<SplitterProps, SplitterState> {
  static Panel = Panel;

  private readonly ref = React.createRef<SplitPaneBase>();

  constructor(props: SplitterProps) {
    super(props);

    this.state = {
      value: null,
      containerWidth: 0,
    };
  }

  componentDidMount(): void {
    // Track the container size during resize
    // This will invoke a recalculate of the size to ensure everything stays in view
    window.addEventListener('resize', () => {
      const ref: any = this.ref.current;
      this.setState({
        containerWidth: ref?.splitPane?.clientWidth,
      });
    });
  }

  render(): React.ReactNode {
    const { className, defaultSize, allowResize, children } = this.props;
    const sizes = this.resolveSizes();

    return (
      <Container>
        <Splitter
          ref={this.ref}
          className={className}
          split="vertical"
          size={sizes.value || defaultSize || '50%'}
          minSize={sizes.minSize}
          maxSize={-sizes.maxSize}
          allowResize={allowResize !== false}
          onDragFinished={value => this.handleResize(value)}
        >
          {children}
        </Splitter>
      </Container>
    );
  }

  private resolveSizes(): Sizes {
    const ref: any = this.ref.current;

    // Ensure a min/max size is resolved
    let { minSize, maxSize } = this.props;

    if (!helperUtils.isNumber(minSize)) {
      minSize = 0;
    }

    if (!helperUtils.isNumber(maxSize)) {
      maxSize = minSize > 0 ? minSize : 0;
    } else if (maxSize < 0) {
      maxSize = -maxSize;
    }

    // Calculate a valid size value
    let value: number = null;
    if (ref) {
      const width = ref.splitPane.clientWidth;

      // Apply the default size if nothing is set
      value = this.state.value || this.props.size;
      if (!helperUtils.isNumber(value)) {
        const { defaultSize } = this.props;
        const percent = numeral(defaultSize || '50%');

        value = width * percent.value();
      }

      // Keep it all in the min/max boundaries
      if (value < minSize) {
        value = minSize;
      } else if (value > width - maxSize) {
        value = width - maxSize;
      }
    }

    return {
      value,
      minSize,
      maxSize,
    };
  }

  private handleResize(value: number): void {
    const { onResize } = this.props;

    // Track the size change
    this.setState({
      value,
    });

    // Pass to value to any parent components
    if (onResize) {
      onResize(value);
    }
  }
}
