import { Store, StoreConfig } from '@datorama/akita';

import { DataState, DataValues } from './models';

@StoreConfig({
  name: 'Client Management > Data',
})
export class DataStore extends Store<DataState> {
  constructor() {
    super({
      data: {},
      saveable: false,
      error: null,
    });
  }

  setData(spreadsheetId: string, data: Partial<DataValues>): void {
    this.update(state => ({
      data: {
        ...state.data,
        [spreadsheetId]: {
          ...state.data[spreadsheetId],
          ...data,
        },
      },
    }));
  }
}

export const store = new DataStore();
