import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../../common/button';
import EmptyBase from '../../../common/empty';
import Flex from '../../../common/flex';
import theme from '../../../theme';
import * as commonState from '../common/state';
import * as scenarioState from '../scenarios/state';

import Grid from './grid';
import { query } from './state/query';
import Tabs from './tabs';

interface InputsProps {
  showEmpty: boolean;
}

const Container = styled(Flex.Col)`
  padding: 1rem;
  height: 100%;
  font-weight: bold;

  ${breakpoint('lg')`
    padding: 0.5rem;
  `}
`;

const Header = styled.div`
  padding: 0 0.5rem;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}
`;

const Layout = styled(Flex.Col)`
  border: 1px solid ${theme.color.steel};
  flex: 1 1 auto;
  margin-top: 0.5rem;
`;

const Empty = styled(({ className, ...props }) => (
  <div className={className}>
    <EmptyBase {...props} />
  </div>
))`
  margin-top: 3rem;
`;

const Title = styled.div`
  color: ${theme.color.white};
  background-color: ${({ color }) => color};
  padding: 0.5rem;
`;

const Inputs: React.FC<InputsProps> = ({ showEmpty }) => {
  const graphType = useObservable(() => query.graphType$, null);
  const scenario = useObservable(
    () => query.scenarios$.pipe(map(scenarios => scenarios.find(({ selected }) => selected))),
    null
  );

  const scenarios = useObservable(() => scenarioState.scenarios$, []);

  if (scenarios.length === 0) {
    if (showEmpty) {
      return <Empty text="No scenario data available" />;
    }

    return null;
  }

  // Show the header based on the scenario state
  let header = <Header>Edit scenario inputs:</Header>;

  if (!scenario?.canEdit) {
    const hasEditableScenarios = scenarios.some(({ canEdit }) => canEdit);

    header = <Header color={theme.color.cranberry}>Selected scenario not editable:</Header>;

    if (!hasEditableScenarios) {
      header = (
        <Flex.Row align="middle" justify="space-between">
          <Flex.Item>{header}</Flex.Item>
          <Flex.Item>
            <Button
              type="light-blue"
              onClick={() => {
                commonState.setPanelId('scenarios');
                scenarioState.setCreateScenario(true);
                scenarioState.setCreateScenarioState({
                  copyId: scenario.id,
                  name: `${scenario.name} (copy)`,
                });
              }}
            >
              Copy scenario
            </Button>
          </Flex.Item>
        </Flex.Row>
      );
    }
  }

  return (
    <Container>
      {header}
      <Layout>
        {graphType === 'multi' && <Title color={scenario?.color}>{scenario?.name}</Title>}
        {graphType !== 'multi' && (
          <>
            <Tabs />
            <Title color={scenario?.color}>{scenario?.name}</Title>
          </>
        )}
        <Grid />
      </Layout>
    </Container>
  );
};

export default Inputs;
