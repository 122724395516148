import { css } from 'styled-components';

import ProjectionToolSvg from '../../assets/icon-projection-tool.svg';

import Icon from './icon';
import { IconProps, IconStyle } from './icon';

const style: IconStyle = (color: string) => css`
  svg {
    rect {
      fill: ${color} !important;
    }
  }

  ${Icon.style(color)}
`;

export default class extends Icon {
  static style = style;

  constructor(props: IconProps) {
    super(ProjectionToolSvg, props, style);
  }
}
