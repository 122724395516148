import React from 'react';
import styled from 'styled-components';

import theme from '../../theme';

interface TermsAndConditionsProps {
  showFullTerms: boolean;
}

const Heading = styled.h2`
  color: ${theme.color.millimanBlue};
  font-size: ${theme.size.subHeading};
  font-weight: ${theme.weight.bold};
  padding-bottom: 0.5rem;
`;

const SubHeading = styled.h3`
  color: ${theme.color.charcoal};
  font-size: ${theme.size.base};
  font-weight: ${theme.weight.bold};
  margin-bottom: 2px;
`;

const Content = styled.p`
  color: ${theme.color.charcoal};
  font-size: ${theme.size.small};
  font-weight: ${theme.weight.medium};
  line-height: 1.2rem;
`;

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ showFullTerms }) => (
  <div>
    {showFullTerms && (
      <div>
        <Heading>Please accept these terms to continue</Heading>
        <Content>
          This is a secure system for access by authorized individuals only and is subject to the
          terms of a written agreement with Milliman, Inc. The right to use this system is
          restricted to authorized individuals and is not transferable to any other person or
          entity. Any unauthorized use or access will be prosecuted to the full extent of the law.
          All transmitted data is encrypted via SSL.
        </Content>
        <Content>
          By continuing, you agree to our Terms of Use and{' '}
          <a href="https://www.milliman.com/en/privacy-policy" target="_blank">
            Privacy Policy
          </a>
          .
        </Content>
      </div>
    )}
    <SubHeading>Reliance on Results</SubHeading>
    <Content>
      The purpose of a projection model is to provide a rough projection of the Plan’s financial
      health under various scenarios. Projection results were developed using models employing
      standard actuarial techniques and reflect possible outcomes based on projection inputs and
      assumptions used. The Plan’s actual results will differ from those projected to the extent
      actual plan provisions, assumptions, and emerging experience differs from the projection
      inputs. Projections should not be relied upon for any purpose without review by your Milliman
      consultant.
    </Content>
  </div>
);

export default TermsAndConditions;
