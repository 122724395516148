import { Checkbox, Input } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Flex from '../../common/flex';
import FormBase from '../../common/form';
import { routes } from '../../constants';
import { Api, ApiResponse } from '../../http/api';
import theme from '../../theme';
import Layout from '../layout';

import TermsAndConditions from './terms';

interface FormProps {
  rememberMe: string;
  agreeTerms: boolean;
  onLogin: (rememberMe: string) => void;
}

interface FormState {
  email: string;
  messages: string[];
  rememberMe: boolean;
  agreeTerms: boolean;
  attempted: boolean;
  showFullTerms: boolean;
  submitting: boolean;
}

const Actions = styled(Flex.Row)`
  margin: 0.5rem 0;
`;

const TermsScroll = styled(Flex.Item)`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.steel};
  border-radius: ${theme.border.radius};
  padding: 1rem;
  overflow-y: auto;
`;

const TermsCheckbox = styled(Checkbox)`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
`;

class Form extends FormBase<FormProps, FormState> {
  private readonly EMAIL_FIELD_NAME = 'email';
  private readonly PASSWORD_FIELD_NAME = 'password';

  constructor(props: FormProps) {
    super(props);

    // Get any persisted login state
    const { rememberMe, agreeTerms } = props;
    this.state = {
      email: rememberMe,
      messages: null,
      attempted: false,
      rememberMe: !!rememberMe,
      agreeTerms,
      showFullTerms: !agreeTerms,
      submitting: false,
    };
  }

  render(): React.ReactNode {
    const { getFieldError } = this.props.form;
    const { rememberMe, agreeTerms, attempted, showFullTerms, submitting } = this.state;

    // Get any field or login attempt errors to show
    let { messages } = this.state;

    if (!messages && attempted) {
      // If the terms are not accepted, then show a message
      if (!agreeTerms) {
        messages = ['Accept terms and conditions to login'];
      } else {
        messages = getFieldError(this.EMAIL_FIELD_NAME) || getFieldError(this.PASSWORD_FIELD_NAME);
      }
    }

    return (
      <FormBase
        className="o-height-100"
        layout="vertical"
        hideRequiredMark={true}
        onSubmit={ev => this.handleFormSubmit(ev)}
      >
        <Flex.Col className="o-height-100">
          <div>
            <Flex.Item>
              <FormBase.Item
                label="Log in using the details provided below:"
                validateStatus=""
                help=""
              >
                {this.renderEmailField()}
              </FormBase.Item>
              <FormBase.Item validateStatus="" help="">
                {this.renderPasswordField()}
              </FormBase.Item>
            </Flex.Item>
            <Actions justify="space-between">
              <Flex.Item>
                <Checkbox
                  checked={rememberMe}
                  disabled={submitting}
                  onClick={() => this.handleRememberMe()}
                >
                  Remember me?
                </Checkbox>
              </Flex.Item>
              <Flex.Item>
                <Link to={routes.auth.forgotPassword}>Forgot password?</Link>
              </Flex.Item>
            </Actions>
          </div>
          <TermsScroll>
            <Flex.Item>
              <TermsAndConditions showFullTerms={showFullTerms} />
            </Flex.Item>
            {showFullTerms && (
              <Flex.Row justify="center">
                <Flex.Item>
                  <TermsCheckbox
                    checked={agreeTerms}
                    disabled={submitting}
                    onClick={() => this.handleAgreeTerms()}
                  >
                    I agree to these terms
                  </TermsCheckbox>
                </Flex.Item>
              </Flex.Row>
            )}
          </TermsScroll>
          <Flex.Item>
            <FormBase.Item>
              <Layout.Button deactivated={!agreeTerms} loading={submitting}>
                Login
              </Layout.Button>
            </FormBase.Item>
            <Layout.Error messages={messages} />
          </Flex.Item>
        </Flex.Col>
      </FormBase>
    );
  }

  private renderEmailField(): React.ReactNode {
    const { getFieldDecorator } = this.props.form;
    const { email, submitting } = this.state;
    const decorator = getFieldDecorator(this.EMAIL_FIELD_NAME, {
      initialValue: email,
      rules: [
        {
          type: 'email',
          required: true,
          message: 'Enter a valid email address',
        },
      ],
    });

    return decorator(<Input placeholder="Enter your email address" disabled={submitting} />);
  }

  private renderPasswordField(): React.ReactNode {
    const { getFieldDecorator } = this.props.form;
    const { submitting } = this.state;
    const decorator = getFieldDecorator(this.PASSWORD_FIELD_NAME, {
      rules: [
        {
          required: true,
          message: 'Enter your password',
        },
      ],
    });

    return decorator(<Input.Password placeholder="Enter your password" disabled={submitting} />);
  }

  private handleRememberMe(): void {
    const { rememberMe } = this.state;
    this.setState({
      rememberMe: !rememberMe,
    });
  }

  private handleAgreeTerms(): void {
    const { agreeTerms } = this.state;
    this.setState({
      agreeTerms: !agreeTerms,
    });
  }

  private handleFormSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();

    // Flag an attempted submit
    this.setState({
      attempted: true,
    });

    // Validate the form fields and submit the login if OK
    const { form, onLogin } = this.props;
    const { agreeTerms } = this.state;

    form.validateFields((err, fieldsValue) => {
      if (agreeTerms && !err) {
        this.setState({
          messages: null,
          submitting: true,
        });

        // Post the form data
        Api.channel
          .post<ApiResponse>('/api/user/login', {
            email: fieldsValue[this.EMAIL_FIELD_NAME],
            password: fieldsValue[this.PASSWORD_FIELD_NAME],
          })
          .then(({ data }) => {
            const success = data.status === Api.SUCCESS;
            this.setState({
              messages: success ? null : ['Incorrect email or password'],
            });

            if (success) {
              // Save the remember me and terms agreement to storage
              const { rememberMe } = this.state;
              const email = rememberMe ? fieldsValue[this.EMAIL_FIELD_NAME] : null;

              if (onLogin) {
                onLogin(email);
              }

              // Redirect to home so the profile is initialized
              window.location.href = routes.app.home;
            } else {
              // Reset the submitting state so another attempt can be done
              this.setState({
                submitting: false,
              });
            }
          })
          .catch(() => {
            this.setState({
              messages: ['An error has occurred'],
              submitting: false,
            });
          });
      }
    });
  }
}

export default Form;
