import { Checkbox, Transfer as TransferBase } from 'antd';
import React from 'react';
import styled from 'styled-components';

import Flex from '../../../common/flex';
import theme from '../../../theme';
import { Scenario } from '../../models';

interface TransferProps {
  scenarios?: Scenario[];
  onChange?: (scenarios: Scenario[]) => void;
}

const Container = styled.div`
  .ant-transfer-list {
    padding-top: 0.25rem;
    width: calc(50% - 1.5rem);

    .ant-transfer-list-header {
      display: none;
    }

    .ant-transfer-list-body-customize-wrapper {
      padding: 0;
    }
  }

  .ant-transfer-operation {
    button {
      color: ${theme.color.white};
      height: 2rem;
      width: 2rem;

      &:nth-child(1) {
        background-color: ${theme.color.millimanBlue};
        border-color: ${theme.color.millimanBlue};
      }

      &:nth-child(2) {
        background-color: ${theme.color.vibrantBlue};
        border-color: ${theme.color.vibrantBlue};
      }

      &:hover {
        color: ${theme.color.white};
      }

      &[disabled] {
        color: ${theme.color.slate};
        background-color: ${theme.color.steel};
        border-color: ${theme.color.steel};
      }
    }
  }
`;

const Header = styled(Flex.Item)`
  color: ${theme.color.millimanBlue};
  font-weight: ${theme.weight.medium};
  padding: 0.1rem 0 0 0.25rem;
  width: calc(50% - 1.5rem);
`;

const Item = styled.div`
  padding: 0.25rem 0.5rem;
`;

const Name = styled(Flex.Item)`
  line-height: 1rem;
  padding-left: 0.5rem;
`;

const Transfer: React.FC<TransferProps> = ({ scenarios, onChange }) => {
  scenarios = scenarios || [];

  // Build the transfer state
  const [selected, setSelected] = React.useState([]);
  const data = scenarios.map(scenario => ({
    ...scenario,
    key: scenario.uniqueId,
  }));

  const hidden = data.filter(scenario => !scenario.public).map(scenario => scenario.uniqueId);

  return (
    <Container>
      <Flex.Row justify="space-between">
        <Header>Client visible</Header>
        <Header>Client hidden</Header>
      </Flex.Row>
      <TransferBase
        targetKeys={hidden}
        selectedKeys={selected}
        dataSource={data}
        showSelectAll={false}
        render={scenario => scenario.name}
        onChange={ids => {
          const list = scenarios.map(scenario => ({
            ...scenario,
            public: ids.indexOf(scenario.uniqueId) === -1,
          }));

          if (onChange) {
            onChange(list);
          }
        }}
        onSelectChange={(source, target) => setSelected([...source, ...target])}
      >
        {({ selectedKeys, filteredItems, onItemSelect }) =>
          filteredItems.map((item: any) => {
            const checked = selectedKeys.indexOf(item.uniqueId) > -1;
            return (
              <Item key={item.uniqueId}>
                <Flex.Row>
                  <Flex.Item>
                    <Checkbox
                      checked={checked}
                      onChange={ev => onItemSelect(item.uniqueId, ev.target.checked)}
                    />
                  </Flex.Item>
                  <Name>{item.name}</Name>
                </Flex.Row>
              </Item>
            );
          })
        }
      </TransferBase>
    </Container>
  );
};

export default Transfer;
