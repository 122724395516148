import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import ButtonBase from '../../common/button';
import Flex from '../../common/flex';

interface PrintButtonProps {
  className?: string;
  onClick: () => void;
}

const Button = styled(ButtonBase)`
  margin: 0 0.1rem;
  padding: 0 0.5rem;
`;

const Text = styled(Flex.Item)`
  padding-left: 0.25rem;

  ${breakpoint('xs', 'sm')`
    display: none;
  `}
`;

const PrintButton: React.FC<PrintButtonProps> = ({ className, onClick }) => (
  <Button className={className} type="orange" onClick={onClick}>
    <Flex.Row align="middle">
      <Flex.Item>
        <Icon type="printer" />
      </Flex.Item>
      <Text>Print</Text>
    </Flex.Row>
  </Button>
);

export default PrintButton;
