import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import ButtonBase from '../common/button';
import Content from '../common/content';
import Flex from '../common/flex';
import { routes } from '../constants';
import Layout from '../layout';
import theme from '../theme';

interface LayoutProps {
  fill?: boolean;
}

interface ErrorProps {
  messages: string[];
}

const Panel = styled(({ className, children }) => (
  <Flex.Item className={className}>{children}</Flex.Item>
))`
  background-color: ${theme.color.white};
  border-radius: ${theme.border.radius};
  position: absolute;
  top: ${({ fill }) => (fill ? '0' : '3rem')};
  right: ${({ fill }) => (fill ? '0' : '1rem')};
  left: ${({ fill }) => (fill ? '0' : '1rem')};
  padding: 1rem;

  ${({ fill }) =>
    fill &&
    css`
      bottom: 0;
    `}

  ${breakpoint('md')`
    background-color: ${theme.color.panelGrey};
    border-radius: 0;
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 3rem 2rem 2rem 2rem;
    width: 30rem;
  `}
`;

const Message = styled(Flex.Row)`
  color: ${theme.color.emerald};
`;

const ErrorText = styled.div`
  color: ${theme.color.cranberry};
  padding-right: 1rem;
`;

const Error: React.FC<ErrorProps> = ({ messages }) => {
  if (messages) {
    return (
      <>
        {messages.map((message, index) => (
          <ErrorText key={index}>{message}</ErrorText>
        ))}
      </>
    );
  }

  return null;
};

const Footer: React.FC<any> = ({ success, errors, canCancel }) => {
  if (success) {
    return (
      <Message>
        <Flex.Item>{success}</Flex.Item>
      </Message>
    );
  }

  return (
    <Flex.Row justify="space-between">
      <Flex.Item>
        <Error messages={errors} />
      </Flex.Item>
      {canCancel !== false && (
        <Flex.Item>
          <Link to={routes.auth.login}>Cancel</Link>
        </Flex.Item>
      )}
    </Flex.Row>
  );
};

export default class extends React.Component<LayoutProps> {
  static Heading = styled(Content.Heading)`
    color: ${theme.color.black};
    font-weight: ${theme.weight.bold};
    margin-bottom: 1rem;
  `;

  static SubHeading = styled(Content.SubHeading)`
    margin-bottom: 1rem;
  `;

  static Button = styled(props => <ButtonBase type="green" htmlType="submit" {...props} />)`
    margin-top: 0.5rem;
    width: 100%;
  `;

  static Message = Message;

  static Error = Error;

  static Footer = Footer;

  render(): React.ReactNode {
    const { fill, children } = this.props;
    return (
      <Layout title="Online Projection Tool & Information Center" showProfile={false}>
        <Flex.Row className="o-height-100">
          <Flex.Item className="o-login-hero" fill={true} />
          <Panel fill={fill === true}>{children}</Panel>
        </Flex.Row>
      </Layout>
    );
  }
}
