import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled from 'styled-components';

import Content from '../../common/content';
import Select from '../../common/select';
import theme from '../../theme';
import addressUtils from '../../utils/address';
import ConsultantSelect from '../common/consultant-select';
import SummarySelect from '../common/summary-select';

import * as consultantState from './consultants/state';
import * as dataState from './data/state';
import * as detailsState from './details/state';
import * as scenarioState from './scenarios/state';
import * as spreadsheetState from './spreadsheets/state';
import * as userState from './users/state';

interface ListProps {
  loading: boolean;
  items: ListItem[];
}

interface ListItem {
  value: string;
  label: string;
  visible: boolean;
}

interface SummaryProps {
  loading: boolean;
}

const Section = styled.div`
  margin-bottom: 1rem;
`;

const Heading = styled(Content.Heading)`
  margin-bottom: 1rem;
`;

const SubHeading = styled(Content.SubHeading)`
  margin-bottom: 0.5rem;
`;

const Label = styled.div`
  margin: 0.25rem 0;
`;

const Line = styled.div`
  line-height: 1rem;
`;

const Note = styled.div`
  color: ${theme.color.slate};
  font-size: ${theme.size.small};
  margin-top: 0.25rem;
`;

const List: React.FC<ListProps> = ({ loading, items }) => {
  if (loading) {
    return <SummarySelect value="Loading..." disabled={true} />;
  }

  // Render the summary list
  const visible = items?.filter(({ visible }) => visible) || [];
  const hidden = items?.filter(({ visible }) => !visible) || [];

  return (
    <SummarySelect value={`${visible.length} visible / ${hidden.length} hidden`}>
      {visible.length > 0 && (
        <Select.Group label="Visible">
          {visible.map(current => (
            <Select.Option key={current.value} value={current.value} disabled={true}>
              {current.label}
            </Select.Option>
          ))}
        </Select.Group>
      )}
      {hidden.length > 0 && (
        <Select.Group label="Hidden">
          {hidden.map(current => (
            <Select.Option key={current.value} value={current.value} disabled={true}>
              {current.label}
            </Select.Option>
          ))}
        </Select.Group>
      )}
    </SummarySelect>
  );
};

const Summary: React.FC<SummaryProps> = ({ loading }) => {
  const name = useObservable(() => detailsState.name$, null);
  const address = useObservable(() => detailsState.address$, null);
  const spreadsheetId = useObservable(() => spreadsheetState.summarySelectedId$, null);
  const spreadsheets = useObservable(() => spreadsheetState.spreadsheets$, []);
  const spreadsheetLoading = useObservable(() => spreadsheetState.summaryLoading$, true);
  const consultants = useObservable(() => consultantState.consultants$, []);
  const adminId = useObservable(() => consultantState.admin$, null);
  const users = useObservable(() => userState.users$, []);

  const admin = consultants.find(current => current.uniqueId === adminId);
  const others = consultants.filter(current => current.uniqueId !== adminId);

  const data = useObservable(
    () =>
      dataState.summaryData$.pipe(
        map(values => {
          const inputs = values?.inputs?.map(input => ({
            value: input.name,
            label: input.displayName,
            visible: input.enabled,
          }));

          const outputs = values?.outputs?.map(input => ({
            value: input.name,
            label: input.displayName,
            visible: input.enabled,
          }));

          const multiOutputs = values?.multiOutputs?.map(input => ({
            value: input.name,
            label: input.displayName,
            visible: input.enabled,
          }));

          return {
            inputs: inputs || [],
            outputs: outputs || [],
            multiOutputs: multiOutputs || [],
          };
        })
      ),
    {
      inputs: [],
      outputs: [],
      multiOutputs: [],
    }
  );

  const scenarios = useObservable(
    () =>
      scenarioState.summaryScenarios$.pipe(
        map(values => {
          if (values) {
            return values.map(scenario => ({
              value: scenario.uniqueId,
              label: scenario.name,
              visible: scenario.public,
            }));
          }

          return [];
        })
      ),
    []
  );

  return (
    <>
      <Heading>Client Summary</Heading>
      {!loading && (
        <>
          <Section>
            <SubHeading>Details</SubHeading>
            {name && <Line>{name}</Line>}
            {address && (
              <div>
                {addressUtils.deserializeAddress(address).map((line, index) => (
                  <Line key={index}>{line}</Line>
                ))}
              </div>
            )}
          </Section>
          <Section>
            <SubHeading>Spreadsheets</SubHeading>
            <Select
              value={spreadsheetId}
              onChange={value => spreadsheetState.setSummarySelectedId(value)}
            >
              {spreadsheets.map(current => (
                <Select.Option key={current.uniqueId} value={current.uniqueId}>
                  {current.label}
                </Select.Option>
              ))}
            </Select>
            <Note>Select spreadsheet to view model setup below</Note>
          </Section>
          <Section>
            <SubHeading>Data</SubHeading>
            <Label>Inputs:</Label>
            <List loading={spreadsheetLoading} items={data.inputs} />
            <Label>Outputs:</Label>
            <List loading={spreadsheetLoading} items={data.outputs} />
            <Label>Multiple outputs:</Label>
            <List loading={spreadsheetLoading} items={data.multiOutputs} />
          </Section>
          <Section>
            <SubHeading>Scenarios</SubHeading>
            <List loading={spreadsheetLoading} items={scenarios} />
          </Section>
          <Section>
            <SubHeading>Consultants</SubHeading>
            <SummarySelect
              value={`${consultants.length} consultant${
                consultants.length === 1 ? '' : 's'
              } assigned`}
            >
              {admin && (
                <Select.Group label="Main Admin">
                  <Select.Option value={admin.uniqueId} disabled={true}>
                    <ConsultantSelect.Option consultant={admin} />
                  </Select.Option>
                </Select.Group>
              )}
              <Select.Group label="Other Consultants">
                {others.map(current => (
                  <Select.Option key={current.uniqueId} value={current.uniqueId} disabled={true}>
                    <ConsultantSelect.Option consultant={current} />
                  </Select.Option>
                ))}
              </Select.Group>
            </SummarySelect>
          </Section>
          <Section>
            <SubHeading>Users</SubHeading>
            <SummarySelect value={`${users.length} user${users.length === 1 ? '' : 's'} added`}>
              {users.map(current => (
                <Select.Option key={current.uniqueId} value={current.uniqueId} disabled={true}>
                  {current.fullName}
                </Select.Option>
              ))}
            </SummarySelect>
          </Section>
        </>
      )}
    </>
  );
};

export default Summary;
