import { Api } from '../http/api';

const getResources = async (clientId: string) => {
  if (clientId) {
    const files = await Api.channel.get<{ status: number; files: any[] }>(
      `/api/client/${clientId}/files`
    );

    if (files.data.status === 0) {
      return files.data.files.map(resource => ({
        ...resource,
        created: new Date(`${resource.created}Z`),
        modified: new Date(`${resource.lastModified}Z`),
        fileType: getResourceCategories(resource.fileType),
        planName: resource.planName,
        format: resource.fileName
          .split('.')
          .pop()
          .toLowerCase(),
      }));
    }
  }

  return [];
};

const getResourceCategories = (type: number): string[] => {
  const categories = [];

  if (type & 1) {
    categories.push('Actuarial Reports');
  }
  if (type & 2) {
    categories.push('Actuarial Letters');
  }
  if (type & 4) {
    categories.push('Presentations');
  }
  if (type & 8) {
    categories.push('Others');
  }

  return categories;
};

export default { getResources, getResourceCategories };
