import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../common/button';
import Content from '../../common/content';
import Drawer from '../../common/drawer';
import FileBase from '../../common/file';
import Flex from '../../common/flex';
import FormBase, { FormProps as FormPropsBase } from '../../common/form';
import TextAreaBase from '../../common/text-area';
import { FileInfo } from '../../models';
import FormLayout from '../common/form-layout';
import { Spreadsheet } from '../models';

interface SpreadsheetDrawerProps {
  spreadsheet: Spreadsheet;
  onSave?: (spreadsheet: Spreadsheet, callback: (error?: string) => void) => void;
  onClose: () => void;
}

interface FormProps {
  uniqueId: string;
  label: string;
  file: FileInfo;
  notes: string;
  selected: boolean;
  onSave?: (spreadsheet: Spreadsheet, callback: (error?: string) => void) => void;
  onClose: () => void;
}

interface FormState {
  message: string;
  submitting: boolean;
}

const Container = styled.div`
  padding: 2rem 1rem;

  ${breakpoint('sm')`
    padding: 2rem;
  `}
`;

const File = styled(FileBase)`
  margin-top: 0.25rem;
`;

const TextArea = styled(TextAreaBase)`
  margin-top: 0.25rem;
`;

class Form extends FormBase<FormProps, FormState> {
  private readonly LABEL_FIELD_NAME = 'label';
  private readonly FILE_FIELD_NAME = 'file';
  private readonly NOTES_FIELD_NAME = 'notes';

  constructor(props: FormProps & FormPropsBase) {
    super(props);

    this.state = {
      message: null,
      submitting: false,
    };
  }

  render(): React.ReactNode {
    const { uniqueId, onClose } = this.props;
    const { message, submitting } = this.state;

    return (
      <FormLayout.Form onSubmit={ev => this.handleFormSubmit(ev)}>
        <FormLayout.Field label="Model label:">{this.renderLabelField()}</FormLayout.Field>
        <FormLayout.Field label="Upload file:">{this.renderFileField()}</FormLayout.Field>
        <FormLayout.Field label="Notes:">{this.renderNotesField()}</FormLayout.Field>
        {message && <FormLayout.Error message={message} />}
        <FormLayout.Buttons>
          <Flex.Item>
            <Button type="blue" htmlType="submit" loading={submitting}>
              {uniqueId ? 'Update' : 'Add'}
            </Button>
          </Flex.Item>
          <Flex.Item>
            <Button type="light-grey" secondary={true} disabled={submitting} onClick={onClose}>
              Close
            </Button>
          </Flex.Item>
        </FormLayout.Buttons>
      </FormLayout.Form>
    );
  }

  private renderLabelField(): React.ReactNode {
    const { label, form } = this.props;
    const { submitting } = this.state;
    const decorator = form.getFieldDecorator(this.LABEL_FIELD_NAME, {
      initialValue: label,
      rules: [
        {
          ...FormBase.rules.notEmptyOrWhiteSpace,
          message: 'A label is required',
        },
      ],
    });

    return decorator(<Input disabled={submitting} />);
  }

  private renderFileField(): React.ReactNode {
    const { file, form } = this.props;
    const { submitting } = this.state;
    const decorator = form.getFieldDecorator(this.FILE_FIELD_NAME, {
      initialValue: file,
      rules: [
        {
          validator: (_: any, __: string, callback: (error: string) => void) =>
            this.validateFile(callback),
        },
      ],
    });

    return decorator(<File disabled={submitting} />);
  }

  private renderNotesField(): React.ReactNode {
    const { notes, form } = this.props;
    const { submitting } = this.state;
    const decorator = form.getFieldDecorator(this.NOTES_FIELD_NAME, {
      initialValue: notes,
    });

    return decorator(<TextArea rows={6} disabled={submitting} />);
  }

  private validateFile(callback: (error?: string) => void): void {
    const { getFieldValue } = this.props.form;
    const file = getFieldValue(this.FILE_FIELD_NAME);

    if (file && file.name) {
      callback();
    } else {
      callback('A file is required');
    }
  }

  private handleFormSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();

    // Validate the fields and post if all g!
    const { form, uniqueId, selected, onSave } = this.props;
    form.validateFields((err, fieldsValue) => {
      if (!err) {
        this.setState({
          message: null,
          submitting: true,
        });

        if (onSave) {
          onSave(
            {
              uniqueId,
              label: fieldsValue[this.LABEL_FIELD_NAME],
              file: fieldsValue[this.FILE_FIELD_NAME],
              notes: fieldsValue[this.NOTES_FIELD_NAME],
              selected,
            },
            error => {
              if (error) {
                this.setState({
                  message: error,
                  submitting: false,
                });
              }
            }
          );
        } else {
          this.setState({
            submitting: false,
          });
        }
      }
    });
  }
}

const SpreadsheetDrawer: React.FC<SpreadsheetDrawerProps> = ({ spreadsheet, onSave, onClose }) => (
  <Drawer visible={!!spreadsheet} onClose={onClose}>
    <Container>
      <Content.Heading>{spreadsheet?.uniqueId ? 'Edit' : 'Add'} Spreadsheet</Content.Heading>
      {FormBase.renderType(Form, {
        uniqueId: spreadsheet?.uniqueId,
        label: spreadsheet?.label,
        file: spreadsheet?.file,
        notes: spreadsheet?.notes,
        selected: spreadsheet ? spreadsheet.selected : false,
        onSave,
        onClose,
      })}
    </Container>
  </Drawer>
);

export default SpreadsheetDrawer;
