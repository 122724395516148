import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';

class FlexRow extends Row {
  render(): JSX.Element {
    return <Row type="flex" {...this.props} />;
  }
}

export default class {
  static Row = styled(FlexRow)`
    flex-wrap: nowrap;
  `;

  static Col = styled(FlexRow)`
    flex-direction: column;
    flex-wrap: nowrap;
  `;

  static Item = styled(({ fill, ...props }) => <Col {...props} />)`
    ${({ fill }) => fill && `flex: 1 1 auto;`}
  `;
}
