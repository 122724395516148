import { Input, Upload as UploadBase } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import { FileInfo } from '../models';
import theme from '../theme';

import Button from './button';

interface FileProps {
  className?: string;
  value?: FileInfo;
  disabled?: boolean;
  leadingComponent?: () => React.ReactNode;
  onChange?: (value: FileInfo) => void;
}

const Container = styled.div`
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: column;
`;

const Upload = styled(UploadBase)`
  // Hide the list handled by the Upload control
  .ant-upload-list {
    display: none;
  }
`;

const FileInput = styled(({ fileRef, ...props }) => (
  <Input ref={fileRef} disabled={true} {...props} />
))`
  color: ${theme.color.steel} !important;
  background-color: ${theme.color.white} !important;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.color.slate} !important;
      background-color: ${theme.color.steel} !important;
    `}
`;

const File: React.FC<FileProps & { fileRef: any }> = ({
  className,
  fileRef,
  disabled,
  leadingComponent,
  value,
  onChange,
}) => (
  <Container className={className}>
    {leadingComponent && leadingComponent()}
    <FileInput fileRef={fileRef} value={value && value.name} disabled={disabled} />
    {/* upload must be in last place for the antd form to pick it up */}
    <Upload
      accept=".xls,.xlsx,.xlsm,.docx,.doc,.jpeg,.jpg,.cvc,.pdf"
      beforeUpload={() => false}
      fileList={[]}
      onChange={(ev: UploadChangeParam) => {
        const [file] = ev.fileList;
        const value = {
          name: file.name,
          data: file.originFileObj,
        };

        // Notify the parent of the change
        if (onChange) {
          onChange(value);
        }
      }}
    >
      <Button type="dark-blue" disabled={disabled}>
        Select
      </Button>
    </Upload>
  </Container>
);

export default React.forwardRef<any, FileProps>((props, ref) => <File fileRef={ref} {...props} />);
