import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import helperUtils from '../../../../utils/helper';
import { FormatType } from '../../common/format';
import * as graphTypeState from '../../graph-types/state';
import * as inputState from '../../inputs/state';
import * as scenarioState from '../../scenarios/state';

import { Output, OutputEntity, OutputState, OutputType } from './models';
import { OutputStore, store } from './store';

export class OutputQuery extends QueryEntity<OutputState, OutputEntity> {
  private readonly _graphType$ = graphTypeState.selectedId$;
  private readonly _years$ = this.select(state => state.years);
  private readonly _selectedId$ = this.select(state => state.selectedId);

  private readonly _scenarios$ = scenarioState.scenarios$.pipe(
    map(scenarios => scenarios.filter(({ enabled }) => enabled))
  );

  private readonly _singleOutput$ = combineLatest([
    this._selectedId$,
    this._graphType$,
    this._years$,
    this._scenarios$,
    this.selectAll(),
  ]).pipe(
    map(([selectedId, graphType, years, scenarios, entities]) => {
      const outputs: Output[] = [];
      const filtered = entities.filter(({ typeId }) => typeId === selectedId);

      // Build the outputs sets for each enabled scenario
      for (const scenario of scenarios) {
        this.appendOutput(
          scenario.index,
          scenario.id,
          graphType,
          scenario.name,
          scenario.name,
          scenario,
          years,
          filtered,
          outputs
        );
      }

      return outputs;
    })
  );

  private readonly _multiSelection$ = combineLatest([
    this._selectedId$,
    inputState.selectedId$,
    this.select(state => state.groups),
    this._scenarios$,
  ]).pipe(
    map(([selectedId, scenarioId, groups, scenarios]) => ({
      scenario: scenarios.find(({ id }) => id === scenarioId),
      group: groups.find(({ id }) => id === selectedId),
    }))
  );

  private readonly _multiOutput$ = combineLatest([
    this._multiSelection$,
    this._years$,
    this.select(state => state.types),
    this.selectAll(),
  ]).pipe(
    map(([selection, years, types, entities]) => {
      const outputs: Output[] = [];

      // Build the list based on the outputs in group
      if (selection.scenario && selection.group) {
        for (const output of selection.group.outputs) {
          const match = types.find(({ index }) => index === output.index);
          const filtered = entities.filter(({ typeId }) => typeId === output.id);

          if (match) {
            this.appendOutput(
              output.index,
              output.id,
              output.type,
              match.name,
              output.displayName,
              selection.scenario,
              years,
              filtered,
              outputs
            );
          }
        }
      }

      return outputs;
    })
  );

  // tslint:disable: member-ordering
  readonly outputTypes$ = combineLatest([
    this._selectedId$,
    this.select(state => state.types),
    this.select(state => state.groups),
  ]).pipe(
    map(([selectedId, types, groups]) => {
      const outputTypes = types.map(({ id, name, div, formatType, decimalPlaces, enabled }) => {
        const type: OutputType = {
          id,
          enabled,
          name,
          div,
          formatType,
          decimalPlaces,
          multi: false,
          selected: id === selectedId,
        };

        return type;
      });

      // Add the output type groups to the list
      const outputTypeGroups = groups.map(({ id, displayName, outputs, enabled }) => {
        // Groups are restricted to being the same format type
        // Therefore, just take the first item in the outputs list
        let div = 1;
        let formatType: FormatType = 'number';
        let decimalPlaces = 0;

        if (outputs.length > 0) {
          const [first] = outputs;
          const match = types.find(({ id }) => id === first.id);

          if (match) {
            div = match.div;
            formatType = match.formatType;
            decimalPlaces = match.decimalPlaces;
          }
        }

        // Create the output type
        const type: OutputType = {
          id,
          name: displayName,
          enabled,
          div,
          formatType,
          decimalPlaces,
          multi: true,
          selected: id === selectedId,
        };

        return type;
      });

      return helperUtils.sort([...outputTypes, ...outputTypeGroups], 'name');
    })
  );

  readonly years$ = this._years$;
  readonly graphType$ = this._graphType$;

  readonly outputs$ = this._graphType$.pipe(
    switchMap(graphType => (graphType === 'multi' ? this._multiOutput$ : this._singleOutput$))
  );

  constructor(_store: OutputStore) {
    super(_store);
  }

  private appendOutput(
    index: number,
    id: string,
    type: string,
    name: string,
    displayName: string,
    scenario: scenarioState.Scenario,
    years: number[],
    entities: OutputEntity[],
    outputs: Output[]
  ): void {
    const output: Output = {
      index,
      id,
      type,
      name,
      displayName,
      values: [],
    };

    // Append each year value
    for (const year of years) {
      const match = entities.find(
        entity => entity.scenario === scenario.id && entity.year === year
      );

      output.values.push({
        year,
        value: match ? match.value : null,
        status: match ? match.status : null,
      });
    }

    outputs.push(output);
  }
}

export const query = new OutputQuery(store);
