import React from 'react';

import Button from '../../common/button';
import SearchSelect from '../../common/search-select';
import { User } from '../../models';
import userUtils from '../../utils/user';
import { Consultant } from '../models';

import ConsultantList from './consultant-list';
import FormLayout from './form-layout';

interface ConsultantSearchProps {
  consultants?: Consultant[];
  deleteIcon?: (consultant: User, callback: () => void) => React.ReactNode;
  onChange?: (consultants: Consultant[]) => void;
}

const ConsultantSearch: React.FC<ConsultantSearchProps> = ({
  consultants,
  deleteIcon,
  onChange,
}) => {
  const [pending, setPending] = React.useState([]);

  const value = consultants || [];
  const selected = value.filter(consultant => consultant.selected);
  const list = value
    .filter(consultant => !consultant.selected)
    .map(({ uniqueId, fullName, userType }) => ({
      label: fullName,
      value: uniqueId,
      badge: userUtils.getRoleString(userType),
    }));

  return (
    <>
      <FormLayout.Field label="Select consultants:">
        <SearchSelect value={pending} options={list} onChange={setPending} />
        <Button
          type="dark-blue"
          disabled={pending.length === 0}
          onClick={() => {
            const selected = value.filter(consultant => consultant.selected);
            const added = value
              .filter(consultant => pending.indexOf(consultant.uniqueId) > -1)
              .map(consultant => ({
                ...consultant,
                selected: true,
              }));

            if (onChange) {
              onChange([...selected, ...added]);
            }
          }}
        >
          Add Consultants
        </Button>
      </FormLayout.Field>
      <FormLayout.Field label="Active consultants:">
        <ConsultantList
          consultants={selected}
          deleteIcon={deleteIcon}
          onDelete={id => {
            const filtered = value.filter(
              consultant => consultant.selected && consultant.uniqueId !== id
            );

            if (onChange) {
              onChange(filtered);
            }
          }}
        />
      </FormLayout.Field>
    </>
  );
};

export default ConsultantSearch;
