import { EntityStore, StoreConfig } from '@datorama/akita';

import helperUtils from '../../../../utils/helper';
import storageUtils from '../../../../utils/storage';
import formatUtils from '../../common/format';
import { OUTPUT_ID_KEY } from '../../common/storage';
import { GraphTypeId } from '../../graph-types/state';
import { ScenarioData } from '../../scenarios/state';

import {
  MultiOutputData,
  OutputData,
  OutputEntity,
  OutputState,
  OutputTypeEntity,
  OutputTypeGroupEntity,
} from './models';

const zoneStatusId = helperUtils.normalize('Zone Status');

@StoreConfig({
  name: 'Projection Tool > Outputs',
})
export class OutputStore extends EntityStore<OutputState, OutputEntity> {
  constructor() {
    super({
      selectedId: null,
      types: [],
      groups: [],
      years: [],
    });
  }

  initialize(
    outputs: OutputData[],
    multiOutputs: MultiOutputData[],
    scenarios: ScenarioData[]
  ): void {
    const years: number[] = [];
    const entities: OutputEntity[] = [];

    // Resolve the type data
    // Zone status is a look up data set, so exclude from types
    const types = outputs
      .map(({ enabled, index, name, div, format, decimalPlaces }) => {
        const type: OutputTypeEntity = {
          id: helperUtils.normalize(name),
          enabled,
          name,
          index,
          div,
          formatType: formatUtils.getFormatType(format),
          decimalPlaces,
        };

        return type;
      })
      .filter(({ id }) => id !== zoneStatusId);

    for (const scenario of scenarios) {
      this.process(types, scenario, entities, years);
    }

    this.remove(() => true);
    this.upsertMany(entities);

    // Add the other state, including the persisted id
    this.update({
      types,
      groups: multiOutputs.map(({ enabled, name, displayName, outputs }) => {
        const group: OutputTypeGroupEntity = {
          id: helperUtils.normalize(displayName),
          name,
          enabled,
          displayName,
          outputs: outputs.map(output => ({
            id: helperUtils.normalize(output.displayName),
            type: output.type?.toLowerCase(),
            displayName: output.displayName,
            index: output.index,
          })),
        };

        return group;
      }),
      years,
    });
  }

  ensureSelectionForGraphType(graphType: GraphTypeId): void {
    const state = this.getValue();

    // Add some smarts to resolve a valid selection for the graph type
    let selectedId = state.selectedId;

    if (graphType === 'multi') {
      const multiMatch = state.groups.find(({ id }) => id === selectedId);
      if (!multiMatch) {
        const multiSelected = helperUtils.ensureSelection(state.groups);
        selectedId = multiSelected ? multiSelected.id : null;
      }
    } else {
      const singleMatch = state.types.find(({ id }) => id === selectedId);
      if (!singleMatch) {
        const singleSelected = helperUtils.ensureSelection(state.types);
        selectedId = singleSelected ? singleSelected.id : null;
      }
    }

    this.setSelectedId(selectedId);
  }

  addScenarioOutputs(scenario: ScenarioData): void {
    const state = this.getValue();

    // Get the outputs for the scenario and add to the store
    const entities: OutputEntity[] = [];
    this.process(state.types, scenario, entities);
    this.upsertMany(entities);
  }

  updateScenarioOutputs(scenarios: ScenarioData[]): void {
    const state = this.getValue();

    // Process the outputs for each scenarios
    const entities: OutputEntity[] = [];
    for (const scenario of scenarios) {
      this.process(state.types, scenario, entities);
    }

    // Update their values in the stores
    this.upsertMany(entities);
  }

  setSelectedId(id: string): void {
    this.update(state => {
      // Match on both single and multi types
      let match = helperUtils.ensureSelection(state.types, type => type.id === id, {});
      if (!match.id) {
        match = helperUtils.ensureSelection(state.groups, group => group.id === id);
      }

      // Resolve the selected id
      const selectedId = match ? match.id : null;
      storageUtils.set(OUTPUT_ID_KEY, selectedId);

      return {
        selectedId,
      };
    });
  }

  private process(
    types: OutputTypeEntity[],
    scenario: ScenarioData,
    entities: OutputEntity[],
    years: number[] = null
  ): void {
    // Find the zone for the current scenario
    const zones = scenario.outputs.find(({ name }) => zoneStatusId === helperUtils.normalize(name));
    for (const output of scenario.outputs) {
      // Don't initialize if not in resolve types list
      const type = types.find(({ id }) => id === helperUtils.normalize(output.name));
      if (type) {
        for (const current of output.values) {
          const zone = zones.values.find(({ year }) => year === current.year);
          entities.push({
            id: `${scenario.uniqueId}_${current.year}_${type.id}`,
            typeId: type.id,
            scenario: scenario.uniqueId,
            year: current.year,
            value: current.value,
            status: helperUtils.normalize(`${zone.value}`),
          });

          // Build a distinct list of years
          if (years && years.indexOf(current.year) === -1) {
            years.push(current.year);
          }
        }
      }
    }
  }
}

export const store = new OutputStore();
