import { Drawer as DrawerBase } from 'antd';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const Drawer = styled(({ className, visible, children, onClose }) => (
  <>
    {visible && (
      <DrawerBase
        className={className}
        placement="right"
        closable={false}
        maskClosable={false}
        onClose={onClose}
        visible={visible}
      >
        {children}
      </DrawerBase>
    )}
  </>
))`
  position: absolute;

  // Drawer width 100% for small screen, otherwise 28rem
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .ant-drawer-body {
    padding: 0;
    height: 100%;
  }

  ${breakpoint('sm')`
    .ant-drawer-content-wrapper {
      width: 28rem !important;
    }
  `}
`;

export default Drawer;
