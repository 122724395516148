import { MultiOutputData, OutputData } from '../../outputs/state';

import { GraphTypeId } from './models';
import { query } from './query';
import { store } from './store';

export { GraphTypeId };

// Expose an API for others to consume
export const selectedId$ = query.selectedId$;

export const initialize = (outputs: OutputData[], multiOutputs: MultiOutputData[]) =>
  store.initialize(outputs, multiOutputs);

export const setGraphType = (type: GraphTypeId) => store.setSelectedId(type);
