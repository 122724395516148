import React from 'react';
import { from, of } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Flex from '../../common/flex';
import LoaderBase from '../../common/loader';
import Select from '../../common/select';
import SplitterBase from '../../common/splitter';
import TabsBase from '../../common/tabs';
import { Api } from '../../http/api';
import { useGuard } from '../../router';
import * as bannerState from '../../state/banner';
import * as clientState from '../../state/clients';
import * as notificationState from '../../state/notifications';
import theme from '../../theme';
import helperUtils from '../../utils/helper';
import storageUtils from '../../utils/storage';
import Layout from '../layout';

import Buttons from './buttons';
import * as commonState from './common/state';
import { GRAPH_TYPE_KEY, INPUT_ID_KEY, OUTPUT_ID_KEY } from './common/storage';
import ControlPanel from './control-panel';
import GraphBase from './graph';
import * as graphTypeState from './graph-types/state';
import * as graphState from './graph/state';
import InputScenarios from './inputs/select';
import * as inputState from './inputs/state';
import Outputs from './outputs';
import * as outputState from './outputs/state';
import PresentButton from './present-button';
import Print from './print';
import PrintButton from './print-button';
import * as scenarioState from './scenarios/state';

interface ModelSelectProps {
  modelId: string;
  onChange: (modelId: string) => void;
}

const Container = styled.div`
  padding-bottom: 1rem;
  height: calc(100% - 65px);

  ${breakpoint('lg')`
    height: 100%;
    padding-bottom: 0;
  `}
`;

const Loader = styled(LoaderBase)`
  height: 100%;
`;

const Splitter = styled(props => <SplitterBase {...props} />)`
  ${({ panelId }) =>
    panelId === 'results' &&
    css`
      .Pane1 {
        overflow-y: auto;
        height: 100%;
      }
    `}

  ${({ panelId }) =>
    panelId !== 'results' &&
    css`
      .Pane2 {
        height: 100%;
        min-height: 25rem;
      }
    `}

  ${({ present }) =>
    present &&
    css`
      .Pane1 {
        width: 100% !important;
      }
    `}

  ${breakpoint('lg')`
    .Pane1 {
      height: auto;
    }
  `}
`;

const Left = styled(SplitterBase.Panel)`
  padding: 1rem 0;

  ${breakpoint('lg')`
    overflow-y: auto;
    padding: 2rem;
  `}
`;

const Right = styled(props => <SplitterBase.Panel {...props} />)`
  display: none;

  ${({ visible }) =>
    visible &&
    css`
      display: block !important;
    `}

  ${breakpoint('lg')`
    background-color: ${theme.color.panelGrey};
    display: block !important;
  `}
`;

const ResultRow = styled(Flex.Item)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const Tabs = styled(({ className, children }) => (
  <Flex.Item className={className}>{children}</Flex.Item>
))`
  margin-top: 1rem;
  width: 100%;

  ${breakpoint('md')`
    padding-right: 1rem;
    padding-left: 1rem;
  `}

  ${breakpoint('lg')`
    display: none;
  `}
`;

const Graph = styled(({ className, showEmpty }) => (
  <ResultRow className={className}>
    <GraphBase showEmpty={showEmpty} />
  </ResultRow>
))`
  display: none;
  margin-top: 1rem;
  width: 100%;

  ${({ visible }) =>
    visible &&
    css`
      display: block !important;
    `}

  ${breakpoint('lg')`
    display: block !important;
  `}
`;

const PrimaryButtons = styled.div`
  border-top: 1px solid ${theme.color.steel};
  padding: 1rem;

  ${breakpoint('lg')`
    display: none;
  `}
`;

const PresentingButtons = styled.div`
  display: none;
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  z-index: 1;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const save = async (
  modelId: string,
  scenarios: scenarioState.Scenario[],
  inputs: inputState.Input[],
  doSave: boolean,
  initialize: () => void,
  complete: () => void
) => {
  const data: scenarioState.ScenarioData[] = [];

  initialize();

  for (const scenario of scenarios) {
    // Only submit changes if values are dirty
    const dirty = inputs
      .filter(input => input.scenario === scenario.id)
      .filter(input => {
        const filtered = input.values.filter(value => value.dirty);
        return filtered.length > 0;
      });

    if (dirty.length > 0) {
      try {
        const response: any = await Api.channel.post(
          `/api/projection/${modelId}/scenario/${scenario.id}`,
          {
            name: scenario.name,
            enabled: scenario.enabled,
            inputs: inputs
              .filter(input => input.scenario === scenario.id)
              .map(({ name, category, values }) => ({
                name,
                category,
                values: values.map(({ year, value, valueType }) => ({
                  year,
                  value,
                  valueType,
                })),
              })),
          }
        );

        // Get the response data and append the scenario to the changes list
        const [first] = response.data.scenarios;
        data.push(first);
      } catch {
        // What do we do if we reach here???
      }
    }
  }

  outputState.updateScenarioOutputs(data);

  if (doSave) {
    try {
      await Api.channel.put(`/api/projection/${modelId}/scenarios`);
      scenarios.forEach(s => {
        s.dirty = false;
        scenarioState.updateScenario(s);
      });
    } catch {
      // What do we do if we reach here???
    }
  }

  inputState.applyInputUpdates(doSave);
  graphState.refreshChart();

  // complete
  complete();
};

const undo = async (modelId: string, initialize: () => void, complete: () => void) => {
  initialize();

  try {
    await Api.channel.get(`/api/projection/${modelId}/scenarios/reload`);
  } catch {
    // What do we do if we reach here???
  }

  complete();
};

const print = () => window.print();

const resolveSelections = (
  outputs: outputState.OutputData[],
  multiOutputs: outputState.MultiOutputData[]
) => {
  const inputId = storageUtils.get(INPUT_ID_KEY);

  // For graph type and outputs ensure the selections are valid
  let graphTypeId = storageUtils.get(GRAPH_TYPE_KEY) as graphTypeState.GraphTypeId;
  let outputId = storageUtils.get(OUTPUT_ID_KEY);

  // First check the output and then multi outputs
  const singleMatch = outputs.find(output => helperUtils.normalize(output.name) === outputId);

  if (!singleMatch) {
    const multiMatch = multiOutputs.find(output => helperUtils.normalize(output.name) === outputId);

    if (!multiMatch) {
      if (outputs.length > 0) {
        const [first] = outputs;
        graphTypeId = null;
        outputId = helperUtils.normalize(first.name);
      } else if (multiOutputs.length > 0) {
        const [first] = multiOutputs;
        graphTypeId = 'multi';
        outputId = helperUtils.normalize(first.name);
      }
    }
  }

  // Initialize the selections
  graphTypeState.setGraphType(graphTypeId);
  inputState.setInputId(inputId);
  outputState.setOutputId(outputId);
};

const ModelSelect: React.FC<ModelSelectProps> = ({ modelId, onChange }) => {
  const guard = useGuard();
  const models = useObservable(() => commonState.models$, []);

  return (
    <Select
      value={modelId}
      borderless={true}
      fullWidth={false}
      onChange={id => guard.invoke('select-model', () => onChange(id))}
    >
      {models.map(model => (
        <Select.Option key={model.id} value={model.id}>
          {model.name}
        </Select.Option>
      ))}
    </Select>
  );
};

const ProjectionTool: React.FC = () => {
  const banner = useObservable(() => bannerState.banner$, null);
  const clientId = useObservable(() => clientState.selectedId$, null);
  const scenarios = useObservable(() => scenarioState.scenarios$, []);
  const inputs = useObservable(() => inputState.inputs$, []);
  const updates = useObservable(() => inputState.updates$, []);
  const graphType = useObservable(() => graphTypeState.selectedId$, null);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [present, setPresent] = React.useState<boolean>(false);

  const modelId = useObservable(() => commonState.modelId$, null);
  const panelId = useObservable(() => commonState.panelId$, null);
  const split = useObservable(() => commonState.split$, null);
  const reload = useObservable(() => commonState.reload$);

  const notificationModelId = useObservable(() => notificationState.modelId$, null);
  const notificationScenarioId = useObservable(() => notificationState.scenarioId$, null);

  // check if there are any scenarios with unsaved changes
  const canApply = updates.filter(u => !u.applied).length > 0;
  const canSave = updates.length > 0 || !!scenarios.find(s => s.dirty);

  const onApply = (
    scenarios: scenarioState.Scenario[],
    inputs: inputState.Input[],
    callback: () => void
  ) =>
    save(
      modelId,
      scenarios,
      inputs,
      false,
      () => bannerState.setApplyingBanner(),
      () => {
        bannerState.clearBanner();
        callback();
      }
    );

  const onSave = (
    scenarios: scenarioState.Scenario[],
    inputs: inputState.Input[],
    callback: () => void
  ) =>
    save(
      modelId,
      scenarios,
      inputs,
      true,
      () => bannerState.setSavingBanner(),
      () => {
        bannerState.clearBanner();
        callback();
      }
    );

  const onUndoAll = (callback: () => void) =>
    undo(
      modelId,
      () => bannerState.setReloadingBanner(),
      () => {
        commonState.setReload();
        callback();
      }
    );

  React.useEffect(() => {
    if (notificationModelId) {
      if (notificationModelId !== modelId) {
        commonState.setModelId(notificationModelId);

        // Only show the banner if the whole of page loader isn't showing
        if (!loading) {
          bannerState.setLoadingBanner();
        }
      }

      // Clear the selection
      notificationState.setModelId(null);
    }
  }, [notificationModelId]);

  React.useEffect(() => {
    if (notificationScenarioId) {
      commonState.setPanelId('scenarios');

      // Clear the selection
      notificationState.setScenarioId(null);
    }
  }, [notificationScenarioId]);

  React.useEffect(() => {
    of(clientId)
      .pipe(
        filter(id => !!id),
        tap(() => {
          if (!loading) {
            bannerState.setLoadingBanner();
          }
        }),
        switchMap(id => from(Api.channel.get<{ models: any[] }>(`/api/client/${id}/models`)))
      )
      .subscribe(({ data }) => {
        commonState.initialize(data.models);

        // Resolve the model id for the loaded client
        const selectedId = notificationModelId || modelId;
        const model = helperUtils.ensureSelection(
          data.models,
          model => model.uniqueId === selectedId,
          data.models.find(model => model.default)
        );

        commonState.setModelId(model.uniqueId);
      });
  }, [clientId]);

  React.useEffect(() => {
    // Build a load pipeline for the state
    of(modelId)
      .pipe(
        filter(id => !!id && !notificationModelId),
        switchMap(id =>
          // Set the model id on the server
          from(Api.channel.get(`/api/projection/${id}`)).pipe(switchMap(() => of(id)))
        )
      )
      .subscribe(id => {
        // Load the project data for the model
        // tslint:disable-next-line: no-floating-promises
        Promise.all([
          Api.channel.get(`/api/projection/${id}/inputs`),
          Api.channel.get(`/api/projection/${id}/outputs`),
          Api.channel.get(`/api/projection/${id}/scenarios`),
        ]).then(([inputsResponse, outputsResponse, scenariosResponse]) => {
          const { inputs } = inputsResponse.data;
          const { outputs, multiOutputs } = outputsResponse.data;
          const { scenarios } = scenariosResponse.data;

          // Initialize the stores with the server data
          inputState.initialize(inputs, scenarios);
          outputState.initialize(outputs, multiOutputs, scenarios);
          scenarioState.initialize(scenarios);
          graphTypeState.initialize(outputs, multiOutputs);
          graphState.initialize();

          resolveSelections(outputs, multiOutputs);
          setLoading(false);

          bannerState.clearBanner();
        });
      });
  }, [modelId, notificationModelId, notificationScenarioId, reload]);

  return (
    <Layout
      print={<Print />}
      guard={context => {
        // If the state is dirty, provide a save handler
        if (canSave) {
          context.save = () =>
            new Promise(resolve => {
              // tslint:disable-next-line: no-floating-promises
              onSave(scenarios, inputs, resolve);
            });
        } else {
          context.skip = true;
        }
      }}
    >
      <Container>
        {loading && <Loader />}
        {!loading && (
          <Splitter
            panelId={panelId}
            present={present}
            size={split}
            defaultSize="60%"
            minSize={450}
            allowResize={!present}
            onResize={(size: number) => commonState.setSplit(size)}
          >
            <Left>
              <Flex.Col align="middle">
                <ResultRow>
                  <ModelSelect
                    modelId={modelId}
                    onChange={id => {
                      commonState.setModelId(id);
                      bannerState.setLoadingBanner();
                    }}
                  />
                </ResultRow>
                {graphType === 'multi' && (
                  <ResultRow>
                    <InputScenarios />
                  </ResultRow>
                )}
                <ResultRow>
                  <Outputs />
                </ResultRow>
                <Tabs>
                  <TabsBase
                    activeKey={panelId || 'inputs'}
                    onChange={(id: string) => commonState.setPanelId(id)}
                  >
                    <TabsBase.Content key="results" tab="Results" />
                    <TabsBase.Content key="inputs" tab="Inputs" />
                    <TabsBase.Content key="scenarios" tab="Scenarios" />
                    <TabsBase.Content key="graph-types" tab="Graph" />
                  </TabsBase>
                </Tabs>
                <Graph showEmpty={!banner} visible={panelId === 'results'} />
              </Flex.Col>
            </Left>
            <Right visible={panelId !== 'results'}>
              <ControlPanel
                loading={!!banner}
                canApply={canApply}
                canSave={canSave}
                onApply={onApply}
                onSave={onSave}
                onUndoAll={onUndoAll}
                onPresent={() => setPresent(true)}
                onPrint={print}
              />
            </Right>
          </Splitter>
        )}
        {present && (
          <PresentingButtons>
            <Flex.Row>
              <Flex.Item>
                <PresentButton open={true} onClick={() => setPresent(false)} />
              </Flex.Item>
              <Flex.Item>
                <PrintButton onClick={print} />
              </Flex.Item>
            </Flex.Row>
          </PresentingButtons>
        )}
      </Container>
      {!loading && (
        <PrimaryButtons>
          <Buttons
            canPresent={false}
            canApply={canApply}
            canSave={canSave}
            onApply={onApply}
            onSave={onSave}
            onUndoAll={onUndoAll}
            onPrint={print}
          />
        </PrimaryButtons>
      )}
    </Layout>
  );
};

export default ProjectionTool;
