import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationItem } from '../../models';

import { NotificationEntity, NotificationState } from './models';
import { NotificationStore, store } from './store';

export class NotificationQuery extends QueryEntity<NotificationState, NotificationEntity> {
  readonly modelId$ = this.select(state => state.modelId);
  readonly scenarioId$ = this.select(state => state.scenarioId);
  readonly fileId$ = this.select(state => state.fileId);

  readonly notifications$: Observable<NotificationItem[]> = this.selectAll().pipe(
    map(entities => entities.map(entity => ({ ...entity })))
  );

  constructor(_store: NotificationStore) {
    super(_store);
  }
}

export const query = new NotificationQuery(store);
