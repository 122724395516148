import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { routes } from '../constants';

import Authorize from './authorize';

interface PageProps {
  path: string;
  allow?: number[];
  secure?: boolean;
  redirect?: boolean;
  component: React.ComponentType;
  data?: any;
}

const Page: React.FC<PageProps> = ({
  path,
  allow,
  secure,
  redirect,
  component: Component,
  data,
  ...props
}) => {
  const route = <Route exact path={path} render={() => <Component {...props} {...data} />} />;

  // The reset password pages should not redirect
  if (redirect !== false) {
    return (
      <Authorize allow={allow}>
        {profile => {
          // Redirect appropriately based on the config and authenticated state
          if (secure !== false && profile.authenticated !== true) {
            return <Redirect to={routes.auth.login} />;
          } else if (secure === false && profile.authenticated === true) {
            return <Redirect to={routes.app.home} />;
          }

          return route;
        }}
      </Authorize>
    );
  }

  return route;
};

export default Page;
