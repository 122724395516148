import { Icon } from 'antd';
import React from 'react';

interface ResourceIconProps {
  format: string;
}

const ResourceIcon: React.FC<ResourceIconProps> = ({ format }) => {
  switch (format) {
    case 'pdf':
      return <Icon type="file-pdf" theme="twoTone" twoToneColor="red" />;
    case 'doc':
    case 'docx':
      return <Icon type="file-word" theme="twoTone" twoToneColor="blue" />;
    case 'xls':
    case 'xlsx':
    case 'xlsm':
      return <Icon type="file-excel" theme="twoTone" twoToneColor="green" />;
    default:
      return <Icon type="file" theme="twoTone" twoToneColor="grey" />;
  }
};

export default ResourceIcon;
