import { EntityStore, StoreConfig } from '@datorama/akita';

import { Spreadsheet } from '../../../models';

import { SpreadsheetsState } from './models';

@StoreConfig({
  name: 'Client Management > Spreadsheets',
  idKey: 'uniqueId',
})
export class SpreadsheetsStore extends EntityStore<SpreadsheetsState, Spreadsheet, string> {
  constructor() {
    super({
      contentSelectedId: null,
      summarySelectedId: null,
      contentLoading: false,
      summaryLoading: false,
    });
  }

  setSpreadsheets(spreadsheets: Spreadsheet[]): void {
    store.remove();
    store.upsertMany(spreadsheets);

    // Check the selected id is still valid
    store.update(state => {
      const contentSelectedId = this.getSelectedId(state.contentSelectedId, spreadsheets);
      const summarySelectedId = this.getSelectedId(state.summarySelectedId, spreadsheets);

      if (contentSelectedId || summarySelectedId) {
        return {
          contentSelectedId: contentSelectedId || state.contentSelectedId,
          summarySelectedId: summarySelectedId || state.summarySelectedId,
        };
      }

      return state;
    });
  }

  private getSelectedId(selectedId: string, spreadsheets: Spreadsheet[]): string {
    const match = spreadsheets.find(({ uniqueId }) => uniqueId === selectedId);

    if (!match && spreadsheets.length > 0) {
      const [first] = spreadsheets;
      return first.uniqueId;
    }

    return null;
  }
}

export const store = new SpreadsheetsStore();
