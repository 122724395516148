import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import * as clientState from '../../../../state/clients';
import helperUtils from '../../../../utils/helper';

import { CommonState, Model } from './models';
import { CommonStore, store } from './store';

export class CommonQuery extends Query<CommonState> {
  readonly clientId$ = clientState.selectedId$;
  readonly modelId$ = this.select(state => state.modelId);
  readonly panelId$ = this.select(state => state.panelId);
  readonly reload$ = this.select(state => state.reload);
  readonly split$ = this.select(state => state.split);

  readonly models$ = combineLatest([
    this.clientId$,
    this.modelId$,
    this.select(state => state.models),
  ]).pipe(
    map(([clientId, modelId, models]) => {
      const clientModels = models.filter(m => m.clientId === clientId);
      const list = clientModels.map(current => {
        const model: Model = {
          ...current,
          selected: current.id === modelId,
        };
        return model;
      });

      return helperUtils.sort(list, 'name');
    })
  );

  constructor(_store: CommonStore) {
    super(_store);
  }
}

export const query = new CommonQuery(store);
