import React from 'react';
import styled, { css } from 'styled-components';

import theme from '../../theme';

interface RadioProps {
  color: string;
  enabled: boolean;
}

const Outer = styled.div`
  cursor: pointer;
  border: 0.25rem solid ${({ color }) => color};
  border-radius: 50%;
  display: inline-block;
`;

const Inner = styled(({ className }) => <div className={className}></div>)`
  background-color: ${theme.color.steel};
  border: 0.25rem solid ${theme.color.steel};
  border-radius: 50%;
  margin: 0.25rem;

  ${({ enabled }) =>
    enabled &&
    css`
      background-color: ${theme.color.black};
      border-color: ${theme.color.black};
    `}
`;

const Radio: React.FC<RadioProps> = ({ color, enabled }) => (
  <Outer color={color}>
    <Inner enabled={enabled} />
  </Outer>
);

export default Radio;
