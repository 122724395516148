import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import NotFound from './not-found';

interface ChildRouterProps {
  defaultTo?: string;
  defaultFrom?: string;
}

const Child: React.FC<ChildRouterProps> = ({ defaultFrom, defaultTo, children }) => (
  <Switch>
    {children}
    {defaultFrom && defaultTo && <Redirect exact from={defaultFrom} to={defaultTo} />}
    <Route render={() => <NotFound />} />
  </Switch>
);

export default Child;
