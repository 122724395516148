import { GraphTypeId } from '../../graph-types/state';
import { ScenarioData } from '../../scenarios/state';

import { MultiOutputData, OutputData, OutputType } from './models';
import { query } from './query';
import { store } from './store';

export { OutputType, OutputData, MultiOutputData };

// Expose an API for others to consume
export const outputTypes$ = query.outputTypes$;
export const years$ = query.years$;
export const outputs$ = query.outputs$;

export const initialize = (
  outputs: OutputData[],
  multiOutputs: MultiOutputData[],
  scenarios: ScenarioData[]
) => store.initialize(outputs, multiOutputs, scenarios);

export const setOutputId = (id: string) => store.setSelectedId(id);

export const ensureSelectionForGraphType = (graphType: GraphTypeId) =>
  store.ensureSelectionForGraphType(graphType);

export const addScenarioOutputs = (scenario: ScenarioData) => store.addScenarioOutputs(scenario);

export const updateScenarioOutputs = (scenarios: ScenarioData[]) =>
  store.updateScenarioOutputs(scenarios);
