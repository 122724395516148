import { Query } from '@datorama/akita';

import { DetailsState } from './models';
import { DetailsStore, store } from './store';

export class DetailsQuery extends Query<DetailsState> {
  readonly name$ = this.select(state => state.name);
  readonly address$ = this.select(state => state.address);
  readonly saveable$ = this.select(state => state.saveable);
  readonly error$ = this.select(state => state.error);

  constructor(_store: DetailsStore) {
    super(_store);
  }
}

export const query = new DetailsQuery(store);
