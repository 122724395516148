import { Tabs } from 'antd';
import ScrollableTabBar from 'rc-tabs/lib/ScrollableTabBar';
import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

interface TabsProps {
  className?: string;
  activeKey: string;
  onChange: (key: string) => void;
}

const TabBar = styled(ScrollableTabBar)`
  border: 0;
  margin: 0;

  // Need to override the ant styles directly
  .ant-tabs-tab-prev {
    height: 2.5rem;
  }

  .ant-tabs-tab-next {
    height: 2.5rem;
  }

  .ant-tabs-nav-scroll {
    height: 3rem;

    .ant-tabs-nav {
      width: 100%;

      & > div {
        display: flex;
        width: 100%;

        .ant-tabs-tab {
          color: ${theme.color.millimanBlue};
          background-color: ${theme.color.panelGrey};
          font-weight: ${theme.weight.medium};
          flex: 1 1 auto;
          position: relative;
          margin: 0 1px;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          text-align: center;
          width: ${props => 100 / props.count}%;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &.ant-tabs-tab-active {
            color: ${theme.color.white};
            background-color: ${theme.color.vibrantBlue};

            &::after {
              content: ' ';
              border-top: 0.5rem solid ${theme.color.vibrantBlue};
              border-left: 0.5rem solid transparent;
              border-right: 0.5rem solid transparent;
              position: absolute;
              bottom: -0.5rem;
              left: 50%;
              width: 0;
              height: 0;
              transform: translate(-50%, 0);
            }
          }

          &.ant-tabs-tab-disabled {
            color: ${theme.color.steel};
          }
        }
      }
    }
  }
`;

export default class extends React.Component<TabsProps> {
  static Content = Tabs.TabPane;

  render(): React.ReactNode {
    const { className, activeKey, onChange, children } = this.props;
    const tabs = React.Children.toArray(children).filter((child: any) => !!child.key);

    return (
      <Tabs
        className={className}
        activeKey={activeKey}
        renderTabBar={props => <TabBar count={tabs.length} {...props} />}
        onChange={onChange}
      >
        {children}
      </Tabs>
    );
  }
}
