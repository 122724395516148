import { QueryEntity } from '@datorama/akita';

import { User } from '../../../../models';

import { UsersState } from './models';
import { store, UsersStore } from './store';

export class UsersQuery extends QueryEntity<UsersState, User, string> {
  readonly users$ = this.selectAll({
    sortBy: 'fullName',
  });

  constructor(_store: UsersStore) {
    super(_store);
  }
}

export const query = new UsersQuery(store);
