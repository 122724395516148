import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import Content from '../../common/content';
import Select from '../../common/select';
import addressUtils from '../../utils/address';
import ConsultantSelect from '../common/consultant-select';
import SummarySelect from '../common/summary-select';

import { query } from './state/query';
import Wizard from './wizard';

const Heading = styled(Content.SubHeading)`
  margin-bottom: 1rem;
`;

const Line = styled.div`
  line-height: 1rem;
`;

const Summary: React.FC = () => {
  const name = useObservable(() => query.name$, null);
  const address = useObservable(() => query.address$, null);
  const spreadsheets = useObservable(() => query.spreadsheets$, []);
  const consultants = useObservable(() => query.consultants$, []);
  const adminId = useObservable(() => query.admin$, null);

  const admin = consultants.find(current => current.uniqueId === adminId);
  const others = consultants.filter(current => current.uniqueId !== adminId);

  return (
    <div>
      <Heading>New Client Summary</Heading>
      <Wizard.Section title="1. Client details">
        {name && <Line>{name}</Line>}
        {address && (
          <div>
            {addressUtils.deserializeAddress(address).map((line, index) => (
              <Line key={index}>{line}</Line>
            ))}
          </div>
        )}
      </Wizard.Section>
      <Wizard.Section title="2. Client spreadsheets">
        <SummarySelect
          value={`${spreadsheets.length} spreadsheet${spreadsheets.length === 1 ? '' : 's'} added`}
        >
          {spreadsheets.map(current => (
            <Select.Option key={current.uniqueId} value={current.uniqueId} disabled={true}>
              {current.label}
            </Select.Option>
          ))}
        </SummarySelect>
      </Wizard.Section>
      <Wizard.Section title="3. Assign Milliman consultants">
        <SummarySelect
          value={`${consultants.length} consultant${consultants.length === 1 ? '' : 's'} assigned`}
        >
          {admin && (
            <Select.Group label="Main Admin">
              <Select.Option value={admin.uniqueId} disabled={true}>
                <ConsultantSelect.Option consultant={admin} />
              </Select.Option>
            </Select.Group>
          )}
          <Select.Group label="Other Consultants">
            {others.map(current => (
              <Select.Option key={current.uniqueId} value={current.uniqueId} disabled={true}>
                <ConsultantSelect.Option consultant={current} />
              </Select.Option>
            ))}
          </Select.Group>
        </SummarySelect>
      </Wizard.Section>
    </div>
  );
};

export default Summary;
