import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import Flex from '../common/flex';
import Router from '../router';
import theme from '../theme';

interface NavbarItemProps {
  icon: React.ReactType;
  color: string;
  label: string;
  route: string;
  disabled?: boolean;
  height: string;
  width: string;
  iconStyle: (color: string) => FlattenSimpleInterpolation;
}

const Label = styled(({ className, text }) => (
  <Flex.Item className={className}>
    <label>{text}</label>
  </Flex.Item>
))`
  padding-top: 0.5rem;
  width: 8rem;

  label {
    cursor: pointer;
    color: ${theme.color.slate};
  }
`;

const Inner = styled(Flex.Col)`
  min-width: 8rem;
`;

// Ensure this is after the Icon and Label components
// This will allow the below styles to override for hover and selection
const Disabled = styled(({ className, children }) => <div className={className}>{children}</div>)`
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  text-align: center;
  height: 8rem;
  width: 100%;

  label {
    cursor: not-allowed;
    color: ${theme.color.steel};
  }

  ${({ iconStyle }) => iconStyle && iconStyle(theme.color.steel)}
`;

const Enabled = styled(({ className, route, children }) => (
  <Link className={className} to={route}>
    {children}
  </Link>
))`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem;
  text-align: center;
  height: 8rem;
  width: 100%;

  &:hover {
    label {
      color: ${({ color }) => color};
    }

    ${({ color, iconStyle }) => iconStyle && iconStyle(color)}
  }

  ${({ color, selected }) =>
    selected &&
    css`
      background-color: ${theme.color.white};

      label {
        color: ${color};
      }
    `}
`;

export default class extends React.Component {
  static Item: React.FC<NavbarItemProps> = ({
    disabled,
    icon,
    color,
    label,
    route,
    height,
    width,
    iconStyle,
  }) => {
    const IconComponent = icon;
    return (
      <Router.Location>
        {location => {
          const selected = location.pathname.startsWith(route);
          const inner = (
            <Inner align="middle" justify="center">
              <Flex.Item>
                <IconComponent
                  color={selected ? color : theme.color.slate}
                  height={height}
                  width={width}
                />
              </Flex.Item>
              <Label text={label} />
            </Inner>
          );

          return disabled ? (
            <Disabled iconStyle={iconStyle}>{inner}</Disabled>
          ) : (
            <Enabled
              key={route}
              color={color}
              selected={selected}
              route={route}
              iconStyle={iconStyle}
            >
              {inner}
            </Enabled>
          );
        }}
      </Router.Location>
    );
  };

  render(): React.ReactNode {
    const { children } = this.props;
    return <>{children}</>;
  }
}
