import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../../common/content';
import Form from '../../common/form';
import Splitter from '../../common/splitter';
import Tabs from '../../common/tabs';
import { Api } from '../../http/api';
import { Client, User } from '../../models';
import { useGuard } from '../../router';
import * as bannerState from '../../state/banner';
import * as clientState from '../../state/clients';
import * as profileState from '../../state/profile';
import theme from '../../theme';
import helperUtils from '../../utils/helper';
import userUtils from '../../utils/user';
import Layout from '../layout';

import List from './list';
import ManageForm from './manage-form';
import NewForm from './new-form';
import { store } from './state/store';

interface ValueState {
  dirty: boolean;
  saveable: boolean;
  values: any;
}

interface ValuesState {
  new: ValueState;
  manage: ValueState;
}

interface LeftProps {
  activeTab: string;
  selected: User;
  consultants: User[];
  clients: Client[];
  state: () => any;
  onSelect: (consultant: User) => void;
  onChange: (values: any) => void;
  onCreate: () => void;
  onUpdate: () => void;
  onDelete: () => void;
  onTabChange: (tab: string) => void;
}

interface RightProps {
  selected: User;
  consultants: User[];
  onSelect: (consultant: User) => void;
}

const Heading = styled(Content.Heading)`
  margin: 1rem;

  ${breakpoint('md')`
    margin: 0 0 1rem 0;
  `}
`;

const Tab = styled(Tabs.Content)`
  margin: 0 1rem 1rem 1rem;
  width: calc(100% - 2rem) !important;

  ${breakpoint('md')`
    margin: 0;
    width: 100% !important;
  `}
`;

const LeftContainer = styled.div`
  padding: 0;
  height: 100%;

  ${breakpoint('md')`
    padding: 1rem;
  `}

  ${breakpoint('lg')`
    padding: 2rem;
  `}
`;

const RightContainer = styled.div`
  background-color: ${theme.color.panelGrey};
  display: none;
  padding: 2rem;
  height: 100%;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const Scroll = styled.div`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.steel};
  overflow-y: auto;
  max-height: calc(100% - 1rem);
`;

const Left: React.FC<LeftProps> = ({
  activeTab,
  selected,
  consultants,
  clients,
  state,
  onSelect,
  onChange,
  onCreate,
  onUpdate,
  onDelete,
  onTabChange,
}) => {
  const guard = useGuard();

  const {
    values: { name, ...overrides },
  } = state();

  const consultant = {
    ...overrides,
    ...(name && { fullName: name }),
  };

  return (
    <LeftContainer>
      <Heading>Admin Management</Heading>
      <Tabs
        activeKey={activeTab}
        onChange={tab => guard.invoke('change-tab', () => onTabChange(tab))}
      >
        <Tab key="manage" tab="Manage Consultant">
          {Form.renderType(
            ManageForm,
            {
              selected: {
                ...selected,
                ...consultant,
              },
              consultants,
              clients,
              onSelect: (consultant: User) =>
                guard.invoke('edit-consultant', () => onSelect(consultant)),
              onUpdate,
              onDelete,
            },
            onChange
          )}
        </Tab>
        <Tab key="new" tab="New Consultant">
          {Form.renderType(
            NewForm,
            {
              consultant: {
                userType: 1,
                ...consultant,
              },
              clients,
              onCreate,
            },
            onChange
          )}
        </Tab>
      </Tabs>
    </LeftContainer>
  );
};

const Right: React.FC<RightProps> = ({ selected, consultants, onSelect }) => {
  const guard = useGuard();
  return (
    <RightContainer>
      <Content.SubHeading>Active Consultants</Content.SubHeading>
      <Scroll>
        <List
          selected={selected}
          dataSource={consultants}
          onEdit={consultant => guard.invoke('edit-consultant', () => onSelect(consultant))}
        />
      </Scroll>
    </RightContainer>
  );
};

const AdminManagement: React.FC = () => {
  const profile = useObservable(() => profileState.profile$, null);
  const [activeTab, setActiveTab] = React.useState('manage');
  const [selected, setSelected] = React.useState<User>(null);
  const [consultants, setConsultants] = React.useState<User[]>([]);
  const [clients, setClients] = React.useState<Client[]>([]);

  // A simple hash to track changes and avoid crappy input focus loss
  const state: ValuesState = {
    new: {
      dirty: false,
      saveable: false,
      values: {},
    },
    manage: {
      dirty: false,
      saveable: false,
      values: {},
    },
  };

  // Helpers
  const canSave = (consultant: any, includePassword: boolean) => {
    const { name, email, password, clientIds } = consultant;
    const valid = !!name && !!email && !!clientIds;

    if (includePassword) {
      return valid && !!password;
    }

    return valid;
  };

  const createConsultant = async (resolve?: () => void, reject?: () => void) => {
    try {
      const { values } = state.new;
      store.setNewError();

      if (canSave(values, true)) {
        bannerState.setCreatingBanner();

        // Get the response to check the create was successful
        const { status, data } = await Api.channel.post(`/api/user`, values);

        if (status === 200 && data?.newUser === false) {
          store.setNewError(`The specified email address is either invalid or already in use.`);

          // Reset the state
          // The password is cleared so ensure the save button is disabled
          store.setNewSaveable(false);
          bannerState.clearBanner();

          // Complete the action
          reject && reject();
          return;
        }

        // Otherwise update the state
        const { name, ...others } = values;
        const consultant = {
          ...selected,
          ...others,
          uniqueId: data?.userId,
          fullName: name,
        };

        // Update and sort the list
        const list = userUtils.getSortedConsultants([...consultants, consultant]);

        setSelected(consultant);
        setConsultants(list);
        setActiveTab('manage');
      }

      // Ensure the override values are cleared
      state.new.dirty = false;
      state.new.saveable = false;
      state.new.values = {};

      store.setNewSaveable(false);

      resolve && resolve();
    } catch (e) {
      store.setNewError(`Server error: ${e}`);
      reject && reject();
    }

    bannerState.clearBanner();
  };

  const updateConsultant = async (resolve?: () => void, reject?: () => void) => {
    try {
      const { values } = state.manage;
      store.setManageError();

      // Only update if the required values are set
      if (canSave(values, false)) {
        bannerState.setSavingBanner();

        await Api.channel.put(`/api/user/${selected.uniqueId}`, values);

        // If the consultant is the logged in user, update the global profile
        const { name, email, clientIds, ...others } = values;
        const consultant = {
          ...selected,
          ...others,
          clientIds,
          fullName: name,
          email,
        };

        if (profile.uniqueId === selected.uniqueId) {
          profileState.updateProfile(name, email);
          clientState.setClients(clients.filter(c => clientIds.includes(c.uniqueId)));
        }

        // Build and sort the list
        let list = consultants.map(current =>
          current.uniqueId === selected.uniqueId ? consultant : current
        );

        list = userUtils.getSortedConsultants(list);

        setSelected(consultant);
        setConsultants(list);
      }

      // Ensure the override values are cleared
      state.manage.dirty = false;
      state.manage.saveable = false;
      state.manage.values = {};

      store.setManageSaveable(false);

      resolve && resolve();
    } catch (e) {
      const { response } = e;
      if (response.status === 400) {
        switch (response.data?.reasonCode) {
          case 4:
            store.setManageError(
              `The specified email address is either invalid or already in use.`
            );
            break;

          case 5:
            store.setManageError(`The specified current password in not valid.`);
            break;

          default:
            store.setManageError(`Server error: ${e}`);
        }
      }

      reject && reject();
    }

    bannerState.clearBanner();
  };

  const deleteConsultant = async () => {
    try {
      bannerState.setDeletingBanner();
      await Api.channel.delete(`/api/user/${selected.uniqueId}`);

      // Update the list
      const id = selected.uniqueId;
      const list = consultants.filter(current => current.uniqueId !== id);

      let consultant = null;
      if (list.length > 0) {
        const [first] = list;
        consultant = first;
      }

      setSelected(consultant);
      setConsultants(list);
    } catch (e) {
      const { response } = e;
      if (response.status === 400) {
        store.setManageError(`Server error: ${e}`);
      }
    }

    bannerState.clearBanner();
  };

  React.useEffect(() => {
    if (!profile) {
      return;
    }

    // tslint:disable-next-line: no-floating-promises
    (async () => {
      const [usersResponse, clientsResponse] = await Promise.all([
        Api.channel.get<{ users: User[] }>(`/api/user`),
        Api.channel.get<{ clients: Client[] }>(`/api/client`),
      ]);
      const consultants = userUtils.getSortedConsultants(usersResponse.data.users);
      setConsultants(consultants);
      setClients(helperUtils.sort(clientsResponse.data.clients, 'name'));

      // Ensure a default consultant is selected
      const selected = helperUtils.ensureSelection(
        consultants,
        ({ uniqueId }) => uniqueId === profile.uniqueId
      );

      setSelected(selected);
    })();
  }, [profile]);

  return (
    <Layout
      guard={context => {
        // Check which tab we are on to determine what is being guarded
        if (activeTab === 'new' && state.new.dirty) {
          if (state.new.saveable) {
            context.save = () =>
              new Promise((resolve, reject) => {
                // tslint:disable-next-line: no-floating-promises
                createConsultant(resolve, reject);
              });
          } else {
            context.message = 'Incomplete consultant detected.';
          }
        } else if (activeTab === 'manage' && state.manage.dirty) {
          if (state.manage.saveable) {
            context.save = () =>
              new Promise((resolve, reject) => {
                // tslint:disable-next-line: no-floating-promises
                updateConsultant(resolve, reject);
              });
          } else {
            context.message = 'Incomplete consultant detected.';
          }
        } else {
          context.skip = true;
        }
      }}
    >
      <Splitter defaultSize="60%" minSize={350}>
        <Left
          activeTab={activeTab}
          selected={selected}
          consultants={consultants}
          clients={clients}
          state={() => {
            if (activeTab === 'new') {
              return state.new;
            } else if (activeTab === 'manage') {
              return state.manage;
            }

            return {};
          }}
          onSelect={consultant => {
            store.setNewSaveable(false);
            store.setManageSaveable(false);

            store.setNewError();
            store.setManageError();

            setSelected(consultant);
          }}
          onChange={values => {
            const { name, email, password, clientIds } = values;

            // Set the state for the tab
            let saveable = !!name && !!email && !!clientIds;

            if (activeTab === 'new') {
              saveable = saveable && !!password;
              store.setNewSaveable(saveable);
              state.new = {
                ...state.new,
                dirty: true,
                saveable,
                values,
              };
            } else if (activeTab === 'manage') {
              store.setManageSaveable(saveable);
              state.manage = {
                ...state.manage,
                dirty: true,
                saveable,
                values,
              };
            }
          }}
          onCreate={createConsultant}
          onUpdate={updateConsultant}
          onDelete={deleteConsultant}
          onTabChange={tab => {
            store.setNewSaveable(false);
            store.setManageSaveable(false);

            store.setNewError();
            store.setManageError();

            setActiveTab(tab);
          }}
        />
        <Right
          selected={selected}
          consultants={consultants}
          onSelect={consultant => {
            setActiveTab('manage');
            setSelected(consultant);
          }}
        />
      </Splitter>
    </Layout>
  );
};

export default AdminManagement;
