import axios from 'axios';

// IE 11 disable cache
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.headers.get['Cache-Control'] = 'no-cache, no-store';

export interface ApiResponse {
  status: number;
  reasonCode: string;
}

export class Api {
  static SUCCESS = 0;
  static FAILED = 1;

  static channel = axios;
}
