import { Checkbox, Icon as IconBase, Input as InputBase } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import Flex from '../../../common/flex';
import ListTableBase from '../../../common/list-table';
import theme from '../../../theme';

interface ListItem {
  name: string;
  displayName: string;
  enabled: boolean;
}

interface ListItemState extends ListItem {
  disabled: boolean;
  canEnable: boolean;
}

interface ListProps {
  items?: ListItem[];
  minCount?: number;
  disableItem: (item: ListItem) => boolean;
  onChange?: (items: ListItem[]) => void;
}

interface CheckboxCellProps {
  item: ListItemState;
  items: ListItemState[];
  onChange: (items: ListItem[]) => void;
}

interface NameCellProps {
  editing: boolean;
  item: ListItemState;
  onEdit: (value: string) => void;
}

interface ActionsCellProps {
  onToggleEdit: () => void;
}

const ListTable = styled(ListTableBase)`
  td.o-name-col {
    padding: 2px !important;
  }
`;

const Icon = styled(IconBase)`
  cursor: pointer;
  color: ${theme.color.millimanBlue};
`;

const Name = styled.div`
  padding: 6px;
`;

const Input = styled(InputBase)`
  padding: 2px 5px;
  height: auto;
`;

const SelectorContainer = styled(Flex.Row)`
  line-height: 1rem;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
`;

const Selector = styled(({ className, disabled, children, onClick }) => (
  <div className={className} onClick={() => !disabled && onClick()}>
    {children}
  </div>
))`
  cursor: pointer;
  color: ${theme.color.vibrantBlue};
  font-size: 0.8rem;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: ${theme.color.steel};
    `}
`;

const CheckboxCell: React.FC<CheckboxCellProps> = ({ item, items, onChange }) => (
  <Checkbox
    checked={item.enabled}
    disabled={item.disabled}
    onChange={() => {
      const list = items.map(current => ({
        ...current,
        enabled: current.name === item.name ? !current.enabled : current.enabled,
      }));

      if (onChange) {
        onChange(list);
      }
    }}
  />
);

const NameCell: React.FC<NameCellProps> = ({ editing, item, onEdit }) => (
  <>
    {!editing && <Name>{item.displayName}</Name>}
    {editing && <Input defaultValue={item.displayName} onChange={ev => onEdit(ev.target.value)} />}
  </>
);

const ActionsCell: React.FC<ActionsCellProps> = ({ onToggleEdit }) => (
  <Icon type="edit" onClick={onToggleEdit} />
);

const List: React.FC<ListProps> = ({ items, minCount, disableItem, onChange }) => {
  const [editing, setEditing] = React.useState([]);

  const enabled = items.filter(({ enabled }) => enabled);
  const state = items.map(item => {
    let disabled = false;
    let canEnable = true;

    if (disableItem) {
      disabled = disableItem(item);
      canEnable = !disabled;
    } else if (minCount && item.enabled && enabled.length === minCount) {
      disabled = true;
    }

    return {
      ...item,
      disabled,
      canEnable,
    };
  });

  // Build the table
  const columns: any[] = [
    {
      key: 'visible',
      title: 'Visible',
      width: 63,
      align: 'center',
      render: (item: ListItemState) => (
        <CheckboxCell item={item} items={state} onChange={onChange} />
      ),
    },
    {
      key: 'name',
      title: 'Name',
      className: 'o-name-col',
      render: (item: ListItemState) => {
        const active = editing.indexOf(item.name) > -1;
        return (
          <NameCell
            editing={active}
            item={item}
            onEdit={value => {
              const list = state.map(current => ({
                ...current,
                displayName: current.name === item.name ? value : current.displayName,
              }));

              if (onChange) {
                onChange(list);
              }
            }}
          />
        );
      },
    },
    {
      key: 'actions',
      title: '',
      width: 40,
      align: 'right',
      render: (item: ListItemState) => (
        <ActionsCell
          onToggleEdit={() => {
            const active = editing.indexOf(item.name) > -1;
            if (active) {
              const list = editing.filter(current => current !== item.name);
              setEditing(list);
            } else {
              setEditing([...editing, item.name]);
            }
          }}
        />
      ),
    },
  ];

  // Calculate the selected and disabled state
  const enabledItems = state.filter(({ enabled }) => enabled);
  const activeItems = state.filter(({ disabled, canEnable }) => !disabled || canEnable);
  const allSelected = enabledItems.length === activeItems.length;

  const disabled = state.filter(({ disabled }) => disabled);
  const allDisabled = disabled.length === state.length;

  return (
    <>
      <ListTable rowKey="name" columns={columns} data={state} height="12rem" />
      <SelectorContainer justify="end">
        <Flex.Item>
          {allSelected && (
            <Selector
              disabled={allDisabled}
              onClick={() => {
                const list = state.map((item, index) => {
                  let enabled = false;
                  if (minCount && index < minCount) {
                    enabled = true;
                  }

                  return {
                    ...item,
                    enabled,
                  };
                });
                onChange(list);
              }}
            >
              Deselect all
            </Selector>
          )}
          {!allSelected && (
            <Selector
              disabled={allDisabled}
              onClick={() => {
                const list = state.map(item => ({
                  ...item,
                  enabled: item.canEnable,
                }));
                onChange(list);
              }}
            >
              Select all
            </Selector>
          )}
        </Flex.Item>
      </SelectorContainer>
    </>
  );
};

export default List;
