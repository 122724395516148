import { Input, List } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../../common/content';
import ResourceIcon from '../../common/resource-icon';
import Select from '../../common/select';
import { Resource } from '../../models';
import * as clientState from '../../state/clients';

const { Option } = Select;

const SelectBefore = styled(props => (
  <Select defaultValue="All" dropdownMatchSelectWidth={false} {...props}>
    <Option value="">All</Option>
    <Option value="Actuarial Reports">Actuarial reports</Option>
    <Option value="Actuarial Letters">Actuarial letters</Option>
    <Option value="Presentations">Presentations</Option>
    <Option value="Others">Other</Option>
  </Select>
))`
  width: 8rem;

  ${breakpoint('lg')`
    width: 6rem;
  `}
`;

const renderSearchResults = (resources: Resource[], category: string, clientId: string) =>
  resources && (
    <List
      dataSource={resources.filter(resource => !category || resource.fileType.includes(category))}
      renderItem={(r: Resource) => {
        const Icon = <ResourceIcon format={r.format} />;
        const description = (
          <a href={`/api/client/${clientId}/files/${r.uniqueId}`} download>
            {r.fileName}
          </a>
        );
        return (
          <List.Item>
            <List.Item.Meta avatar={Icon} description={description}></List.Item.Meta>
            <div>{r.created.toLocaleDateString()}</div>
          </List.Item>
        );
      }}
    />
  );

interface SearchProps {
  resources: Resource[];
}

const Search: React.FC<SearchProps> = ({ resources }) => {
  const [searchResults, setSearchResults] = React.useState(undefined as Resource[] | undefined);
  const [category, setCategory] = React.useState<string>(undefined);
  const clientId = useObservable(() => clientState.selectedId$, null);

  const updateResults = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const results =
      value && resources.filter(r => r.fileName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    setSearchResults(results);
  };

  return (
    <div>
      <Content.Heading>Search</Content.Heading>
      <Input addonBefore={<SelectBefore onChange={setCategory} />} onChange={updateResults} />
      {renderSearchResults(searchResults, category, clientId)}
    </div>
  );
};

export default Search;
