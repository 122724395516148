import { QueryEntity } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import helperUtils from '../../../../utils/helper';
import * as commonState from '../../common/state';

import { Scenario, ScenarioEntity, ScenarioState } from './models';
import { ScenarioStore, store } from './store';

export class ScenarioQuery extends QueryEntity<ScenarioState, ScenarioEntity> {
  private readonly _enabledIds$ = this.select(state => state.enabledIds);

  // tslint:disable: member-ordering
  readonly modelId$ = commonState.modelId$;

  readonly createScenario$ = this.select(state => state.createScenario);
  readonly createScenarioState$ = this.select(state => state.createScenarioState);

  readonly scenarios$ = combineLatest([this._enabledIds$, this.modelId$, this.selectAll()]).pipe(
    map(([enabledIds, modelId, entities]) => {
      const list = entities
        .filter(s => s.modelId === modelId)
        .map(entity => {
          const scenario: Scenario = {
            ...entity,
            enabled: enabledIds.indexOf(entity.id) > -1,
          };

          return scenario;
        });

      return helperUtils.sort(list, 'name');
    })
  );

  constructor(_store: ScenarioStore) {
    super(_store);
  }
}

export const query = new ScenarioQuery(store);
