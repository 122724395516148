import React from 'react';

import Layout from '../layout';

import Form from './form';

const ResetPassword: React.FC = props => (
  <Layout>
    <Layout.Heading>Password Reset</Layout.Heading>
    <Layout.SubHeading>Enter New Password</Layout.SubHeading>
    <Form {...props} />
  </Layout>
);

export default ResetPassword;
