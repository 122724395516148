import { Icon as IconBase } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../common/button';
import Flex from '../../common/flex';
import FormBase from '../../common/form';
import { ADMIN_ROLE } from '../../constants';
import { Profile, User } from '../../models';
import * as profileState from '../../state/profile';
import theme from '../../theme';
import ConsultantSearchBase from '../common/consultant-search';
import { Consultant } from '../models';

import { query } from './state/query';
import Wizard from './wizard';

interface StepTwoProps {
  consultants: Consultant[];
  onChange: (values: any) => void;
  onCancel: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

interface FormProps {
  consultants: Consultant[];
  profile: Profile;
  saving: boolean;
  onCancel: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

const Icon = styled(IconBase)`
  color: ${theme.color.cranberry};
`;

const Agreement = styled.div`
  ${breakpoint('md')`
    display: flex;
    flex-direction: row;
  `}
`;

const Download = styled(Button)`
  margin: 1rem 0 0 0;

  ${breakpoint('md')`
    margin: 0 0 0 1rem;
  `}
`;

const Note = styled.div`
  font-weight: ${theme.weight.medium};
  margin-top: 1rem;
`;

const BackButton = styled(props => (
  <Button type="blue" {...props}>
    Back
  </Button>
))`
  margin-right: 0.25rem;
`;

const buttonLayout = {
  wrapperCol: {
    span: 24,
  },
};

const ConsultantSearch = React.forwardRef<any, any>((props, _) => (
  <ConsultantSearchBase {...props} />
));

class Form extends FormBase<FormProps, {}> {
  private readonly CONSULTANTS_FIELD_NAME = 'consultants';

  render(): React.ReactNode {
    const { form, saving, onCancel, onPrevious } = this.props;
    const consultantErrors = form.getFieldError(this.CONSULTANTS_FIELD_NAME);

    return (
      <Wizard.Form onSubmit={ev => this.handleFormSubmit(ev)}>
        <Wizard.Section title="3. Assign Milliman consultants">
          {this.renderConsultantsField()}
          {consultantErrors?.length > 0 && (
            <Wizard.Error
              message={consultantErrors[0]}
              layout={{
                wrapperCol: {
                  xs: 24,
                  md: {
                    offset: 6,
                    span: 18,
                  },
                },
              }}
            />
          )}
        </Wizard.Section>
        <Wizard.Section title="4. Client users">
          <Agreement>
            <Flex.Item fill={true}>
              You must have a signed copy of the Milliman Optic&trade; license agreement from your
              client before you can add users.
            </Flex.Item>
            <Flex.Item>
              <Download
                type="dark-blue"
                disabled={saving}
                onClick={() => {
                  window.open('/Optic License Agreement.doc', '_blank');
                }}
              >
                Download License Agreement
              </Download>
            </Flex.Item>
          </Agreement>
          <Note>Add client users with the client management tool.</Note>
        </Wizard.Section>
        <Wizard.Buttons layout={buttonLayout}>
          <Flex.Item>
            <Button type="dark-blue" htmlType="submit" loading={saving}>
              Create Client
            </Button>
          </Flex.Item>
          <Flex.Item>
            <BackButton disabled={saving} onClick={onPrevious} />
            <Button type="light-grey" secondary={true} disabled={saving} onClick={onCancel}>
              Cancel
            </Button>
          </Flex.Item>
        </Wizard.Buttons>
      </Wizard.Form>
    );
  }

  private renderConsultantsField(): React.ReactNode {
    const { form, profile, consultants } = this.props;
    const decorator = form.getFieldDecorator(this.CONSULTANTS_FIELD_NAME, {
      initialValue: consultants,
      valuePropName: 'consultants',
      rules: [
        {
          validator: (_: any, __: string, callback: (error: string) => void) =>
            this.validateAdmins(callback),
        },
      ],
    });

    return decorator(
      <ConsultantSearch
        deleteIcon={(consultant: User, callback: () => void) => {
          if (consultant.uniqueId !== profile?.uniqueId) {
            return <Icon type="delete" onClick={callback} />;
          }

          return null;
        }}
      />
    );
  }

  private validateAdmins(callback: (error?: string) => void): void {
    const { getFieldValue } = this.props.form;
    const consultants: Consultant[] = getFieldValue(this.CONSULTANTS_FIELD_NAME);
    const admins = consultants.filter(
      consultant => consultant.selected && consultant.userType === ADMIN_ROLE
    );

    if (admins.length > 0) {
      callback();
    } else {
      callback('At least one Admin is required');
    }
  }

  private handleFormSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();

    // Validate the fields and move to next step
    const { form, onNext } = this.props;
    form.validateFields(err => {
      if (!err) {
        this.setState({
          submitting: true,
        });

        onNext();
      }
    });
  }
}

const StepTwo: React.FC<StepTwoProps> = ({ onChange, ...props }) => {
  const profile = useObservable(() => profileState.profile$, null);
  const saving = useObservable(() => query.saving$, false);

  return (
    <Wizard.Step title="Step Two">
      {FormBase.renderType(
        Form,
        {
          profile: profile || {},
          saving,
          ...props,
        },
        onChange
      )}
    </Wizard.Step>
  );
};

export default StepTwo;
