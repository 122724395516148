import { Badge as BadgeBase, Icon } from 'antd';
import React from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import Button from '../common/button';
import Content from '../common/content';
import EmptyBase from '../common/empty';
import Flex from '../common/flex';
import SelectBase from '../common/select';
import { routes } from '../constants';
import { Api } from '../http/api';
import { NotificationCategory, NotificationItem } from '../models';
import { useGuard } from '../router';
import * as bannerState from '../state/banner';
import * as clientState from '../state/clients';
import * as notificationState from '../state/notifications';
import * as profileState from '../state/profile';
import theme from '../theme';

interface NotificationsProps {
  items: NotificationItem[];
  onClick: () => void;
  onClear: () => void;
}

interface ListProps {
  category: NotificationCategory;
  label: string;
  items: NotificationItem[];
  onClick: (item: NotificationItem) => void;
}

const Container = styled.div`
  padding: 1.5rem 1.5rem 1.5rem 0.75rem;
`;

const Board = styled.div`
  margin-bottom: 1rem;
`;

const BadgeContainer = styled(Flex.Item)`
  line-height: 1rem;
  padding-top: 1px;
`;

const Badge = styled(BadgeBase)`
  border-radius: 50%;
`;

const Category = styled.div`
  margin-bottom: 1.5rem;
`;

const Heading = styled(Content.Heading)`
  font-size: ${theme.size.base};
  padding-left: 0.75rem;
`;

const Select = styled(SelectBase)`
  margin-left: 0.5rem;

  .ant-badge {
    margin-top: 4px;
  }
`;

const Item = styled(Flex.Row)`
  padding-bottom: 0.5rem;
`;

const Viewed = styled(({ viewed, ...props }) => <Flex.Item {...props} />)`
  color: ${theme.color.gold};
  font-size: 0.6rem;
  visibility: ${({ viewed }) => (viewed ? 'hidden' : 'visible')};
  width: 0.8rem;
`;

const Date = styled(Flex.Item)`
  flex: 0 0 4.8rem;
  align-self: flex-start;
`;

const Link = styled.span`
  cursor: pointer;
  color: ${theme.color.vibrantBlue};
  text-decoration: underline;
`;

const Buttons = styled.div`
  padding-left: 0.75rem;
`;

const Empty = styled(({ className, ...props }) => (
  <div className={className}>
    <EmptyBase {...props} />
  </div>
))`
  margin-top: 2rem;
`;

const List: React.FC<ListProps> = ({ category, label, items, onClick }) => {
  const profile = useObservable(() => profileState.profile$, null);
  const list = items.filter(item => item.category === category);

  // Only render the category if notifications exist for it
  if (list.length === 0) {
    return null;
  }

  return (
    <Category>
      <Heading>{label}:</Heading>
      {list.map(item => (
        <Item key={item.uniqueId} align="middle">
          <Viewed viewed={item.viewed}>
            <Icon type="star" theme="filled" />
          </Viewed>
          <Flex.Item fill={true}>
            <Link
              onClick={async () => {
                // Mark the items as viewed
                if (!item.viewed) {
                  await Api.channel.post(
                    `/api/user/${profile?.uniqueId}/notifications/${item.uniqueId}`
                  );
                  notificationState.markAsViewed(item.uniqueId);
                }

                // Callback to the handler for the specific item type
                onClick(item);
              }}
            >
              {item.description}
            </Link>
          </Flex.Item>
          <Date>
            <Moment format="D MMM YYYY">{item.date}</Moment>
          </Date>
        </Item>
      ))}
    </Category>
  );
};

const Notifications: React.FC<NotificationsProps> = ({ items, onClick, onClear }) => {
  const guard = useGuard();
  const history = useHistory();
  const clientId = useObservable(() => clientState.selectedId$, null);
  const clients = useObservable(() => clientState.clients$, []);
  const banner = useObservable(() => bannerState.banner$, null);

  const list = items.filter(item => item.clientId === clientId);

  return (
    <Container>
      {clients.length > 1 && (
        <Board>
          <Heading>Board:</Heading>
          <Select
            value={clientId}
            disabled={!!banner}
            onChange={value =>
              value !== clientId &&
              guard.invoke('select-client', () => clientState.setSelectedId(value))
            }
          >
            {clients.map(client => {
              const unviewed = items.filter(
                item => item.clientId === client.uniqueId && !item.viewed
              );
              return (
                <Select.Option key={client.uniqueId} value={client.uniqueId}>
                  <Flex.Row justify="space-between">
                    <Flex.Item>{client.name}</Flex.Item>
                    <BadgeContainer>
                      <Badge count={unviewed.length} overflowCount={10} />
                    </BadgeContainer>
                  </Flex.Row>
                </Select.Option>
              );
            })}
          </Select>
        </Board>
      )}
      <List
        category="newmodel"
        label="Models"
        items={list}
        onClick={item => {
          notificationState.setModelId(item.modelId);
          history.push(`${routes.app.projectionTool}`);

          if (onClick) {
            onClick();
          }
        }}
      />
      <List
        category="newscenario"
        label="Scenarios"
        items={list}
        onClick={item => {
          notificationState.setModelId(item.modelId);
          notificationState.setScenarioId(item.scenarioId);

          history.push(`${routes.app.projectionTool}`);

          if (onClick) {
            onClick();
          }
        }}
      />
      <List
        category="newfile"
        label="Documents"
        items={list}
        onClick={item => {
          notificationState.setFileId(item.fileId);
          history.push(`${routes.app.informationCenter}`);

          if (onClick) {
            onClick();
          }
        }}
      />
      {list.length === 0 && <Empty text="No notifications available" />}
      {list.length > 0 && (
        <Buttons>
          <Button type="dark-grey" onClick={onClear}>
            Clear
          </Button>
        </Buttons>
      )}
    </Container>
  );
};

export default Notifications;
