import React from 'react';
import { useHistory } from 'react-router';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import { ADMIN_ROLE, routes } from '../../constants';
import { Api, ApiResponse } from '../../http/api';
import { User } from '../../models';
import * as bannerState from '../../state/banner';
import * as clientState from '../../state/clients';
import * as profileState from '../../state/profile';
import addressUtils from '../../utils/address';
import helperUtils from '../../utils/helper';
import userUtils from '../../utils/user';
import SpreadsheetDrawer from '../common/spreadsheet-drawer';
import LayoutBase from '../layout';
import { Spreadsheet } from '../models';

import { store } from './state/store';
import StepOne from './step-one';
import StepTwo from './step-two';
import Wizard from './wizard';

interface FileData extends ApiResponse {
  clientId: string;
  fileId: string;
}

interface ValuesState {
  name?: string;
  address?: string;
  spreadsheets?: Spreadsheet[];
  consultants?: User[];
  admin?: string;
}

interface ContentProps {
  state: () => ValuesState;
  consultants: User[];
  onChange: (values: ValuesState) => void;
  onReset: () => void;
}

const Layout = styled(LayoutBase)`
  position: relative;
`;

const Content: React.FC<ContentProps> = ({ state, consultants, onChange, onReset }) => {
  const { name, address, spreadsheets, consultants: selected } = state();

  const history = useHistory();
  const [step, setStep] = React.useState(1);
  const [clientId, setClientId] = React.useState(null);
  const [spreadsheet, setSpreadsheet] = React.useState(null);
  const [render, setRender] = React.useState(0);

  const isSelected = (id: string) => {
    const ids = selected.map(consultant => consultant.uniqueId);
    return ids.indexOf(id) > -1;
  };

  const reset = () => {
    onReset();
    setClientId(null);
    setStep(1);
  };

  React.useEffect(() => {
    // Always return to step 1 if no client id is set
    if (step !== 1 && !clientId) {
      setStep(1);
    }
  }, [step]);

  return (
    <Layout>
      <Wizard>
        {step === 1 && (
          <StepOne
            name={name}
            address={address}
            spreadsheets={spreadsheets}
            onChange={onChange}
            onAddSpreadsheet={() => setSpreadsheet({})}
            onEditSpreadsheet={setSpreadsheet}
            onDeleteSpreadsheet={async (spreadsheet, callback) => {
              await Api.channel.delete<ApiResponse>(
                `/api/client/${clientId}/models/${spreadsheet.uniqueId}`
              );
              callback();
            }}
            onCancel={reset}
            onNext={() => setStep(2)}
          />
        )}
        {step === 2 && (
          <StepTwo
            consultants={consultants.map(consultant => ({
              ...consultant,
              selected: isSelected(consultant.uniqueId),
            }))}
            onChange={values => {
              onChange(values);

              // Need to force a reload to update the UI
              setRender(render + 1);
            }}
            onCancel={reset}
            onPrevious={() => setStep(1)}
            onNext={() => {
              store.setSaving(true);

              // Post the form data
              const client = state();
              const defaultFile = client.spreadsheets.find(spreadsheet => spreadsheet.selected);

              bannerState.setCreatingBanner();

              Api.channel
                .post<{ clientId: string }>(`/api/client/${clientId}`, {
                  name: client.name,
                  address: client.address,
                  defaultFileId: defaultFile.uniqueId,
                  consultantIds: client.consultants.map(consultant => consultant.uniqueId),
                  adminConsultantId: client.admin,
                })
                .then(response => {
                  clientState.updateClient({
                    uniqueId: response.data.clientId,
                    name: client.name,
                    address: client.address,
                    hasAcceptedTerms: false,
                    selected: true,
                  });

                  clientState.setSelectedId(response.data.clientId);
                  bannerState.clearBanner();
                  store.setSaving(false);

                  history.push(`${routes.admin.clientManagement}`);
                })
                .catch(() => {
                  bannerState.clearBanner();
                  store.setSaving(false);
                });
            }}
          />
        )}
      </Wizard>
      <SpreadsheetDrawer
        spreadsheet={spreadsheet}
        onSave={(spreadsheet, callback) => {
          // Post the form data
          const formData = new FormData();
          const { uniqueId, label, notes, file } = spreadsheet;

          if (uniqueId) {
            formData.append('fileId', uniqueId);
          }

          formData.append('label', label);
          formData.append('notes', notes || '');

          formData.append('file', file.data, file.name);

          Api.channel
            .post<FileData>(`/api/client/${clientId || 'null'}/models`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(({ data }) => {
              // Pass the file info back up to the parent
              if (data.status === Api.SUCCESS) {
                let list = spreadsheets;

                // Ensure the client id is memoized
                setClientId(data.clientId);

                // Determine how to store the spreadsheet provided
                // Could be an add or update
                const match = spreadsheets.find(
                  ({ uniqueId }) => uniqueId === spreadsheet.uniqueId
                );

                if (match) {
                  list = list.map(current =>
                    current.uniqueId === match.uniqueId ? spreadsheet : current
                  );
                } else {
                  list = [
                    ...list,
                    {
                      ...spreadsheet,
                      uniqueId: data.fileId,
                      selected: list.length === 0,
                    },
                  ];
                }

                onChange({
                  spreadsheets: list,
                });

                // Force the drawer to close if successful
                setSpreadsheet(null);
                callback();
              }
            })
            .catch(() => {
              callback('An error occurred. Verify the spreadsheet and try again.');
            });
        }}
        onClose={() => setSpreadsheet(null)}
      />
    </Layout>
  );
};

const NewClient: React.FC = () => {
  const profile = useObservable(() => profileState.profile$, null);

  const [consultants, setConsultants] = React.useState([]);
  const [initialData, setInitialData] = React.useState({
    consultants: [],
  });

  const resolveAdmin = (list: User[]) => {
    if (profile?.userType === ADMIN_ROLE) {
      return profile.uniqueId;
    }

    if (list.length > 0) {
      const [first] = list.filter(item => item.userType === ADMIN_ROLE);

      return first?.uniqueId;
    }

    return null;
  };

  React.useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      const response = await Api.channel.get<{ users: User[] }>(`/api/user`);
      const consultants = userUtils.getSortedConsultants(response.data.users);

      setConsultants(consultants);
    })();

    // Ensure the store is reset
    store.setValues({
      name: null,
      address: null,
      spreadsheets: [],
      consultants: [],
      admin: null,
    });
  }, []);

  React.useEffect(() => {
    if (profile) {
      const user: User = {
        uniqueId: profile.uniqueId,
        userType: profile.userType,
        fullName: profile.fullName,
        email: profile.email,
        clientIds: [],
      };

      // Ensure the current user is added by default
      // Also resolve the default admin
      const list = [user];

      setInitialData({
        consultants: list,
      });

      store.setValues({
        consultants: list,
        admin: resolveAdmin(list),
      });
    }
  }, [profile]);

  // Use a standard hash to track input state
  // This avoids crappy issues with focus
  let state: ValuesState = {
    name: null,
    address: null,
    spreadsheets: [],
    consultants: initialData.consultants,
    admin: resolveAdmin(initialData.consultants),
  };

  return (
    <Content
      state={() => state}
      consultants={consultants}
      onChange={({ name, address, spreadsheets, consultants }) => {
        if (name || name === '') {
          state = {
            ...state,
            name,
          };
        }

        if (address || address === '') {
          state = {
            ...state,
            address: addressUtils.transformAddress(address, '\n', ','),
          };
        }

        if (spreadsheets) {
          state = {
            ...state,
            spreadsheets: helperUtils.sort(spreadsheets, 'label'),
          };
        }

        if (consultants) {
          state = {
            ...state,
            consultants: userUtils.getSortedConsultants(consultants),
            admin: resolveAdmin(consultants),
          };
        }

        store.setValues(state);
      }}
      onReset={() => {
        state = {
          name: null,
          address: null,
          spreadsheets: [],
          consultants: initialData.consultants,
          admin: resolveAdmin(initialData.consultants),
        };

        store.setValues(state);
      }}
    />
  );
};

export default NewClient;
