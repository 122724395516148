import { QueryEntity } from '@datorama/akita';

import { User } from '../../../../models';

import { ConsultantsState } from './models';
import { ConsultantsStore, store } from './store';

export class ConsultantsQuery extends QueryEntity<ConsultantsState, User, string> {
  readonly admin$ = this.select(state => state.admin);
  readonly saveable$ = this.select(state => state.saveable);
  readonly error$ = this.select(state => state.error);
  readonly consultants$ = this.selectAll();

  constructor(_store: ConsultantsStore) {
    super(_store);
  }
}

export const query = new ConsultantsQuery(store);
