import { Checkbox, Dropdown } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';
import SelectBase, { OptionTypeBase } from 'react-select';
import styled, { css } from 'styled-components';

import theme from '../theme';

import Badge from './badge';
import Caret from './caret';
import Flex from './flex';

interface Option {
  value: string;
  label: string;
  badge?: string;
}

interface SearchSelectProps {
  className?: string;
  value?: any[];
  options: Option[];
  disabled?: boolean;
  formatCount?: (count: number) => string;
  onChange?: (values: string[]) => void;
}

interface SearchSelectState {
  visible: boolean;
}

type SelectProps = OptionTypeBase & {
  options: any[];
  onChange: (value: any[]) => void;
};

interface SelectState {
  criteria: string;
}

const Toggle = styled.div`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.steel};
  border-radius: ${theme.border.radius};
  line-height: 1rem;
  padding: 0.5rem;
`;

const Container = styled.div`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.steel};
  border-radius: ${theme.border.radius};
  padding: 0.5rem;
`;

const Option = styled(({ checked, ...props }) => <Flex.Row {...props} />)`
  padding: 0.25rem;

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${theme.color.panelGrey};
    `};
`;

const Name = styled.span`
  margin-left: 0.5rem;
`;

class Select extends React.Component<SelectProps, SelectState> {
  constructor(props: SelectProps) {
    super(props);

    this.state = {
      criteria: '',
    };
  }

  render(): React.ReactNode {
    const { options, value: initialValue } = this.props;
    const { criteria } = this.state;

    return (
      <Container>
        <SelectBase
          isMulti={true}
          isClearable={false}
          menuIsOpen={true}
          closeMenuOnSelect={false}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          placeholder="Search..."
          inputValue={criteria}
          options={options}
          value={initialValue}
          styles={{
            menu: provided => ({
              ...provided,
              borderRadius: 0,
              boxShadow: 'none',
              position: 'relative',
              marginBottom: 0,
            }),
            option: provided => ({
              ...provided,
              color: `${theme.color.black} !important`,
              backgroundColor: `${theme.color.white} !important`,
              marginBottom: '1px',
              padding: 0,
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
          }}
          formatOptionLabel={option => {
            const match = initialValue.find((id: string) => id === option.value);
            const checked = !!match;

            return (
              <Option align="middle" justify="space-between" checked={checked}>
                <Flex.Item>
                  <Checkbox
                    checked={checked}
                    onChange={ev => this.handleCheck(option.value, ev)}
                  ></Checkbox>
                  <Name>{option.label}</Name>
                </Flex.Item>
                <Flex.Item>{option.badge && <Badge>{option.badge}</Badge>}</Flex.Item>
              </Option>
            );
          }}
          onInputChange={value =>
            this.setState({
              criteria: value,
            })
          }
        />
      </Container>
    );
  }

  private handleCheck(id: string, ev: CheckboxChangeEvent): void {
    const { criteria } = this.state;
    const { value } = this.props;
    const checked = ev.target.checked;
    const match = value.find((current: string) => current === id);

    let ids: any[] = [];
    if (checked && !match) {
      ids = [...value, id];
    } else if (!checked && match) {
      ids = value.filter((current: string) => current !== id);
    }

    this.setState({
      criteria,
    });

    const { onChange } = this.props;
    onChange(ids);
  }
}

export default class extends React.Component<SearchSelectProps, SearchSelectState> {
  constructor(props: SearchSelectProps) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render(): React.ReactNode {
    const { className, disabled, options, value: initialValue } = this.props;
    const { visible } = this.state;

    return (
      <Dropdown
        className={className}
        visible={visible}
        disabled={disabled}
        onVisibleChange={visible => {
          if (!visible) {
            this.setState({
              visible,
            });
          }
        }}
        overlay={() => (
          <Select
            options={options}
            value={initialValue}
            onChange={value => this.handleSelect(value)}
          />
        )}
      >
        <Toggle onClick={() => this.toggleVisibility()}>
          <Flex.Row align="middle" justify="space-between">
            <Flex.Item>
              {initialValue.length} item{initialValue.length === 1 ? '' : 's'} selected
            </Flex.Item>
            <Flex.Item>
              <Caret />
            </Flex.Item>
          </Flex.Row>
        </Toggle>
      </Dropdown>
    );
  }

  private toggleVisibility(): void {
    this.setState({
      visible: !this.state.visible,
    });
  }

  private handleSelect(value: any[]): void {
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  }
}
