import { Store, StoreConfig } from '@datorama/akita';

import storageUtils from '../../../../utils/storage';
import {
  DISABLED_ID_KEY,
  END_YEAR_KEY,
  START_YEAR_KEY,
  YEARS_MODEL_ID_KEY,
} from '../../common/storage';

import { GraphState } from './models';

@StoreConfig({
  name: 'Projection Tool > Graph',
})
export class GraphStore extends Store<GraphState> {
  constructor() {
    super({
      ref: 0,
      disabledIds: [],
      startYear: null,
      endYear: null,
      modelId: null,
    });
  }

  initialize(useBrowserStore: boolean): void {
    if (!useBrowserStore) {
      storageUtils.set(DISABLED_ID_KEY, undefined);
      storageUtils.set(START_YEAR_KEY, undefined);
      storageUtils.set(END_YEAR_KEY, undefined);
      storageUtils.set(YEARS_MODEL_ID_KEY, undefined);
    }

    const ids = storageUtils.get(DISABLED_ID_KEY);
    const startYear = storageUtils.get(START_YEAR_KEY);
    const endYear = storageUtils.get(END_YEAR_KEY);
    const modelId = storageUtils.get(YEARS_MODEL_ID_KEY);

    this.update({
      disabledIds: ids ? JSON.parse(ids) : [],
      startYear: startYear ? +startYear : null,
      endYear: endYear ? +endYear : null,
      modelId,
    });
  }

  refreshChart(): void {
    // Updating the ref causes React to re-render the chart
    this.update({
      ref: Date.now(),
    });
  }

  setDisabled(id: string, disabled: boolean): void {
    this.update(state => {
      let { disabledIds } = state;

      // Either include or exclude the requested id
      const index = disabledIds.indexOf(id);
      if (disabled && index === -1) {
        disabledIds = [...disabledIds, id];
      } else if (!disabled && index > -1) {
        disabledIds = disabledIds.filter(current => current !== id);
      }

      // Store the ids
      const ids = JSON.stringify(disabledIds);
      storageUtils.set(DISABLED_ID_KEY, ids);

      return {
        ref: Date.now(),
        disabledIds,
      };
    });
  }

  setStartYear(year: number, modelId: string): void {
    storageUtils.set(START_YEAR_KEY, `${year}`);
    storageUtils.set(YEARS_MODEL_ID_KEY, modelId);

    this.update({
      ref: Date.now(),
      startYear: year,
      modelId,
    });
  }

  setEndYear(year: number, modelId: string): void {
    storageUtils.set(END_YEAR_KEY, `${year}`);
    storageUtils.set(YEARS_MODEL_ID_KEY, modelId);

    this.update({
      ref: Date.now(),
      endYear: year,
      modelId,
    });
  }
}

export const store = new GraphStore();
