import { QueryEntity } from '@datorama/akita';
import numeral from 'numeral';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { scenarioColors } from '../../common/colors';
import * as commonState from '../../common/state';
import * as graphTypeState from '../../graph-types/state';
import * as scenarioState from '../../scenarios/state';

import { Input, InputEntity, InputState, InputType, InputUpdate, InputValues } from './models';
import { InputStore, store } from './store';

export class InputQuery extends QueryEntity<InputState, InputEntity> {
  private readonly _scenarios$ = scenarioState.scenarios$.pipe(
    map(scenarios => scenarios.filter(({ enabled }) => enabled))
  );

  // tslint:disable: member-ordering
  readonly panelId$ = commonState.panelId$;
  readonly split$ = commonState.split$;
  readonly graphType$ = graphTypeState.selectedId$;
  readonly selectedId$ = this.select(state => state.selectedId);
  readonly ref$ = this.select(state => state.ref);
  readonly years$ = this.select(state => state.years);

  readonly scenarios$ = combineLatest([this.selectedId$, this._scenarios$]).pipe(
    map(([selectedId, scenarios]) =>
      scenarios.map((scenario, index) => ({
        ...scenario,
        color: scenarioColors[index % scenarioColors.length],
        selected: scenario.id === selectedId,
      }))
    )
  );

  readonly inputTypes$ = this.select(state => state.types).pipe(
    map(types =>
      types.map(({ id, name, formatType, decimalPlaces }) => {
        const inputType: InputType = {
          id,
          name,
          formatType,
          decimalPlaces,
        };

        return inputType;
      })
    )
  );

  readonly inputValues$ = combineLatest([
    this.selectedId$,
    this.inputTypes$,
    this.years$,
    this.selectAll(),
    this.select(state => state.updates),
  ]).pipe(
    map(([selectedId, types, years, entities, updates]) => {
      const inputs: InputValues[] = [];
      const filtered = entities.filter(entity => entity.scenario === selectedId);

      // Build the input year rows
      for (const year of years) {
        // Add the input name/value pairs as properties
        const values: any = {};
        for (const type of types) {
          const match = filtered.find(entity => type.id === entity.typeId && entity.year === year);
          if (match) {
            // Check if an unapplied value needs to be used
            const update = updates[match.id];

            // Get the current value and adjust if a percentage
            let value = update && !update.applied ? update.value : match.value;
            if (value && !isNaN(+value) && type.formatType === 'percent') {
              const adjusted = numeral(value)
                .multiply(100)
                .value();

              value = `${adjusted}`;
            }

            values[type.id] = value;
          }
        }

        inputs.push({
          id: `${selectedId}_${year}`,
          year,
          ...values,
        });
      }

      return inputs;
    })
  );

  readonly inputs$ = combineLatest([
    this.select(state => state.types),
    this.scenarios$,
    this.selectAll(),
    this.select(state => state.updates),
  ]).pipe(
    map(([types, scenarios, entities, updates]) => {
      const inputs: Input[] = [];
      for (const scenario of scenarios) {
        for (const type of types) {
          const input: Input = {
            id: `${scenario.id}_${type.id}`,
            name: type.name,
            category: type.category,
            scenario: scenario.id,
            values: entities
              .filter(entity => type.id === entity.typeId && entity.scenario === scenario.id)
              .map(entity => {
                const update = updates[entity.id];
                const dirty = update && !update.applied;
                const value = dirty ? update.value : entity.value;

                return {
                  year: entity.year,
                  value: entity.valueType === 1 ? +value : value,
                  valueType: entity.valueType,
                  dirty,
                };
              }),
          };

          inputs.push(input);
        }
      }

      return inputs;
    })
  );

  readonly updates$ = combineLatest([
    this.select(state => state.updateIds),
    this.select(state => state.updates),
  ]).pipe(
    map(([ids, updates]) =>
      ids.map(id => {
        const update: InputUpdate = {
          ...updates[id],
          id,
        };

        return update;
      })
    )
  );

  constructor(_store: InputStore) {
    super(_store);
  }
}

export const query = new InputQuery(store);
