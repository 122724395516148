const serializeAddress = (address: string[], separator: string = ','): string => {
  if (address) {
    return address
      .map(line => line.trim())
      .filter(line => !!line)
      .join(separator);
  }

  return null;
};

const deserializeAddress = (address: string, separator: string = ','): string[] => {
  if (address) {
    return address
      .split(separator)
      .map(line => line.trim())
      .filter(line => !!line);
  }

  return [];
};

const transformAddress = (address: string, from: string, to: string): string => {
  const deserialized = deserializeAddress(address, from);
  return deserialized.join(to);
};

export default { serializeAddress, deserializeAddress, transformAddress };
