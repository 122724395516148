import { EntityStore, StoreConfig } from '@datorama/akita';

import helperUtils from '../../../../utils/helper';
import storageUtils from '../../../../utils/storage';
import formatUtils from '../../common/format';
import { INPUT_ID_KEY } from '../../common/storage';
import { ScenarioData } from '../../scenarios/state';

import { InputData, InputEntity, InputState, InputTypeEntity, InputValues } from './models';

@StoreConfig({
  name: 'Projection Tool > Inputs',
})
export class InputStore extends EntityStore<InputState, InputEntity> {
  constructor() {
    super({
      ref: 0,
      selectedId: null,
      scenarioIds: [],
      types: [],
      years: [],
      updateIds: [],
      updates: {},
    });
  }

  initialize(inputs: InputData[], scenarios: ScenarioData[]): void {
    const years: number[] = [];
    const entities: InputEntity[] = [];

    // Split the scenario/input/year data for easy store updates
    const types = inputs
      .filter(({ enabled }) => enabled)
      .map(({ name, category, format, decimalPlaces }) => {
        const type: InputTypeEntity = {
          id: helperUtils.normalize(name),
          name,
          category,
          formatType: formatUtils.getFormatType(format),
          decimalPlaces,
        };

        return type;
      });

    for (const scenario of scenarios) {
      this.process(types, scenario, entities, years);
    }

    this.remove();
    this.upsertMany(entities);

    // Load state
    this.update({
      ref: Date.now(),
      scenarioIds: scenarios.filter(({ enabled }) => enabled).map(({ uniqueId }) => uniqueId),
      types,
      years,
      updateIds: [],
      updates: {},
    });
  }

  ensureSelectionForScenarios(scenarioIds: string[]): void {
    // Update the scenario ids
    this.update({
      scenarioIds,
    });

    // Ensure the current selection is valid for the scenario ids
    const state = this.getValue();
    this.setSelectedId(state.selectedId);
  }

  applyInputUpdates(doSave: boolean): void {
    this.update(state => {
      const entities = { ...state.entities };
      const updates = { ...state.updates };

      // Apply the update to the original entity and mark as applied
      for (const id of state.updateIds) {
        const update = updates[id];
        if (update) {
          entities[id] = {
            ...entities[id],
            value: update.value,
          };

          updates[id] = {
            ...updates[id],
            applied: true,
          };
        }
      }

      return {
        entities,
        // if we are saving we do not need to persist the updates
        updates: doSave ? {} : updates,
        updateIds: doSave ? [] : state.updateIds,
      };
    });
  }

  addScenarioInputs(scenario: ScenarioData): void {
    const state = this.getValue();

    // Process the scenario data and add the inputs to the store
    const entities: InputEntity[] = [];
    this.process(state.types, scenario, entities);
    this.add(entities);
  }

  setSelectedId(id: string): void {
    this.update(state => {
      const selectedId = helperUtils.ensureSelection(
        state.scenarioIds,
        scenarioId => scenarioId === id
      );

      storageUtils.set(INPUT_ID_KEY, selectedId);

      return {
        ref: Date.now(),
        selectedId,
      };
    });
  }

  setInputUpdates(inputs: InputValues): void {
    let dirty = false;

    // Get the matching inputs and compare with original value
    const { types, entities, updateIds, updates } = this.getValue();
    const state: any = {
      updateIds: [...updateIds],
      updates: { ...updates },
    };

    for (const type of types) {
      const id = `${inputs.id}_${type.id}`;
      const entity = entities[id];

      if (entity) {
        const value = inputs[type.id];
        if (entity.value !== value) {
          // Add the id if needed
          if (state.updateIds.indexOf(id) === -1) {
            state.updateIds.push(id);
          } else if (state.updates[id].value === value) {
            continue;
          }

          // Set the update value for the input
          state.updates = {
            ...state.updates,
            [id]: {
              value,
              applied: false,
            },
          };
          dirty = true;
        } else if (state.updateIds.indexOf(id) > -1) {
          // Remove the update as there are no longer any changes
          state.updateIds = state.updateIds.filter((current: string) => current !== id);
          state.updates = state.updateIds.reduce((output: any, current: string) => {
            output[current] = {
              ...state.updates[current],
            };

            return output;
          }, {});
          dirty = true;
        }
      }
    }

    if (dirty) {
      this.update(state);
    }
  }

  private process(
    types: InputTypeEntity[],
    scenario: ScenarioData,
    entities: InputEntity[],
    years: number[] = null
  ): void {
    for (const input of scenario.inputs) {
      // Only initialize enabled inputs
      const type = types.find(({ id }) => id === helperUtils.normalize(input.name));
      if (type) {
        for (const current of input.values) {
          entities.push({
            id: `${scenario.uniqueId}_${current.year}_${type.id}`,
            typeId: type.id,
            scenario: scenario.uniqueId,
            year: current.year,
            value: current.value == null ? null : `${current.value}`,
            valueType: current.valueType,
          });

          // Build a distinct list of years
          if (years && years.indexOf(current.year) === -1) {
            years.push(current.year);
          }
        }
      }
    }
  }
}

export const store = new InputStore();
