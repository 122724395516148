import ManageSenariosSvg from '../../assets/icon-manage-scenarios.svg';

import Icon from './icon';
import { IconProps } from './icon';

export default class extends Icon {
  constructor(props: IconProps) {
    super(ManageSenariosSvg, props);
  }
}
