import React from 'react';

import { ADMIN_ROLE, routes } from '../constants';
import Router from '../router';

import AdminManagement from './admin-management';
import ClientManagement from './client-management';
import InformationCenter from './information-center';
import NewClient from './new-client';

const Admin: React.FC = () => (
  <Router.Child>
    <Router.Page path={routes.admin.clientManagement} component={ClientManagement} />
    <Router.Page path={routes.admin.informationCenter} component={InformationCenter} />
    <Router.Page path={routes.admin.newClient} component={NewClient} />
    <Router.Page
      path={routes.admin.adminManagement}
      component={AdminManagement}
      allow={[ADMIN_ROLE]}
    />
  </Router.Child>
);

export default Admin;
