import React from 'react';
import styled from 'styled-components';

import Select from '../../common/select';
import { Client } from '../../models';
import theme from '../../theme';

interface ClientSelectProps {
  className?: string;
  clients: Client[];
  selectedClient: Client;
  onChange?: (value: string) => void;
}

const ClientSelect: React.FC<ClientSelectProps> = ({
  className,
  clients,
  selectedClient,
  onChange,
}) => (
  <Select
    className={className}
    value={selectedClient?.uniqueId}
    fullWidth={false}
    borderless={true}
    onChange={onChange}
  >
    {clients.map(client => (
      <Select.Option key={client.uniqueId} value={client.uniqueId}>
        {client.name}
      </Select.Option>
    ))}
  </Select>
);

export default styled(ClientSelect)`
  color: ${theme.color.millimanBlue};
  font-size: ${theme.size.heading};
  font-weight: ${theme.weight.medium};
  margin-bottom: 1rem;

  .ant-select-selection__rendered {
    margin-left: 0;
  }
`;
