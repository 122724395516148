import { Query } from '@datorama/akita';

import { NewClientState } from './models';
import { NewClientStore, store } from './store';

export class NewClientQuery extends Query<NewClientState> {
  readonly name$ = this.select(state => state.name);
  readonly address$ = this.select(state => state.address);
  readonly spreadsheets$ = this.select(state => state.spreadsheets);
  readonly consultants$ = this.select(state => state.consultants);
  readonly admin$ = this.select(state => state.admin);
  readonly saving$ = this.select(state => state.saving);

  constructor(_store: NewClientStore) {
    super(_store);
  }
}

export const query = new NewClientQuery(store);
