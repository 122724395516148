import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import theme from '../../../../theme';
import helperUtils from '../../../../utils/helper';
import { multiOutputColors, scenarioColors, zoneColors } from '../../common/colors';
import * as graphTypeState from '../../graph-types/state';
import * as outputState from '../../outputs/state';

import { GraphData, GraphLegend, GraphState } from './models';
import { GraphStore, store } from './store';

const zoneStatusMap = {
  [zoneColors.green]: [
    helperUtils.normalize('Not critical or endangered'),
    helperUtils.normalize('Not Critical or Endangered, Can Elect Critical'),
    helperUtils.normalize('Endangered but Safe'),
    helperUtils.normalize('Endangered but Safe, Can Elect Critical'),
  ],
  [zoneColors.yellow]: [
    helperUtils.normalize('Endangered'),
    helperUtils.normalize('Endangered, Can Elect Critical'),
  ],
  [zoneColors.orange]: [
    helperUtils.normalize('Seriously Endangered'),
    helperUtils.normalize('Seriously Endangered, Can Elect Critical'),
  ],
  [zoneColors.red]: [helperUtils.normalize('Critical')],
  [zoneColors.deepRed]: [helperUtils.normalize('Critical and Declining')],
};

export class GraphQuery extends Query<GraphState> {
  private readonly _disabledIds$ = this.select(state => state.disabledIds);

  // tslint:disable: member-ordering
  readonly ref$ = this.select(state => state.ref);

  readonly outputType$ = outputState.outputTypes$.pipe(
    map(outputTypes => outputTypes.find(outputType => outputType.selected))
  );

  readonly graphType$ = graphTypeState.selectedId$;

  readonly minYear$ = outputState.years$.pipe(
    map(years => (years.length > 0 ? Math.min(...years) : null))
  );

  readonly maxYear$ = outputState.years$.pipe(
    map(years => (years.length > 0 ? Math.max(...years) : null))
  );

  readonly startYear$ = combineLatest([this.select(state => state.startYear), this.minYear$]).pipe(
    map(([startYear, minYear]) => startYear || minYear)
  );

  readonly endYear$ = combineLatest([
    this.select(state => state.endYear),
    this.maxYear$,
    this.startYear$,
  ]).pipe(map(([endYear, maxYear, startYear]) => endYear || Math.min(maxYear, startYear + 19)));

  readonly years$ = combineLatest([this.startYear$, this.endYear$, outputState.years$]).pipe(
    map(([startYear, endYear, years]) => years.filter(year => year >= startYear && year <= endYear))
  );

  readonly modelIdForYears$ = this.select(state => state.modelId);

  readonly legend$ = combineLatest([
    this.graphType$,
    this._disabledIds$,
    outputState.outputs$,
  ]).pipe(
    map(([graphType, disabledIds, outputs]) =>
      outputs.map((output, index) => {
        const legend: GraphLegend = {
          id: output.id,
          name: output.name,
          displayName: output.displayName,
          color: this.getGraphColor(index, output.index, graphType),
          enabled: disabledIds.indexOf(output.id) === -1,
        };

        return legend;
      })
    )
  );

  readonly data$ = combineLatest([
    this.graphType$,
    this._disabledIds$,
    this.years$,
    outputState.outputs$,
  ]).pipe(
    map(([graphType, disabledIds, years, outputs]) => {
      const mapped = outputs.map((output, index) => {
        // Build the graph data object
        const data: GraphData = {
          id: output.id,
          type: output.type,
          name: output.name,
          color: this.getGraphColor(index, output.index, graphType),
          values: [],
        };

        // Flatten the outputs to groups of year, and scenario values
        for (const year of years) {
          const match = output.values.find(value => value.year === year);
          data.values.push({
            year,
            value: match ? match.value : 0,
            color: match ? this.getZoneColor(match.status) : null,
          });
        }

        return data;
      });

      return mapped.filter(output => disabledIds.indexOf(output.id) === -1);
    })
  );

  constructor(_store: GraphStore) {
    super(_store);
  }

  private getGraphColor(
    index: number,
    outputIndex: number,
    graphType: graphTypeState.GraphTypeId
  ): string {
    if (graphType === 'multi') {
      return multiOutputColors[outputIndex];
    }

    return scenarioColors[index % scenarioColors.length];
  }

  private getZoneColor(status: string): string {
    const match: any = Object.keys(zoneStatusMap).find(key => {
      const items = zoneStatusMap[key];
      return items && items.indexOf(status) > -1;
    });

    return match ?? theme.color.steel;
  }
}

export const query = new GraphQuery(store);
