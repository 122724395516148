import React from 'react';
import styled from 'styled-components';

import Badge from '../../common/badge';
import Flex from '../../common/flex';
import List from '../../common/list';
import { User } from '../../models';
import userUtils from '../../utils/user';

interface ConsultantListProps {
  consultants: User[];
  deleteIcon?: (consultant: User, callback: () => void) => React.ReactNode;
  onDelete: (vaule: string) => void;
}

const DeleteContainer = styled(Flex.Item)`
  text-align: right;
  width: 1.5rem;
`;

const ConsultantList: React.FC<ConsultantListProps> = ({ consultants, deleteIcon, onDelete }) => {
  // Work out how many items can be deleted
  const deleteIcons = consultants.reduce<any>(
    (output, current) => {
      const icon = deleteIcon(current, () => onDelete(current.uniqueId));

      if (icon) {
        output.icons[current.uniqueId] = icon;
        output.deletable += 1;
      }

      return output;
    },
    {
      icons: {},
      deletable: 0,
    }
  );

  return (
    <List>
      {consultants.map(consultant => (
        <List.ListItem key={consultant.uniqueId}>
          <Flex.Row align="middle">
            <Flex.Item fill={true}>{consultant.fullName}</Flex.Item>
            <Flex.Item>
              <Badge>{userUtils.getRoleString(consultant.userType)}</Badge>
            </Flex.Item>
            {deleteIcons.deletable > 0 && (
              <DeleteContainer>{deleteIcons.icons[consultant.uniqueId]}</DeleteContainer>
            )}
          </Flex.Row>
        </List.ListItem>
      ))}
    </List>
  );
};

export default ConsultantList;
