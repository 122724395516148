import { Badge as BadgeBase } from 'antd';
import React from 'react';
import Moment from 'react-moment';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Flex from '../common/flex';
import { AvatarIcon } from '../common/icons';
import * as notificationState from '../state/notifications';
import * as profileState from '../state/profile';
import theme from '../theme';
import userUtils from '../utils/user';

interface UserProps {
  onAvatarClick: () => void;
}

const Avatar = styled.div`
  cursor: pointer;
  background-color: ${theme.color.millimanBlue};
  border-radius: 50%;
  padding: 0.25rem;

  ${breakpoint('md')`
    svg {
      height: 2rem;
      width: 2rem;
    }
  `}
`;

const Info = styled.div`
  display: none;
  margin: 0.25rem 0.5rem 0.25rem 0;
  text-align: right;

  ${breakpoint('md')`
    display: block;
  `}
`;

const Badge = styled(BadgeBase)`
  .ant-badge-count {
    top: 0.5rem;
  }
`;

const User: React.FC<UserProps> = ({ onAvatarClick }) => {
  const profile = useObservable(() => profileState.profile$, null);
  const unviewed = useObservable(
    () =>
      notificationState.notifications$.pipe(
        map(notifications => notifications.filter(current => !current.viewed))
      ),
    []
  );

  // Wrap the avatar in a badge if a notification count is needed
  let avatar = (
    <Avatar onClick={onAvatarClick}>
      <AvatarIcon color={theme.color.white} height="1.5rem" width="1.5rem" />
    </Avatar>
  );

  if (userUtils.isUser(profile?.userType) && unviewed.length > 0) {
    avatar = (
      <Badge count={unviewed.length} overflowCount={10}>
        {avatar}
      </Badge>
    );
  }

  return (
    <Flex.Row align="middle">
      <Flex.Item>
        <Info>Welcome {profile?.fullName}</Info>
        <Info>
          <Moment format="MMMM DD, YYYY" />
        </Info>
      </Flex.Item>
      <Flex.Item>{avatar}</Flex.Item>
    </Flex.Row>
  );
};

export default User;
