import { Input } from 'antd';
import React from 'react';

import FormBase from '../../common/form';
import { Api, ApiResponse } from '../../http/api';
import Layout from '../layout';

interface FormState {
  messages: string[];
  attempted: boolean;
  submitting: boolean;
  submitted: boolean;
}

class Form extends FormBase<{}, FormState> {
  private readonly EMAIL_FIELD_NAME = 'email';

  constructor(props: {}) {
    super(props);

    this.state = {
      messages: null,
      attempted: false,
      submitting: false,
      submitted: false,
    };
  }

  render(): React.ReactNode {
    const { getFieldError } = this.props.form;
    const { attempted, submitting, submitted } = this.state;

    // Check if any message are to be displayed
    let { messages } = this.state;

    if (!messages && attempted) {
      messages = getFieldError(this.EMAIL_FIELD_NAME);
    }

    return (
      <FormBase
        layout="vertical"
        hideRequiredMark={true}
        onSubmit={ev => this.handleFormSubmit(ev)}
      >
        <FormBase.Item label="Enter your email address below:" validateStatus="" help="">
          {this.renderEmailField()}
        </FormBase.Item>
        <FormBase.Item>
          <Layout.Button loading={submitting}>Confirm</Layout.Button>
        </FormBase.Item>
        <Layout.Footer
          success={submitted ? 'Password reset instruction sent to your email' : null}
          errors={messages}
        />
      </FormBase>
    );
  }

  private renderEmailField(): React.ReactNode {
    const { getFieldDecorator } = this.props.form;
    const { submitting } = this.state;
    const decorator = getFieldDecorator(this.EMAIL_FIELD_NAME, {
      rules: [
        {
          type: 'email',
          required: true,
          message: 'Enter a valid email address',
        },
      ],
    });

    return decorator(<Input placeholder="Enter your email address" disabled={submitting} />);
  }

  private handleFormSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();

    // Flag an attempted submit
    this.setState({
      attempted: true,
    });

    // Validate the fields and post if all g!
    const { form } = this.props;
    form.validateFields((err, fieldsValue) => {
      if (!err) {
        this.setState({
          messages: null,
          submitting: true,
          submitted: false,
        });

        // Post the form data
        Api.channel
          .post<ApiResponse>('/api/user/resetpassword/request', {
            email: fieldsValue[this.EMAIL_FIELD_NAME],
          })
          .then(({ data }) => {
            this.setState({
              messages: null,
              submitting: false,
              submitted: data.status === Api.SUCCESS,
            });
          })
          .catch(() => {
            this.setState({
              messages: ['An error has occurred'],
              submitting: false,
            });
          });
      }
    });
  }
}

export default FormBase.createType(Form);
