import React from 'react';
import { Observable, Subject } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../../common/content';
import FormBase from '../../common/form';
import ResourceTableBase from '../../common/resource-table';
import Splitter from '../../common/splitter';
import TabsBase from '../../common/tabs';
import { Api } from '../../http/api';
import { Model, Resource } from '../../models';
import * as bannerState from '../../state/banner';
import * as clientState from '../../state/clients';
import theme from '../../theme';
import helperUtils from '../../utils/helper';
import resourceUtils from '../../utils/resource';
import ClientSelectBase from '../common/client-select';
import Layout from '../layout';

import Form from './form';

interface ResourceTableProps {
  loading: boolean;
  resources: Resource[];
  renderObservable: Observable<number>;
  onDelete: (resources: string[]) => void;
}

const Left = styled(Splitter.Panel)`
  padding: 1rem 0;

  ${breakpoint('md')`
    padding: 1rem;
  `}

  ${breakpoint('lg')`
    padding: 2rem;
  `}
`;

const Right = styled(props => <Splitter.Panel {...props} />)`
  display: none;
  padding: 1rem;

  ${({ visible }) =>
    visible &&
    css`
      display: block !important;
    `}

  ${breakpoint('lg')`
    background-color: ${theme.color.panelGrey};
    display: block !important;
    padding: 2rem;
  `}
`;

const Heading = styled(Content.Heading)`
  padding: 0 1rem 0.5rem 1rem;

  ${breakpoint('md')`
    padding: 0 0 0.5rem 0;
  `}
`;

const ClientSelect = styled(ClientSelectBase)`
  padding: 0 1rem;

  ${breakpoint('md')`
    padding: 0;
  `}
`;

const Tabs = styled.div`
  ${breakpoint('lg')`
    display: none;
  `}
`;

const Table = styled(({ className, children }) => <div className={className}>{children}</div>)`
  display: none;
  padding: 1rem;

  ${({ visible }) =>
    visible &&
    css`
      display: block !important;
    `}

  ${breakpoint('md')`
    padding: 1rem 0;
  `}

  ${breakpoint('lg')`
    display: block !important;
    padding: 0 0 1rem 0;
  `}
`;

const ResourceTable: React.FC<ResourceTableProps> = ({
  loading,
  resources,
  renderObservable,
  onDelete,
}) => {
  const [size, setSize] = React.useState(0);

  React.useEffect(() => {
    if (renderObservable) {
      const subscription = renderObservable.subscribe(value => {
        setSize(value);
      });

      return () => subscription.unsubscribe();
    }

    return null;
  });

  return (
    <ResourceTableBase
      loading={loading}
      showDelete={true}
      dataSource={resources}
      onResourceDeleted={onDelete}
    />
  );
};

const InformationCenter: React.FC = () => {
  // Hack to rerender the table after the splitter is moved
  const renderObservable = new Subject<number>();

  const clientId = useObservable(() => clientState.selectedId$, null);
  const clients = useObservable(() => clientState.clients$, []);

  const [activeTab, setActiveTab] = React.useState('list');
  const [resources, setResources] = React.useState<Resource[]>([]);
  const [loading, setLoading] = React.useState(true);

  const currentClient = clients?.find(c => c.selected);

  const loadResources = async () => {
    let list = await resourceUtils.getResources(clientId);
    list = helperUtils.sort(list, 'fileName');

    setResources(list);
  };

  React.useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      await loadResources();
      setLoading(false);
    })();
  }, [clientId]);

  return (
    <Layout>
      <Splitter defaultSize="60%" minSize={350} onResize={size => renderObservable.next(size)}>
        <Left>
          <ClientSelect
            selectedClient={currentClient}
            clients={clients}
            onChange={clientState.setSelectedId}
          />
          <Heading>Information Center</Heading>
          <Tabs>
            <TabsBase activeKey={activeTab} onChange={setActiveTab}>
              <TabsBase.Content key="list" tab="View Resources" />
              <TabsBase.Content key="upload" tab="Upload Resource" />
            </TabsBase>
          </Tabs>
          <Table visible={activeTab === 'list'}>
            <ResourceTable
              loading={loading}
              resources={resources}
              renderObservable={renderObservable}
              onDelete={(ids: string[]) => {
                const list = resources.filter(item => !ids.some(r => item.uniqueId === r));
                setResources(list);
              }}
            />
          </Table>
        </Left>
        <Right visible={activeTab === 'upload'}>
          {FormBase.renderType(Form, {
            onSave: async (formData: FormData) => {
              bannerState.setUploadingBanner();

              // add the file
              await Api.channel.post(`/api/client/${clientId}/files`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });

              // tslint:disable-next-line: no-floating-promises
              loadResources();
              bannerState.clearBanner();
            },
          })}
        </Right>
      </Splitter>
    </Layout>
  );
};

export default InformationCenter;
