import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../common/button';
import Flex from '../../common/flex';
import LoaderBase from '../../common/loader';
import Modal from '../../common/modal';
import TabsBase from '../../common/tabs';
import { ADMIN_STANDARD_ROLE } from '../../constants';
import { Client, Profile, User } from '../../models';
import { useGuard } from '../../router';
import ClientSelectBase from '../common/client-select';
import ConfirmPassword from '../common/confirm-password';
import { Input, MultiOutput, Output, Scenario, Spreadsheet } from '../models';

import Consultants from './consultants';
import Data from './data';
import Details from './details';
import Scenarios from './scenarios';
import Spreadsheets from './spreadsheets';
import * as spreadsheetState from './spreadsheets/state';
import Users from './users';

interface ContentProps {
  loading: boolean;
  profile: Profile;
  activeTab: string;
  selected: Client;
  clients: Client[];
  consultants: User[];
  data: {
    inputs: Input[];
    outputs: Output[];
    multiOutputs: MultiOutput[];
  };
  scenarios: Scenario[];
  state: () => any;
  onTabChange: (tab: string) => void;
  onSelect: (clientId: string) => void;
  onChange: (values: any) => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete: (password: string, callback: (error?: string) => void) => void;
  onSelectSpreadsheet: (spreadsheet: Spreadsheet, previous: Spreadsheet) => void;
  onAddSpreadsheet: () => void;
  onEditSpreadsheet: (spreadsheet: Spreadsheet) => void;
  onDeleteSpreadsheet: (spreadsheet: Spreadsheet, callback: (error?: string) => void) => void;
  onAddUser: () => void;
  onEditUser: (user: User) => void;
  onDeleteUser: (user: User, callback: (error?: string) => void) => void;
}

interface DeleteProps {
  onDelete: (password: string, callback: (error?: string) => void) => void;
}

const Loader = styled(LoaderBase)`
  margin-top: 5rem;
`;

const Container = styled.div`
  padding: 1rem 0;
  height: 100%;

  ${breakpoint('md')`
    padding: 1rem;
  `}

  ${breakpoint('lg')`
    padding: 2rem;
  `}
`;

const ClientContainer = styled(Flex.Item)`
  max-width: calc(100% - 9rem);

  ${breakpoint('md')`
    width: auto;
  `}
`;

const ClientSelect = styled(ClientSelectBase)`
  padding: 0 1rem;
  width: 100%;

  ${breakpoint('md')`
    padding: 0;
    width: auto;
  `}
`;

const Tabs = styled(TabsBase)`
  .ant-tabs-tab {
    width: auto !important;
  }
`;

const Tab = styled(TabsBase.Content)`
  padding: 0 1rem;

  ${breakpoint('md')`
      padding: 0;
    `}
`;

const DeleteButton = styled(Button)`
  margin: 0 1rem 1rem 1rem;

  ${breakpoint('md')`
    margin: 0 0 1rem 0;
  `}
`;

const Delete: React.FC<DeleteProps> = ({ onDelete }) => {
  const [step, setStep] = React.useState<number>(null);

  return (
    <>
      <DeleteButton type="red" onClick={() => setStep(1)}>
        Delete Client
      </DeleteButton>
      <Modal
        title="Delete Client - Step One"
        visible={step === 1}
        okText="Agree"
        okType="danger"
        onOk={() => setStep(2)}
        onCancel={() => setStep(null)}
      >
        <p>
          I understand that deleting this client will remove them from Milliman Optic&trade; and
          that this change cannot be undone.
        </p>
      </Modal>
      <ConfirmPassword
        title="Delete Client - Step Two"
        visible={step === 2}
        confirmType="danger"
        confirmText="Delete"
        onConfirm={(password, callback) => {
          onDelete(password, err => {
            if (!err) {
              setStep(null);
            }

            callback(err);
          });
        }}
        onCancel={() => setStep(null)}
      >
        Enter your password below to confirm client deletion:
      </ConfirmPassword>
    </>
  );
};

const Content: React.FC<ContentProps> = ({
  loading,
  profile,
  activeTab,
  selected,
  clients,
  consultants,
  data,
  scenarios,
  state,
  onTabChange,
  onSelect,
  onChange,
  onSave,
  onCancel,
  onDelete,
  onSelectSpreadsheet,
  onAddSpreadsheet,
  onEditSpreadsheet,
  onDeleteSpreadsheet,
  onAddUser,
  onEditUser,
  onDeleteUser,
}) => {
  const guard = useGuard();
  const { values } = state();

  const [render, setRender] = React.useState(0);

  const standardRole = profile?.userType === ADMIN_STANDARD_ROLE;

  const isSelected = (id: string, list: User[]) => {
    const ids = list.map(consultant => consultant.uniqueId);
    return ids.indexOf(id) > -1;
  };

  // Build the client values
  const client = {
    ...selected,
  };

  if (values.name || values.name === '') {
    client.name = values.name;
  }

  if (values.address || values.address === '') {
    client.address = values.address;
  }

  return (
    <Container>
      <Flex.Row align="middle" justify="space-between">
        <ClientContainer>
          <ClientSelect
            selectedClient={selected}
            clients={clients}
            onChange={value => guard.invoke('edit-client', () => onSelect(value))}
          />
        </ClientContainer>
        {!standardRole && (
          <Flex.Item>
            <Delete onDelete={onDelete} />
          </Flex.Item>
        )}
      </Flex.Row>
      <Tabs
        activeKey={activeTab}
        onChange={tab => guard.invoke('change-tab', () => onTabChange(tab))}
      >
        <Tab key="details" tab="Details">
          {loading && <Loader />}
          {!loading && (
            <Details
              name={selected?.name}
              client={client}
              onChange={onChange}
              onSave={onSave}
              onCancel={onCancel}
            />
          )}
        </Tab>
        <Tab key="spreadsheets" tab="Spreadsheets">
          {loading && <Loader />}
          {!loading && (
            <Spreadsheets
              onSelect={onSelectSpreadsheet}
              onAdd={onAddSpreadsheet}
              onEdit={onEditSpreadsheet}
              onDelete={onDeleteSpreadsheet}
            />
          )}
        </Tab>
        <Tab key="data" tab="Data">
          {loading && <Loader />}
          {!loading && (
            <Data
              data={{
                ...data,
                ...values,
              }}
              onSelect={value =>
                guard.invoke('change-spreadsheet', () =>
                  spreadsheetState.setContentSelectedId(value)
                )
              }
              onChange={onChange}
              onSave={onSave}
              onCancel={onCancel}
            />
          )}
        </Tab>
        <Tab key="scenarios" tab="Scenarios" disabled={standardRole}>
          {loading && <Loader />}
          {!loading && (
            <Scenarios
              scenarios={values.scenarios || scenarios}
              onSelect={value =>
                guard.invoke('change-spreadsheet', () =>
                  spreadsheetState.setContentSelectedId(value)
                )
              }
              onChange={onChange}
              onSave={onSave}
              onCancel={onCancel}
            />
          )}
        </Tab>
        <Tab key="consultants" tab="Consultants">
          {loading && <Loader />}
          {!loading && (
            <Consultants
              profile={profile}
              consultants={
                (consultants = consultants.map(consultant => ({
                  ...consultant,
                  selected: isSelected(consultant.uniqueId, values.consultants || []),
                })))
              }
              admin={values.admin}
              deletable={values.added}
              onChange={values => {
                onChange(values);

                // Hack a forced re-render to load the new selections
                setRender(render + 1);
              }}
              onSave={onSave}
              onCancel={onCancel}
            />
          )}
        </Tab>
        <Tab key="users" tab="Users">
          {loading && <Loader />}
          {!loading && (
            <Users
              profile={profile}
              client={selected}
              onAdd={onAddUser}
              onEdit={onEditUser}
              onDelete={onDeleteUser}
            />
          )}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default Content;
