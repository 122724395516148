import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import MultiChartSvg from '../../../assets/icon-multi-chart.svg';
import theme from '../../../theme';

import Icon from './icon';

const Multi = styled(props => (
  <Icon {...props}>
    <MultiChartSvg />
  </Icon>
))`
  #bar-and-line-2,
  #bar-and-line-3 {
    stroke: ${theme.color.steel} !important;
  }

  #bar-and-line-4,
  #bar-and-line-5,
  #bar-and-line-6,
  #bar-and-line-7,
  #bar-and-line-8,
  #bar-and-line-9,
  #bar-and-line-10 {
    fill: ${theme.color.steel} !important;
  }

  ${({ selected, enabled }) =>
    enabled &&
    !selected &&
    css`
      &:hover {
        #bar-and-line-2 {
          stroke: ${theme.color.cranberry} !important;
        }

        #bar-and-line-3 {
          stroke: ${theme.color.sky} !important;
        }

        #bar-and-line-4,
        #bar-and-line-6,
        #bar-and-line-8 {
          fill: ${theme.color.gold} !important;
        }

        #bar-and-line-5,
        #bar-and-line-7,
        #bar-and-line-9 {
          fill: ${theme.color.spring} !important;
        }

        #bar-and-line-10 {
          fill: ${theme.color.slate} !important;
        }
      }
    `}

  ${({ selected, enabled }) =>
    enabled &&
    selected &&
    css`
      #bar-and-line-2 {
        stroke: ${theme.color.cranberry} !important;
      }

      #bar-and-line-3 {
        stroke: ${theme.color.sky} !important;
      }

      #bar-and-line-4,
      #bar-and-line-6,
      #bar-and-line-8 {
        fill: ${theme.color.gold} !important;
      }

      #bar-and-line-5,
      #bar-and-line-7,
      #bar-and-line-9 {
        fill: ${theme.color.spring} !important;
      }

      #bar-and-line-10 {
        fill: ${theme.color.black} !important;
      }
    `}
`;

export default Multi;
