import { EntityStore, StoreConfig } from '@datorama/akita';

import { Client } from '../../models';
import helperUtils from '../../utils/helper';
import storageUtils from '../../utils/storage';

import { ClientData, ClientEntity, ClientState } from './models';

@StoreConfig({
  name: 'App > Clients',
  idKey: 'uniqueId',
})
export class ClientStore extends EntityStore<ClientState, ClientEntity> {
  private readonly CLIENT_ID_KEY = '_app_client_id';

  constructor() {
    super({
      loaded: false,
      selectedId: null,
      email: null,
    });
  }

  initialize(clients: ClientData[]): void {
    const entities = clients.map(client => ({
      ...client,
      selected: false,
    }));

    this.setClients(entities);
  }

  setSelectedId(uniqueId: string): void {
    this.update(state => {
      const clients = state.ids.map(current => state.entities[current]);
      const selected = helperUtils.ensureSelection(clients, client => client.uniqueId === uniqueId);
      const selectedId = selected ? selected.uniqueId : null;

      storageUtils.set(this.CLIENT_ID_KEY, selectedId);

      return {
        selectedId,
      };
    });
  }

  setClients(clients: Client[]): void {
    this.update({
      loaded: false,
    });

    // Readd the clients and ensure selected is removed - its handled by another property
    const entities = clients.map(({ selected, ...props }) => ({
      ...props,
    }));

    this.remove(() => true);
    this.upsertMany(entities);

    // Resolve the initial client id
    const selectedId = storageUtils.get(this.CLIENT_ID_KEY);
    this.setSelectedId(selectedId);

    this.update({
      loaded: true,
    });
  }
}

export const store = new ClientStore();
