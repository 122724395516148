import { Icon, Spin as SpinBase } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import Flex from '../common/flex';

interface BannerProps {
  color: string;
  background: string;
  text: string;
}

const Spin = styled(props => <SpinBase {...props} />)`
  margin-right: 0.5rem;
  width: 2.5rem;

  svg {
    font-size: 1.5rem;

    ${({ color }) =>
      color &&
      css`
        color: ${color};
      `}
  }
`;

const Container = styled(props => <Flex.Row {...props} />)`
  padding: 0.5rem;
  height: 100%;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ background }) =>
    background &&
    css`
      background-color: ${background};
    `}
`;

const Banner: React.FC<BannerProps> = ({ color, background, text }) => {
  const loader = <Icon type="loading" spin />;
  return (
    <Container color={color} background={background} align="middle" justify="center">
      <Flex.Item>
        <Spin color={color} indicator={loader} />
      </Flex.Item>
      <Flex.Item>{text}</Flex.Item>
    </Container>
  );
};

export default Banner;
