import { Tag as TagBase } from 'antd';
import React from 'react';
import styled from 'styled-components';

import theme from '../../../theme';

const Tag = styled(TagBase)`
  color: ${theme.color.millimanBlue};
  border-color: ${theme.color.millimanBlue};
  line-height: 1;
  padding-bottom: 1px;
`;

const Badge: React.FC = () => <Tag>Milliman</Tag>;

export default Badge;
