import classnames from 'classnames';
import { lighten } from 'polished';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Flex from '../../common/flex';
import { HelpIcon, InfoCenterIcon, NewClientIcon, ProjectionToolIcon } from '../../common/icons';
import { routes } from '../../constants';
import Router from '../../router';
import * as clientState from '../../state/clients';
import * as profileState from '../../state/profile';
import theme from '../../theme';
import userUtils from '../../utils/user';
import Layout from '../layout';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 1rem 0;
  height: 100%;
  width: 10rem;

  ${breakpoint('sm')`
    flex-direction: row;
    margin: 0;
    padding-top: 0;
    width: auto;
  `}
`;

const Scroll = styled(({ className, children }) => (
  <div className={classnames(className, 'o-height-100', 'o-page-background')}>{children}</div>
))`
  overflow-y: auto;
`;

const Tile = styled.div`
  cursor: pointer;
  border-radius: 0.75rem;
  margin: 1rem;
  height: 10rem;
  width: 10rem;

  ${breakpoint('md')`
    margin: 0 1.5rem;
    height: 12rem;
    width: 12rem;
  `}

  ${breakpoint('lg')`
    margin: 0 3rem;
    height: 14rem;
    width: 14rem;
  `}
`;

const Content = styled(({ className, icon, text, path }) => {
  const Icon = icon;
  return (
    <Router.Navigate>
      {navigate => (
        <div className={className} onClick={() => navigate(path)}>
          <Flex.Col className="o-height-100" align="middle" justify="center">
            <Flex.Item>
              <Icon color={theme.color.white} height="5rem" width="5rem" />
            </Flex.Item>
            <Flex.Item>{text}</Flex.Item>
          </Flex.Col>
        </div>
      )}
    </Router.Navigate>
  );
})`
  ${({ color }) => css`
    color: ${theme.color.white};
    background: linear-gradient(to bottom, ${color} 0%, ${lighten(0.35, color)} 100%);
    border-radius: 0.75rem;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.3), inset 0px 4px 1px 1px rgba(255, 255, 255, 0.25),
      inset 0px -3px 1px 1px rgba(0, 0, 0, 0.25);
    text-align: center;
    height: 100%;
    width: 100%;

    svg {
      margin-bottom: 0.5rem;
    }

    &:hover {
      background: linear-gradient(to bottom, ${color} 0%, ${lighten(0.25, color)} 100%);
    }

    ${breakpoint('lg')`
      svg {
        margin-bottom: 1rem;
        height: 6rem;
        width: 6rem;
      }
    `}
  `}
`;

const Home: React.FC = () => {
  const clients = useObservable(() => clientState.clients$, []);
  const profile = useObservable(() => profileState.profile$, null);
  const admin = userUtils.isAdmin(profile?.userType);

  return (
    <Layout title="Online Projection Tool & Information Center" showNavbar={false}>
      <Scroll>
        <Container>
          {admin && clients.length === 0 && (
            <>
              <div>
                <h2>You currently have no clients assigned to you.</h2>
                <h2>Use the New Client Wizard to create one.</h2>
              </div>
              <Tile>
                <Content
                  color={theme.color.millimanBlue}
                  icon={NewClientIcon}
                  text="New Client Wizard"
                  path={routes.admin.newClient}
                ></Content>
              </Tile>
            </>
          )}
          {clients.length > 0 && (
            <>
              <Tile>
                <Content
                  color={theme.color.vibrantBlue}
                  icon={ProjectionToolIcon}
                  text="Projection Tool"
                  path={routes.app.projectionTool}
                ></Content>
              </Tile>
              <Tile>
                <Content
                  color={theme.color.cranberry}
                  icon={InfoCenterIcon}
                  text="Information Center"
                  path={routes.app.informationCenter}
                ></Content>
              </Tile>
              <Tile>
                <Content
                  color={theme.color.gold}
                  icon={HelpIcon}
                  text="Help"
                  path={routes.app.help}
                ></Content>
              </Tile>
            </>
          )}
        </Container>
      </Scroll>
    </Layout>
  );
};

export default Home;
