import React from 'react';
import { useObservable } from 'rxjs-hooks';

import Button from '../../common/button';
import Flex from '../../common/flex';
import Form from '../../common/form';
import { Client, User } from '../../models';
import FormLayout from '../common/form-layout';
import UserForm from '../common/user-form';

import { query } from './state/query';

interface FormProps {
  consultant: User;
  clients: Client[];
  onCreate: () => void;
}

const CreateButton: React.FC = () => {
  const saveable = useObservable(() => query.newSaveable$, false);
  return (
    <Button htmlType="submit" type="blue" disabled={!saveable}>
      Create Consultant
    </Button>
  );
};

const Error: React.FC = () => {
  const message = useObservable(() => query.newError$, null);
  return message ? <FormLayout.Error message={message} /> : null;
};

class NewForm extends Form<FormProps, {}> {
  render(): React.ReactNode {
    const { consultant, clients, form } = this.props;
    return (
      <FormLayout.Form onSubmit={e => this.handleSubmit(e)}>
        <FormLayout.Heading>Consultant Details</FormLayout.Heading>
        <UserForm type="admin" currentUser={consultant} clients={clients} form={form} />
        <Error />
        <FormLayout.Buttons>
          <Flex.Item>
            <CreateButton />
          </Flex.Item>
        </FormLayout.Buttons>
      </FormLayout.Form>
    );
  }

  private handleSubmit(e: React.FormEvent<HTMLElement>): void {
    e.preventDefault();

    // Trigger the onCreate handler
    const { form, onCreate } = this.props;
    form.validateFields(err => {
      if (!err && onCreate) {
        onCreate();
      }
    });
  }
}

export default NewForm;
