import { ModelData } from './models';
import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const modelId$ = query.modelId$;
export const panelId$ = query.panelId$;
export const split$ = query.split$;
export const models$ = query.models$;
export const reload$ = query.reload$;

export const initialize = (models: ModelData[]) => store.initialize(models);
export const setModelId = (id: string) => store.setModelId(id);
export const setPanelId = (id: string) => store.setPanelId(id);
export const setSplit = (size: number) => store.setSplit(size);
export const setReload = () => store.setReload();
