import { Checkbox as CheckboxBase, Icon, Input as InputBase, Popconfirm } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import Button from '../../../common/button';
import Flex from '../../../common/flex';
import { Api } from '../../../http/api';
import * as bannerState from '../../../state/banner';
import theme from '../../../theme';
import * as inputState from '../inputs/state';

import Badge from './badge';
import { Scenario, ScenarioData } from './state/models';
import { store } from './state/store';

interface UpdateState {
  [id: string]: Scenario & {
    rename?: boolean;
    newName?: string;
    newEnabled?: boolean;
  };
}

interface RemoveState {
  scenario?: Scenario;
  submitting?: boolean;
  message?: string;
}

interface ListProps {
  modelId: string;
  scenarios: Scenario[];
  onCreate: () => void;
}

const Container = styled(Flex.Col)`
  height: 100%;
`;

const Header = styled(Flex.Item)`
  background-color: ${theme.color.panelGrey};
  padding: 1rem;
`;

const Table = styled(Flex.Item)`
  position: relative;
  padding: 1rem 0 1rem 1rem;
`;

const Heading = styled(Flex.Item)`
  font-weight: ${theme.weight.medium};
  margin-bottom: 0.5rem;
  padding: 0 19px 0 28px;
`;

const Scroll = styled.div`
  position: absolute;
  top: 2.25rem;
  right: 0;
  bottom: 0;
  left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
`;

const Column = styled(Flex.Item)`
  margin-bottom: 0.25rem;
`;

const Name = styled(Column)`
  margin-right: 0.5rem;
  position: relative;
  overflow: hidden;
  height: 23px;
`;

const Checkbox = styled(({ className, onChange, ...props }) => (
  <div className={className}>
    <CheckboxBase onChange={ev => onChange(ev.target.checked)} {...props} />
  </div>
))`
  padding-top: 2px;
  width: 1.5rem;
`;

const Label = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 5px 0 4px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Input = styled(InputBase)`
  padding: 0 3px;
  height: 23px;
`;

const Action = styled(({ disabled, ...props }) => <Icon {...props} />)`
  margin: 0 0.25rem;

  ${({ type }) =>
    type === 'edit' &&
    css`
      color: ${theme.color.millimanBlue};
    `}};

  ${({ type }) =>
    type === 'delete' &&
    css`
      color: ${theme.color.cranberry};
    `}};

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${theme.color.steel};
    `}};
`;

const Buttons = styled(Flex.Item)`
  padding: 0.5rem 1rem 1rem 1rem;

  button {
    margin-right: 0.25rem;
  }
`;

const DeleteIcon = styled(Icon)`
  color: ${theme.color.cranberry} !important;
`;

const isEnabled = (scenario: Scenario, updates: UpdateState) => {
  const match = updates[scenario.id];
  return match && match.newEnabled;
};

const isChanged = (scenario: Scenario, updates: UpdateState) => {
  const match = updates[scenario.id];
  return match && (match.name !== match.newName || match.enabled !== match.newEnabled);
};

const canEnable = (scenarios: Scenario[], updates: UpdateState) => {
  const enabled = scenarios.filter(({ id }) => {
    const match = updates[id];
    return match && match.newEnabled;
  });

  return enabled.length < 4;
};

const canConfirm = (scenarios: Scenario[], updates: UpdateState) => {
  const changed = scenarios.filter(scenario => isChanged(scenario, updates));
  const invalids = scenarios.filter(scenario => {
    const match = updates[scenario.id];
    if (match) {
      return match.newName.trim() === '';
    }

    return false;
  });

  return changed.length > 0 && invalids.length === 0;
};

const renderName = (scenario: Scenario, updates: UpdateState, onChange: (name: string) => void) => {
  const match = updates[scenario.id];

  if (match && match.rename) {
    return <Input value={match.newName} onChange={ev => onChange(ev.target.value)} />;
  }

  return match && <Label>{match.newName}</Label>;
};

const updateScenarios = async (modelId: string, scenarios: Scenario[], updates: UpdateState) => {
  const changes: ScenarioData[] = [];
  for (const scenario of scenarios) {
    const match = updates[scenario.id];

    // Create a server call for only those with changes
    if (isChanged(scenario, updates)) {
      try {
        await Api.channel.post(`/api/projection/${modelId}/scenario/${scenario.id}`, {
          name: match.newName,
          enabled: match.newEnabled,
        });
      } catch {
        // What do we do if we reach here???
      }
    }

    // Copy the updates to the scenario entity
    // TODO: update graph store if scenario is disabled
    if (match) {
      changes.push({
        uniqueId: match.id,
        name: match.newName,
        enabled: match.newEnabled,
        milliman: match.milliman,
        canEdit: match.canEdit,
        canDelete: match.canEdit,
        modelId: match.modelId,
        dirty: true,
        inputs: [],
        outputs: [],
      });
    }
  }

  // TODO: update graph store if scenario is disabled
  store.updateScenarios(changes);

  // Check the input selection is still value
  const scenarioIds = changes.filter(({ enabled }) => enabled).map(({ uniqueId }) => uniqueId);

  inputState.ensureSelectionForScenarios(scenarioIds);
};

const removeScenario = async (modelId: string, scenario: Scenario) => {
  try {
    // TODO: tidy up other stores to remove scenario references
    await Api.channel.delete(`/api/projection/${modelId}/scenario/${scenario.id}`);
    store.removeScenario(scenario.id);
    return true;
  } catch {
    return false;
  }
};

const List: React.FC<ListProps> = ({ modelId, scenarios, onCreate }) => {
  const defaultUpdates: UpdateState = {};
  const defaultRemove: RemoveState = { submitting: false };
  const [updates, setUpdates] = React.useState({ ...defaultUpdates });
  const [remove, setRemove] = React.useState({ ...defaultRemove });
  const [confirm, setConfirm] = React.useState({ confirming: false });

  React.useEffect(() => {
    const map = scenarios.reduce((output: any, scenario) => {
      output[scenario.id] = {
        ...scenario,
        rename: false,
        newName: scenario.name,
        newEnabled: scenario.enabled,
      };
      return output;
    }, {});

    setUpdates(map);
  }, [scenarios]);

  return (
    <>
      <Container>
        <Header>
          Use the check boxes to select the scenario(s) you wish to view (max 4). Select the New
          button to create a scenario.
        </Header>
        <Table fill={true}>
          <Flex.Row justify="space-between">
            <Heading>Name</Heading>
            <Heading>Actions</Heading>
          </Flex.Row>
          <Scroll>
            {scenarios.map(scenario => (
              <Flex.Row key={scenario.id} align="middle">
                <Column>
                  <Checkbox
                    checked={isEnabled(scenario, updates)}
                    onChange={(checked: boolean) => {
                      if (!checked || canEnable(scenarios, updates)) {
                        setUpdates({
                          ...updates,
                          [scenario.id]: {
                            ...updates[scenario.id],
                            newEnabled: checked,
                          },
                        });
                      }
                    }}
                  />
                </Column>
                <Name fill={true}>
                  {renderName(scenario, updates, name => {
                    setUpdates({
                      ...updates,
                      [scenario.id]: {
                        ...updates[scenario.id],
                        newName: name,
                      },
                    });
                  })}
                </Name>
                <Column>{scenario.milliman && <Badge />}</Column>
                <Column>
                  <Action
                    type="edit"
                    disabled={!scenario.canEdit}
                    onClick={() => {
                      if (!scenario.canEdit) {
                        return;
                      }
                      const update = updates[scenario.id];
                      if (update.rename) {
                        update.rename = false;
                        update.newName = update.name;
                      } else {
                        update.rename = true;
                      }
                      setUpdates({
                        ...updates,
                        [scenario.id]: { ...update },
                      });
                    }}
                  />
                  <Popconfirm
                    title="Are you sure you want to delete this scenario?"
                    placement="left"
                    icon={<DeleteIcon type="question-circle" theme="outlined" />}
                    visible={!!remove.scenario && remove.scenario.id === scenario.id}
                    overlayClassName="o-popover-confirm"
                    okType="danger"
                    okText="Delete"
                    cancelText="Cancel"
                    okButtonProps={{ loading: remove.submitting }}
                    cancelButtonProps={{ disabled: remove.submitting }}
                    onCancel={() => setRemove({ ...defaultRemove })}
                    onConfirm={async () => {
                      bannerState.setDeletingBanner();
                      setRemove({ ...remove, submitting: true });
                      if (await removeScenario(modelId, remove.scenario)) {
                        setRemove({ ...defaultRemove });
                      } else {
                        setRemove({
                          ...remove,
                          submitting: false,
                          message: 'Unable to delete scenario',
                        });
                      }
                      bannerState.clearBanner();
                    }}
                  >
                    <Action
                      type="delete"
                      disabled={!scenario.canDelete}
                      onClick={() => {
                        if (!scenario.canEdit) {
                          return;
                        }
                        setRemove({ ...remove, scenario });
                      }}
                    />
                  </Popconfirm>
                </Column>
              </Flex.Row>
            ))}
          </Scroll>
        </Table>
        <Buttons>
          <Button
            type="dark-blue"
            loading={confirm.confirming}
            disabled={!canConfirm(scenarios, updates)}
            onClick={async () => {
              bannerState.setApplyingBanner();
              setConfirm({ confirming: true });
              await updateScenarios(modelId, scenarios, updates);
              setConfirm({ confirming: false });
              bannerState.clearBanner();
            }}
          >
            Confirm
          </Button>
          <Button type="green" onClick={onCreate} disabled={scenarios.length === 0}>
            New
          </Button>
        </Buttons>
      </Container>
    </>
  );
};

export default List;
