import { Icon, Input, Radio as RadioBase } from 'antd';
import { ColProps } from 'antd/lib/col';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import SearchSelectBase from '../../common/search-select';
import { Client, User } from '../../models';
import theme from '../../theme';
import FormLayout from '../common/form-layout';

interface UserFormProps {
  type: 'user' | 'admin';
  form: WrappedFormUtils<any>;
  currentUser: User;
  clients: Client[];
  passwordRequired?: boolean;
  disabled?: boolean;
  layout?: {
    labelCol?: ColProps;
    wrapperCol?: ColProps;
  };
}

const SearchSelect = styled(SearchSelectBase)`
  margin-top: 5px;
`;

const Rule = styled.div`
  font-size: ${theme.size.small};
  line-height: 1rem;
  margin-left: 0.25rem;
`;

const RadioField = styled(FormLayout.Field)`
  .ant-form-item-label {
    line-height: 1.5rem;
  }
`;

const RadioGroup = styled(RadioBase.Group)`
  margin-top: 0.25rem;

  ${breakpoint('md')`
    margin-top: 1rem;
  `}
`;

const Radio = styled(RadioBase)`
  display: block;
  margin-bottom: 0.25rem;
  height: 2rem;
`;

const Description = styled.div`
  font-size: ${theme.size.small};
  padding-left: 24px;
`;

/**
 * some garbage code to generate a password
 */
const generatePassword = (length: number) => {
  const chars = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZ', // letters
    'abcdefghijklmnopqrstuvwxyz',
    '0123456789', // numbers
    '!@#$%^&*()', // special chars
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()', // all
  ];

  return [1, 1, 1, 1, length - 4]
    .map((len, i) =>
      Array(len)
        .fill(chars[i])
        .map(x => x[Math.floor(Math.random() * x.length)])
        .join('')
    )
    .concat()
    .join('')
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');
};

const UserForm: React.FC<UserFormProps> = ({
  currentUser,
  clients,
  form,
  type,
  layout,
  disabled,
  passwordRequired,
}) => (
  <>
    <FormLayout.Field label="Full name:" layout={layout}>
      {form.getFieldDecorator('name', {
        initialValue: currentUser?.fullName,
        rules: [
          {
            required: true,
            message: 'A full name is required',
          },
        ],
      })(<Input disabled={!!disabled} />)}
    </FormLayout.Field>
    <FormLayout.Field label="Email address:" layout={layout}>
      {form.getFieldDecorator('email', {
        initialValue: currentUser?.email,
        rules: [
          {
            required: true,
            type: 'email',
            message: 'A valid email address is required',
          },
        ],
      })(<Input disabled={!!disabled} />)}
    </FormLayout.Field>
    <FormLayout.Field label="Password:" layout={layout}>
      {form.getFieldDecorator('password', {
        rules: [
          {
            required: passwordRequired === true,
            message: 'A password is required',
          },
          {
            pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-)(]).{8,}$/,
            message:
              'Passwords must have a minimum length of 8 characters including upper and lower case letters, plus a number and symbol',
          },
        ],
      })(
        <Input.Password
          disabled={!!disabled}
          autoComplete="new-password"
          addonAfter={
            <Icon
              type="sync"
              title="Generate Random Password"
              onClick={() => {
                form.setFieldsValue({ password: generatePassword(8) });
              }}
            />
          }
        />
      )}
      <Rule>Min 8 mixed case letters, plus a number and symbol.</Rule>
    </FormLayout.Field>
    <FormLayout.Field label={type === 'admin' ? 'Clients:' : 'Select boards:'} layout={layout}>
      {form.getFieldDecorator('clientIds', {
        initialValue: currentUser?.clientIds ?? [],
      })(
        <SearchSelect
          options={clients.map(c => ({ label: c.name, value: c.uniqueId }))}
          disabled={!!disabled}
        />
      )}
    </FormLayout.Field>
    {type === 'admin' && (
      <RadioField label="Privilege:" layout={layout}>
        {form.getFieldDecorator('userType', {
          initialValue: currentUser?.userType,
          rules: [
            {
              required: true,
              message: 'A privilege is required',
            },
          ],
        })(
          <RadioGroup>
            <Radio value={3} disabled={!!disabled}>
              <span>
                Administrator
                <Description>Allow creation/deletion of Milliman consultants</Description>
              </span>
            </Radio>
            <Radio value={2} disabled={!!disabled}>
              <span>
                Publisher
                <Description>Can share approved scenarios with client users</Description>
              </span>
            </Radio>
            <Radio value={1} disabled={!!disabled}>
              Standard
            </Radio>
          </RadioGroup>
        )}
      </RadioField>
    )}
  </>
);

export default UserForm;
