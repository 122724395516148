import React from 'react';
import { useObservable } from 'rxjs-hooks';

import LayoutBase from '../layout';
import { GuardFunction } from '../router';
import * as profileState from '../state/profile';

import Navbar from './navbar';

interface LayoutProps {
  guard?: GuardFunction;
}

const Layout: React.FC<LayoutProps> = ({ guard, children, ...props }) => {
  const profile = useObservable(() => profileState.profile$, null);
  return (
    <LayoutBase {...props} profile={profile} navbar={<Navbar />} guard={guard}>
      {children}
    </LayoutBase>
  );
};

export default Layout;
