import { Icon } from 'antd';
import React from 'react';
import styled from 'styled-components';

import ButtonBase from '../../common/button';

interface PresentButtonProps {
  open: boolean;
  onClick: () => void;
}

const Button = styled(ButtonBase)`
  font-size: 1.2rem;
  margin: 0 0.1rem;
  padding: 0 0.25rem;
`;

const PresentButton: React.FC<PresentButtonProps> = ({ open, onClick }) => (
  <Button type="light-blue" onClick={onClick}>
    <Icon type={open ? 'fullscreen-exit' : 'fullscreen'} />
  </Button>
);

export default PresentButton;
