import React from 'react';
import styled from 'styled-components';
import { css, FlattenSimpleInterpolation } from 'styled-components';

export type IconStyle = (color: string) => FlattenSimpleInterpolation;

export interface IconProps {
  className?: string;
  color?: string;
  colorHover?: string;
  height: string;
  width: string;
}

const defaultStyle: IconStyle = (color: string) => css`
  svg {
    path {
      fill: ${color} !important;
    }
  }
`;

const SvgIcon = styled(({ className, svg }) => {
  const Svg = svg;
  return (
    <div className={className}>
      <Svg />
    </div>
  );
})`
  display: inline;

  svg {
    ${({ height }) =>
      height &&
      css`
        height: ${height};
      `}

    ${({ width }) =>
      width &&
      css`
        width: ${width};
      `}
  }

  ${({ color, style }) => color && style(color)}

  ${({ colorHover, style }) =>
    colorHover &&
    css`
      &:hover {
        ${style(colorHover)}
      }
    `}
`;

class Icon extends React.Component<IconProps> {
  static style = defaultStyle;

  constructor(
    // tslint:disable-next-line: no-parameter-properties
    private readonly svg: React.ReactNode,
    props: IconProps,
    // tslint:disable-next-line: no-parameter-properties
    private readonly style?: IconStyle
  ) {
    super(props);
  }

  render(): React.ReactNode {
    return <SvgIcon svg={this.svg} style={this.style || defaultStyle} {...this.props} />;
  }
}

export default Icon;
