import React from 'react';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';

import LayoutBase from '../layout';
import { GuardFunction } from '../router';
import * as clientState from '../state/clients';
import * as profileState from '../state/profile';

import Navbar from './navbar';

interface LayoutProps {
  title?: string;
  showNavbar?: boolean;
  print?: React.ReactNode;
  guard?: GuardFunction;
}

const Layout: React.FC<LayoutProps> = ({ title, showNavbar, print, guard, children }) => {
  const profile = useObservable(() => profileState.profile$, null);
  const client = useObservable(
    () => clientState.clients$.pipe(map(clients => clients.find(({ selected }) => selected))),
    null
  );

  return (
    <LayoutBase
      profile={profile}
      client={client}
      title={title}
      navbar={showNavbar !== false && <Navbar />}
      print={print}
      guard={guard}
    >
      {children}
    </LayoutBase>
  );
};

export default Layout;
