import React from 'react';
import { useObservable } from 'rxjs-hooks';

import FormBase from '../../../common/form';
import { Client } from '../../../models';
import ClientForm from '../../common/client-form';
import FormLayout from '../../common/form-layout';
import Buttons from '../buttons';

import * as detailsState from './state';

interface FormProps {
  name: string;
  client: Client;
  onSave: () => void;
  onCancel: () => void;
}

interface DetailsProps extends FormProps {
  onChange: (values: any) => void;
}

const Error: React.FC = () => {
  const message = useObservable(() => detailsState.error$, null);
  return message ? <FormLayout.Error message={message} /> : null;
};

class Form extends FormBase<FormProps, {}> {
  render(): React.ReactNode {
    const { name, client, form, onCancel } = this.props;
    return (
      <FormLayout.Form onSubmit={e => this.handleSubmit(e)}>
        <FormLayout.Heading>Client Details</FormLayout.Heading>
        <ClientForm name={name} client={client} form={form} />
        <Error />
        <Buttons savableObservable={detailsState.saveable$} onCancel={onCancel} />
      </FormLayout.Form>
    );
  }

  private handleSubmit(e: React.FormEvent<HTMLElement>): void {
    e.preventDefault();

    // Trigger the onUpdate handler
    const { form, onSave } = this.props;
    form.validateFields(err => {
      if (!err && onSave) {
        onSave();
      }
    });
  }
}

const Details: React.FC<DetailsProps> = ({ onChange, ...props }) => (
  <>{FormBase.renderType(Form, props, onChange)}</>
);

export default Details;
