import { ProfileData } from './models';
import { query } from './query';
import { store } from './store';

export { ProfileData };

// Expose an API for others to consume
export const profile$ = query.profile$;

export const initialize = (data: ProfileData) => store.initialize(data);

export const updateProfile = (fullName: string, email: string) =>
  store.update({
    fullName,
    email,
  });
