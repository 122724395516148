import { Collapse as CollapseBase } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Content from '../../common/content';
import Flex from '../../common/flex';
import { GraphTypeIcon, InputTableIcon, ManageSenariosIcon } from '../../common/icons';
import theme from '../../theme';

import ButtonsBase from './buttons';
import * as commonState from './common/state';
import GraphTypes from './graph-types';
import Inputs from './inputs';
import * as inputState from './inputs/state';
import Scenarios from './scenarios';
import * as scenarioState from './scenarios/state';

const icons: any = {
  inputs: InputTableIcon,
  scenarios: ManageSenariosIcon,
  'graph-types': GraphTypeIcon,
};

interface ControlPanelProps {
  loading: boolean;
  canApply: boolean;
  canSave: boolean;
  onApply: (
    scenarios: scenarioState.Scenario[],
    inputs: inputState.Input[],
    callback: () => void
  ) => void;
  onSave: (
    scenarios: scenarioState.Scenario[],
    inputs: inputState.Input[],
    callback: () => void
  ) => void;
  onUndoAll: (callback: () => void) => void;
  onPresent: () => void;
  onPrint: () => void;
}

const Container = styled(Flex.Col)`
  padding: 0;
  height: 100%;

  ${breakpoint('lg')`
    padding: 2rem 2rem 1rem 2rem;
  `}
`;

const Heading = styled(Content.SubHeading)`
  display: none;
  margin-bottom: 1rem;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const Collapse = styled(CollapseBase)`
  border: 0;
  background-color: transparent;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Panel = styled(Collapse.Panel)`
  background-color: ${theme.color.white};
  border-bottom: 0 !important;
  border-radius: 0 !important;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.25rem;

  // Change the styling added by ANTD
  .ant-collapse-header {
    color: ${theme.color.millimanBlue} !important;
    background-color: ${theme.color.white};
    border-radius: 0 !important;
    display: none;
    line-height: 1rem;
  }

  .ant-collapse-content {
    border: 0;
    flex: 1 1 auto;
    position: relative;

    .ant-collapse-content-box {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
    }
  }

  &.ant-collapse-item-active {
    flex: 1 1 auto;

    .ant-collapse-header {
      color: ${theme.color.white} !important;
      background-color: ${theme.color.millimanBlue};
    }
  }

  ${breakpoint('lg')`
    border: 1px solid ${theme.color.steel} !important;

    .ant-collapse-header {
      display: block;
    }
  `}
`;

const Buttons = styled(ButtonsBase)`
  display: none;
  margin-top: 0.5rem;

  ${breakpoint('lg')`
    display: flex;
  `}
`;

const ControlPanel: React.FC<ControlPanelProps> = ({ loading, ...props }) => {
  const keys = ['inputs', 'scenarios', 'graph-types'];

  // Because of the responsive implementation we need to resolve a valid key
  let panelId = useObservable(() => commonState.panelId$, null);
  panelId = keys.indexOf(panelId) === -1 ? 'inputs' : panelId;

  return (
    <Container>
      <Heading>Control Panel</Heading>
      <Collapse
        accordion={true}
        activeKey={panelId}
        expandIcon={(context: any) => {
          const IconComponent = icons[context.panelKey];
          const color = context.panelKey === panelId ? theme.color.white : theme.color.millimanBlue;

          return <IconComponent color={color} height="1.2rem" width="1.2rem" />;
        }}
        onChange={(id: string) => commonState.setPanelId(id)}
      >
        <Panel key="inputs" header="Input Table">
          <Inputs showEmpty={!loading} />
        </Panel>
        <Panel key="scenarios" header="Manage Scenarios">
          <Scenarios loading={loading} />
        </Panel>
        <Panel key="graph-types" header="Graph Type">
          <GraphTypes loading={loading} />
        </Panel>
      </Collapse>
      <Buttons canPresent={true} {...props} />
    </Container>
  );
};

export default ControlPanel;
