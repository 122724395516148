import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

const Content = styled.div`
  padding: 2rem;
`;

export default class extends React.Component<React.HTMLAttributes<HTMLElement>> {
  static Heading = styled.div`
    color: ${theme.color.millimanBlue};
    font-size: ${theme.size.heading};
    font-weight: ${theme.weight.medium};
    margin-bottom: 0.5rem;
  `;

  static SubHeading = styled.div`
    color: ${theme.color.millimanBlue};
    font-size: ${theme.size.subHeading};
    font-weight: ${theme.weight.medium};
    margin-bottom: 0.5rem;
  `;

  render(): React.ReactNode {
    const { children } = this.props;
    return <Content {...this.props}>{children}</Content>;
  }
}
