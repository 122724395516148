import * as graphTypeState from '../graph-types/state';
import * as graphState from '../graph/state';
import * as outputState from '../outputs/state';

import formatUtils from './format';

const getData = (
  outputType: outputState.OutputType,
  years: number[],
  data: graphState.GraphData[]
) => ({
  labels: years,
  datasets: data.map(series => ({
    datasetId: series.id,
    yAxisID: outputType.formatType,
    backgroundColor: series.color,
    borderColor: series.color,
    borderWidth: 3,
    pointRadius: 1,
    fill: false,
    type: series.type === 'stacked' ? 'bar' : series.type,
    stack: series.type === 'stacked' ? outputType.name : series.id,
    label: series.name,
    data: series.values.map(({ value }) => value),
    colors: series.values.map(({ color }) => color),
  })),
});

const getOptions = (outputType: outputState.OutputType, graphType: graphTypeState.GraphTypeId) => {
  // Determine the y axis label
  let yLabel: string;
  if (outputType) {
    switch (outputType.formatType) {
      case 'percent':
        yLabel = 'Percent (%)';
        break;
      case 'dollar':
        yLabel = 'Dollars ($)';
        break;
      default:
        yLabel = null;
        break;
    }
  }

  return {
    responsive: true,
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          id: 'year',
          offset: graphType !== 'line',
          gridLines: {
            tickMarkLength: 0,
            zeroLineWidth: 1,
            lineWidth: 0,
          },
          ticks: {
            padding: 12,
          },
        },
      ],
      yAxes: [
        {
          id: outputType.formatType,
          gridLines: {
            tickMarkLength: 0,
            zeroLineWidth: 1,
            lineWidth: 0,
          },
          scaleLabel: {
            display: !!yLabel,
            labelString: yLabel,
          },
          ticks: {
            padding: 12,
            suggestedMin: 0,
            callback: (value: string | number) =>
              formatUtils.format(
                outputType.formatType,
                value,
                outputType.div,
                outputType.decimalPlaces,
                false
              ),
          },
        },
      ],
    },
  };
};

export default { getData, getOptions };
