import { GraphData } from './models';
import { query } from './query';
import { store } from './store';

export { GraphData };

// Expose an API for others to consume
export const ref$ = query.ref$;
export const years$ = query.years$;
export const data$ = query.data$;

export const initialize = (useBrowserStore = true) => store.initialize(useBrowserStore);
export const refreshChart = () => store.refreshChart();
