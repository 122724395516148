import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import Flex from '../../../common/flex';
import theme from '../../../theme';

const Icon = styled(({ className, label, children }) => (
  <div className={className}>
    <Flex.Col align="middle">
      <Flex.Item>{children}</Flex.Item>
      <Flex.Item>{label}</Flex.Item>
    </Flex.Col>
  </div>
))`
  color: ${theme.color.steel};
  text-align: center;

  svg {
    margin-bottom: 0.5rem;
    height: 5rem;
  }

  ${({ enabled }) =>
    enabled &&
    css`
      &:hover {
        color: ${theme.color.slate};
      }
    `}

  ${({ selected, enabled }) =>
    enabled &&
    selected &&
    css`
      color: ${theme.color.black} !important;
    `}
`;

export default Icon;
