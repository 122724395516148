import React from 'react';

import { routes } from '../constants';
import Router from '../router';

import Help from './help';
import Home from './home';
import InformationCenter from './information-center';
import ProjectionTool from './projection-tool';

const App: React.FC = () => (
  <Router.Child>
    <Router.Page path={routes.app.home} component={Home} />
    <Router.Page path={routes.app.projectionTool} component={ProjectionTool} />
    <Router.Page path={routes.app.informationCenter} component={InformationCenter} />
    <Router.Page path={routes.app.help} component={Help} />
  </Router.Child>
);

export default App;
