import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { AdminMgtIcon, ClientMgtIcon, InfoCenterMgtIcon, NewClientIcon } from '../common/icons';
import { ADMIN_ROLE, routes } from '../constants';
import Layout from '../layout';
import * as clientState from '../state/clients';
import * as profileState from '../state/profile';
import theme from '../theme';

const Navbar: React.FC = () => {
  const profile = useObservable(() => profileState.profile$, null);
  const clients = useObservable(() => clientState.clients$, []);

  return (
    <Layout.Navbar>
      <Layout.Navbar.Item
        icon={ClientMgtIcon}
        color={theme.color.millimanBlue}
        label="Client Management"
        route={routes.admin.clientManagement}
        height="3.5rem"
        width="3.5rem"
        iconStyle={color => ClientMgtIcon.style(color)}
        disabled={clients.length <= 0}
      />
      <Layout.Navbar.Item
        icon={InfoCenterMgtIcon}
        color={theme.color.millimanBlue}
        label="Information Center Management"
        route={routes.admin.informationCenter}
        height="3.5rem"
        width="3.5rem"
        iconStyle={color => InfoCenterMgtIcon.style(color)}
        disabled={clients.length <= 0}
      />
      <Layout.Navbar.Item
        icon={NewClientIcon}
        color={theme.color.millimanBlue}
        label="New Client Wizard"
        route={routes.admin.newClient}
        height="3.5rem"
        width="3.5rem"
        iconStyle={color => NewClientIcon.style(color)}
        disabled={false}
      />
      <Layout.Navbar.Item
        icon={AdminMgtIcon}
        color={theme.color.millimanBlue}
        label="Admin Management"
        route={routes.admin.adminManagement}
        height="3.5rem"
        width="3.5rem"
        iconStyle={color => AdminMgtIcon.style(color)}
        disabled={profile?.userType !== ADMIN_ROLE}
      />
    </Layout.Navbar>
  );
};

export default Navbar;
