import numeral from 'numeral';

import helperUtils from '../../../utils/helper';

export type FormatType = 'percent' | 'dollar' | 'number';

const getFormatType = (formatType: number) => {
  switch (formatType) {
    case 1:
      return 'percent';
    case 2:
      return 'dollar';
    default:
      return 'number';
  }
};

const getFormatPattern = (formatType: FormatType, decimalPlaces: number, symbol?: boolean) => {
  const value = 0;
  const decimals = value.toFixed(decimalPlaces);

  // Add the symbol if requested
  let pattern = `0,${decimals}`;
  if (symbol) {
    switch (formatType) {
      case 'percent':
        pattern = `${pattern}%`;
        break;
      case 'dollar':
        pattern = `$${pattern}`;
        break;
    }
  }

  return pattern;
};

const getDollarSuffix = (div: number) => {
  switch (div) {
    case 1000000000:
      return 'b';
    case 1000000:
      return 'm';
    case 1000:
      return 'k';
    default:
      return '';
  }
};

const getDollarFormatPattern = (
  diff: number,
  div: number,
  decimalPlaces: number,
  symbol: boolean
) => {
  // If negative, flip to positive for number comparing
  if (diff < 0) {
    diff = -diff;
  }

  // Adjust the decimal place for certain value ranges
  if (div !== 1) {
    if (diff < 100000000 || diff >= 1000000000) {
      decimalPlaces = 1;
    }
  }

  // Build the final pattern
  return getFormatPattern('dollar', decimalPlaces, symbol);
};

const sanitizedFormat = (value: string, formatter: () => string) =>
  value === 'NaN' ? '' : formatter();

const format = (
  type: FormatType,
  value: number | string,
  div: number,
  decimalPlaces: number,
  symbol: boolean = true
) => {
  // Check the value is valid
  if (!helperUtils.isNumber(value)) {
    return value == null ? null : `${value}`;
  }

  // Apply the divide and format
  const parsed = numeral(value).divide(div);
  switch (type) {
    case 'percent':
      // Ensure the symbol is in the pattern to output the currect value
      const percentPattern = getFormatPattern('percent', decimalPlaces, true);

      // Then manually remove the symbol if needed
      let percent = parsed.format(percentPattern);
      if (!symbol) {
        percent = percent.replace('%', '');
      }

      return sanitizedFormat(percent, () => percent);

    case 'dollar':
      const diff = parsed.difference(0);
      const dollarPattern = getDollarFormatPattern(diff, div, decimalPlaces, symbol);
      let dollar = parsed.format(dollarPattern);
      const dollarSuffix = getDollarSuffix(div);
      // work around a formatting issue with numeral
      if (dollar === '0$.0') {
        dollar = '$0.0';
      }

      return sanitizedFormat(dollar, () => `${dollar}${dollarSuffix}`);

    default:
      const numericPattern = getFormatPattern('number', decimalPlaces);
      const numeric = parsed.format(numericPattern);

      return sanitizedFormat(numeric, () => numeric);
  }
};

export default { getFormatType, format };
