import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import ButtonBase from '../../common/button';
import Flex from '../../common/flex';

import * as inputState from './inputs/state';
import PresentButton from './present-button';
import PrintButtonBase from './print-button';
import * as scenarioState from './scenarios/state';

interface ButtonsProps {
  className?: string;
  canPresent: boolean;
  canApply: boolean;
  canSave: boolean;
  onApply: (
    scenarios: scenarioState.Scenario[],
    inputs: inputState.Input[],
    callback: () => void
  ) => void;
  onSave: (
    scenarios: scenarioState.Scenario[],
    inputs: inputState.Input[],
    callback: () => void
  ) => void;
  onUndoAll: (callback: () => void) => void;
  onPresent?: () => void;
  onPrint: () => void;
}

const Button = styled(ButtonBase)`
  margin: 0 0.1rem;
`;

const PrintButton = styled(PrintButtonBase)`
  ${breakpoint('xs', 'sm')`
    font-size: 0.75rem;
  `}
`;

const Buttons: React.FC<ButtonsProps> = ({
  className,
  canPresent,
  canApply,
  canSave,
  onApply,
  onSave,
  onUndoAll,
  onPresent,
  onPrint,
}) => {
  const [state, setState] = React.useState({
    applying: false,
    saving: false,
    undoing: false,
  });

  const inputs = useObservable(() => inputState.inputs$);
  const scenarios = useObservable(() => scenarioState.scenarios$, []);

  return (
    <Flex.Row className={className} justify="space-between">
      <Flex.Item>
        <Button
          type="green"
          loading={state.applying}
          disabled={!canApply || state.applying || state.saving || state.undoing}
          onClick={() => {
            setState({ applying: true, saving: false, undoing: false });
            onApply(scenarios, inputs, () =>
              setState({ applying: false, saving: false, undoing: false })
            );
          }}
        >
          Apply
        </Button>
        <Button
          type="dark-blue"
          loading={state.saving}
          disabled={!canSave || state.applying || state.saving || state.undoing}
          onClick={() => {
            setState({ applying: false, saving: true, undoing: false });
            onSave(scenarios, inputs, () =>
              setState({ applying: false, saving: false, undoing: false })
            );
          }}
        >
          Save
        </Button>
        <Button
          type="dark-grey"
          loading={state.undoing}
          disabled={!canSave || state.applying || state.saving || state.undoing}
          onClick={() => {
            setState({ applying: false, saving: false, undoing: true });
            onUndoAll(() => setState({ applying: false, saving: false, undoing: false }));
          }}
        >
          Undo All
        </Button>
      </Flex.Item>
      <Flex.Item>
        <Flex.Row>
          {canPresent && (
            <Flex.Item>
              <PresentButton open={false} onClick={onPresent} />
            </Flex.Item>
          )}
          <Flex.Item>
            <PrintButton onClick={onPrint} />
          </Flex.Item>
        </Flex.Row>
      </Flex.Item>
    </Flex.Row>
  );
};

export default Buttons;
