import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Flex from '../../../common/flex';
import theme from '../../../theme';
import RadioBase from '../radio';

import { query } from './state/query';
import { store } from './state/store';

const Heading = styled.div`
  font-weight: ${theme.weight.medium};
  margin-bottom: 0.5rem;
  text-align: center;

  ${breakpoint('lg')`
    text-align: left;
  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  ${breakpoint('lg')`
    flex-direction: column;
  `}
`;

const Inner = styled(Flex.Item)`
  width: ${({ width }) => width}%;

  ${breakpoint('lg')`
    width: auto;
  `}
`;

const Series = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.5rem;

  ${breakpoint('lg')`
    flex-direction: row;
    margin-bottom: 0.5rem;
    padding: 0;
    width: 100%;
  `}
`;

const Radio = styled(({ className, color, enabled }) => (
  <Flex.Item className={className}>
    <RadioBase color={color} enabled={enabled} />
  </Flex.Item>
))`
  ${breakpoint('lg')`
    align-self: flex-start;
  `}
`;

const Name = styled(Flex.Item)`
  cursor: pointer;
  overflow: visible;
  margin-top: 0.25rem;
  padding-left: 0;
  text-align: center;
  text-overflow: ellipsis;

  ${breakpoint('lg')`
    margin-top: 0;
    padding-left: 0.5rem;
    text-align: left;
  `}
`;

const Legend: React.FC = () => {
  const graphType = useObservable(() => query.graphType$, null);
  const legend = useObservable(() => query.legend$, []);

  return (
    <>
      <Heading>{graphType === 'multi' ? 'Outputs' : 'Scenarios'}</Heading>
      <Container>
        {legend.map(series => (
          <Inner key={series.id} width={100 / legend.length}>
            <Series onClick={() => store.setDisabled(series.id, series.enabled ? true : false)}>
              <Radio color={series.color} enabled={series.enabled} />
              <Name>{series.displayName ?? series.name}</Name>
            </Series>
          </Inner>
        ))}
      </Container>
    </>
  );
};

export default Legend;
