import { Icon, Layout } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import BadgeBase from '../common/badge';
import Flex from '../common/flex';
import { Client, Profile } from '../models';
import theme from '../theme';
import userUtils from '../utils/user';

import User from './user';

interface HeaderProps {
  title: string;
  profile?: Profile;
  client?: Client;
  showProfile: boolean;
  showNavbarToggle: boolean;
  onNavbarToggleClick: () => void;
  onAvatarClick: () => void;
}

const Content = styled(Flex.Row)`
  border-bottom: 1px solid ${theme.color.millimanBlue};
  font-size: ${theme.size.small};
  padding: 0.5rem 1rem;

  ${breakpoint('md')`
    font-size: ${theme.size.base};
    padding: 0.5rem 2rem;
  `}
`;

const NavbarToggle = styled(({ className, onClick }) => (
  <Flex.Item className={className}>
    <Icon type="menu" onClick={onClick} />
  </Flex.Item>
))`
  color: ${theme.color.vibrantBlue};
  font-size: 1.2rem;

  ${breakpoint('md')`
    display: none;
  `}
`;

const AppInfo = styled(props => <Flex.Col {...props} />)`
  align-items: ${({ alignment }) => alignment};
  justify-content: center;

  ${breakpoint('md')`
    align-items: flex-start;
  `}
`;

const Brand = styled.span`
  color: ${theme.color.millimanBlue};
  font-size: ${theme.size.subHeading};
  font-weight: ${theme.weight.medium};

  ${breakpoint('md')`
    font-size: ${theme.size.heading};
  `}
`;

const App = styled(({ responsive, ...props }) => <span {...props} />)`
  color: ${theme.color.vibrantBlue};
  font-size: ${theme.size.subHeading};
  font-weight: ${theme.weight.medium};

  &.o-app-responsive {
    display: none;
  }

  ${breakpoint('md')`
    font-size: ${theme.size.heading};
  `};

  ${({ responsive }) =>
    responsive &&
    css`
      &.o-app-default {
        display: none;
      }

      &.o-app-responsive {
        display: inline-block;
      }

      ${breakpoint('md')`
        &.o-app-default {
          display: inline-block;
        }

        &.o-app-responsive {
          display: none;
        }
      `};
    `};
`;

const ClientRow = styled(Flex.Row)`
  margin-top: 0.25rem;
`;

const ClientBadge = styled(BadgeBase)`
  margin-left: 0.25rem;
`;

const Header: React.FC<HeaderProps> = ({
  title,
  profile,
  client,
  showProfile,
  showNavbarToggle,
  onNavbarToggleClick,
  onAvatarClick,
}) => {
  // Get the client info but only if needed
  // Need to ensure containers are not interrogated if showProfile = false
  let clientName = null;
  let clientRole = null;

  if (profile && client && showProfile) {
    // Get the current client name
    clientName = <div>{client?.name}</div>;
    clientRole = userUtils.isAdmin(profile.userType) && (
      <ClientBadge>{userUtils.getRoleString(profile.userType)}</ClientBadge>
    );
  }

  return (
    <Layout.Header>
      <Content align="middle" justify="space-between">
        {showProfile && showNavbarToggle && <NavbarToggle onClick={onNavbarToggleClick} />}
        <Flex.Item>
          <AppInfo alignment={showNavbarToggle ? 'center' : 'flex-start'}>
            <Flex.Item>
              <Brand>Milliman</Brand>{' '}
              <App
                className="o-app-default"
                responsive={showProfile}
                dangerouslySetInnerHTML={{ __html: title || 'Optic&trade;' }}
              />
              <App
                className="o-app-responsive"
                responsive={showProfile}
                dangerouslySetInnerHTML={{ __html: 'Optic&trade;' }}
              />
            </Flex.Item>
            {showProfile && (
              <Flex.Item>
                <ClientRow align="middle">
                  <Flex.Item>{clientName}</Flex.Item>
                  <Flex.Item>{clientRole}</Flex.Item>
                </ClientRow>
              </Flex.Item>
            )}
          </AppInfo>
        </Flex.Item>
        {showProfile && (
          <Flex.Item>
            <User onAvatarClick={onAvatarClick} />
          </Flex.Item>
        )}
      </Content>
    </Layout.Header>
  );
};

export default Header;
