import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import BarChartSvg from '../../../assets/icon-bar-chart.svg';
import theme from '../../../theme';

import Icon from './icon';

const Bar = styled(props => (
  <Icon {...props}>
    <BarChartSvg />
  </Icon>
))`
  #bar-chart-2,
  #bar-chart-3,
  #bar-chart-4,
  #bar-chart-5,
  #bar-chart-6 {
    fill: ${theme.color.steel} !important;
  }

  ${({ selected, enabled }) =>
    enabled &&
    !selected &&
    css`
      &:hover {
        #bar-chart-2 {
          fill: ${theme.color.slate} !important;
        }

        #bar-chart-3 {
          fill: ${theme.color.spring} !important;
        }

        #bar-chart-4 {
          fill: ${theme.color.sky} !important;
        }

        #bar-chart-5 {
          fill: ${theme.color.gold} !important;
        }

        #bar-chart-6 {
          fill: ${theme.color.millimanBlue} !important;
        }
      }
    `}

  ${({ selected, enabled }) =>
    enabled &&
    selected &&
    css`
      #bar-chart-2 {
        fill: ${theme.color.black} !important;
      }

      #bar-chart-3 {
        fill: ${theme.color.spring} !important;
      }

      #bar-chart-4 {
        fill: ${theme.color.sky} !important;
      }

      #bar-chart-5 {
        fill: ${theme.color.gold} !important;
      }

      #bar-chart-6 {
        fill: ${theme.color.millimanBlue} !important;
      }
    `}
`;

export default Bar;
