import { Query } from '@datorama/akita';

import { AdminManagementState } from './models';
import { AdminManagementStore, store } from './store';

export class AdminManagementQuery extends Query<AdminManagementState> {
  readonly newSaveable$ = this.select(state => state.newSaveable);
  readonly newError$ = this.select(state => state.newError);
  readonly manageSaveable$ = this.select(state => state.manageSaveable);
  readonly manageError$ = this.select(state => state.manageError);

  constructor(_store: AdminManagementStore) {
    super(_store);
  }
}

export const query = new AdminManagementQuery(store);
