import { Icon as IconBase } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import FormBase from '../../../common/form';
import Modal from '../../../common/modal';
import theme from '../../../theme';
import FormLayout from '../../common/form-layout';
import SpreadsheetListBase from '../../common/spreadsheet-list';
import { Spreadsheet } from '../../models';

import * as spreadsheetState from './state';

interface SpreadsheetsProps {
  onSelect: (current: Spreadsheet, previous: Spreadsheet) => void;
  onAdd: () => void;
  onEdit: (spreadsheet: Spreadsheet) => void;
  onDelete: (spreadsheet: Spreadsheet, callback: (error?: string) => void) => void;
}

interface FormProps extends SpreadsheetsProps {
  spreadsheets: Spreadsheet[];
}

interface DeleteProps {
  onDelete: () => void;
}

const Icon = styled(IconBase)`
  color: ${theme.color.cranberry};
  margin-left: 1rem;
`;

const SpreadsheetList = React.forwardRef<any, any>((props, _) => (
  <SpreadsheetListBase {...props} />
));

const DeleteIcon: React.FC<DeleteProps> = ({ onDelete }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Icon type="delete" onClick={() => setVisible(true)} />
      <Modal
        title="Delete Spreadsheet Database"
        visible={visible}
        onOk={() => {
          setVisible(false);
          onDelete();
        }}
        okText="Delete"
        okType="danger"
        onCancel={() => setVisible(false)}
      >
        <p>
          <FormLayout.WarningMessage>Warning:</FormLayout.WarningMessage> Deleting this spreadsheet
          database will remove it and all associated scenario's from Optic&trade; and cannot be
          restored.
        </p>
        <p>Are you sure you want to continue?</p>
      </Modal>
    </>
  );
};

class Form extends FormBase<FormProps, {}> {
  private readonly SPREADSHEETS_FIELD_NAME = 'spreadsheets';

  render(): React.ReactNode {
    return (
      <>
        <FormLayout.Form onSubmit={e => e.preventDefault()}>
          <FormLayout.Heading>Active Spreadsheets</FormLayout.Heading>
          <FormLayout.TableListField label="Spreadsheets:">
            {this.renderSpreadsheetsField()}
          </FormLayout.TableListField>
        </FormLayout.Form>
      </>
    );
  }

  private renderSpreadsheetsField(): React.ReactNode {
    const { form, spreadsheets, onSelect, onAdd, onEdit, onDelete } = this.props;
    const decorator = form.getFieldDecorator(this.SPREADSHEETS_FIELD_NAME, {
      initialValue: spreadsheets,
      valuePropName: 'spreadsheets',
    });

    return decorator(
      <SpreadsheetList
        deleteIcon={(spreadsheet: Spreadsheet, callback: () => void) => {
          const list = form.getFieldValue(this.SPREADSHEETS_FIELD_NAME) || [];
          if (list.length > 1) {
            return (
              <DeleteIcon
                onDelete={() =>
                  onDelete(spreadsheet, error => {
                    if (!error) {
                      callback();
                    }
                  })
                }
              />
            );
          }

          return null;
        }}
        onSelect={onSelect}
        onAdd={onAdd}
        onEdit={onEdit}
      />
    );
  }
}

const Spreadsheets: React.FC<SpreadsheetsProps> = props => {
  const spreadsheets = useObservable(() => spreadsheetState.spreadsheets$, []);
  return (
    <>
      {FormBase.renderType(
        Form,
        {
          spreadsheets,
          ...props,
        },
        (values: any) => {
          spreadsheetState.setSpreadsheets(values.spreadsheets);
        }
      )}
    </>
  );
};

export default Spreadsheets;
