import { Modal as ModalBase } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';
import { ModalProps } from 'antd/lib/modal';
import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

interface ModalButtonProps {
  showOk?: boolean;
  showCancel?: boolean;
}

const Container = styled(ModalBase)`
  .ant-modal-header {
    background-color: ${theme.color.millimanBlue};
  }

  .ant-modal-title {
    color: ${theme.color.white};
  }

  .ant-modal-close-x {
    color: ${theme.color.white};
  }

  .ant-modal-body {
    padding: 1rem 2rem;
  }

  .ant-modal-footer {
    border-top: 0;
    padding: 0 1rem 1rem 1rem;
    text-align: center;
  }
`;

const configureButton = (
  show: boolean,
  props: NativeButtonProps,
  on: (e: React.MouseEvent<HTMLElement>) => void
) => {
  // ** THIS IS FILTHY - NEED TO SET INLINE STYLES **

  // Check if the button show is explicity false
  // Also check if it is not true and there is no handler
  let display = 'inline-block';
  if (show === false) {
    display = 'none';
  } else if (show !== true && !on) {
    display = 'none';
  }

  return {
    ...props,
    style: {
      display,
    },
  };
};

const Modal: React.FC<ModalProps & ModalButtonProps> = ({
  closable,
  okButtonProps,
  cancelButtonProps,
  showOk,
  showCancel,
  onOk,
  onCancel,
  children,
  ...props
}) => {
  const okProps = configureButton(showOk, okButtonProps, onOk);
  const cancelProps = configureButton(showCancel, cancelButtonProps, onCancel);

  return (
    <Container
      closable={closable && !!onCancel}
      okButtonProps={okProps}
      cancelButtonProps={cancelProps}
      onOk={onOk}
      onCancel={onCancel}
      {...props}
    >
      {children}
    </Container>
  );
};

export default Modal;
