import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import helperUtils from '../../../../utils/helper';
import { Scenario } from '../../../models';
import * as spreadsheetState from '../../spreadsheets/state';

import { ScenariosState } from './models';
import { ScenariosStore, store } from './store';

export class ScenariosQuery extends Query<ScenariosState> {
  readonly contentScenarios$ = combineLatest([
    spreadsheetState.contentSelectedId$,
    this.select(state => state.scenarios),
  ]).pipe(map(([spreadsheetId, scenarios]) => this.getScenarios(spreadsheetId, scenarios)));

  readonly summaryScenarios$ = combineLatest([
    spreadsheetState.summarySelectedId$,
    this.select(state => state.scenarios),
  ]).pipe(map(([spreadsheetId, scenarios]) => this.getScenarios(spreadsheetId, scenarios)));

  readonly saveable$ = this.select(state => state.saveable);
  readonly error$ = this.select(state => state.error);

  constructor(_store: ScenariosStore) {
    super(_store);
  }

  private getScenarios(selectedId: string, scenarios: { [id: string]: Scenario[] }): Scenario[] {
    const list = scenarios[selectedId];

    if (list) {
      return helperUtils.sort(list, 'name');
    }

    return null;
  }
}

export const query = new ScenariosQuery(store);
