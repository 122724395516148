import { Layout } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import favicon from '../assets/favicon.ico';
import { Client, Profile } from '../models';
import Router, { GuardFunction } from '../router';
import * as bannerState from '../state/banner';

import BannerBase from './banner';
import Footer from './footer';
import Header from './header';
import Navbar from './navbar';
import Sidebar from './sidebar';

interface LayoutProps {
  profile?: Profile;
  showProfile?: boolean;
  client?: Client;
  title?: string;
  navbar?: React.ReactNode;
  print?: React.ReactNode;
  guard?: GuardFunction;
}

interface LayoutState {
  showNavbar: boolean;
  showSidebar: boolean;
  responsive: boolean;
}

const Page = styled(Layout)`
  overflow-x: hidden;
  min-height: 100vh;

  @media print {
    display: none;
  }
`;

const Print = styled.div`
  visibility: hidden;

  @media print {
    visibility: visible;
  }
`;

const Body = styled(Layout)`
  position: relative;
  flex-direction: row;
  height: calc(100vh - 100px);
`;

const Container = styled(Layout)`
  z-index: 1;
`;

const Sider = styled(Layout.Sider)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 2;

  ${breakpoint('md')`
    position: relative;
  `}
`;

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const BannerContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;
`;

const Banner: React.FC = () => {
  const banner = useObservable(() => bannerState.banner$, null);
  return (
    <>
      {banner && (
        <BannerContainer>
          <BannerBase {...banner} />
        </BannerContainer>
      )}
    </>
  );
};

export default class extends React.Component<LayoutProps, LayoutState> {
  static Navbar = Navbar;

  constructor(props: LayoutProps) {
    super(props);

    this.state = {
      showNavbar: false,
      showSidebar: false,
      responsive: false,
    };
  }

  render(): React.ReactNode {
    const {
      profile,
      showProfile,
      client,
      title,
      navbar,
      print,
      guard,
      children,
      ...others
    } = this.props;

    const { showNavbar, showSidebar } = this.state;
    const body = (
      <>
        {navbar && (
          <Sider
            breakpoint="md"
            collapsed={!showNavbar}
            collapsedWidth={0}
            width={150}
            trigger={null}
            onBreakpoint={broken =>
              this.setState({
                showNavbar: !broken,
                responsive: broken,
              })
            }
          >
            {navbar}
          </Sider>
        )}
        <Container {...others}>
          <Layout.Content>
            <Inner>{children}</Inner>
          </Layout.Content>
        </Container>
        <Banner />
        {showProfile !== false && (
          <Sidebar visible={showSidebar} onClose={() => this.setState({ showSidebar: false })} />
        )}
      </>
    );

    return (
      <>
        <Helmet>
          <title>Milliman Optic&trade;</title>
          <link rel="shortcut icon" href={favicon} type="image/x-icon" />
        </Helmet>
        <Page>
          <Header
            title={title}
            profile={profile}
            client={client}
            showProfile={showProfile !== false}
            showNavbarToggle={!!navbar}
            onNavbarToggleClick={() => this.handleToggleNavbar()}
            onAvatarClick={() => this.handleToggleSidebar()}
          />
          <Body>
            {guard && <Router.Guard action={guard}>{body}</Router.Guard>}
            {!guard && body}
          </Body>
          <Footer showTerms={showProfile !== false} />
        </Page>
        <Print>{print}</Print>
      </>
    );
  }

  private handleToggleNavbar(): void {
    const { showNavbar, showSidebar } = this.state;
    const show = !showNavbar;

    this.setState({
      showNavbar: show,
      showSidebar: show ? false : showSidebar,
    });
  }

  private handleToggleSidebar(): void {
    const { showNavbar, showSidebar, responsive } = this.state;
    const show = !showSidebar;

    this.setState({
      showNavbar: show && responsive ? false : showNavbar,
      showSidebar: show,
    });
  }
}
