import { Store, StoreConfig } from '@datorama/akita';

import { DetailsState } from './models';

@StoreConfig({
  name: 'Client Management > Details',
})
export class DetailsStore extends Store<DetailsState> {
  constructor() {
    super({
      name: null,
      address: null,
      saveable: false,
      error: null,
    });
  }
}

export const store = new DetailsStore();
