import React from 'react';
import { Observable } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../../common/button';
import Content from '../../../common/content';
import DrawerBase from '../../../common/drawer';
import Flex from '../../../common/flex';
import FormBase, { FormProps as FormPropsBase } from '../../../common/form';
import { Api } from '../../../http/api';
import { Client, User } from '../../../models';
import * as clientState from '../../../state/clients';
import FormLayout from '../../common/form-layout';
import UserForm from '../../common/user-form';

import * as userState from './state';

interface DrawerProps {
  user: User;
  clients: Client[];
  deleteObservable: Observable<{
    user: User;
    callback: (error?: string) => void;
  }>;
  onExistingUser: () => void;
  onClose: () => void;
}

interface FormProps {
  user: User;
  clients: Client[];
  message: string;
  onSave: (user: User, password: string, callback: (error?: string) => void) => void;
  onClose: () => void;
}

interface FormState {
  message: string;
  submitting: boolean;
}

const Container = styled.div`
  padding: 2rem 1rem;

  ${breakpoint('sm')`
    padding: 2rem;
  `}
`;

const Error = styled(FormLayout.Error)`
  margin-top: 0.5rem;
`;

const inputLayout = {
  labelCol: {
    xs: 24,
    md: 8,
  },
  wrapperCol: {
    xs: 24,
    md: 16,
  },
};

const buttonLayout = {
  wrapperCol: {
    xs: 24,
    md: {
      offset: 8,
      span: 16,
    },
  },
};

class Form extends FormBase<FormProps, FormState> {
  constructor(props: FormProps & FormPropsBase) {
    super(props);

    this.state = {
      message: props.message,
      submitting: false,
    };
  }

  render(): React.ReactNode {
    const { form, user, clients, onClose } = this.props;
    const { message, submitting } = this.state;

    return (
      <FormLayout.Form onSubmit={ev => this.handleFormSubmit(ev)}>
        <UserForm
          type="user"
          currentUser={user}
          clients={clients.filter(({ hasAcceptedTerms }) => hasAcceptedTerms)}
          form={form}
          layout={inputLayout}
          disabled={submitting}
          passwordRequired={!user?.uniqueId}
        />
        {message && <Error message={message} layout={buttonLayout} />}
        <FormLayout.Buttons layout={buttonLayout}>
          <Flex.Item>
            <Button type="blue" htmlType="submit" loading={submitting}>
              {user?.uniqueId ? 'Update' : 'Add'}
            </Button>
          </Flex.Item>
          <Flex.Item>
            <Button type="light-grey" secondary={true} disabled={submitting} onClick={onClose}>
              Close
            </Button>
          </Flex.Item>
        </FormLayout.Buttons>
      </FormLayout.Form>
    );
  }

  private handleFormSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();

    // Validate the fields and post if all g!
    const { form, user, onSave } = this.props;
    form.validateFields((err, fieldsValue) => {
      if (!err) {
        this.setState({
          message: null,
          submitting: true,
        });

        if (onSave) {
          onSave(
            {
              uniqueId: user?.uniqueId,
              fullName: fieldsValue.name,
              email: fieldsValue.email,
              userType: fieldsValue.userType,
              clientIds: fieldsValue.clientIds,
            },
            fieldsValue.password,
            error => {
              if (error) {
                this.setState({
                  message: error,
                  submitting: false,
                });
              }
            }
          );
        } else {
          this.setState({
            submitting: false,
          });
        }
      }
    });
  }
}

const Drawer: React.FC<DrawerProps> = ({
  user,
  clients,
  deleteObservable,
  onExistingUser,
  onClose,
}) => {
  const users = useObservable(() => userState.users$, []);
  const clientId = useObservable(() => clientState.selectedId$, null);
  const [message, setMessage] = React.useState(null);

  const addUser = (
    current: User,
    password: string,
    callback: (user: User, error?: string) => void
  ) => {
    const { fullName, ...values } = current;
    setMessage(null);

    Api.channel
      .post<{ userId: string; newUser: boolean }>('/api/user', {
        ...values,
        password,
        name: fullName,
      })
      .then(({ data }) => {
        if (data.newUser) {
          callback({
            ...current,
            uniqueId: data.userId,
          });
        } else {
          setMessage('Existing user has been added to the client. Password has not been updated.');

          // Callback to load the existing user data
          onExistingUser();
        }
      })
      .catch(() => {
        callback(current, 'An error occurred');
      });
  };

  const updateUser = (current: User, password: string, callback: (error?: string) => void) => {
    const { fullName, ...values } = current;
    Api.channel
      .put(`/api/user/${user.uniqueId}`, {
        ...values,
        password,
        name: fullName,
      })
      .then(() => {
        callback();
      })
      .catch(err => {
        if (err.response.status === 400) {
          callback(`The email address ${current.email} is already in use`);
        } else {
          callback('An error occurred');
        }
      });
  };

  const deleteUser = (current: User, callback: (error?: string) => void) => {
    Api.channel
      .delete(`/api/user/${current.uniqueId}`)
      .then(() => {
        callback();
      })
      .catch(() => {
        callback('An error occurred');
      });
  };

  React.useEffect(() => {
    if (deleteObservable) {
      const subscription = deleteObservable.subscribe(context => {
        deleteUser(context.user, context.callback);
      });

      return () => subscription.unsubscribe();
    }

    return null;
  });

  return (
    <DrawerBase visible={!!user} onClose={onClose}>
      <Container>
        <Content.Heading>{user?.uniqueId ? 'Edit' : 'Add'} User</Content.Heading>
        {FormBase.renderType(Form, {
          user,
          clients,
          message,
          onSave: async (values: User, password: string, callback: (error?: string) => void) => {
            const { uniqueId, fullName, email } = values;
            const response = await Api.channel.post<{ valid: boolean }>(
              `/api/user/validate/${values.uniqueId}`,
              {
                name: fullName,
                email,
              }
            );

            if (!response.data.valid) {
              callback(`The email address ${email} is already in use`);
            } else if (uniqueId) {
              updateUser(values, password, error => {
                if (!error) {
                  const list = users
                    .map(current => (current.uniqueId === uniqueId ? values : current))
                    .filter(user => user.clientIds.includes(clientId));

                  userState.setUsers(list);
                  onClose();
                }

                callback(error);
              });
            } else {
              addUser(values, password, (added, error) => {
                if (!error) {
                  userState.setUsers([...users, added]);
                  onClose();
                }

                callback(error);
              });
            }
          },
          onClose: () => {
            setMessage(null);
            onClose();
          },
        })}
      </Container>
    </DrawerBase>
  );
};

export default Drawer;
