import { Input } from 'antd';
import { ColProps } from 'antd/lib/col';
import { FormProps } from 'antd/lib/form';
import React from 'react';
import styled from 'styled-components';

import FormBase from '../../common/form';
import TextAreaBase from '../../common/text-area';
import { Api, ApiResponse } from '../../http/api';
import { Client } from '../../models';
import addressUtils from '../../utils/address';
import FormLayout from '../common/form-layout';

const NAME_FIELD_NAME = 'name';
const ADDRESS_FIELD_NAME = 'address';

interface ValidateData extends ApiResponse {
  valid: boolean;
}

interface ClientFormProps extends FormProps {
  name?: string;
  client: Client;
  inputLayout?: {
    labelCol?: ColProps;
    wrapperCol?: ColProps;
  };
}

const TextArea = styled(TextAreaBase)`
  margin-top: 3px;
`;

const validateName = (value: string, callback: (error?: string) => void): void => {
  if (!value) {
    callback();
  } else {
    Api.channel
      .options<ValidateData>(`/api/client/names/${value}`)
      .then(({ data }) => {
        if (data.valid) {
          callback();
        } else {
          callback('Client name already exists');
        }
      })
      .catch(() => {
        callback('An error occurred');
      });
  }
};

const ClientForm: React.FC<ClientFormProps> = ({ name, client, form, inputLayout }) => (
  <>
    <FormLayout.Field label="Client name:" layout={inputLayout}>
      {form.getFieldDecorator(NAME_FIELD_NAME, {
        initialValue: client?.name,
        rules: [
          {
            ...FormBase.rules.notEmptyOrWhiteSpace,
            message: 'A name is required',
          },
          {
            validator: (_: any, value: string, callback: (error: string) => void) => {
              if (name && value === name) {
                return true;
              }

              return validateName(value, callback);
            },
          },
        ],
      })(<Input />)}
    </FormLayout.Field>
    <FormLayout.Field label="Client address:" layout={inputLayout}>
      {form.getFieldDecorator(ADDRESS_FIELD_NAME, {
        initialValue: addressUtils.transformAddress(client?.address, ',', '\n'),
        rules: [
          {
            ...FormBase.rules.notEmptyOrWhiteSpace,
            message: 'An address is required',
          },
        ],
      })(<TextArea rows={6} />)}
    </FormLayout.Field>
  </>
);

export default ClientForm;
