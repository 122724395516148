import { Drawer as DrawerBase } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import ButtonBase from '../common/button';
import Content from '../common/content';
import Flex from '../common/flex';
import Tabs from '../common/tabs';
import { routes } from '../constants';
import { Api } from '../http/api';
import Router, { useGuard } from '../router';
import * as clientState from '../state/clients';
import * as notificationState from '../state/notifications';
import * as profileState from '../state/profile';
import theme from '../theme';
import userUtils from '../utils/user';

import AdminTools from './admin-tools';
import Notifications from './notifications';
import Settings from './settings';

type SidebarProps = DrawerProps & {
  visible: boolean;
};

interface SidebarState {
  activeTab: string;
}

interface BodyProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
  onClickNotification: () => void;
  onClearNotifications: (userId: string, clientId: string) => void;
  onLogout: () => void;
}

const Drawer = styled(DrawerBase)`
  position: absolute;

  // Overwrite ANTD to get responsive width and content full height
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 28rem !important;

    .ant-drawer-body {
      padding: 0;
      height: 100%;
    }

    &::after {
      content: ' ';
      border-bottom: 0.5rem solid ${theme.color.millimanBlue};
      border-left: 0.5rem solid transparent;
      border-right: 0.5rem solid transparent;
      display: none;
      position: absolute;
      top: -0.5rem;
      right: 1.5rem;
      width: 0;
      height: 0;

      ${breakpoint('md')`
        right: 2.75rem;
      `}
    }
  }

  .ant-drawer-wrapper-body {
    overflow-y: hidden;
  }

  &.ant-drawer-open {
    .ant-drawer-content-wrapper {
      &::after {
        display: block;
      }
    }
  }
`;

const Container = styled(Flex.Col)`
  height: 100%;

  // Also need to make sure the ANTD element are full height
  .ant-tabs {
    height: 100%;

    .ant-tabs-content {
      height: 90%;
    }
  }
`;

const Tab = styled(Tabs.Content)`
  background-color: ${theme.color.panelGrey};
  overflow-y: auto;
`;

const TabHeader = styled.div`
  background-color: ${theme.color.white};
  padding: 1rem 1rem 1.5rem 1rem;
`;

const TabHeading = styled(Content.Heading)`
  font-size: ${theme.size.subHeading};
`;

const Button = styled(ButtonBase)`
  font-size: ${theme.size.subHeading};
  margin: 1rem 0.5rem;
  width: 8rem;
`;

const Body: React.FC<BodyProps> = ({
  activeTab,
  onTabChange,
  onClickNotification,
  onClearNotifications,
  onLogout,
}) => {
  const guard = useGuard();

  const profile = useObservable(() => profileState.profile$, null);
  const clientId = useObservable(() => clientState.selectedId$, null);
  const clients = useObservable(() => clientState.clients$, []);
  const notifications = useObservable(() => notificationState.notifications$, []);

  const admin = userUtils.isAdmin(profile?.userType);

  return (
    <Router.Location>
      {location => {
        let navigateLabel = 'Admin Portal';
        let navigatePath = routes.admin.clientManagement;
        let showAdminTools = admin;

        // Calculate the active tab
        if (!activeTab) {
          if (admin) {
            activeTab = clients.length === 0 ? 'settings' : 'admin-tools';
          } else {
            activeTab = notifications.length === 0 ? 'settings' : 'notifications';
          }
        }

        // Overrides when in the admin portal
        if (location.pathname.startsWith(routes.admin.root)) {
          activeTab = 'settings';
          navigateLabel = 'Client Portal';
          navigatePath = routes.app.home;
          showAdminTools = false;
        }

        return (
          <Container>
            <Flex.Item fill={true}>
              <Tabs activeKey={activeTab} onChange={onTabChange}>
                {!admin && (
                  <Tab
                    key="notifications"
                    tab="Notifications"
                    disabled={notifications.length === 0}
                  >
                    <TabHeader>
                      <TabHeading>Optic&trade; Notifications</TabHeading>
                      The following files have been added to your account:
                    </TabHeader>
                    <Notifications
                      items={notifications}
                      onClick={onClickNotification}
                      onClear={() => onClearNotifications(profile?.uniqueId, clientId)}
                    />
                  </Tab>
                )}
                {showAdminTools && (
                  <Tab key="admin-tools" tab="Admin Tools" disabled={clients.length === 0}>
                    <TabHeader>
                      <TabHeading>Optic&trade; Admin Tools</TabHeading>
                      Use the tools below to switch between clients, access the admin portal, and
                      review assigned consultants and plan data.
                    </TabHeader>
                    <AdminTools />
                  </Tab>
                )}
                <Tab key="settings" tab="Settings">
                  <TabHeader>
                    <TabHeading>Optic&trade; Settings</TabHeading>
                    To update your details; click on the field to update. Enter new details, confirm
                    your password and select 'Update Settings' button.
                  </TabHeader>
                  <Settings />
                </Tab>
              </Tabs>
            </Flex.Item>
            <Flex.Item>
              <Flex.Row align="middle" justify="center">
                {admin && (
                  <Flex.Item>
                    <Router.Navigate>
                      {navigate => (
                        <Button
                          type="green"
                          onClick={() => navigate(navigatePath)}
                          disabled={false}
                        >
                          {navigateLabel}
                        </Button>
                      )}
                    </Router.Navigate>
                  </Flex.Item>
                )}
                <Flex.Item>
                  <Button onClick={() => guard.invoke('logout', onLogout)}>Logout</Button>
                </Flex.Item>
              </Flex.Row>
            </Flex.Item>
          </Container>
        );
      }}
    </Router.Location>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ visible, onClose, ...others }) => {
  const [activeTab, setActiveTab] = React.useState(null);
  return (
    <Drawer
      placement="right"
      closable={false}
      visible={visible}
      getContainer={false}
      onClose={onClose}
      {...others}
    >
      <Body
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onClickNotification={() => onClose(null)}
        onClearNotifications={(userId, clientId) => {
          // tslint:disable-next-line: no-floating-promises
          Api.channel.delete(`/api/user/${userId}/notifications/${clientId}`).finally(() => {
            notificationState.remove(clientId);
            setActiveTab(null);
          });
        }}
        onLogout={() => {
          // tslint:disable-next-line: no-floating-promises
          Api.channel.post('/api/user/logout').finally(() => {
            window.location.href = routes.auth.login;
          });
        }}
      />
    </Drawer>
  );
};

export default Sidebar;
