import React from 'react';

import Flex from './flex';
import { EmptyIcon } from './icons';

interface EmptyProps {
  text?: string;
}

const Empty: React.FC<EmptyProps> = ({ text }) => (
  <Flex.Col align="middle">
    <Flex.Item>
      <EmptyIcon height="3rem" width="3rem" />
    </Flex.Item>
    <Flex.Item>{text || 'No data'}</Flex.Item>
  </Flex.Col>
);

export default Empty;
