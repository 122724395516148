import theme from '../../../theme';
import helperUtils from '../../../utils/helper';

interface MultiOutputColorMap {
  [output: string]: string;
}

interface ZoneColorMap {
  [color: string]: string;
}

const scenarioColors: string[] = [
  theme.color.millimanBlue,
  theme.color.gold,
  theme.color.spring,
  theme.color.sky,
];

const zoneColors: ZoneColorMap = {
  green: theme.color.spring,
  yellow: theme.color.yellow,
  orange: theme.color.putty,
  red: '#f00000',
  deepRed: theme.color.cranberry,
};

// Build a map of colors for multi outputs
const lightBlue = '#337BAA';
const multiOutputColors: MultiOutputColorMap = {};

multiOutputColors[1] = theme.color.orange;
multiOutputColors[0] = theme.color.cranberry;
multiOutputColors[2] = lightBlue;
multiOutputColors[10] = lightBlue;
multiOutputColors[13] = theme.color.millimanGreen;
multiOutputColors[4] = theme.color.cranberry;
multiOutputColors[5] = lightBlue;
multiOutputColors[7] = lightBlue;
multiOutputColors[12] = lightBlue;
multiOutputColors[15] = theme.color.millimanGreen;

export { scenarioColors, multiOutputColors, zoneColors };
