import { Store, StoreConfig } from '@datorama/akita';

import { BannerState } from './models';

@StoreConfig({
  name: 'App > Banner',
})
export class BannerStore extends Store<BannerState> {
  constructor() {
    super({
      color: null,
      background: null,
      text: null,
    });
  }
}

export const store = new BannerStore();
