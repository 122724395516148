import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import * as graphState from '../app/projection-tool/graph/state';
import Badge from '../common/badge';
import Content from '../common/content';
import Flex from '../common/flex';
import LoaderBase from '../common/loader';
import Select from '../common/select';
import { Api } from '../http/api';
import { Model, User } from '../models';
import { useGuard } from '../router';
import * as bannerState from '../state/banner';
import * as clientState from '../state/clients';
import theme from '../theme';
import helperUtils from '../utils/helper';
import userUtils from '../utils/user';

const Loader = styled(LoaderBase)`
  margin-top: 3rem;
`;

const Container = styled.div`
  padding: 1.5rem 1rem;
  overflow-y: auto;
`;

const Item = styled.div`
  margin-bottom: 1.5rem;
`;

const Heading = styled(Content.Heading)`
  font-size: ${theme.size.base};
  padding-left: 0.25rem;
`;

const List = styled.div`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.steel};
  border-radius: ${theme.border.radius};
  padding: 0.25rem 0.5rem;
  overflow-y: auto;
  max-height: 6.6rem;
`;

const ListItem = styled.div`
  padding: 0.25rem 0;
`;

const ListBadge = styled(Badge)`
  margin-left: 0.5rem;
`;

const AdminTools: React.FC = () => {
  const guard = useGuard();

  const banner = useObservable(() => bannerState.banner$, null);
  const clientId = useObservable(() => clientState.selectedId$, null);
  const clients = useObservable(() => clientState.clients$, []);

  const [admins, setAdmins] = React.useState<User[]>([]);
  const [models, setModels] = React.useState<Model[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (clientId) {
      // tslint:disable-next-line: no-floating-promises
      Promise.all([
        Api.channel.get<{ users: User[] }>(`/api/client/${clientId}/users`),
        Api.channel.get<{ models: Model[] }>(`/api/client/${clientId}/models`),
      ]).then(([userResponse, modelResponse]) => {
        const consultants = userUtils.getSortedConsultants(userResponse.data.users);
        const models = helperUtils.sort(modelResponse.data.models, 'label');

        setAdmins(consultants);
        setModels(models);
        setLoading(false);
      });
    }
  }, [clientId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Container>
      <Item>
        <Heading>Change Client:</Heading>
        <Select
          value={clientId}
          disabled={!!banner}
          onChange={value =>
            value !== clientId &&
            guard.invoke('select-client', () => {
              graphState.initialize(false);
              clientState.setSelectedId(value);
            })
          }
        >
          {clients.map(client => (
            <Select.Option key={client.uniqueId} value={client.uniqueId}>
              {client.name}
            </Select.Option>
          ))}
        </Select>
      </Item>
      <Item>
        <Heading>Assigned Milliman Consultants:</Heading>
        <List>
          {admins.map(admin => (
            <ListItem key={admin.uniqueId}>
              <Flex.Row justify="space-between">
                <Flex.Item>{admin.fullName}</Flex.Item>
                <Flex.Item>
                  <ListBadge>{userUtils.getRoleString(admin.userType)} </ListBadge>
                </Flex.Item>
              </Flex.Row>
            </ListItem>
          ))}
          {admins.length === 0 && (
            <ListItem>
              <Flex.Row align="middle">
                <Flex.Item>No Assigned Consultants</Flex.Item>
              </Flex.Row>
            </ListItem>
          )}
        </List>
      </Item>
      <Item>
        <Heading>Available Models (change above chart):</Heading>
        <List>
          {models.map(model => (
            <ListItem key={model.uniqueId}>{model.label}</ListItem>
          ))}
          {models.length === 0 && (
            <ListItem>
              <Flex.Row align="middle">
                <Flex.Item>No Available Models</Flex.Item>
              </Flex.Row>
            </ListItem>
          )}
        </List>
      </Item>
    </Container>
  );
};

export default AdminTools;
