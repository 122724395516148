import React from 'react';
import ChartComponent, { ChartComponentProps } from 'react-chartjs-2';
import { useObservable } from 'rxjs-hooks';

import chartUtils from '../common/chart';
import formatUtils from '../common/format';

import { query } from './state/query';

interface ZoneColorProvider {
  colors: string[];
}

interface ContainerProps extends ChartComponentProps {
  containerRef: React.RefObject<HTMLDivElement>;
}

class Container extends ChartComponent<ContainerProps> {
  render(): React.ReactNode {
    const { containerRef } = this.props;
    return (
      <div className="o-chart" ref={containerRef}>
        {super.render()}
        <div className="o-caret"></div>
        <div className="o-chart-tooltip"></div>
      </div>
    );
  }
}

const Chart: React.FC = () => {
  let caret: HTMLDivElement;
  let tooltip: HTMLDivElement;

  const containerRef = React.createRef<HTMLDivElement>();
  const stateRef = useObservable(() => query.ref$, 0);
  const outputType = useObservable(() => query.outputType$, null);
  const graphType = useObservable(() => query.graphType$, null);
  const years = useObservable(() => query.years$, []);
  const data = useObservable(() => query.data$, []);

  // Determine the y axis label
  let yLabel: string;
  if (outputType) {
    switch (outputType.formatType) {
      case 'percent':
        yLabel = 'Percent (%)';
        break;
      case 'dollar':
        yLabel = 'Dollars ($)';
        break;
      default:
        yLabel = null;
        break;
    }
  }

  return (
    outputType && (
      <Container
        key={stateRef}
        containerRef={containerRef}
        datasetKeyProvider={dataset => dataset.datasetId}
        data={chartUtils.getData(outputType, years, data)}
        options={{
          ...chartUtils.getOptions(outputType, graphType),
          tooltips: {
            mode: 'index',
            position: 'nearest',
            enabled: false,
            displayColors: true,
            caretSize: 6,
            caretPadding: 2,
            titleFontSize: 14,
            titleMarginBottom: 0,
            titleSpacing: 0,
            bodyFontSize: 12,
            bodySpacing: 0,
            footerFontSize: 0,
            footerMarginTop: 0,
            footerSpacing: 0,
            // Add some padding adjustment for values not included by the chart calcs
            // The y value is based on the number of active scenarios
            yPadding: 9 - 3 * (data.length - 1),
            xPadding: 40,
            callbacks: {
              title: items => {
                // Use the x label of the first item
                if (items.length > 0) {
                  const [first] = items;
                  return first.label;
                }

                return '';
              },
              beforeLabel: (item, data) => {
                const dataset = data.datasets[item.datasetIndex];
                return dataset.label;
              },
              label: item =>
                formatUtils.format(
                  outputType.formatType,
                  item.yLabel,
                  outputType.div,
                  outputType.decimalPlaces
                ),
              labelTextColor: (item, chart) => {
                const dataset = chart.data.datasets[item.datasetIndex] as ZoneColorProvider;
                return dataset.colors[item.index];
              },
            },
            custom: model => {
              // Going old school to add the tooltip - raw html all the way
              const { current } = containerRef;
              if (containerRef) {
                caret = caret || current.querySelector<HTMLDivElement>('.o-caret');
                tooltip = tooltip || current.querySelector<HTMLDivElement>('.o-chart-tooltip');

                // Hide the tooltip elements and exit
                const show = model.opacity && tooltip && caret;
                if (!show) {
                  if (caret) {
                    caret.style.opacity = '0';
                  }

                  if (tooltip) {
                    tooltip.style.opacity = '0';
                  }

                  return;
                }

                // Configure the caret styling
                caret.setAttribute('data-caret-y', model.yAlign);
                caret.setAttribute('data-caret-x', model.xAlign);

                caret.style.opacity = '1';
                caret.style.top = `${model.caretY}px`;
                caret.style.left = `${model.caretX}px`;

                // Build the tooltip content and styling
                const title = model.title.join('');
                const body = model.body.map((item, index) => {
                  const legend: any = model.labelColors[index];
                  const zone: any = model.labelTextColors[index];

                  return `
                    <div class="o-line">
                      <div class="o-label">
                        <div class="o-legend" style="border-color: ${
                          legend.backgroundColor
                        };"></div>
                        <div class="o-name">${item.before.join('')}</div>
                      </div>
                      <div class="o-value">
                        <div class="o-zone" style="background-color: ${zone};border-color: ${zone};"></div>
                        <div>${item.lines.join('')}</div>
                      </div>
                    </div>
                  `;
                });

                tooltip.innerHTML = `
                  <div class="o-title">${title}</div>
                  ${body.join('')}
                `;

                tooltip.style.opacity = '1';
                tooltip.style.top = `${model.y}px`;
                tooltip.style.left = `${model.x}px`;
                tooltip.style.height = `${model.height}px`;
                tooltip.style.width = `${model.width}px`;
              }
            },
          },
        }}
      />
    )
  );
};

export default Chart;
