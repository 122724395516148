import React from 'react';
import { useHistory } from 'react-router';
import { Prompt as PromptBase } from 'react-router-dom';
import styled from 'styled-components';

import Modal from '../common/modal';
import theme from '../theme';

export type GuardFunction = (context: GuardFunctionContext) => void;
export type SaveFunction = () => Promise<void>;
export type CallbackFunction = (confirmed: boolean) => void;

export interface GuardFunctionInvoker {
  invoke: (name: string, action: () => void) => void;
}

interface GuardTrigger {
  name: string;
  data?: any;
}

interface GuardFunctionContext {
  trigger: GuardTrigger;
  message: string;
  save: SaveFunction;
  skip: boolean;
}

interface GuardState {
  showModal?: boolean;
  message?: string;
  save?: SaveFunction;
  callback?: CallbackFunction;
}

interface GuardContextState {
  active: boolean;
  prompt: (trigger: GuardTrigger, callbackIfSkip: boolean, callback: CallbackFunction) => boolean;
}

interface GuardProps {
  action: GuardFunction;
}

const Warning = styled.span`
  color: ${theme.color.cranberry};
  font-weight: ${theme.weight.bold};
`;

const Text = styled.div`
  margin-bottom: 1rem;
`;

const GuardContext = React.createContext<GuardContextState>({
  active: false,
  prompt: () => false,
});

const RouteGuard: React.FC = () => {
  const history = useHistory();
  const mountedRef = React.useRef(true);
  const [prompting, setPrompting] = React.useState(false);
  const { active, prompt } = React.useContext(GuardContext);

  React.useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  return (
    <PromptBase
      when={active}
      message={next => {
        if (!prompting) {
          const path = next.pathname;
          const trigger: GuardTrigger = {
            name: 'route',
            data: {
              path,
            },
          };

          setPrompting(true);

          // If the prompt returns false, it means the prompt was not shown
          // In this case just let the navigation happen
          const prompted = prompt(trigger, false, confirmed => {
            if (confirmed) {
              history.push(path);
            }

            // Note that this comes after the above to prevent blocking the navigation
            // We also need to prevent the upate once the component has unmounted
            if (mountedRef.current) {
              setPrompting(false);
            }
          });

          if (!prompted) {
            setPrompting(false);
            return true;
          }

          // Otherwise return false and let the callback kick in
          return false;
        }

        return true;
      }}
    />
  );
};

const Guard: React.FC<GuardProps> = ({ action, children }) => {
  const mountedRef = React.useRef(true);
  const [state, setState] = React.useState<GuardState>({});

  React.useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);

  return (
    <GuardContext.Provider
      value={{
        active: !!action,
        prompt: (trigger, callbackIfSkip, callback) => {
          // Call the guard function to get the settings for the current prompt
          const context: GuardFunctionContext = {
            trigger,
            message: null,
            save: null,
            skip: false,
          };

          action(context);

          // If not skipping, show the modal
          if (!context.skip) {
            setState({
              showModal: true,
              message: context.message || 'Unsaved changes detected.',
              save: context.save,
              callback,
            });

            return true;
          }

          // Otherwise invoke the callback to continue any logic
          // Return false to indicate the prompt will not be shown
          if (callbackIfSkip && callback) {
            callback(true);
          }

          return false;
        },
      }}
    >
      <RouteGuard />
      {children}
      <Modal
        title="Save Warning"
        visible={state.showModal}
        okText="Save"
        cancelText="Continue"
        showOk={!!state.save}
        onOk={() => {
          // Invoke the save and then the callback
          // tslint:disable-next-line: no-floating-promises
          state
            .save()
            .then(() => {
              if (state.callback) {
                state.callback(true);
              }
            })
            .catch(() => {
              // Do nothing
            })
            .finally(() => {
              // Reset the state - only if still mounted
              if (mountedRef.current) {
                setState({});
              }
            });
        }}
        onCancel={ev => {
          // Need to determine whether the button click caused the cancel
          // If not then it is a click outside the modal and the action is short circuited
          // Use a hack to get the button class because we can't use react ref objects
          if (state.callback) {
            const evt: any = ev || {};
            const target: any = evt.target || {};
            const confirmed = target.classList.contains('ant-btn');

            state.callback(confirmed);
          }

          // Reset the state - only if still mounted
          if (mountedRef.current) {
            setState({});
          }
        }}
      >
        <Text>
          <Warning>Warning:</Warning> {state.message}
        </Text>
        {state.save && (
          <Text>
            Save your changes by selecting the save button below. To proceed without saving, select
            continue.
          </Text>
        )}
        {!state.save && <Text>To proceed, select continue.</Text>}
      </Modal>
    </GuardContext.Provider>
  );
};

export const useGuard = (): GuardFunctionInvoker => {
  const { active, prompt } = React.useContext(GuardContext);
  return {
    invoke: (name: string, action: () => void) => {
      if (action) {
        if (active) {
          const trigger: GuardTrigger = {
            name,
          };

          prompt(trigger, true, confirmed => {
            if (confirmed) {
              action();
            }
          });
        } else {
          action();
        }
      }
    },
  };
};

export default Guard;
