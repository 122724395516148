import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { BannerState } from './models';
import { BannerStore, store } from './store';

export class BannerQuery extends Query<BannerState> {
  readonly banner$: Observable<BannerState> = this.select(({ color, background, text }) => {
    if (!!color && !!background && !!text) {
      return {
        color,
        background,
        text,
      };
    }

    return null;
  });

  constructor(_store: BannerStore) {
    super(_store);
  }
}

export const query = new BannerQuery(store);
