import React from 'react';

import { routes } from '../constants';
import Router from '../router';

import ForgotPassword from './forgot-password';
import Login from './login';
import ResetPassword from './reset-password';

const Auth: React.FC = () => (
  <Router.Child>
    <Router.Page path={routes.auth.login} secure={false} component={Login} />
    <Router.Page
      path={routes.auth.forgotPassword}
      secure={false}
      redirect={false}
      component={ForgotPassword}
    />
    <Router.Page
      path={routes.auth.resetPassword}
      secure={false}
      redirect={false}
      component={ResetPassword}
    />
  </Router.Child>
);

export default Auth;
