import { AxiosRequestConfig } from 'axios';
import React from 'react';
import { useObservable } from 'rxjs-hooks';

import { Api } from '../http/api';
import * as profileState from '../state/profile';

import Modal from './modal';

// authentication timeout should be 60 minutes so we will show the dialog with 15 minutes to spare
// and force a refresh after the session expiry
const warningTime = 45 * 60 * 1000;
const expiryTime = 15 * 60 * 1000;

const TimeoutDialog: React.FC = () => {
  const [showDialog, setShowDialog] = React.useState(false);
  const profile = useObservable(() => profileState.profile$);

  const refresh = async () => {
    setShowDialog(false);
    try {
      await Api.channel.get('/api/user/profile');
    } catch {
      window.location.reload();
    }
  };

  React.useEffect(() => {
    let showDialogHandle = 0;
    let logoutHandle = 0;
    let interceptRequestHandle = 0;

    if (profile) {
      if (showDialog) {
        logoutHandle = setTimeout(refresh, expiryTime);
      } else {
        showDialogHandle = setTimeout(() => setShowDialog(true), warningTime);
      }

      // handle requests
      interceptRequestHandle = Api.channel.interceptors.request.use(request => {
        clearTimeout(showDialogHandle);
        clearTimeout(logoutHandle);
        showDialogHandle = setTimeout(() => setShowDialog(true), warningTime);
        return request;
      });
    }

    return () => {
      clearTimeout(showDialogHandle);
      clearTimeout(logoutHandle);
      Api.channel.interceptors.request.eject(interceptRequestHandle);
    };
  }, [showDialog, profile]);

  return (
    <Modal
      title="Timeout Warning"
      visible={showDialog}
      okText="Close"
      showCancel={false}
      onOk={refresh}
      onCancel={refresh}
    >
      <div>
        <p>A period of inactivity has been detected.</p>
        <p>You will be logged out shortly and any unsaved work will be lost.</p>
      </div>
    </Modal>
  );
};

export default TimeoutDialog;
