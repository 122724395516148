import theme from '../../theme';

import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const banner$ = query.banner$;

export const setCreatingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.spring,
    text: 'Creating...please wait',
  });

export const setUpdatingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.spring,
    text: 'Updating...please wait',
  });

export const setApplyingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.spring,
    text: 'Applying...please wait',
  });

export const setSavingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.spring,
    text: 'Saving...please wait',
  });

export const setDeletingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.cranberry,
    text: 'Deleting...please wait',
  });

export const setReloadingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.spring,
    text: 'Reloading...please wait',
  });

export const setLoadingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.spring,
    text: 'Loading...please wait',
  });

export const setUploadingBanner = () =>
  store.update({
    color: theme.color.white,
    background: theme.color.spring,
    text: 'Uploading...please wait',
  });

export const clearBanner = () =>
  store.update({
    color: null,
    background: null,
    text: null,
  });
