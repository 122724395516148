import { Input as InputBase } from 'antd';
import React from 'react';

import Modal from '../../common/modal';

import FormLayout from './form-layout';

interface ConfirmPasswordProps {
  title: string;
  visible: boolean;
  confirmType: 'primary' | 'danger';
  confirmText: string;
  onConfirm: (password: string, callback: (error?: string) => void) => void;
  onCancel: () => void;
}

const ConfirmPassword: React.FC<ConfirmPasswordProps> = ({
  title,
  visible,
  confirmType,
  confirmText,
  onConfirm,
  onCancel,
  children,
}) => {
  const [password, setPassword] = React.useState<string>(null);
  const [error, setError] = React.useState<string>(null);

  return (
    <Modal
      title={title}
      visible={visible}
      okType={confirmType}
      okText={confirmText}
      okButtonProps={{
        disabled: !password,
      }}
      onOk={() =>
        onConfirm(password, err => {
          if (err) {
            setError(err);
          } else {
            setError(null);
            setPassword(null);
          }
        })
      }
      onCancel={() => {
        onCancel();
        setError(null);
        setPassword(null);
      }}
    >
      <p>{children}</p>
      <p>
        <InputBase type="password" value={password} onChange={e => setPassword(e.target.value)} />
      </p>
      {error && <FormLayout.ErrorMessage>{error}</FormLayout.ErrorMessage>}
    </Modal>
  );
};

export default ConfirmPassword;
