import React from 'react';
import styled from 'styled-components';

import theme from '../theme';

interface ListProps {
  disabled?: boolean;
}

const List = styled.div`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.steel};
  border-radius: ${theme.border.radius};
  padding: 0.25rem 0.5rem;
  overflow-y: auto;
  max-height: 7.25rem;
`;

export default class extends React.Component<ListProps> {
  static ListItem = styled.div`
    line-height: 1rem;
    padding: 0.25rem 0;
  `;

  render(): React.ReactNode {
    return <List {...this.props} />;
  }
}
