import React from 'react';
import styled from 'styled-components';

import Button from '../common/button';
import Content from '../common/content';
import Flex from '../common/flex';
import { routes } from '../constants';
import Layout from '../layout';
import theme, { Theme } from '../theme';

const Heading = styled(Content.Heading)`
  font-size: 5rem;
`;

const SubHeading = styled(Content.SubHeading)`
  color: ${theme.color.black};
  font-size: ${theme.size.heading};
  margin-bottom: 2rem;
`;

const NotFound: React.FC = () => (
  <Theme>
    <Layout showProfile={false}>
      <Flex.Col className="o-height-100 o-page-background" align="middle" justify="center">
        <Heading>404 Error</Heading>
        <SubHeading>Sorry page could not be found!</SubHeading>
        <Button type="dark-blue" onClick={() => (window.location.href = routes.app.home)}>
          Home
        </Button>
      </Flex.Col>
    </Layout>
  </Theme>
);

export default NotFound;
