import { Icon as IconBase, Table } from 'antd';
import { ColumnProps, SorterResult, TableProps } from 'antd/lib/table';
import React from 'react';
import styled from 'styled-components';

import { User } from '../../models';
import theme from '../../theme';

type ListProps = TableProps<User> & {
  selected?: User;
  onEdit: (consultant: User) => void;
};

const Edit = styled(IconBase)`
  color: ${theme.color.millimanBlue};
`;

const List: React.FC<ListProps> = ({ selected, onEdit, ...props }) => {
  const [tableInfo, setTableInfo] = React.useState<{ sorter: SorterResult<User> }>({
    sorter: {} as any,
  });

  // Build the list of table columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      width: 100,
      ellipsis: true,
      key: 'fullName',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortOrder: tableInfo.sorter.columnKey === 'fullName' && tableInfo.sorter.order,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: 150,
      ellipsis: true,
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortOrder: tableInfo.sorter.columnKey === 'email' && tableInfo.sorter.order,
      render: (_, consultant) => <a>{consultant.email}</a>,
    },
    {
      title: 'Clients',
      key: 'clients',
      width: 100,
      sorter: (a, b) =>
        a.clientIds?.length === b.clientIds?.length ? 0 : a.clientIds?.length < b.clientIds?.length,
      sortOrder: tableInfo.sorter.columnKey === 'clients' && tableInfo.sorter.order,
      render: (_, consultant) => `${consultant.clientIds?.length}`,
    },
    {
      title: 'Privilege',
      key: 'privilege',
      width: 100,
      sorter: (a, b) => (a.userType === b.userType ? 0 : a.userType < b.userType),
      sortOrder: tableInfo.sorter.columnKey === 'privilege' && tableInfo.sorter.order,
      render: (_, consultant) => {
        switch (consultant.userType) {
          case 1:
            return 'Standard';
          case 2:
            return 'Publisher';
          case 3:
            return 'Admin';
        }
      },
    },
    {
      title: '',
      key: 'edit',
      fixed: 'right',
      render: (_, consultant) => <Edit type="edit" onClick={() => onEdit(consultant)} />,
    },
  ] as ColumnProps<User>[];

  return (
    <Table
      rowKey="uniqueId"
      size="small"
      bordered={false}
      pagination={false}
      columns={columns}
      scroll={{ x: '100%' }}
      rowClassName={(consultant: User) => (selected === consultant ? 'ant-table-row-selected' : '')}
      onChange={(_, __, sorter) => setTableInfo({ sorter })}
      {...props}
    />
  );
};

export default List;
