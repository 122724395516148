import { Scenario } from '../../../models';

import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const contentScenarios$ = query.contentScenarios$;
export const summaryScenarios$ = query.summaryScenarios$;
export const saveable$ = query.saveable$;
export const error$ = query.error$;

export const setScenarios = (spreadsheetId: string, scenarios: Scenario[]) =>
  store.setScenarios(spreadsheetId, scenarios);

export const setSaveable = (saveable: boolean) =>
  store.update({
    saveable,
  });

export const setError = (error?: string) =>
  store.update({
    error,
  });
