import React from 'react';
import { useHistory } from 'react-router';
import { Observable, Subject } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Splitter from '../../common/splitter';
import { routes } from '../../constants';
import { Api } from '../../http/api';
import { Client, Profile, User } from '../../models';
import * as bannerState from '../../state/banner';
import * as clientState from '../../state/clients';
import * as profileState from '../../state/profile';
import addressUtils from '../../utils/address';
import helperUtils from '../../utils/helper';
import userUtils from '../../utils/user';
import ConfirmPassword from '../common/confirm-password';
import Layout from '../layout';
import { Consultant, Input, MultiOutput, Output, Scenario, Spreadsheet } from '../models';

import * as consultantState from './consultants/state';
import Content from './content';
import * as dataState from './data/state';
import * as detailsState from './details/state';
import * as scenarioState from './scenarios/state';
import SpreadsheetDrawer from './spreadsheets/drawer';
import * as spreadsheetState from './spreadsheets/state';
import Summary from './summary';
import UserDrawer from './users/drawer';
import * as userState from './users/state';

interface SpreadsheetData {
  uniqueId: string;
  label: string;
  notes: string;
  default?: boolean;
  fileName: string;
}

interface ValueState {
  dirty: boolean;
  saveable: boolean;
  values: any;
}

interface ValuesState {
  details: ValueState;
  data: ValueState;
  scenarios: ValueState;
  consultants: ValueState;
}

interface ContainerProps {
  loading: boolean;
  profile: Profile;
  activeTab: string;
  selected: Client;
  clients: Client[];
  consultants: User[];
  state: () => any;
  confirmScenarios: Observable<{
    scenarios: Scenario[];
    callback: (error?: string) => void;
  }>;
  onTabChange: (tab: string) => void;
  onSelect: (clientId: string) => void;
  onChange: (values: any) => void;
  onSave: () => void;
  onCancel: () => void;
  onDelete: (password: string, callback: (error?: string) => void) => void;
  onSpreadsheetLoad: (spreadsheetId: string, callback: () => void) => void;
  onExistingUser: () => void;
}

const SummaryContainer = styled.div`
  display: none;
  padding: 2rem;
  height: 100%;

  ${breakpoint('lg')`
    display: block;
  `}
`;

const Container: React.FC<ContainerProps> = ({
  loading,
  selected,
  clients,
  confirmScenarios,
  onSpreadsheetLoad,
  onExistingUser,
  ...props
}) => {
  const selectSpreadsheet = new Subject<{
    current: Spreadsheet;
    previous?: Spreadsheet;
  }>();

  const deleteSpreadsheet = new Subject<{
    spreadsheet: Spreadsheet;
    callback: (error?: string) => void;
  }>();

  const deleteUser = new Subject<{
    user: User;
    callback: (error?: string) => void;
  }>();

  const [spreadsheet, setSpreadsheet] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [confirm, setConfirm] = React.useState(null);

  // Track the selected spreadsheet and load the inputs, outputs and scenarios here to prevent the state cache from resetting
  const contentSpreadsheetId = useObservable(() => spreadsheetState.contentSelectedId$, null);
  const summarySpreadsheetId = useObservable(() => spreadsheetState.summarySelectedId$, null);
  const contentData = useObservable(() => dataState.contentData$, null);
  const summaryData = useObservable(() => dataState.summaryData$, null);
  const contentScenarios = useObservable(() => scenarioState.contentScenarios$, null);
  const summaryScenarios = useObservable(() => scenarioState.summaryScenarios$, null);

  React.useEffect(() => {
    if (confirmScenarios) {
      const subscription = confirmScenarios.subscribe(context => {
        setConfirm(context);
      });

      return () => subscription.unsubscribe();
    }

    return null;
  });

  React.useEffect(() => {
    if (contentSpreadsheetId) {
      const contentLoaded = contentData && contentScenarios;

      if (!contentLoaded) {
        spreadsheetState.setContentLoading(true);
        onSpreadsheetLoad(contentSpreadsheetId, () => {
          spreadsheetState.setContentLoading(false);

          // Change the summary dropdown to match the content selection
          spreadsheetState.setSummarySelectedId(contentSpreadsheetId);
        });
      } else {
        spreadsheetState.setSummarySelectedId(contentSpreadsheetId);
      }
    }
  }, [contentSpreadsheetId]);

  React.useEffect(() => {
    if (summarySpreadsheetId) {
      const summaryLoaded = summaryData && summaryScenarios;

      if (!summaryLoaded) {
        spreadsheetState.setSummaryLoading(true);
        onSpreadsheetLoad(summarySpreadsheetId, () => {
          spreadsheetState.setSummaryLoading(false);
        });
      }
    }
  }, [summarySpreadsheetId]);

  return (
    <>
      <Splitter defaultSize="70%" minSize={300}>
        <Content
          loading={loading}
          selected={selected}
          clients={clients}
          data={contentData}
          scenarios={contentScenarios}
          {...props}
          onSelectSpreadsheet={(current, previous) => {
            selectSpreadsheet.next({ current, previous });

            // Propagate the default selection to other areas
            spreadsheetState.setContentSelectedId(current.uniqueId);
            spreadsheetState.setSummarySelectedId(current.uniqueId);
          }}
          onAddSpreadsheet={() => setSpreadsheet({})}
          onEditSpreadsheet={setSpreadsheet}
          onDeleteSpreadsheet={(current, callback) => {
            bannerState.setDeletingBanner();
            deleteSpreadsheet.next({
              spreadsheet: current,
              callback: error => {
                bannerState.clearBanner();
                callback(error);
              },
            });
          }}
          onAddUser={() =>
            setUser({
              clientIds: [selected.uniqueId],
            })
          }
          onEditUser={setUser}
          onDeleteUser={(current, callback) => {
            bannerState.setDeletingBanner();
            deleteUser.next({
              user: current,
              callback: error => {
                bannerState.clearBanner();
                callback(error);
              },
            });
          }}
        />
        <SummaryContainer>
          <Summary loading={loading} />
        </SummaryContainer>
      </Splitter>
      <SpreadsheetDrawer
        spreadsheet={spreadsheet}
        selectObservable={selectSpreadsheet}
        deleteObservable={deleteSpreadsheet}
        onClose={() => setSpreadsheet(null)}
      />
      <UserDrawer
        user={user}
        clients={clients}
        deleteObservable={deleteUser}
        onExistingUser={onExistingUser}
        onClose={() => setUser(null)}
      />
      <ConfirmPassword
        title="Scenario Management"
        visible={!!confirm}
        confirmType="primary"
        confirmText="Confirm"
        onConfirm={(password, callback) => {
          bannerState.setSavingBanner();

          // tslint:disable-next-line: no-floating-promises
          Api.channel
            .put(`/api/model/${contentSpreadsheetId}/scenarios`, {
              scenarios: confirm.scenarios,
              password,
            })
            .then(() => {
              callback();
              confirm.callback();
              setConfirm(null);
            })
            .catch(e => {
              const { response } = e;

              // Parse the response for the error
              let err: string = null;
              if (response.status === 400 && response.data?.reasonCode === 5) {
                err = 'Incorrect password, try again';
              } else {
                err = `Server error: ${e}`;
              }

              callback(err);
              confirm.callback(err);
            })
            .finally(() => {
              bannerState.clearBanner();
            });
        }}
        onCancel={() => setConfirm(null)}
      >
        Enter your password below to update scenario(s) displayed on client account(s):
      </ConfirmPassword>
    </>
  );
};

const initialState: ValuesState = {
  details: {
    dirty: false,
    saveable: false,
    values: {},
  },
  data: {
    dirty: false,
    saveable: false,
    values: {},
  },
  scenarios: {
    dirty: false,
    saveable: false,
    values: {},
  },
  consultants: {
    dirty: false,
    saveable: false,
    values: {},
  },
};

const ClientManagement: React.FC = () => {
  const confirmScenarios = new Subject<{
    scenarios: Scenario[];
    callback: (error?: string) => void;
  }>();

  const history = useHistory();
  const profile = useObservable(() => profileState.profile$, null);
  const clientsLoaded = useObservable(() => clientState.loaded$, false);
  const clientId = useObservable(() => clientState.selectedId$, null);
  const clients = useObservable(() => clientState.clients$, []);
  const contentSpreadsheetId = useObservable(() => spreadsheetState.contentSelectedId$, null);

  const selected = clients.find(current => current.selected);

  const [activeTab, setActiveTab] = React.useState<string>('details');
  const [consultants, setConsultants] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [render, setRender] = React.useState(0);

  const [initialData, setInitialData] = React.useState({
    consultants: [],
    admin: null,
  });

  // A simple hash to track changes and avoid crappy input focus loss
  let state: ValuesState = {
    ...initialState,
    consultants: {
      dirty: false,
      saveable: false,
      values: {
        ...initialData,
        added: [],
        deleted: [],
      },
    },
  };

  // Helpers
  const setConsultantsAndUsers = (consultants: User[], users: User[], admin: User) => {
    // Set the consultants store
    const consultantList = userUtils.getSortedConsultants(consultants);
    const adminId = admin?.uniqueId;

    consultantState.setConsultants(consultantList);
    consultantState.setAdmin(adminId);

    // Set the users store
    const userList = users.filter(
      user => userUtils.isUser(user.userType) && user.clientIds.includes(clientId)
    );

    userState.setUsers(userList);

    setInitialData({
      consultants: consultantList,
      admin: adminId,
    });
  };

  const loadSpreadsheet = (spreadsheetId: string, callback: () => void) => {
    if (spreadsheetId) {
      // tslint:disable-next-line: no-floating-promises
      Promise.all([
        Api.channel.get<{ inputs: Input[] }>(`/api/model/${spreadsheetId}/inputs`),
        Api.channel.get<{ outputs: Output[]; multiOutputs: MultiOutput[] }>(
          `/api/model/${spreadsheetId}/outputs`
        ),
        Api.channel.get<{ scenarios: Scenario[] }>(`/api/model/${spreadsheetId}/scenarios`),
      ])
        .then(([inputsResponse, outputsResponse, scenariosResponse]) => {
          const outputs = outputsResponse.data.outputs.filter(
            ({ name }) => helperUtils.normalize(name) !== helperUtils.normalize('Zone Status')
          );

          dataState.setInputs(spreadsheetId, inputsResponse.data.inputs);
          dataState.setOutputs(spreadsheetId, outputs);
          dataState.setMultiOutputs(spreadsheetId, outputsResponse.data.multiOutputs);
          scenarioState.setScenarios(spreadsheetId, scenariosResponse.data.scenarios);
        })
        .finally(callback);
    }
  };

  const saveDetails = async (resolve?: () => void, reject?: () => void) => {
    bannerState.setSavingBanner();

    try {
      const { values } = state.details;
      detailsState.setError();

      await Api.channel.put(`/api/client/${clientId}`, values);

      clientState.updateClient({
        ...selected,
        ...values,
      });

      detailsState.setValues(values.name, values.address);

      // Ensure the override values are cleared
      state.details = {
        dirty: false,
        saveable: false,
        values: {},
      };

      detailsState.setSaveable(false);

      resolve && resolve();
    } catch (e) {
      detailsState.setError('An error has occurred.');
      reject && reject();
    }

    bannerState.clearBanner();
  };

  const saveData = async (resolve?: () => void, reject?: () => void) => {
    bannerState.setSavingBanner();

    try {
      const { values } = state.data;
      dataState.setError();

      await Api.channel.put(`/api/model/${contentSpreadsheetId}/inputs`, {
        inputs: values.inputs,
      });

      await Api.channel.put(`/api/model/${contentSpreadsheetId}/outputs`, {
        outputs: values.outputs,
        multiOutputs: values.multiOutputs,
      });

      dataState.setInputs(contentSpreadsheetId, values.inputs);
      dataState.setOutputs(contentSpreadsheetId, values.outputs);
      dataState.setMultiOutputs(contentSpreadsheetId, values.multiOutputs);

      // Ensure the override values are cleared
      state.data = {
        dirty: false,
        saveable: false,
        values: {},
      };

      dataState.setSaveable(false);

      resolve && resolve();
    } catch (e) {
      dataState.setError('An error has occurred.');
      reject && reject();
    }

    bannerState.clearBanner();
  };

  const saveScenarios = async (resolve?: () => void, reject?: () => void) => {
    const { values } = state.scenarios;
    scenarioState.setError();

    confirmScenarios.next({
      ...values,
      callback: err => {
        if (!err) {
          scenarioState.setScenarios(contentSpreadsheetId, values.scenarios);

          // Ensure the override values are cleared
          state.scenarios = {
            dirty: false,
            saveable: false,
            values: {},
          };

          scenarioState.setSaveable(false);

          resolve && resolve();
        }
      },
    });
  };

  const saveConsultants = async (resolve?: () => void, reject?: () => void) => {
    bannerState.setSavingBanner();

    try {
      const { values } = state.consultants;
      consultantState.setError();

      await Promise.all([
        ...values.added.map((uniqueId: string) =>
          Api.channel.post(`/api/client/${clientId}/users/${uniqueId}`)
        ),
        ...values.deleted.map((uniqueId: string) =>
          Api.channel.delete<any>(`/api/client/${clientId}/users/${uniqueId}`)
        ),
        Api.channel.post<any>(`/api/client/${clientId}/admin/${values.admin}`),
      ]);

      consultantState.setConsultants(values.consultants);
      consultantState.setAdmin(values.admin);

      // Keep the state values - they are used to build the various lists
      state.consultants = {
        dirty: false,
        saveable: false,
        values: {},
      };

      setInitialData({
        consultants: values.consultants,
        admin: values.admin,
      });

      consultantState.setSaveable(false);

      resolve && resolve();
    } catch (e) {
      consultantState.setError('An error has occurred.');
      reject && reject();
    }

    bannerState.clearBanner();
  };

  const deleteClient = async (password: string, callback: (error?: string) => void) => {
    bannerState.setDeletingBanner();

    try {
      await Api.channel.post('/api/user/validatepassword', { password });
      await Api.channel.delete(`/api/client/${clientId}`);

      callback();

      // Remove the client from the list
      const updated = clients.filter(current => current.uniqueId !== clientId);

      if (updated.length === 0) {
        clientState.setSelectedId(null);
        clientState.setClients([]);

        bannerState.clearBanner();

        history.push(`${routes.admin.newClient}`);
      } else {
        const [first] = updated;

        clientState.setSelectedId(first.uniqueId);
        clientState.setClients(updated);

        bannerState.clearBanner();
      }
    } catch (e) {
      const { response } = e;

      if (response.status === 400 && response.data?.reasonCode === 5) {
        callback('Incorrect password, try again');
      } else {
        callback(`Server error: ${e}`);
      }

      bannerState.clearBanner();
    }
  };

  const reset = () => {
    detailsState.setSaveable(false);
    detailsState.setError();

    dataState.setSaveable(false);
    dataState.setError();

    scenarioState.setSaveable(false);
    scenarioState.setError();

    consultantState.setSaveable(false);
    consultantState.setError();

    // Good ol' hack to get things to reset
    setRender(render + 1);
  };

  React.useEffect(() => {
    // If no clients, then redirect to the new client screen
    if (clientsLoaded && clients.length === 0) {
      history.push(`${routes.admin.newClient}`);
    }
  }, [clients]);

  React.useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    (async () => {
      const response = await Api.channel.get<{ users: User[] }>(`/api/user`);
      const list = userUtils.getSortedConsultants(response.data.users);

      setConsultants(list);
    })();
  }, []);

  React.useEffect(() => {
    setLoading(true);

    state = {
      ...initialState,
    };

    // Initialize the store for the summary to render
    if (selected) {
      (async () => {
        // tslint:disable-next-line: no-floating-promises
        const [
          spreadsheetsResponse,
          consultantsResponse,
          adminResponse,
          usersResponse,
        ] = await Promise.all([
          Api.channel.get<{ models: SpreadsheetData[] }>(`/api/client/${clientId}/models`),
          Api.channel.get<{ users: User[] }>(`/api/client/${clientId}/users`),
          Api.channel.get<{ user: User }>(`/api/client/${clientId}/admin`),
          Api.channel.get<{ users: User[] }>(`/api/user`),
        ]);

        // Set the details store
        detailsState.setValues(selected.name, selected.address);

        // Set the spreadsheets store
        const spreadsheets: Spreadsheet[] = spreadsheetsResponse.data.models.map(model => ({
          uniqueId: model.uniqueId,
          label: model.label,
          notes: model.notes,
          file: {
            name: model.fileName,
          },
          selected: model.default,
        }));

        // Ensure a default spreadsheet is configured
        // If not (legacy data) pick the first
        let defaultSpreadsheet = spreadsheets.find(spreadsheet => spreadsheet.selected);
        if (!defaultSpreadsheet && spreadsheets.length > 0) {
          const [first] = spreadsheets;

          first.selected = true;
          defaultSpreadsheet = first;
        }

        // Mark the default spreadsheet for all selections
        if (defaultSpreadsheet) {
          spreadsheetState.setContentLoading(true);
          spreadsheetState.setSummaryLoading(true);

          loadSpreadsheet(defaultSpreadsheet.uniqueId, () => {
            spreadsheetState.setContentSelectedId(defaultSpreadsheet.uniqueId);
            spreadsheetState.setSummarySelectedId(defaultSpreadsheet.uniqueId);
            spreadsheetState.setContentLoading(false);
            spreadsheetState.setSummaryLoading(false);
            setLoading(false);
          });
        } else {
          setLoading(false);
        }

        spreadsheetState.setSpreadsheets(spreadsheets);

        // Set the consultants and users
        setConsultantsAndUsers(
          consultantsResponse.data.users,
          usersResponse.data.users,
          adminResponse.data.user
        );
      })();
    }
  }, [clientId]);

  return (
    <Layout
      guard={context => {
        if (activeTab === 'details' && state.details.dirty) {
          if (state.details.saveable) {
            context.save = () =>
              new Promise((resolve, reject) => {
                // tslint:disable-next-line: no-floating-promises
                saveDetails(resolve, reject);
              });
          } else {
            context.message = 'Incomplete details detected.';
          }
        } else if (activeTab === 'data' && state.data.dirty) {
          context.save = () =>
            new Promise((resolve, reject) => {
              // tslint:disable-next-line: no-floating-promises
              saveData(resolve, reject);
            });
        } else if (activeTab === 'scenarios' && state.scenarios.dirty) {
          context.save = () =>
            new Promise((resolve, reject) => {
              // tslint:disable-next-line: no-floating-promises
              saveScenarios(resolve, reject);
            });
        } else if (activeTab === 'consultants' && state.consultants.dirty) {
          context.save = () =>
            new Promise((resolve, reject) => {
              // tslint:disable-next-line: no-floating-promises
              saveConsultants(resolve, reject);
            });
        } else {
          context.skip = true;
        }
      }}
    >
      <Container
        loading={loading}
        profile={profile}
        activeTab={activeTab}
        selected={selected}
        clients={clients}
        consultants={consultants}
        confirmScenarios={confirmScenarios}
        state={() => {
          let slice: ValueState = null;
          switch (activeTab) {
            case 'details':
              slice = state.details;
              break;
            case 'data':
              slice = state.data;
              break;
            case 'scenarios':
              slice = state.scenarios;
              break;
            case 'consultants':
              slice = state.consultants;
              break;
          }

          return (
            slice || {
              values: {},
            }
          );
        }}
        onTabChange={tab => {
          reset();
          setActiveTab(tab);
        }}
        onSelect={id => clientState.setSelectedId(id)}
        onChange={values => {
          switch (activeTab) {
            case 'details':
              const { name, address } = values;
              const details: any = {
                name,
                address: addressUtils.transformAddress(address, '\n', ','),
              };

              // Update the state tracking
              const saveable = !!details.name && !!details.address;
              state.details = {
                ...state.details,
                dirty: true,
                saveable,
                values: details,
              };

              detailsState.setSaveable(saveable);
              break;
            case 'data':
              state.data = {
                ...state.data,
                dirty: true,
                values: {
                  ...values,
                },
              };

              dataState.setSaveable(true);
              break;
            case 'scenarios':
              state.scenarios = {
                ...state.scenarios,
                dirty: true,
                values: {
                  ...values,
                },
              };

              scenarioState.setSaveable(true);
              break;
            case 'consultants':
              // Get only the selected consultants
              let list: Consultant[] = userUtils.getSortedConsultants(values.consultants);
              list = list.filter(consultant => consultant.selected);

              // Calculate the changes
              const initialIds = initialData.consultants.map(({ uniqueId }) => uniqueId);
              const listIds = list.map(({ uniqueId }) => uniqueId);

              state.consultants = {
                ...state.consultants,
                dirty: true,
                values: {
                  consultants: list,
                  admin: values.admin,
                  added: listIds.filter(uniqueId => initialIds.indexOf(uniqueId) === -1),
                  deleted: initialIds.filter(uniqueId => listIds.indexOf(uniqueId) === -1),
                },
              };

              consultantState.setSaveable(true);
              break;
          }
        }}
        onSave={() => {
          switch (activeTab) {
            case 'details':
              // tslint:disable-next-line: no-floating-promises
              saveDetails();
              break;
            case 'data':
              // tslint:disable-next-line: no-floating-promises
              saveData();
              break;
            case 'scenarios':
              // tslint:disable-next-line: no-floating-promises
              saveScenarios();
              break;
            case 'consultants':
              // tslint:disable-next-line: no-floating-promises
              saveConsultants();
              break;
          }
        }}
        onCancel={reset}
        onDelete={deleteClient}
        onSpreadsheetLoad={loadSpreadsheet}
        onExistingUser={async () => {
          // Reload the users to get the existing user assignment
          const [consultantsResponse, usersResponse, adminResponse] = await Promise.all([
            Api.channel.get<{ users: User[] }>(`/api/client/${clientId}/users`),
            Api.channel.get<{ users: User[] }>(`/api/user`),
            Api.channel.get<{ user: User }>(`/api/client/${clientId}/admin`),
          ]);

          setConsultantsAndUsers(
            consultantsResponse.data.users,
            usersResponse.data.users,
            adminResponse.data.user
          );
        }}
      />
    </Layout>
  );
};

export default ClientManagement;
