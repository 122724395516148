import { Collapse, Icon } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Button from '../../common/button';
import Content from '../../common/content';
import Flex from '../../common/flex';
import * as clientState from '../../state/clients';
import theme from '../../theme';
import Layout from '../layout';

interface FaqProps {
  heading: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${breakpoint('lg')`
    flex-direction: row;
  `}
`;

const Heading = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;

  ${breakpoint('lg')`
    padding: 2rem 2rem 1rem 2rem;
  `}
`;

const Faqs = styled.div`
  padding: 0 1rem 1rem 1rem;

  ${breakpoint('lg')`
    padding: 0 2rem 2rem 2rem;
  `}
`;

const Panel = styled(Collapse.Panel)`
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.steel};
  border-radius: 0 !important;
  margin-bottom: 0.25rem;

  // Change the styling added by ANTD
  .ant-collapse-header {
    color: ${theme.color.millimanBlue} !important;
    background-color: ${theme.color.white};
    border-radius: 0 !important;
    font-size: ${theme.size.subHeading};
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 1rem !important;
    }

    &.ant-collapse-content-active {
      max-height: 25rem;
      line-height: 1.5;
      overflow-y: scroll;
    }
  }

  &.ant-collapse-item-active {
    .ant-collapse-header {
      color: ${theme.color.white} !important;
      background-color: ${theme.color.millimanBlue};
    }
  }
`;

const SupportContent = styled.div`
  padding: 1rem;

  ${breakpoint('lg')`
    padding: 2rem;
  `}
`;

const SupportSideText = styled.div`
  line-height: 1.5;
`;

const SupportButton = styled(({ className, email }) => (
  <div className={className}>
    <Button type="blue" onClick={() => (location.href = `mailto:${email}`)}>
      Contact
    </Button>
  </div>
))`
  margin-top: 1rem;
`;

const SupportSidebar = styled(Flex.Item)`
  background-color: ${theme.color.panelGrey};
  display: none;
  width: 20rem;
  min-width: 20rem;

  ${breakpoint('lg')`
    border-left: 1px solid ${theme.color.steel};
    display: block;
  `}
`;

const Faq: React.FC<FaqProps> = ({ heading, children }) => (
  <div>
    <strong>{heading}</strong>
    {children}
  </div>
);

const Help: React.FC = () => {
  const email = useObservable(() => clientState.email$, null);
  return (
    <Layout>
      <Container>
        <Flex.Item fill={true}>
          <Heading>
            <Content.Heading>Frequently Asked Questions</Content.Heading>
          </Heading>
          <Faqs>
            <p>Find the answers to the most frequently asked questions below:</p>
            <Collapse
              accordion
              bordered={false}
              defaultActiveKey={1}
              expandIconPosition="right"
              expandIcon={({ isActive }) =>
                isActive ? <Icon type="minus" /> : <Icon type="plus" />
              }
            >
              <Panel key="1" header="Commonly Asked Questions">
                <Faq heading="How do I navigate to a different page?">
                  <p>
                    You can navigate to a different page in Milliman Optic&trade; by selecting one
                    of the options from the navigation side bar on the left.
                  </p>
                </Faq>
                <Faq heading="How do I contact my Milliman consultant?">
                  <p>
                    Contact your Milliman consultant by selecting the contact link located in the
                    footer bar. This will create an email in your default mail client, prepopulated
                    with your consultant’s email address.
                  </p>
                </Faq>
                <Faq heading="Where can I review the Privacy Policy?">
                  <p>A link to our Privacy Policy is located in the footer bar.</p>
                </Faq>
                <Faq heading="Why is there a delay in Optic™ updating once I have made a change?">
                  <p>
                    When you apply a change to the projection tool, Optic&trade; will send your
                    updates to the actuarial calculation tool via the server. Once the calculations
                    are complete the server extracts the data and returns the results to the
                    projection tool. Due to the complexities of the calculations, and the transfer
                    of data, this can take a few seconds to complete.
                  </p>
                </Faq>
                <Faq heading="I have created a new scenario; how do I share it with other users?">
                  <p>
                    Contact your Milliman consultant if you have a scenario that you would like to
                    be reviewed and shared with other users.
                  </p>
                </Faq>
              </Panel>
              <Panel key="2" header="User Settings">
                <Faq heading="Where can I find the user settings tool?">
                  <p>
                    The user settings tool is located at the top right-hand corner of Optic&trade;’s
                    header bar. You access it by selecting the blue circle featuring the person
                    icon.
                  </p>
                </Faq>
                <Faq heading="What is the user settings tool?">
                  <p>
                    The user settings tool provides you with: notifications of the latest changes
                    Milliman has made to your plan, the ability to update your personal settings,
                    switch to another board (if applicable), and logout.
                  </p>
                </Faq>
                <Faq heading="What is the notifications tab for?">
                  <p>
                    Optic&trade; notifies you whenever Milliman makes a change to your account (new
                    scenarios, plans, documents). When this occurs a red circle with the number of
                    updates appears above the user settings dropdown. Selecting this will open the
                    notifications tab where you can explore the latest changes.
                  </p>
                </Faq>
                <Faq heading="How do I update my user details and password?">
                  <p>
                    You can edit your user details and password by opening the user settings
                    dropdown and then selecting the settings tab.
                  </p>
                </Faq>
                <Faq heading="How do I change to a different trustee board?">
                  <p>
                    You can switch between different trustee boards (provided they are managed by
                    Milliman) by using the change board option in the settings tab. This is located
                    in the user settings dropdown.
                  </p>
                </Faq>
                <Faq heading="How do I logout of Optic&trade;?">
                  <p>
                    You can logout of Optic&trade; by selecting the logout button which appears at
                    the bottom of the user settings dropdown.
                  </p>
                </Faq>
              </Panel>
              <Panel key="3" header="Projection Tool">
                <Faq heading="Why can’t I edit the input table when I first log in?">
                  <p>
                    When you log into Optic&trade; for the first time you will be unable to make
                    changes to the input table until you have created a new scenario. To get
                    started, select the copy scenario button which appears at the top of the input
                    table. Selecting this button will open the manage scenarios tab, pre-populated
                    with a scenario to copy. Press the create button to create the new scenario.
                  </p>
                  <p>
                    Once your scenario is created you will need to activate it. To do this check the
                    scenario you would like to activate before pressing the confirm button. This
                    will update Optic&trade; with your selected scenario. You will then be able to
                    make changes to the input table for that scenario.
                  </p>
                  <p>
                    <strong>Please note:</strong> The copy scenario button only appears when you
                    have no editable scenarios available.
                  </p>
                </Faq>
                <Faq heading="Why can’t I activate a scenario?">
                  <p>
                    Optic&trade; allows you to view four scenarios at once. If you already have four
                    scenarios activated you will first need to deactivate a scenario before you can
                    activate a new one. To do this uncheck a scenario from the list of active
                    scenarios and then select a new one. When you are ready, press the confirm
                    button.
                  </p>
                </Faq>
                <Faq heading="How do I edit the input table?">
                  <p>
                    Select the cell that you want to update and make your change. Once you have made
                    all your changes, select the green apply button. This appears at the bottom of
                    the control panel. Optic&trade; will reload and display the results in the graph
                    and data table. Don’t forget to save once you are happy with your changes.
                  </p>
                </Faq>
                <Faq heading="Can I copy and paste my changes across multiple cells?">
                  <p>
                    You can apply your change to all the cells in an input’s column by selecting the
                    green copy down button. This will update all remaining cells to match your
                    change.
                  </p>
                </Faq>
                <Faq heading="How do I view another of my plans?">
                  <p>
                    Select the name of your active plan (appears above the graph) to reveal a list
                    of available plans and click on the plan you want to view. Optic&trade; will
                    then load that plan’s data into the projection tool.
                  </p>
                </Faq>
                <Faq heading="How do I show/hide a scenario on the projection tool?">
                  <p>
                    You can control which scenarios are visible by using the scenario legend
                    (located next to the graph). Toggle the scenario you want to show/hide by
                    selecting the radio button which appears alongside the scenario’s name. When the
                    scenario is visible the radio button will have a dark center. When the scenario
                    is hidden the center will appear light grey.
                  </p>
                </Faq>
                <Faq heading="How can I view different outputs?">
                  <p>
                    The active output’s name is displayed above the graph, directly below the plan
                    name. Select the active output’s name to reveal a list of available outputs.
                    These are split into two categories: single outputs and multiple outputs.
                    Clicking on a new output will cause Optic&trade; to update to reflect your
                    choice.
                  </p>
                  <p>
                    <strong>Please note:</strong> when you select a multiple output the graph will
                    also update to the multiple output graph type.
                  </p>
                </Faq>
                <Faq heading="Can I change the date range?">
                  <p>
                    Select the date range title (below the graph) to reveal the date picker tool.
                    Drag the selectors from left to right to change the years which are shown on the
                    graph and data table. You can manually change the years by using the input boxes
                    which appear at either end of the date picker tool.
                  </p>
                </Faq>
                <Faq heading="What is the control panel?">
                  <p>
                    The control panel contains all the tools you need to create and customize a
                    scenario, edit and run projections, and change the projection tool’s graph type.
                    You also use the control panel to apply, save, and undo your changes, view
                    Optic&trade; in presentation mode, and print out your results using our
                    optimized layout.
                  </p>
                </Faq>
                <Faq heading="Is it possible to make the control panel larger/smaller?">
                  <p>
                    Drag the resize button from left to right to change the width of the control
                    panel. Please note: increasing the size of the control panel will reduce the
                    size of the graph.
                  </p>
                </Faq>
                <Faq heading="Is it possible to make the graph bigger?">
                  <p>
                    You can use the presentation button (located at the bottom of the control panel)
                    to hide the control panel from view. This will increase the size of the graph
                    and data table, making maximum use of your display. Reselecting the presentation
                    button will reveal the control panel.
                  </p>
                </Faq>
                <Faq heading="Why can’t I edit the data table?">
                  <p>
                    The data table provides you with an overview of the data powering the projection
                    tool and is, therefore, not editable. To make changes to the projection tool you
                    will need to edit the input table, located in the control panel.
                  </p>
                </Faq>
                <Faq heading="What do the colored dots in the data table mean?">
                  <p>
                    The colored dots in the data table represent the zone status. The zone status
                    key is located below the data table.
                  </p>
                </Faq>
                <Faq heading="I have made changes to Optic&trade;, why can’t I see them in the graph/data table?">
                  <p>
                    Once you have made changes to the Optic™ input table you will need to select
                    either the apply or save buttons for your changes to be reflected on the
                    graph/data table. Remember saved changes cannot be undone, you should only save
                    your changes once you are happy with the results.
                  </p>
                </Faq>
                <Faq heading="How do I save my changes?">
                  <p>
                    If you are happy with the changes you have made to the projection tool you will
                    need to select the save button to store them in Optic&trade;’s database for
                    future use. The save button appears at the bottom of the control panel.
                  </p>
                </Faq>
                <Faq heading="I have saved Optic&trade; but I have noticed an error – why can’t I undo the change?">
                  <p>
                    When Optic&trade; saves your changes it commits them to the database. This means
                    that any errors saved cannot be undone using Optic&trade;’s undo button.
                  </p>
                </Faq>
                <Faq heading="What is the difference between the apply and save buttons?">
                  <p>
                    Selecting the apply button will update the graph/data table to reflect any
                    changes you have made. Applying a change does not commit it to the database
                    which means you are able to use the undo button to correct a mistake. However,
                    it also means that if you close or logout of Optic&trade; your changes will be
                    lost.
                  </p>
                  <p>
                    When you select the save button it commits your changes to the database which
                    allows you to continue working on your changes in the future.{' '}
                  </p>
                </Faq>
                <Faq heading="How can I undo a change?">
                  <p>
                    If you make a mistake, or want to revert recent changes, select the undo button
                    which appears at the bottom of the control panel. This will revert the
                    projection tool to the latest saved version of Optic&trade;.
                  </p>
                  <p>
                    <strong>Please note:</strong> the undo button will be inactive until you make a
                    change to Optic&trade;.
                  </p>
                </Faq>
                <Faq heading="Why can’t I edit a scenario created by Milliman?">
                  <p>
                    Scenarios created by Milliman have been developed to meet a specific set of
                    requirements. While you cannot change these directly, you are able to create a
                    copy of any scenario and then edit this new version.
                  </p>
                </Faq>
                <Faq heading="How do I create a scenario?">
                  <p>
                    To create a new scenario, select the manage scenarios tab and then click on the
                    new button. This brings up the tools you need to create a new scenario. First
                    give your scenario a name (tip: choose a name that describes the scenario in as
                    few words as possible), then select a scenario you want to use as a starting
                    point. When you are ready, select the create button to finish setting up your
                    new scenario.
                  </p>
                </Faq>
                <Faq heading="Can I rename a scenario?">
                  <p>
                    Yes! To rename a scenario select the manage scenarios tab. Choose the scenario
                    you would like to rename and click on the pencil icon next to it. This will make
                    the scenario’s name editable. Make your change and then press the confirm
                    button. Please note: Milliman scenarios cannot be renamed.
                  </p>
                </Faq>
                <Faq heading="How do I delete a scenario?">
                  <p>
                    To delete a scenario, select the manage scenarios tab and choose the scenario
                    you would like to delete. Clicking on the trash can icon next to it will display
                    a confirmation popup. To proceed with the deletion select the delete button, to
                    cancel select the cancel button. Please note: Milliman scenarios cannot be
                    deleted.
                  </p>
                </Faq>
                <Faq heading="How do I view a different scenario?">
                  <p>
                    While Optic&trade; allows you to view up to four scenarios at one time, you can
                    have many more available to you in the manage scenarios tab. If Optic&trade; has
                    four scenarios active, you will first need to deactivate a scenario before you
                    can select a new one to view. To do this open the manage scenarios tab and
                    uncheck one scenario before selecting the one you would like to view. When you
                    are ready, press the confirm button. This will update Optic&trade; with your
                    selected scenario.
                  </p>
                </Faq>
                <Faq heading="Is it possible to view the graph as a bar chart?">
                  <p>
                    Optic&trade; contains three different graph views: the line graph, the bar
                    graph, and the multiple outputs graph. To switch between these options, select
                    the graph type tab in the control panel and click on the version you would like
                    to view.
                  </p>
                </Faq>
              </Panel>
              <Panel key="4" header="Information Center">
                <Faq heading="How do I sort the available plan resources?">
                  <p>
                    Optic&trade; gives you the ability to sort between: file name, plan, uploaded,
                    types, and format. Select the arrow alongside each of these tiles to change the
                    order in which your files appear.
                  </p>
                </Faq>
                <Faq heading="How do I download files?">
                  <p>
                    Select the checkboxes next to the file(s) that you would like to download and
                    then click on the download button. This button appears below the list of
                    documents available to you. You can download multiple files at once by selecting
                    more than one file at a time.
                  </p>
                </Faq>
                <Faq heading="How do I view more files?">
                  <p>
                    Select the page links (below the list of documents) to view additional files.
                  </p>
                </Faq>
                <Faq heading="How do I search for a file?">
                  <p>
                    Optic&trade; provides you with the ability to search for a specific file using
                    the search tool. This appears in the side bar on the right-hand side of the
                    page. Use the drop-down box to select a document type:
                  </p>
                  <ul>
                    <li>All</li>
                    <li>Actuarial reports</li>
                    <li>Actuarial letters</li>
                    <li>Presentations</li>
                    <li>Other</li>
                  </ul>
                  <p>
                    Once you have selected a document type, start inputting the name of the file you
                    are looking for. Optic&trade; will automatically display a list of possible
                    files to match the file name you are inputting.
                  </p>
                </Faq>
              </Panel>
            </Collapse>
          </Faqs>
        </Flex.Item>
        <SupportSidebar>
          <SupportContent>
            <Content.Heading>Support Contact</Content.Heading>
            <SupportSideText>
              Should you have any questions which are not covered in the FAQs section opposite then
              please contact your Milliman contact for help.
            </SupportSideText>
            <SupportButton email={email} />
          </SupportContent>
        </SupportSidebar>
      </Container>
    </Layout>
  );
};

export default Help;
