import React from 'react';
import { useObservable } from 'rxjs-hooks';

import Button from '../../common/button';
import Flex from '../../common/flex';
import Form from '../../common/form';
import Modal from '../../common/modal';
import { Client, User } from '../../models';
import ConsultantSelect from '../common/consultant-select';
import FormLayout from '../common/form-layout';
import UserForm from '../common/user-form';

import { query } from './state/query';

interface FormProps {
  selected: User;
  consultants: User[];
  clients: Client[];
  onSelect: (consultant: User) => void;
  onUpdate: () => void;
  onDelete: () => void;
}

interface DeleteButtonProps {
  onClick: () => void;
}

const SaveButton: React.FC = () => {
  const saveable = useObservable(() => query.manageSaveable$, false);
  return (
    <Button htmlType="submit" disabled={!saveable}>
      Save
    </Button>
  );
};

const DeleteButton: React.FC<DeleteButtonProps> = ({ onClick }) => {
  const [showDeleteWarning, setShowDeleteWarning] = React.useState(false);
  return (
    <>
      <Button type="red" onClick={() => setShowDeleteWarning(true)}>
        Delete Consultant
      </Button>
      <Modal
        title="Delete Consultant"
        visible={showDeleteWarning}
        onOk={() => {
          setShowDeleteWarning(false);
          onClick();
        }}
        okText="Delete"
        okType="danger"
        onCancel={() => setShowDeleteWarning(false)}
      >
        <p>
          <FormLayout.WarningMessage>Warning:</FormLayout.WarningMessage> Deleting this consultant
          will remove them from the system and cannot be restored.
        </p>
        <p>Are you sure you want to continue?</p>
      </Modal>
    </>
  );
};

const Error: React.FC = () => {
  const message = useObservable(() => query.manageError$, null);
  return message ? <FormLayout.Error message={message} /> : null;
};

class ManageForm extends Form<FormProps, {}> {
  render(): React.ReactNode {
    const { selected, consultants, clients, form, onSelect, onDelete } = this.props;
    return (
      <FormLayout.Form onSubmit={e => this.handleSubmit(e)}>
        <FormLayout.Heading>Select Consultant</FormLayout.Heading>
        <FormLayout.Field label="Consultant:">
          <ConsultantSelect
            selectedId={selected?.uniqueId}
            consultants={consultants}
            onChange={id => {
              const consultant = consultants.find(consultant => consultant.uniqueId === id);
              onSelect(consultant);
            }}
          />
        </FormLayout.Field>
        <FormLayout.Heading>Consultant Details</FormLayout.Heading>
        <UserForm type="admin" currentUser={selected} clients={clients} form={form} />
        <Error />
        <FormLayout.Buttons>
          <Flex.Item>
            <SaveButton />
          </Flex.Item>
          <Flex.Item>
            <DeleteButton onClick={onDelete} />
          </Flex.Item>
        </FormLayout.Buttons>
      </FormLayout.Form>
    );
  }

  private handleSubmit(e: React.FormEvent<HTMLElement>): void {
    e.preventDefault();

    // Trigger the onUpdate handler
    const { form, onUpdate } = this.props;
    form.validateFields(err => {
      if (!err && onUpdate) {
        onUpdate();
      }
    });
  }
}

export default ManageForm;
