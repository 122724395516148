import { Spreadsheet } from '../../../models';

import { query } from './query';
import { store } from './store';

// Expose an API for others to consume
export const contentSelectedId$ = query.contentSelectedId$;
export const summarySelectedId$ = query.summarySelectedId$;
export const contentLoading$ = query.contentLoading$;
export const summaryLoading$ = query.summaryLoading$;
export const spreadsheets$ = query.spreadsheets$;

export const setContentSelectedId = (uniqueId: string) =>
  store.update({
    contentSelectedId: uniqueId,
  });

export const setSummarySelectedId = (uniqueId: string) =>
  store.update({
    summarySelectedId: uniqueId,
  });

export const setContentLoading = (loading: boolean) =>
  store.update({
    contentLoading: loading,
  });

export const setSummaryLoading = (loading: boolean) =>
  store.update({
    summaryLoading: loading,
  });

export const setSpreadsheets = (spreadsheets: Spreadsheet[]) => store.setSpreadsheets(spreadsheets);
