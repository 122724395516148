import React from 'react';
import { Observable } from 'rxjs';
import { useObservable } from 'rxjs-hooks';

import Button from '../../common/button';
import Flex from '../../common/flex';
import FormLayout from '../common/form-layout';

interface ButtonsProps {
  savableObservable: Observable<boolean>;
  onCancel: () => void;
}

const Buttons: React.FC<ButtonsProps> = ({ savableObservable, onCancel }) => {
  const saveable = useObservable(() => savableObservable, false);
  return (
    <FormLayout.Buttons>
      <Flex.Item>
        <Button htmlType="submit" disabled={!saveable}>
          Save
        </Button>
      </Flex.Item>
      <Flex.Item>
        <Button type="light-grey" secondary={true} onClick={onCancel}>
          Cancel
        </Button>
      </Flex.Item>
    </FormLayout.Buttons>
  );
};

export default Buttons;
