import { Table as TableBase } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import theme from '../theme';

interface ListTableColumn {
  key: string;
  title: string;
  field?: string;
  width?: number;
  align?: 'left' | 'right' | 'center';
  render?: (data: any) => React.ReactNode;
}

interface ListTableProps {
  className?: string;
  rowKey: string;
  columns: ListTableColumn[];
  data: any[];
  empty?: React.ReactNode;
  height?: string;
}

const Container = styled.div`
  position: relative;
  padding: 0.25rem 0.25rem 0 0.25rem;
`;

const Table = styled(props => <TableBase {...props} />)`
  position: relative;
  z-index: 2;

  th {
    border-bottom: 0 !important;
  }

  td {
    background: none !important;
    border-bottom: 0 !important;
    vertical-align: top;
  }

  .ant-table-column-title {
    color: ${theme.color.millimanBlue};
  }

  .ant-table-placeholder {
    border: 0;
    margin: 2px 1px;
  }

  ${({ placeholder }) =>
    !placeholder &&
    css`
      .ant-table-placeholder {
        display: none;
      }
    `}
`;

const Border = styled.div`
  border: 1px solid ${theme.color.steel};
  border-radius: ${theme.border.radius};
  position: absolute;
  top: 30px;
  right: 0;
  bottom: -1px;
  left: 0;
  z-index: 1;
`;

const EmptyContainer = styled.div`
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;
`;

const ListTable: React.FC<ListTableProps> = ({
  className,
  rowKey,
  columns,
  data,
  empty,
  height,
}) => {
  const x = '100%';
  const y = height && data.length > 0 ? height : null;
  const cols = columns.map(({ field, ...column }) => ({
    ...column,
    dataIndex: field,
    ellipsis: true,
  }));

  return (
    <Container className={className}>
      <Table
        rowKey={rowKey}
        size="small"
        bordered={false}
        pagination={false}
        columns={cols}
        dataSource={data}
        scroll={{ x, y }}
        placeholder={!empty}
      />
      <Border />
      {empty && data.length === 0 && <EmptyContainer>{empty}</EmptyContainer>}
    </Container>
  );
};

export default ListTable;
