import { Store, StoreConfig } from '@datorama/akita';

import storageUtils from '../../../../utils/storage';
import { PANEL_ID_KEY, SPLIT_KEY } from '../storage';

import { CommonState, ModelData } from './models';

@StoreConfig({
  name: 'Projection Tool > Common',
})
export class CommonStore extends Store<CommonState> {
  constructor() {
    super({
      modelId: null,
      panelId: null,
      split: null,
      models: [],
      reload: 0,
    });
  }

  initialize(models: ModelData[]): void {
    const split = storageUtils.get(SPLIT_KEY);

    this.update({
      panelId: storageUtils.get(PANEL_ID_KEY),
      split: split ? +split : null,
      models: models.map(model => ({
        id: model.uniqueId,
        name: model.label,
        default: model.default,
        clientId: model.clientId,
      })),
    });
  }

  setModelId(id: string): void {
    this.update({
      modelId: id,
    });
  }

  setPanelId(id: string): void {
    storageUtils.set(PANEL_ID_KEY, id);
    this.update({
      panelId: id,
    });
  }

  setSplit(size: number): void {
    storageUtils.set(SPLIT_KEY, `${size}`);
    this.update({
      split: size,
    });
  }

  setReload(): void {
    this.update(state => {
      this.update({ reload: ++state.reload });
    });
  }
}

export const store = new CommonStore();
