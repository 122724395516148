import { Button as ButtonBase } from 'antd';
import { ButtonProps as ButtonPropsBase } from 'antd/lib/button';
import React from 'react';
import styled from 'styled-components';
import { css } from 'styled-components';

import theme from '../theme';

interface InvariantTypes {
  green: string;
  blue: string;
  red: string;
  orange: string;
  'dark-blue': string;
  'light-grey': string;
  'dark-grey': string;
  'light-blue': string;
}

const Invariants: InvariantTypes = {
  green: theme.color.spring,
  blue: theme.color.vibrantBlue,
  red: theme.color.cranberry,
  orange: theme.color.gold,
  'dark-blue': theme.color.millimanBlue,
  'dark-grey': theme.color.charcoal,
  'light-grey': theme.color.lightGrey,
  'light-blue': theme.color.sky,
};

interface ButtonProps extends Omit<ButtonPropsBase, 'type'> {
  type?: keyof InvariantTypes;
  deactivated?: boolean;
  secondary?: boolean;
}

const InvariantButton = styled(({ invariant, deactivated, secondary, disabled, ...props }) => (
  <ButtonBase disabled={disabled && !deactivated} {...props} />
))`
  ${({ deactivated }) =>
    deactivated &&
    css`
      color: ${theme.color.slate};
      background-color: ${theme.color.steel};
      border: 2px solid ${theme.color.steel};

      &:hover {
        color: ${theme.color.slate};
        background-color: ${theme.color.steel};
        border: 2px solid ${theme.color.steel};
      }

      &:active {
        color: ${theme.color.slate};
        background-color: ${theme.color.steel};
        border: 2px solid ${theme.color.steel};
      }

      &:focus {
        color: ${theme.color.slate};
        background-color: ${theme.color.steel};
        border: 2px solid ${theme.color.steel};
      }
    `}

  ${({ invariant, deactivated }) =>
    !deactivated &&
    css`
      color: ${theme.color.white};
      background-color: ${invariant};
      border: 2px solid ${invariant};

      &:hover {
        color: ${invariant};
        background-color: ${theme.color.white};
        border: 2px solid ${invariant};
      }

      &:active {
        color: ${theme.color.white};
        background-color: ${invariant};
        border: 2px solid ${invariant};
      }

      &:focus {
        color: ${theme.color.white};
        background-color: ${invariant};
        border: 2px solid ${invariant};
      }
    `}
    ${({ invariant, deactivated, secondary }) =>
      !deactivated &&
      secondary &&
      css`
        color: ${invariant};
        background-color: ${theme.color.white};
        border: 2px solid ${invariant};

        &:hover {
          color: ${theme.color.white};
          background-color: ${invariant};
          border: 2px solid ${invariant};
        }

        &:active {
          color: ${invariant};
          background-color: ${theme.color.white};
          border: 2px solid ${invariant};
        }

        &:focus {
          color: ${invariant};
          background-color: ${theme.color.white};
          border: 2px solid ${invariant};
        }
      `}
`;

const Button: React.FC<ButtonProps> = ({ type, secondary, ...props }) => {
  // Get the invariant settings based on the requested type
  // If not resolved, fall back to dark-blue
  const invariant = Invariants[type] || Invariants['dark-blue'];
  return <InvariantButton invariant={invariant} secondary={secondary} {...props} />;
};

export default Button;
