import { Icon as IconBase } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

import ButtonBase from '../../common/button';
import Flex from '../../common/flex';
import ListTable from '../../common/list-table';
import theme from '../../theme';
import { Spreadsheet } from '../models';

interface SpreadsheetListProps {
  className?: string;
  spreadsheets?: Spreadsheet[];
  deleteIcon?: (spreadsheet: Spreadsheet, callback: () => void) => React.ReactNode;
  onSelect?: (spreadsheet: Spreadsheet, previous?: Spreadsheet) => void;
  onAdd: () => void;
  onEdit: (spreadsheet: Spreadsheet) => void;
  onChange?: (spreadsheet: Spreadsheet[]) => void;
}

interface StarCellProps {
  spreadsheet: Spreadsheet;
  spreadsheets: Spreadsheet[];
  onSelect: (spreadsheet: Spreadsheet, previous?: Spreadsheet) => void;
  onChange: (spreadsheet: Spreadsheet[]) => void;
}

interface LabelCellProps {
  spreadsheet: Spreadsheet;
}

interface ActionsCellProps {
  spreadsheet: Spreadsheet;
  spreadsheets: Spreadsheet[];
  deleteIcon: (spreadsheet: Spreadsheet, callback: () => void) => React.ReactNode;
  onSelect: (spreadsheet: Spreadsheet, previous?: Spreadsheet) => void;
  onEdit: (spreadsheet: Spreadsheet) => void;
  onChange: (spreadsheet: Spreadsheet[]) => void;
}

const Empty = styled(props => (
  <Flex.Row {...props}>
    <Flex.Item fill={true}>
      <Label>Uploaded spreadsheets will appear here.</Label>
      <Instructions>
        You can also set the default spreadsheet, review notes, edit or delete spreadsheets.
      </Instructions>
    </Flex.Item>
  </Flex.Row>
))`
  line-height: 1rem;
`;

const Icon = styled(IconBase)`
  cursor: pointer;
`;

const Star = styled(({ selected, ...props }) => (
  <Icon type="star" theme={selected ? 'filled' : 'outlined'} {...props} />
))`
  color: ${theme.color.steel};

  ${({ selected }) =>
    selected &&
    css`
      color: ${theme.color.spring};
    `}
`;

const Edit = styled(props => <Icon type="edit" {...props} />)`
  color: ${theme.color.millimanBlue};
  margin-left: 1rem;
`;

const Label = styled.div`
  font-weight: ${theme.weight.medium};
`;

const Instructions = styled.div`
  margin-top: 0.5rem;
`;

const Notes = styled.div`
  color: ${theme.color.darkGrey};
  margin-top: 0.5rem;
`;

const Actions = styled.div`
  white-space: nowrap;
`;

const Button = styled(props => (
  <ButtonBase type="blue" {...props}>
    Add Spreadsheet
  </ButtonBase>
))`
  margin-top: 0.5rem;
`;

const StarCell: React.FC<StarCellProps> = ({ spreadsheet, spreadsheets, onSelect, onChange }) => (
  <Star
    selected={spreadsheet.selected}
    onClick={() => {
      const previous = spreadsheets.find(item => item.selected);

      // Update the selected state
      let items = spreadsheets || [];
      items = spreadsheets.map(item => ({
        ...item,
        selected: item.uniqueId === spreadsheet.uniqueId,
      }));

      if (onChange) {
        onChange(items);
      }

      if (onSelect) {
        const selected = items.find(item => item.selected);
        onSelect(selected, previous);
      }
    }}
  />
);

const LabelCell: React.FC<LabelCellProps> = ({ spreadsheet }) => (
  <>
    <div>{spreadsheet.label}</div>
    {spreadsheet.notes && <Notes>{spreadsheet.notes}</Notes>}
  </>
);

const ActionsCell: React.FC<ActionsCellProps> = ({
  spreadsheet,
  spreadsheets,
  deleteIcon,
  onSelect,
  onEdit,
  onChange,
}) => (
  <Actions>
    <Edit onClick={() => onEdit(spreadsheet)} />
    {deleteIcon &&
      deleteIcon(spreadsheet, () => {
        let items = spreadsheets || [];
        items = items.filter(item => item.uniqueId !== spreadsheet.uniqueId);

        // Ensure a default is always selected
        const selected = items.find(current => current.selected);
        if (!selected && items.length > 0) {
          const [first] = items;

          // Update the selected state
          items = items.map(item => ({
            ...item,
            selected: item.uniqueId === first.uniqueId,
          }));

          // If a new selection is made, allow any handlers to do their twerk
          if (onSelect) {
            onSelect(first);
          }
        }

        if (onChange) {
          onChange(items);
        }
      })}
  </Actions>
);

const SpreadsheetList: React.FC<SpreadsheetListProps> = ({
  className,
  spreadsheets,
  deleteIcon,
  onSelect,
  onAdd,
  onEdit,
  onChange,
}) => {
  const columns: any[] = [
    {
      key: 'selected',
      title: 'Default',
      width: 63,
      align: 'center',
      render: (spreadsheet: Spreadsheet) => (
        <StarCell
          spreadsheet={spreadsheet}
          spreadsheets={spreadsheets}
          onSelect={onSelect}
          onChange={onChange}
        />
      ),
    },
    {
      key: 'label',
      title: 'Label',
      render: (spreadsheet: Spreadsheet) => <LabelCell spreadsheet={spreadsheet} />,
    },
    {
      key: 'actions',
      title: '',
      width: 85,
      align: 'right',
      render: (spreadsheet: Spreadsheet) => (
        <ActionsCell
          spreadsheet={spreadsheet}
          spreadsheets={spreadsheets}
          deleteIcon={deleteIcon}
          onSelect={onSelect}
          onEdit={onEdit}
          onChange={onChange}
        />
      ),
    },
  ];

  return (
    <div className={className}>
      <ListTable rowKey="uniqueId" columns={columns} data={spreadsheets} empty={<Empty />} />
      <Button onClick={onAdd} />
    </div>
  );
};

export default SpreadsheetList;
