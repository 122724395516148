import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import Area from './area';
import Child from './child';
import Guard, {
  GuardFunction as GuardFunctionBase,
  GuardFunctionInvoker as GuardFunctionInvokerBase,
  useGuard as useGuardBase,
} from './guard';
import NotFound from './not-found';
import Page from './page';

interface Location {
  pathname: string;
}

interface RouterProps {
  defaultTo?: string;
}

interface LocationProps {
  children: (location: Location) => React.ReactElement;
}

interface NavigateProps {
  children: (navigate: (path: string) => void) => React.ReactElement;
}

export type GuardFunction = GuardFunctionBase;
export type GuardFunctionInvoker = GuardFunctionInvokerBase;
export const useGuard = useGuardBase;

export default class extends React.Component<RouterProps> {
  static Child = Child;
  static Area = Area;
  static Page = Page;
  static Redirect = Redirect;
  static Guard = Guard;

  static Location: React.FC<LocationProps> = ({ children }) => {
    const location = useLocation();
    return children(location);
  };

  static Navigate: React.FC<NavigateProps> = ({ children }) => {
    const history = useHistory();
    return children(path => history.push(path));
  };

  render(): React.ReactNode {
    const { defaultTo, children } = this.props;
    return (
      <>
        <Router>
          <Switch>
            {children}
            {defaultTo && <Redirect exact from="/" to={defaultTo} />}
            <Route render={() => <NotFound />} />
          </Switch>
        </Router>
      </>
    );
  }
}
