import { Layout } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import LogoIcon from '../assets/milliman-logo.svg';
import Flex from '../common/flex';
import * as clientState from '../state/clients';
import theme from '../theme';

interface FooterProps {
  showTerms: boolean;
}

const Content = styled(Flex.Row)`
  padding: 0.25rem 1rem;

  ${breakpoint('md')`
    padding: 0.25rem 2rem;
  `}
`;

const Logo = styled(LogoIcon)`
  height: 1.5rem;
  width: 6.5rem;

  * {
    fill: ${theme.color.white} !important;
  }

  ${breakpoint('md')`
    height: 2rem;
  `}
`;

const Link = styled.div`
  color: ${theme.color.white};
  font-size: ${theme.size.small};
  margin: 0 0.25rem;

  a {
    color: ${theme.color.white};

    &:hover {
      color: ${theme.color.vibrantBlue};
    }
  }

  ${breakpoint('md')`
    font-size: ${theme.size.base};
    margin: 0 0 0 2rem;
  `}
`;

const Footer: React.FC<FooterProps> = ({ showTerms }) => {
  const email = useObservable(() => clientState.email$, null);
  return (
    <Layout.Footer>
      <Content align="middle" justify="space-between">
        <Flex.Item>
          <a
            href="https://us.milliman.com/en/retirement-and-benefits/multiemployer-plans"
            target="_blank"
          >
            <Logo />
          </a>
        </Flex.Item>
        <Flex.Item>
          <Flex.Row>
            {showTerms && (
              <Flex.Item>
                <Link>
                  <a href="https://www.milliman.com/en/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                </Link>
              </Flex.Item>
            )}
            {email && (
              <Flex.Item>
                <Link>
                  <a href={`mailto:${email}`}>Contact</a>
                </Link>
              </Flex.Item>
            )}
          </Flex.Row>
        </Flex.Item>
      </Content>
    </Layout.Footer>
  );
};

export default Footer;
