import { InputNumber, Slider as SliderBase } from 'antd';
import React from 'react';
import { useObservable } from 'rxjs-hooks';
import styled from 'styled-components';

import CaretBase from '../../../common/caret';
import Flex from '../../../common/flex';
import theme from '../../../theme';
import { query as commonQuery } from '../common/state/query';

import { query } from './state/query';
import { store } from './state/store';

interface YearRangeState {
  startYear: number;
  endYear: number;
}

const Container = styled.div`
  margin: 1rem 0 2rem 0;
  padding: 0 0.5rem;
`;

const Toggle = styled(Flex.Row)`
  cursor: pointer;
  font-weight: ${theme.weight.medium};
`;

const Caret = styled(({ className, rotate }) => (
  <Flex.Item className={className}>
    <CaretBase rotate={rotate} />
  </Flex.Item>
))`
  padding: 2px 0 0 0.5rem;
`;

const Slider = styled(SliderBase)`
  .ant-slider-handle {
    &:focus {
      border-color: #004877;
    }

    &::after {
      content: ' ';
      border-top: 0.25rem solid ${theme.color.millimanBlue};
      border-left: 0.25rem solid transparent;
      border-right: 0.25rem solid transparent;
      position: absolute;
      top: 9px;
      left: -1px;
      width: 0;
      height: 0;
    }
  }
`;

const Input = styled(InputNumber)`
  width: 5rem;

  input {
    text-align: center;
  }

  &:hover {
    input {
      text-align: left;
    }
  }
`;

const Panel = styled(({ className, children }) => <div className={className}>{children}</div>)`
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

const handleInput = (
  value: number,
  minYear: number,
  maxYear: number,
  callback: (year: number) => void
) => {
  const year = +value;
  if (year >= minYear && year <= maxYear && callback) {
    callback(year);
  }
};

const YearRange: React.FC = () => {
  const startYear = useObservable(() => query.startYear$);
  const endYear = useObservable(() => query.endYear$);
  const minYear = useObservable(() => query.minYear$);
  const maxYear = useObservable(() => query.maxYear$);
  const modelIdForYears = useObservable(() => query.modelIdForYears$);
  const modelId = useObservable(() => commonQuery.modelId$);

  const [open, setOpen] = React.useState<boolean>(false);
  if (!minYear || !maxYear) {
    return null;
  }

  return (
    <Container>
      <Flex.Row justify="space-around">
        <Flex.Item>
          <Toggle align="middle" onClick={() => setOpen(!open)}>
            <Flex.Item>Date range</Flex.Item>
            <Caret rotate={open} />
          </Toggle>
        </Flex.Item>
      </Flex.Row>
      <Panel open={open}>
        <Slider
          range
          value={[startYear, endYear]}
          min={minYear}
          max={maxYear}
          tooltipVisible={false}
          onChange={(ev: any) => {
            const [start, end] = ev;
            store.setStartYear(start, modelId);
            store.setEndYear(end, modelId);
          }}
        />
        <Flex.Row justify="space-between">
          <Flex.Item>
            <Input
              type="number"
              value={startYear}
              min={minYear}
              max={endYear}
              onChange={value =>
                handleInput(value, minYear, endYear, year => store.setStartYear(year, modelId))
              }
            />
          </Flex.Item>
          <Flex.Item>
            <Input
              type="number"
              value={endYear}
              min={startYear}
              max={maxYear}
              onChange={value =>
                handleInput(value, startYear, maxYear, year => store.setEndYear(year, modelId))
              }
            />
          </Flex.Item>
        </Flex.Row>
      </Panel>
    </Container>
  );
};

export default YearRange;
