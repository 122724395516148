import InfoCenterSvg from '../../assets/icon-info-center.svg';

import Icon from './icon';
import { IconProps } from './icon';

export default class extends Icon {
  constructor(props: IconProps) {
    super(InfoCenterSvg, props);
  }
}
