import { Icon, Spin as SpinBase } from 'antd';
import React from 'react';
import styled from 'styled-components';

import Flex from './flex';

interface LoaderProps {
  className?: string;
}

const Spin = styled(SpinBase)`
  margin-right: 0.5rem;
  width: 2.5rem;
`;

const Loader: React.FC<LoaderProps> = ({ className }) => {
  const loader = <Icon type="loading" spin />;
  return (
    <Flex.Row className={className} align="middle" justify="center">
      <Flex.Item>
        <Spin indicator={loader} />
      </Flex.Item>
      <Flex.Item>Loading...</Flex.Item>
    </Flex.Row>
  );
};

export default Loader;
