import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';

import { Profile } from '../../models';

import { ProfileState } from './models';
import { ProfileStore, store } from './store';

export class ProfileQuery extends Query<ProfileState> {
  readonly profile$: Observable<Profile> = this.select(state => ({ ...state }));

  constructor(_store: ProfileStore) {
    super(_store);
  }
}

export const query = new ProfileQuery(store);
