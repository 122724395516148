import React from 'react';
import ChartComponent from 'react-chartjs-2';
import { useObservable } from 'rxjs-hooks';

import chartUtils from './common/chart';
import * as graphTypeState from './graph-types/state';
import * as graphState from './graph/state';
import * as outputState from './outputs/state';

interface PrintChartProps {
  outputType: outputState.OutputType;
  graphType: graphTypeState.GraphTypeId;
  years: number[];
  data: graphState.GraphData[];
}

const PrintChart: React.FC<PrintChartProps> = ({ outputType, graphType, years, data }) => {
  const stateRef = useObservable(() => graphState.ref$, 0);
  return (
    <ChartComponent
      key={stateRef}
      datasetKeyProvider={dataset => dataset.datasetId}
      data={chartUtils.getData(outputType, years, data)}
      options={{
        ...chartUtils.getOptions(outputType, graphType),
        tooltips: {
          enabled: false,
        },
      }}
    />
  );
};

export default PrintChart;
