import React from 'react';

import { HelpIcon, HomeIcon, InfoCenterIcon, ProjectionToolIcon } from '../common/icons';
import { routes } from '../constants';
import Layout from '../layout';
import theme from '../theme';

const Navbar: React.FC = () => (
  <Layout.Navbar>
    <Layout.Navbar.Item
      icon={HomeIcon}
      color={theme.color.putty}
      label="Home"
      route={routes.app.home}
      height="4rem"
      width="4rem"
      iconStyle={color => HomeIcon.style(color)}
    />
    <Layout.Navbar.Item
      icon={ProjectionToolIcon}
      color={theme.color.vibrantBlue}
      label="Projection Tool"
      route={routes.app.projectionTool}
      height="4rem"
      width="4rem"
      iconStyle={color => ProjectionToolIcon.style(color)}
    />
    <Layout.Navbar.Item
      icon={InfoCenterIcon}
      color={theme.color.cranberry}
      label="Information Center"
      route={routes.app.informationCenter}
      height="4rem"
      width="4rem"
      iconStyle={color => InfoCenterIcon.style(color)}
    />
    <Layout.Navbar.Item
      icon={HelpIcon}
      color={theme.color.gold}
      label="Help"
      route={routes.app.help}
      height="4rem"
      width="4rem"
      iconStyle={color => HelpIcon.style(color)}
    />
  </Layout.Navbar>
);

export default Navbar;
