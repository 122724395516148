import { Icon } from 'antd';
import React from 'react';
import { combineLatest } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { map } from 'rxjs/operators';
import styled from 'styled-components';

import Button from '../../common/button';
import Flex from '../../common/flex';
import FormBase from '../../common/form';
import { Client } from '../../models';
import theme from '../../theme';
import ClientForm from '../common/client-form';
import SpreadsheetListBase from '../common/spreadsheet-list';
import { Spreadsheet } from '../models';

import { query } from './state/query';
import Wizard from './wizard';

interface StepOneProps {
  name: string;
  address: string;
  spreadsheets: Spreadsheet[];
  onChange: (values: any) => void;
  onAddSpreadsheet: () => void;
  onEditSpreadsheet: (spreadsheet: Spreadsheet) => void;
  onDeleteSpreadsheet: (spreadsheet: Spreadsheet, callback: () => void) => void;
  onCancel: () => void;
  onNext: () => void;
}

interface FormProps extends StepOneProps {}

const SpreadsheetDelete = styled(props => <Icon type="delete" {...props} />)`
  color: ${theme.color.cranberry};
  margin-left: 1rem;
`;

const SpreadsheetList = React.forwardRef<any, any>((props, _) => (
  <SpreadsheetListBase {...props} />
));

const NextButton: React.FC = () => {
  const completed = useObservable(
    () =>
      combineLatest([query.name$, query.address$, query.spreadsheets$]).pipe(
        map(([name, address, spreadsheets]) => !!name && !!address && spreadsheets.length > 0)
      ),
    false
  );

  return (
    <Button type="dark-blue" htmlType="submit" disabled={!completed}>
      Next Step
    </Button>
  );
};

class Form extends FormBase<FormProps, {}> {
  private readonly SPREADSHEETS_FIELD_NAME = 'spreadsheets';

  render(): React.ReactNode {
    const { name, address, form, onCancel } = this.props;
    const client: Client = {
      uniqueId: null,
      name,
      address,
      hasAcceptedTerms: false,
      selected: false,
    };

    return (
      <>
        <Wizard.Form onSubmit={ev => this.handleFormSubmit(ev)}>
          <Wizard.Section title="1. Client details">
            <ClientForm client={client} form={form} inputLayout={Wizard.inputLayout} />
          </Wizard.Section>
          <Wizard.Section title="2. Client spreadsheets">
            <Wizard.TableListField label="Uploaded spreadsheets:">
              {this.renderSpreadsheetsField()}
            </Wizard.TableListField>
          </Wizard.Section>
          <Wizard.Buttons>
            <Flex.Item>
              <NextButton />
            </Flex.Item>
            <Flex.Item>
              <Button type="light-grey" secondary={true} onClick={onCancel}>
                Cancel
              </Button>
            </Flex.Item>
          </Wizard.Buttons>
        </Wizard.Form>
      </>
    );
  }

  private renderSpreadsheetsField(): React.ReactNode {
    const {
      form,
      spreadsheets,
      onAddSpreadsheet,
      onEditSpreadsheet,
      onDeleteSpreadsheet,
    } = this.props;

    const decorator = form.getFieldDecorator(this.SPREADSHEETS_FIELD_NAME, {
      initialValue: spreadsheets,
      valuePropName: 'spreadsheets',
      rules: [
        {
          validator: (_: any, __: string, callback: (error: string) => void) =>
            this.validateSpreadsheets(callback),
        },
      ],
    });

    return decorator(
      <SpreadsheetList
        deleteIcon={(spreadsheet: Spreadsheet, callback: () => void) => (
          <SpreadsheetDelete onClick={() => onDeleteSpreadsheet(spreadsheet, callback)} />
        )}
        onAdd={onAddSpreadsheet}
        onEdit={onEditSpreadsheet}
      />
    );
  }

  private validateSpreadsheets(callback: (error?: string) => void): void {
    const { getFieldValue } = this.props.form;
    const spreadsheets = getFieldValue(this.SPREADSHEETS_FIELD_NAME);

    if (spreadsheets.length > 0) {
      callback();
    } else {
      callback('A spreadsheet is required');
    }
  }

  private handleFormSubmit(ev: React.FormEvent<HTMLFormElement>): void {
    ev.preventDefault();

    // Validate the fields and move to next step
    const { form, onNext } = this.props;
    form.validateFields(err => {
      if (!err) {
        onNext();
      }
    });
  }
}

const StepOne: React.FC<StepOneProps> = ({ onChange, ...props }) => (
  <Wizard.Step title="Step One">{FormBase.renderType(Form, props, onChange)}</Wizard.Step>
);

export default StepOne;
