import { Client } from '../../models';

import { ClientData } from './models';
import { query } from './query';
import { store } from './store';

export { ClientData };

// Expose an API for others to consume
export const selectedId$ = query.selectedId$;
export const loaded$ = query.loaded$;
export const clients$ = query.clients$;
export const email$ = query.email$;

export const initialize = (clients: ClientData[]) => store.initialize(clients);

export const setClients = (clients: Client[]) => store.setClients(clients);

export const setSelectedId = (uniqueId: string) => store.setSelectedId(uniqueId);

export const setEmail = (email: string) =>
  store.update({
    email,
  });

export const updateClient = (client: Client) => store.upsert(client.uniqueId, client);
