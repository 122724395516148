import HelpSvg from '../../assets/icon-help.svg';

import Icon from './icon';
import { IconProps } from './icon';

export default class extends Icon {
  constructor(props: IconProps) {
    super(HelpSvg, props);
  }
}
